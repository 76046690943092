import {ProcessTranscriptFunction} from '@autocut/types/Transcription';
import {compute} from '@autocut/utils/compute.utils';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {setAutocutStore} from '@autocut/utils/zustand';

export const handleViralClipsProcessTranscript: ProcessTranscriptFunction =
  async (intl, usedModel = 0) => {
    const processProgress = initProcessProgress(
      'viralClips_transcript',
      'progress_steps_transcript_mainProcess',
    );
    addProcessSteps(processProgress, [
      {
        id: 'caption_transcript_get_language',
        translationKey: 'progress_steps_caption_transcript_get_language',
        progress: 1,
      },
      {
        id: 'chapters_transcript_mainProcess',
        translationKey: 'progress_steps_transcript_mainProcess',
        progress: '',
        countFor: 4,
      },
    ]);

    startProcessProgress(processProgress);

    const parameters = getParametersForMode('viral_clips');

    const language = getTranscriptionLanguage({
      languageOfTranscriptionValue: parameters.languageOfTranscription.value,
      usedModel,
    });
    endProcessStep(processProgress, 'caption_transcript_get_language');

    const transcriptProcess = compute.process.getViralClips({
      skipEmbedding: true,
      language,
    });

    updateProcessStep(processProgress, 'viral_transcript_mainProcess', {
      progress: {
        computeTaskId: transcriptProcess.requestId,
      },
    });

    const {intervals: result, filePath} = await transcriptProcess;
    const timelineInfos = await compute.timeline.getInfos();

    setAutocutStore('onGoingProcess.viralClips', result);
    setAutocutStore('onGoingProcess.audioFilepath', filePath);
    setAutocutStore('onGoingProcess.timelineInfos', timelineInfos);
    endProcessStep(processProgress, 'caption_transcript_mainProcess');
  };
