import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {manageError} from '@autocut/utils/manageError';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import {captionPresetTitleSchema} from '@autocut/validationSchemas/caption.validationSchema';
import {AxiosError} from 'axios';
import React, {useState} from 'react';
import {IoCreateOutline} from 'react-icons/io5';
import {useIntl} from 'react-intl';
import {ZodError} from 'zod';

import {AnimatedCanvasType} from '../../../utils/canvas/classes/animatedCanvas.class.utils';
import {DraggableCanvas} from '../../../utils/canvas/classes/draggableCanvas.class.utils';
import {createCaptionsPreset} from '../../../utils/captionsPresets/createCaptionsPreset';
import css from './CreatePresetModal.module.scss';

export const CreatePresetModal = () => {
  const intl = useIntl();

  const {captionsParameters} = useAutoCutStore(state => ({
    captionsParameters: state.ui.parameters.caption,
  }));
  const [newPresetTitle, setNewPresetTitle] = useState<string>('');
  const [isPublic, setIsPublic] = useState<boolean>(true);
  const [error, setError] = useState<React.ReactNode | undefined>(undefined);
  const [textPreviewCanvas, setTextPreviewCanvas] = useState<
    AnimatedCanvasType<DraggableCanvas> | undefined
  >(undefined);

  const handleCreate = async (presetTitle: string, isPublic: boolean) => {
    try {
      captionPresetTitleSchema.parse(presetTitle);
    } catch (e: any) {
      const firstIssue =
        (e as ZodError).issues[0].message ??
        'validation_error_caption_title_unknown';
      setError(
        <TranslatedMessage
          id={firstIssue as any}
          defaultMessage="An unknown error occurred while validating the title."
        />,
      );

      return;
    }

    try {
      const newPreset = await createCaptionsPreset({
        captionsParameters,
        title: presetTitle,
        isPublic,
        canvas: textPreviewCanvas,
      });

      if (newPreset === null) throw new Error('Could not create preset');

      if (await handleTrialTask('preset'))
        addModalToQueue(ModalType.GamingTrial);

      setAutocutStore('ui.parameters.caption.userPresets', newPreset);

      closeModal();
    } catch (e: any) {
      if (e.message === 'preset-already-exists') {
        setError(
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_presets_create_alreadyExists'
            }
            defaultMessage="Preset already exists. Choose a different name."
          />,
        );
        return;
      }

      manageError({
        error: new IncrementalError(
          e as AxiosError<
            {
              message: string;
              statusCode: number;
            },
            any
          >,
          'createCaptionsPreset',
        ),
      });

      textPreviewCanvas?.destroy();
      closeModal();
    }
  };

  return (
    <>
      <Modal
        icon={
          <IoCreateOutline
            color="#eb5b26"
            size="1.5rem"
          />
        }
        title={
          <TranslatedMessage
            id={'modes_caption_steps_customization_parts_presets_create_title'}
            defaultMessage="Create new preset"
          />
        }
      >
        {/* {isPublic && !!newPresetTitle.length && (
          <div style={{display: 'none'}}>
            <ExampleCaptionText
              key={`captions-text-preview`}
              boundingBox={{
                height: TEXT_PREVIEW_HEIGHT,
                width: TEXT_PREVIEW_WIDTH,
              }}
              height={TEXT_PREVIEW_HEIGHT}
              setCanvas={setTextPreviewCanvas}
              displayedText={newPresetTitle}
            />
          </div>
        )} */}
        <FlexContainer
          flexDirection="column"
          gap={24}
          style={{
            minWidth: '300px',
          }}
        >
          <FlexContainer
            flexDirection="column"
            gap={8}
            style={{
              width: '300px',
            }}
          >
            <FlexContainer
              flexDirection="column"
              gap={2}
            >
              <Input
                itemType="text"
                value={newPresetTitle}
                onChange={value => setNewPresetTitle(value)}
                placeholder={intl.formatMessage({
                  id: 'modes_caption_steps_customization_parts_presets_create_placeholder',
                  defaultMessage: 'Preset name (at least two words)',
                })}
              />

              {error && (
                <p
                  style={{
                    color: 'red',
                  }}
                >
                  {error}
                </p>
              )}
            </FlexContainer>

            <FlexContainer
              flexDirection="row"
              flexWrap="nowrap"
              gap={24}
              alignItems="center"
            >
              <p
                style={{
                  whiteSpace: 'nowrap',
                }}
              >
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_presets_create_community'
                  }
                  defaultMessage="Share to community"
                />
              </p>

              <Switch
                checked={isPublic}
                onChange={setIsPublic}
                size={10}
                rightLabel={
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_presets_create_public'
                    }
                    defaultMessage="Public"
                  />
                }
              />
            </FlexContainer>
          </FlexContainer>

          <div className={css.buttonsContainer}>
            <Button
              onClick={() => closeModal()}
              className={css.button}
              variant="secondary"
            >
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_presets_create_cancel'
                }
                defaultMessage="Cancel"
              />
            </Button>

            <Button
              onClick={() => handleCreate(newPresetTitle, isPublic)}
              className={css.button}
              color={colors.primary600}
            >
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_presets_create_confirm'
                }
                defaultMessage="Create preset"
              />
            </Button>
          </div>
        </FlexContainer>
      </Modal>
    </>
  );
};
