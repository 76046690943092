import {ColorKeys, colors} from '@autocut/designSystem/colors';
import React from 'react';

import css from './Text.module.scss';
import {TextVariant} from './variants';

export type TextType = {
  children: React.ReactNode;
  variant?: TextVariant;
  color?: ColorKeys | string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Text = ({
  children,
  variant = 'textMd',
  color = colors.gray300,
  className,
  style,
  ...props
}: TextType) => {
  return (
    <div
      data-variant={variant}
      className={`${css.root} ${className}`}
      style={{color, ...style}}
      {...props}
    >
      {children}
    </div>
  );
};
