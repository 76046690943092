import {SentryLogLevel} from '@autocut/enums/sentryLogLevel.enum';
import * as Sentry from '@sentry/browser';
import {SeverityLevel} from '@sentry/react';
// import { autocutStoreVanilla } from './zustand';
import logLevel from '@autocut/enums/logLevel.enum';
import {preload} from '@autocut/types/ElectronPreload';

import {safeJSONStringify} from './logger';
import {autocutStoreVanilla} from './zustand';

export const reportSentryError = (
  fingerPrint: string | string[],
  error: Error,
  context: {[key: string]: any} = {},
  level = logLevel.error,
  scopeModifier?: (scope: Sentry.Scope) => void,
) => {
  Sentry.withScope(scope => {
    scope.setFingerprint(
      Array.isArray(fingerPrint) ? fingerPrint : [fingerPrint],
    );

    scope.addAttachment({
      filename: 'error_context.json',
      data: safeJSONStringify({
        context,
        state: autocutStoreVanilla(),
      }),
    });

    const logFiles = [
      'autocut.aea.log',
      'autocut.compute.log',
      'autocut.front.log',
      'autocut.host.log',
    ];

    const tempFolderPath =
      preload().electron.getSharedVariables().TEMP_FOLDER_PATH;

    logFiles.forEach(file => {
      const logFilePath = preload().path.join(tempFolderPath, file);
      if (preload().fs.existsSync(logFilePath)) {
        scope.addAttachment({
          filename: file,
          data: preload().fs.readFileSync(logFilePath, 'utf-8'),
        });
      }
    });

    scope.setLevel(getSentryLogLevel(level));

    scopeModifier?.(scope);

    scope.setTag('mode', autocutStoreVanilla().ui.process.mode.id);
    scope.setTag('host', autocutStoreVanilla().ui.host);

    Sentry.captureException(new Error(`${fingerPrint}: ${error.message}`));
  });
};

export const reportSentryMessage = (
  fingerPrint: string,
  context: {[key: string]: any} = {},
  level = logLevel.info,
) => {
  Sentry.withScope(scope => {
    scope.setFingerprint([fingerPrint]);
    scope.addAttachment({
      filename: 'message_context.json',
      data: JSON.stringify({context, state: autocutStoreVanilla()}),
    });
    scope.setLevel(getSentryLogLevel(level));

    Sentry.captureMessage(fingerPrint);
  });
};

export const logSentryUser = ({
  id,
  pcName,
  uuid,
  email,
}: {
  id: string;
  pcName: string;
  uuid: string;
  email: string;
}) => {
  Sentry.setUser({id: id, pcName: pcName, uuid: uuid, email: email});
};

export const logOutSentryUser = () => {
  Sentry.setUser(null);
};

export const getSentryLogLevel = (level: logLevel): SeverityLevel => {
  switch (level) {
    case logLevel.crit:
      return SentryLogLevel.FATAL;
    case logLevel.error:
      return SentryLogLevel.ERROR;
    case logLevel.warn:
      return SentryLogLevel.WARNING;
    case logLevel.info:
      return SentryLogLevel.INFO;
    case logLevel.debug:
      return SentryLogLevel.DEBUG;
    case logLevel.notice:
      return SentryLogLevel.LOG;
    default:
      return SentryLogLevel.LOG;
  }
};
