import React from 'react';
import {IoIosCheckmark} from 'react-icons/io';

import css from './CheckBox.module.css';

export type CheckBoxProps = {
  size: number;
  checked: boolean;
  onChange?: (checked: boolean) => void;
};

export const CheckBox = ({size, checked, onChange}: CheckBoxProps) => {
  return (
    <label
      className={css.container}
      style={{
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <input
        className={css.input}
        type="checkbox"
        checked={checked}
        onChange={onChange as any}
      />
      <div className={css.checkbox} />
      <div className={css.checkmark}>
        <IoIosCheckmark
          size={size}
          color="white"
        />
      </div>
    </label>
  );
};
