import {preload} from '@autocut/types/ElectronPreload';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import axios from 'axios';

import {requestResourceOnInit} from '../downloadableRessources.utils';

export const getDavinciExtension = () => {
  const {os, path, electron, fs, resources} = preload();
  const platform = os.platform() === 'win32' ? 'windows' : 'mac';

  const platforms = {
    mac: {
      versionFilename:
        CURRENT_ENV === Env.Production ? 'AutoCut.lua' : 'AutoCutStaging.lua',
      folderPath: path.join(
        os.homedir(),
        'Library',
        'Application Support',
        'Blackmagic Design',
        'DaVinci Resolve',
        'Fusion',
        'Scripts',
        'Utility',
      ),
      downloadUrl: path.joinUrl(
        electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
          'autocut-lua-script.zip',
      ),
    },
    windows: {
      versionFilename:
        CURRENT_ENV === Env.Production ? 'AutoCut.lua' : 'AutoCutStaging.lua',
      folderPath: path.join(
        'C:',
        'ProgramData',
        'Blackmagic Design',
        'DaVinci Resolve',
        'Fusion',
        'Scripts',
        'Utility',
      ),
      downloadUrl: path.joinUrl(
        electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
          'autocut-lua-script.zip',
      ),
    },
  };

  const checkIfUpToDate = async (versionFileContent: string) => {
    const version = versionFileContent
      .split(/\r?\n/)[0]
      .match(/--version="(\S*)"/)?.[1];

    if (!version) return true;

    const response = await axios.get(
      path.joinUrl(
        electron.getSharedVariables().DAVINCI_DOWNLOAD_URL + 'version',
      ),
    );
    const data = response.data;

    const outdated = data.toString() !== version.toString();

    return outdated;
  };

  return requestResourceOnInit({
    fileName: 'da_vinci_lua_script',
    downloadMethod: async () => {
      return (await resources.download(platforms[platform].downloadUrl)) || '';
    },
    move: async (downloadedFilePath: string) => {
      await resources.unzip(
        downloadedFilePath,
        path.join(platforms[platform].folderPath, ''),
      );

      return platforms[platform].folderPath;
    },
    existCheck: async () => {
      if (!fs.existsSync(platforms[platform].folderPath)) {
        // If the folder does not exist, we don't need to download the script
        return {isExist: true, outputFilePath: platforms[platform].folderPath};
      }

      if (!platforms[platform].versionFilename || !checkIfUpToDate)
        return {isExist: false, outputFilePath: platforms[platform].folderPath};

      const versionFilePath = path.join(
        platforms[platform].folderPath,
        platforms[platform].versionFilename || '',
      );
      if (!fs.existsSync(versionFilePath)) {
        return {isExist: false, outputFilePath: versionFilePath};
      }
      const versionFileContent = fs
        .readFileSync(versionFilePath, 'utf-8')
        .trim();

      const outdated = await checkIfUpToDate(versionFileContent);
      if (outdated) {
        return {isExist: false, outputFilePath: versionFilePath};
      }
      return {isExist: true, outputFilePath: versionFilePath};
    },
    scope: 'global',
  })();
};
