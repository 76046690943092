import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {CARD_SIZE} from '@autocut/pages/Homepage/utils';
import {preload} from '@autocut/types/ElectronPreload';
import {CSSProperties, useEffect, useRef} from 'react';

import css from './HomepageCard.module.scss';
import {rotateToMouse} from './utils';

export type HomepageCardProps = {
  disabled?: boolean;
  upgrade?: boolean;
  showTrialbanner?: boolean;
  additionalText?: string | JSX.Element;
  text: string;
  description: React.ReactNode;
  thumbnail: string;
  onClick: () => void;
};

export const HomepageCard = ({
  disabled,
  upgrade,
  additionalText,
  text,
  description,
  thumbnail,
  onClick,
}: HomepageCardProps) => {
  const key = useAutoCutStore(state => state.user.key);
  const cardRef = useRef<HTMLDivElement>(null);

  const handleOnClick = async () => {
    if (disabled) {
      if (upgrade)
        await preload().electron.openLinkInBrowser(
          `https://autocut.fr/app/&upgrade=${key}`,
        );
      return;
    }

    onClick();
  };

  useEffect(() => {
    if (!cardRef.current || disabled) return;
    const bounds = cardRef.current.getBoundingClientRect();

    const mousemoveHandler = (e: MouseEvent) =>
      rotateToMouse(e, cardRef.current as HTMLDivElement, bounds);

    cardRef.current.addEventListener('mouseenter', () => {
      document.addEventListener('mousemove', mousemoveHandler);
    });

    cardRef.current.addEventListener('mouseleave', () => {
      document.removeEventListener('mousemove', mousemoveHandler);
      cardRef.current?.style.removeProperty('transform');

      const glowElement = cardRef.current?.querySelector(
        'span.glow',
      ) as HTMLDivElement | null;
      if (!glowElement) return;
      glowElement.style.backgroundImage = '';
    });

    return () => {
      document.removeEventListener('mousemove', mousemoveHandler);
    };
  }, []);

  const classNames = [css.card, disabled ? css.disabled : null]
    .filter(Boolean)
    .join(' ');

  return (
    <FlexContainer
      id={text}
      ref={cardRef}
      onClick={handleOnClick}
      className={classNames}
      flexDirection="column"
      gap={8}
      style={{
        ...({
          '--card-size': `${CARD_SIZE}px`,
          '--border-radius': '8px',
        } as CSSProperties),
      }}
    >
      <div
        className={css.thumbnail}
        style={{
          backgroundImage: `url(${thumbnail})`,
        }}
      />

      <FlexContainer
        flexDirection="column"
        gap={8}
        className={css.cardContent}
        justifyContent="flex-start"
      >
        <div className={css.text}>{text}</div>

        <p className={css.description}>{description}</p>
      </FlexContainer>

      <span className="glow" />

      {additionalText ? (
        <div className={css.additionalContainer}>
          {typeof additionalText === 'string' ? (
            <div className={css.additionalText}>{additionalText}</div>
          ) : (
            additionalText
          )}
        </div>
      ) : null}
    </FlexContainer>
  );
};
