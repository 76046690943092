import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

import {ChaptersFooterButton} from './steps/ChaptersFooterButton/ChaptersFooterButton';
import {ColorStep} from './steps/ColorStep/ColorStep';
import {TimecodesStep} from './steps/TimecodesStep/TimecodesStep';
import {YoutubeButton} from './steps/youtube/YoutubeButton';

export const ChaptersEditor = () => {
  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <ChaptersFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
              handleClickWithValidation={handleClickWithValidation}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <TimecodesStep />
        <YoutubeButton />
        <Divider />
        <ColorStep />
      </FlexContainer>
    </ModeLayout>
  );
};
