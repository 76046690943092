import {ModalType} from '@autocut/enums/modals.enum';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {
  CaptionsParameters,
  CaptionsPreset,
} from '@autocut/types/CaptionsParameters';
import {syncSelectedFont} from '@autocut/utils/fonts.utils';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {merge} from 'lodash';

import {removeUnwantedKeysFromCaptionsParameters} from '../removeUnwantedKeysFromCaptionsParameters';

export const encodeCaptionParams = (params: CaptionsParameters) => {
  const stringifiedParams = JSON.stringify(params);

  const encodedParams = btoa(encodeURIComponent(stringifiedParams));

  return encodedParams;
};

export const decodeCaptionParams = (
  encodedParams: string,
): CaptionsParameters => {
  const decodedParams = decodeURIComponent(atob(encodedParams));

  const params = JSON.parse(decodedParams);

  return params;
};

export const handleApplyPreset = (preset: CaptionsPreset) => {
  const currentParameters = autocutStoreVanilla().ui.parameters.caption;

  //Deep clone with readonly modifier removed
  const defaultParameters = JSON.parse(
    JSON.stringify(AutocutModes.Captions.defaultParameters),
  );

  const formattedDefaultParams =
    removeUnwantedKeysFromCaptionsParameters(defaultParameters);
  const formattedPresetParams = removeUnwantedKeysFromCaptionsParameters(
    preset.params as CaptionsParameters,
  );

  const finalParameters = merge(
    JSON.parse(JSON.stringify(currentParameters)),
    formattedDefaultParams,
    formattedPresetParams,
  );

  const syncedFont = syncSelectedFont(finalParameters.text.font);

  if (!syncedFont) {
    addModalToQueue(ModalType.FontNotFound);
  } else {
    finalParameters.text.font = syncedFont;
  }

  setAutocutStore('ui.parameters.caption', finalParameters);
};
