import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import * as React from 'react';

import css from './footerLayout.module.css';

export type FooterLayoutProps = {
  children: React.ReactNode;
};

export const FooterLayout = ({children}: FooterLayoutProps) => {
  const {modalName} = useAutoCutStore(state => ({
    modalName: state.ui.openedModalName,
  }));

  if (modalName === ModalType.Processing) {
    return null;
  }

  return (
    <div
      className={css.container}
      id="legacy-step6-podcast-step7"
    >
      <div className={css.innerContainer}>{children}</div>
    </div>
  );
};
