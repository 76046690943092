import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import {
  localBlurEffectName,
  localMotionEffectName,
  localOpacityEffectName,
  localTextEffectName,
  localTransformEffectName,
} from '@autocut/enums/videoEffect.enum';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';

export const INITIAL_COPIED_VIDEO_FILTER_COMPONENTS = [
  localTextEffectName[CAPTIONS_XML_PROJECT_LOCALE],
  localOpacityEffectName[CAPTIONS_XML_PROJECT_LOCALE],
];

export const getSelectedTransitions = (parameters: CaptionsParameters) => {
  const transitionsVideoFilterComponents: string[] = [];
  const isTransitionEnabled = parameters.transition.enabled;
  const transitions = parameters.transition.effects ?? [];
  if (!isTransitionEnabled || !transitions.length)
    return transitionsVideoFilterComponents;

  transitionsVideoFilterComponents.push(
    localMotionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
  );

  if (transitions.includes('zoomIn'))
    transitionsVideoFilterComponents.push(
      localTransformEffectName[CAPTIONS_XML_PROJECT_LOCALE],
    );
  if (transitions.includes('blurIn'))
    transitionsVideoFilterComponents.push(
      localBlurEffectName[CAPTIONS_XML_PROJECT_LOCALE],
    );

  return transitionsVideoFilterComponents;
};
