import {preload} from '@autocut/types/ElectronPreload';

export const getBlobUrlFromFilepath = (outpath: string) => {
  try {
    const data = preload().fs.readFileSync(outpath);
    const blob = new Blob([data]);
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
  } catch (err) {
    throw new Error('Could not read jpg');
  }
};
