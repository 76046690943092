import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {manageError} from '@autocut/utils/manageError';

import {AutoCutApiError} from '../errors/AutoCutApiError';
import {autocutApi} from '../http.utils';
import {setAutocutStore} from '../zustand';

export const getScoreFromApi = async () => {
  const res = await autocutApi
    .get(`/score`)
    .catch((error: AutoCutApiError | IncrementalError) => {
      manageError({
        error: new IncrementalError(error, 'getScoreFromApi'),
      });
      return;
    });

  if (res?.status === 200) {
    setAutocutStore('game.level.currentProgress', res.data);

    return res.data;
  }
};
