import * as React from 'react';
import {useEffect, useState} from 'react';

export type ArrowTipProps = {
  variant: 'primary' | 'secondary' | 'orange';
  height: number;
  direction: 'right' | 'left' | 'top' | 'bottom';
};

const ArrowTip = ({variant, height, direction}: ArrowTipProps) => {
  const getRotationDeg = React.useCallback(() => {
    switch (direction) {
      case 'right':
        return 0;
      case 'left':
        return 180;
      case 'top':
        return -90;
      case 'bottom':
        return 90;
    }
  }, [direction]);
  const [rotationDeg, setRotationDeg] = useState<number>(getRotationDeg());

  useEffect(() => {
    setRotationDeg(getRotationDeg());
  }, [direction]);

  return (
    <svg
      height={`${height}px`}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 13 23"
      transform={`rotate(${rotationDeg} 0 0)`}
    >
      <path
        stroke={
          variant == 'primary'
            ? '#F8FAFC'
            : variant == 'orange'
              ? '#e64e19'
              : '#0F0F0E'
        }
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.7"
        d="m2 2 9 10-9 9"
      />
    </svg>
  );
};

export default ArrowTip;
