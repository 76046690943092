import chroma from 'chroma-js';

import {SELECT_COLORS} from './SelectStyle';

export const SELECT_VARIANTS = [
  'default',
  'trackSelection',
  'speakerSelection',
  'bleepSelect',
] as const;

export type SelectVariant = (typeof SELECT_VARIANTS)[number];

type styleProps = {
  data: {[key: string]: any};
  [key: string]: any;
};

export const getVariant = (variant: SelectVariant) => {
  return {
    container: (state: styleProps) => ({
      ...(variant === 'trackSelection' ? {width: 'fit-content'} : {}),
    }),
    control: (state: styleProps) => ({
      ...(variant === 'trackSelection'
        ? {
            padding: '6px 6px',
            backgroundColor: '#ffffff0d',
            borderColor: 'transparent !important',
          }
        : {}),
      ...(variant === 'bleepSelect' ? {width: '150px'} : {}),
    }),
    dropdownIndicator: (state: styleProps) => ({}),
    indicatorsContainer: (state: styleProps) => ({}),
    indicatorSeparator: (state: styleProps) => ({}),
    clearIndicator: (state: styleProps) => ({}),
    valueContainer: (state: styleProps) => ({
      ...(variant === 'trackSelection' ? {marginRight: '4px'} : {}),
    }),
    input: (state: styleProps) => ({}),
    placeholder: (state: styleProps) => ({}),
    singleValue: (state: styleProps) => ({}),
    menu: (state: styleProps) => ({}),
    menuList: (state: styleProps) => ({}),
    option: (state: styleProps) => ({}),
    multiValue: (state: styleProps) => {
      const color =
        state.data.index !== undefined
          ? chroma(
              SELECT_COLORS[
                state.data.index >= SELECT_COLORS.length
                  ? SELECT_COLORS.length - 1
                  : state.data.index
              ],
            )
          : undefined;

      return {
        ...(variant === 'speakerSelection'
          ? {...(color ? {backgroundColor: color.css()} : {})}
          : {}),
      };
    },
    multiValueLabel: (state: styleProps) => ({}),
    multiValueRemove: (state: styleProps) => {
      const color =
        state.data.index !== undefined
          ? chroma(SELECT_COLORS[state.data.index % SELECT_COLORS.length])
          : undefined;

      return {
        ...(variant === 'speakerSelection'
          ? {
              ':hover': {
                ...(color ? {color: color.css()} : {}),
                backgroundColor: 'white',
              },
            }
          : {}),
      };
    },
  };
};
