import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useScroll} from '@autocut/hooks/useScroll';
import {HTMLAttributes, ReactNode, useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import FlexContainer from '../../molecules/FlexContainer';
import {IconChevronDown} from '../Icon/arrows/IconChevronDown';
import {IconXClose} from '../Icon/general/IconXClose';
import {Input} from '../Input/Input';
import PopupPortal from '../PopupPortal/PopupPortal';
import {Item} from './Item/Item';
import css from './Select.module.scss';
import {SelectVariant} from './variants';

export type SelectOption = {
  value: string;
  label: ReactNode;
  valueInDropdown?: ReactNode;
  color?: string;
};

export type SelectProps = {
  variant?: SelectVariant;
  placeholder?: ReactNode;
  fullWidth?: boolean;
  clearable?: boolean;
  options: SelectOption[];
  maxHeightDropdown?: number;
  filterUsedOptions?: boolean;
  isSearchable?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'onChange' | 'placeholder'> &
  (
    | {
        allowMultiple: true;
        selected?: SelectOption['value'][];
        onChange: (value: SelectOption['value'][]) => void;
        allowSelectAll?: boolean;
      }
    | {
        allowMultiple?: false;
        selected?: SelectOption['value'];
        onChange: (value: SelectOption['value'] | undefined) => void;
        allowSelectAll?: undefined;
      }
  );

export const Select = ({
  variant = 'primary',
  allowSelectAll = false,
  allowMultiple,
  fullWidth = false,
  clearable = false,
  placeholder,
  onChange,
  selected,
  options,
  style,
  maxHeightDropdown = 180,
  filterUsedOptions,
  isSearchable = false,
}: SelectProps) => {
  const intl = useIntl();
  const {addOnScroll, removeOnScroll} = useScroll();
  const [id, _] = useState(Math.random().toString(36).substring(7));
  const [isOpen, setIsOpen] = useState(false);
  const [selectRef, setSelectRef] = useState<HTMLElement | null>(null);
  const [position, setPosition] = useState<'top' | 'bottom'>('bottom');
  const [filterValue, setFilterValue] = useState('');

  const selectedArray = useMemo(() => {
    if (selected === undefined) return [];

    return Array.isArray(selected) ? selected : [selected];
  }, [selected]);

  const filteredOptions = useMemo(() => {
    let filteredOptions = options;

    if (isSearchable)
      filteredOptions = filteredOptions.filter(option =>
        option.label
          ?.toString()
          .toLocaleLowerCase()
          .startsWith(filterValue.toLocaleLowerCase()),
      );

    if (selectedArray.length === 0) return filteredOptions;

    if (!filterUsedOptions && !allowMultiple) return filteredOptions;

    filteredOptions = filteredOptions.filter(
      option => !selectedArray.includes(option.value),
    );

    return filteredOptions;
  }, [
    options,
    isSearchable,
    selectedArray,
    filterUsedOptions,
    allowMultiple,
    filterValue,
  ]);

  const handleSelect = (value: SelectOption['value']) => {
    if (allowMultiple === true) {
      if (selectedArray.includes(value)) {
        onChange(
          selectedArray.filter(selectedOption => selectedOption !== value),
        );
        return;
      } else {
        onChange([...selectedArray, value]);
      }
    } else {
      onChange(value);
      close();
    }
  };

  const handleSelectAll = () => {
    if (!allowMultiple) return;

    onChange(options.map(option => option.value));
    close();
  };

  const close = () => {
    setIsOpen(false);
    setFilterValue('');
  };

  const innerContainerStyle = isOpen
    ? position === 'bottom'
      ? {borderRadius: `${Spacing.s1} ${Spacing.s1} 0 0`}
      : {borderRadius: `0 0 ${Spacing.s1} ${Spacing.s1}`}
    : {borderRadius: Spacing.s1};

  const optionsStyle = {
    ...(position === 'bottom'
      ? {
          borderTop: 'none',
          borderRadius: `0 0 ${Spacing.s1} ${Spacing.s1}`,
        }
      : {
          borderBottom: 'none',
          borderRadius: `${Spacing.s1} ${Spacing.s1} 0 0`,
        }),
    maxHeight: maxHeightDropdown,
  };

  useEffect(() => {
    const getPosition = () => {
      const windowHeight = window.innerHeight;
      const selectRect = selectRef?.getBoundingClientRect();
      const selectBottom = selectRect ? selectRect.bottom : 0;

      if (maxHeightDropdown + selectBottom <= windowHeight) {
        setPosition('bottom');
      } else {
        setPosition('top');
      }
    };

    addOnScroll(id, getPosition);
    getPosition();

    return () => removeOnScroll(id);
  }, [maxHeightDropdown, selectRef]);

  return (
    <>
      <div
        data-variant={variant}
        id={`designSelect.${id}`}
        style={{
          width: fullWidth ? '100%' : 'fit-content',
          height: 'fit-content',
          zIndex: isOpen ? 1 : 0,
          ...style,
        }}
        className={`${css.container} ${isOpen ? css.open : ''}`}
        ref={setSelectRef}
      >
        <FlexContainer
          gap={8}
          className={css.innerContainer}
          onClick={() => setIsOpen(prev => !prev)}
          alignItems="center"
          justifyContent="space-between"
          style={innerContainerStyle}
        >
          {/* Actual value(s) */}
          <FlexContainer
            gap={Spacing.s1}
            alignItems="center"
            flexWrap="wrap"
          >
            {selectedArray.length > 0 ? (
              selectedArray.map((option, index) =>
                allowMultiple ? (
                  <Item
                    variant={variant}
                    key={index}
                    isBadge={true}
                    onDelete={() => handleSelect(option)}
                    color={options.find(o => o.value === option)?.color}
                  >
                    {options.find(o => o.value === option)?.label}
                  </Item>
                ) : (
                  <Item
                    variant={variant}
                    key={index}
                    isBadge={false}
                  >
                    {options.find(o => o.value === option)?.label}
                  </Item>
                ),
              )
            ) : (
              <Item
                variant={variant}
                isPlaceholder
                isBadge={false}
              >
                {placeholder || (
                  <TranslatedMessage
                    id="globals_defaults_components_Select_placeholder"
                    defaultMessage="Select an option"
                  />
                )}
              </Item>
            )}
          </FlexContainer>

          {/* Chevron & clear all buttons */}
          <FlexContainer
            gap={Spacing.s1}
            flexShrink={false}
          >
            <IconChevronDown
              className={css.chevron}
              size={16}
              color={isOpen ? 'white' : colors.gray400}
            />
            {clearable && selectedArray.length !== 0 && (
              <IconXClose
                className={css.close}
                size={16}
                color={colors.gray400}
                onClick={event => {
                  event.stopPropagation();

                  if (!allowMultiple) {
                    onChange(undefined);
                    return;
                  } else {
                    onChange([]);
                  }

                  close();
                }}
              />
            )}
          </FlexContainer>
        </FlexContainer>

        {/* Dropdown list */}
        {isOpen && selectRef && (
          <PopupPortal
            target={selectRef}
            position={position}
          >
            <div
              data-variant={variant}
              className={css.options}
              style={{
                ...optionsStyle,
                width: selectRef.getBoundingClientRect().width,
              }}
            >
              {isSearchable && (
                <Input
                  onChange={value => setFilterValue(value)}
                  style={{
                    border: 'unset',
                    borderBottom: `1px solid ${colors.gray500}`,
                  }}
                  placeholder={intl.formatMessage({
                    id: 'globals_defaults_components_Select_search',
                    defaultMessage: 'Search',
                  })}
                />
              )}
              {filteredOptions.length === 0 ? (
                <div className={css.option}>
                  <Item
                    variant={variant}
                    isBadge={false}
                    isPlaceholder
                  >
                    <TranslatedMessage
                      id="globals_defaults_components_Select_no-more"
                      defaultMessage="No more options available"
                    />
                  </Item>
                </div>
              ) : (
                filteredOptions.map((option, index) => (
                  <div
                    className={css.option}
                    key={index}
                    onClick={() => handleSelect(option.value)}
                  >
                    <Item
                      variant={variant}
                      isBadge={false}
                    >
                      {option.valueInDropdown ?? option.label}
                    </Item>
                  </div>
                ))
              )}
              {allowSelectAll &&
                allowMultiple &&
                filteredOptions.length !== 0 && (
                  <div
                    className={css.option}
                    onClick={handleSelectAll}
                  >
                    <Item
                      variant={variant}
                      isBadge={false}
                    >
                      <TranslatedMessage
                        id="globals_defaults_components_Select_select-all"
                        defaultMessage="Select all"
                      />
                    </Item>
                  </div>
                )}
            </div>
          </PopupPortal>
        )}
      </div>
      {/* Div covering the whole screen, used to close the dropdown when click is outside*/}
      <div
        data-variant={variant}
        className={`${css.overlay} ${isOpen ? css.open : ''}`}
        onClick={() => close()}
      />
    </>
  );
};
