import type {ResizedXMLTemplates} from './_resizeTemplate';

import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import {localPositionEffectName} from '@autocut/enums/videoEffect.enum';
import {CaptionChunk} from '@autocut/types/Captions';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import {getLineYOffset} from './_getLineYOffset';

export const handleTextPosition = ({
  chunk,
  parameters,
  templates,
  position: {x: xPosition, y: yPosition},
}: {
  chunk: CaptionChunk;
  parameters: CaptionsParameters;
  templates: ResizedXMLTemplates;
  position: {x: number; y: number};
}) => {
  const blockXOffset = 0;
  const blockYOffset =
    -getLineYOffset({
      fontSize: parameters.text.fontSize,
      nbLines: chunk.nbLines,
      indexLine: chunk.nbLines - 1,
      maxLineHeight: parameters.text.maxLineHeight ?? 0,
    }) / 2;

  const blockPositionX = xPosition + blockXOffset;
  const blockPositionY = yPosition + blockYOffset;
  const blockPositionXPercentage =
    blockPositionX * templates.factors.finalXFactor;
  const blockPositionYPercentage =
    blockPositionY * templates.factors.finalYFactor;

  templates.captionsClip.updateTextParam(
    localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
    [blockPositionXPercentage, blockPositionYPercentage],
  );
};
