import logLevel from '@autocut/enums/logLevel.enum';

import {logger} from './logger';

export {
  getAllLocalStorage,
  getKey,
  getLanguage,
  getLastSeenModalDate,
  getLocalStorage,
  getSeenTrialPromo,
  getTrialPromoEnd,
  isFirstCut,
  isFirstCutToday,
  isMacUserUsingSerialNumber,
  localStorageDefault,
  removeAllLocalStorage,
  setKey,
  setLanguage,
  setLastSeenModalDate,
  setLocalStorage,
  setMacUserUsingSerialNumber,
  setSeenTrialPromo,
  startTrialPromo,
};

const localStorageDefault = {
  seenTrialPromo: false,
  trialPromoEnd: (new Date().getTime() + 60 * 60 * 24 * 1000).toString(),
  key: '',
  language: 'EN',
  seenModal: [] as string[],
  licence: {
    type: 'trial',
    left: 0,
  },
};

const setLocalStorage = (variableName: string, value: any, log = false) => {
  if (value === undefined || value === null) return;

  if (log) {
    logger('localStorageUtils', logLevel.debug, 'Saving in localStorage', {
      variableName,
      value,
    });
  }

  localStorage.setItem(variableName, JSON.stringify(value));
};

const getLocalStorage = (variableName: string, log = true): any => {
  const value = localStorage.getItem(variableName);

  if (log)
    logger('localStorageUtils', logLevel.debug, 'Loading from localStorage', {
      variableName,
      value,
    });

  return value ? JSON.parse(value) : undefined;
};

const getAllLocalStorage = (): any => {
  const keys = Object.keys(localStorage);
  const items: {[key: string]: any} = {};

  for (const key of keys) {
    items[key] = getLocalStorage(key, false);
  }

  return items;
};

const removeAllLocalStorage = () => {
  localStorage.clear();
};

const setSeenTrialPromo = (value: boolean) => {
  setLocalStorage('seenTrialPromo', value);
};

const getSeenTrialPromo = (): boolean => {
  const seenTrialPromo = getLocalStorage('seenTrialPromo', false) as
    | boolean
    | undefined;

  return !!seenTrialPromo;
};

const startTrialPromo = () => {
  if (getTrialPromoEnd()) {
    return;
  }

  setLocalStorage('trialPromoEnd', new Date().getTime() + 60 * 60 * 24 * 1000);
};

const getTrialPromoEnd = (): number | undefined => {
  return getLocalStorage('trialPromoEnd', false) as number | undefined;
};

const setKey = (value: string) => {
  setLocalStorage('userKey', value);
};

const getKey = (log = true): string | undefined => {
  return getLocalStorage('userKey', log) as string;
};

const setLanguage = (value: string) => {
  setLocalStorage('language', value);
};

const getLanguage = (): 'EN' | 'FR' => {
  const language = getLocalStorage('language', false) as
    | 'EN'
    | 'FR'
    | undefined;
  return language ? language : 'EN';
};

const setLastSeenModalDate = (lastSeenModalDate: Date | string) => {
  setLocalStorage('lastSeenModal', new Date(lastSeenModalDate));
};

const getLastSeenModalDate = (): Date => {
  const lastSeenModalDate = getLocalStorage('lastSeenModal', false);

  if (lastSeenModalDate && !isNaN(Date.parse(lastSeenModalDate))) {
    return new Date(lastSeenModalDate);
  } else {
    return new Date(0);
  }
};

const isFirstCut = () => {
  const firstCut = getLocalStorage('firstCut', false) as boolean | undefined;
  if (!firstCut) {
    setLocalStorage('firstCut', true);
    return true;
  }
  return false;
};

const isFirstCutToday = () => {
  const lastCut = getLocalStorage('lastCut', false) as string | undefined;
  const today = new Date().toDateString();
  if (lastCut && lastCut === today) {
    return false;
  }

  setLocalStorage('lastCut', today);
  return true;
};

const isMacUserUsingSerialNumber = () => {
  return !!getLocalStorage('moveToSerialNumber');
};

const setMacUserUsingSerialNumber = () => {
  setLocalStorage('moveToSerialNumber', true);
};
