import React from 'react';

import FlexContainer from '../../molecules/FlexContainer';
import css from './Selector.module.scss';

export type SelectorProps<in out T extends string | number> = {
  options: {
    content: React.ReactNode;
    value: T;
  }[];
  value: T;
  onChange: (value: T) => void;
  direction?: 'row' | 'column';
};

export const Selector = <T extends string | number>({
  options,
  value,
  onChange,
  direction = 'row',
}: SelectorProps<T>) => {
  return (
    <FlexContainer
      className={css.tabs}
      flexDirection={direction}
    >
      {options.map(({content, value: optionValue}) => (
        <Tab
          key={optionValue}
          isActive={value === optionValue}
          onClick={() => onChange(optionValue)}
        >
          {content}
        </Tab>
      ))}
    </FlexContainer>
  );
};

type TabProps = {
  children: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
};

const Tab = ({children, isActive, onClick}: TabProps) => {
  return (
    <div
      className={`${css.tab} ${isActive ? css.active : null}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
