import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {SelectedAudioTrackStep} from '@autocut/components/SelectedAudioTracksStep/SelectedAudioTracksStep';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useCheckDeactivatedTracks} from '@autocut/hooks/useCheckDeactivatedTracks';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {snakeToCamel} from '@autocut/utils/string.utils';
import {useEffect} from 'react';

type TranscriptLanguageProps = {
  mode: 'caption' | 'viral_clips' | 'broll';
};

export const TranscriptLanguage = ({mode}: TranscriptLanguageProps) => {
  const {allTracksDeactivated, isInitialized} = useCheckDeactivatedTracks();
  const {handleProcessTranscript, loading} = useHandleProcessTranscript(mode);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({isLoading, isDisabled}) => (
            <Button
              onClick={() => handleProcessTranscript()}
              disabled={
                loading ||
                isLoading ||
                isDisabled ||
                allTracksDeactivated ||
                !isInitialized
              }
              isLoading={!isInitialized || isLoading || loading}
              color={colors.primary600}
            >
              <TranslatedMessage
                id={
                  `modes_${snakeToCamel(mode)}_steps_language_button` as TranslationKeys
                }
                defaultMessage="Go to next step"
              />
            </Button>
          )}
          errorId={
            allTracksDeactivated
              ? 'modes_general_steps_transcription_noTrackSelected'
              : undefined
          }
        />
      }
    >
      <FlexContainer
        style={{paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <TranscriptLanguageStep modeId={mode} />
        <SelectedAudioTrackStep />
      </FlexContainer>
    </ModeLayout>
  );
};
