import {AutocutModes, AutocutModesKeys} from '@autocut/enums/modes.enum';
import {getModeById} from '@autocut/utils/modes.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect, useRef, useState} from 'react';
import React from 'react';
import {useLocation} from 'react-router-dom';

import {ScrollProvider} from './ScrollContext';

export const ModeProvider = ({children}: {children: React.ReactNode}) => {
  const location = useLocation();

  const [scrollValue, setScrollValue] = useState(0);
  const scrollElementRef = useRef<any>();

  useEffect(() => {
    const modeId = (location.pathname.split('/').pop() ||
      'legacy') as (typeof AutocutModes)[AutocutModesKeys]['id'];
    const mode = getModeById(modeId);
    if (mode) {
      setAutocutStore('ui.process.mode', mode);
    }
  }, [location]);

  return (
    <div
      ref={scrollElementRef}
      style={{
        padding: '16px 16px 0 ',
        flexGrow: 1,
        flexShrink: 1,
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
      onScroll={event => setScrollValue(event.currentTarget.scrollTop)}
    >
      <ScrollProvider
        scrollElementRef={scrollElementRef}
        scrollValue={scrollValue}
      >
        {children}
      </ScrollProvider>
    </div>
  );
};
