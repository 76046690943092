import {
  getLocalStorage,
  setLocalStorage,
} from '@autocut/utils/localStorage.utils';
import {
  getCurrentTranscript,
  updateCurrentTranscriptName,
} from '@autocut/utils/transcript/transcriptFiles.utils';
import {createContext, useContext, useState} from 'react';

import {CheckTranscriptModal} from '../CheckTranscriptModal';
import {EditTranscriptModal} from './EditTranscriptModal';

type EditTranscriptModalContextType = {
  currentTranscriptName: string;
  updateTranscriptName: (name: string) => void;
  hasCheckedTranscript: boolean;
  editTranscriptModal: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
  };
  checkTranscriptModal: {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    validate: (dontShowAgain: boolean) => void;
  };
};

const EditTranscriptModalContext =
  createContext<EditTranscriptModalContextType>({
    currentTranscriptName: '',
    updateTranscriptName: () => {},
    hasCheckedTranscript: false,
    editTranscriptModal: {
      isOpen: false,
      open: () => {},
      close: () => {},
    },
    checkTranscriptModal: {
      isOpen: false,
      open: () => {},
      close: () => {},
      validate: () => {},
    },
  });

export const EditTranscriptModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasCheckedTranscriptModalOpen, setHasCheckedTranscriptModalOpen] =
    useState(false);
  const [currentTranscriptName, setCurrentTranscriptName] = useState(
    getCurrentTranscript()?.name ?? '',
  );
  const [hasCheckedTranscript, setHasCheckedTranscript] = useState(
    getLocalStorage('dontShowCheckTranscriptModal'),
  );

  const updateTranscriptName = (name: string) => {
    setCurrentTranscriptName(name);
    updateCurrentTranscriptName(name);
  };

  const openEditTranscriptModal = () => {
    setHasCheckedTranscriptModalOpen(false);
    setHasCheckedTranscript(true);
    setIsModalOpen(true);
  };

  const closeEditTranscriptModal = () => {
    setIsModalOpen(false);
    setCurrentTranscriptName(getCurrentTranscript()?.name ?? '');
  };

  const openHasCheckedTranscriptModal = () => {
    setHasCheckedTranscriptModalOpen(true);
  };

  const closeHasCheckedTranscriptModal = () => {
    setHasCheckedTranscriptModalOpen(false);
  };

  const validateTranscript = (dontShowAgain: boolean) => {
    setHasCheckedTranscript(true);
    setHasCheckedTranscriptModalOpen(false);
    setLocalStorage('dontShowCheckTranscriptModal', dontShowAgain);
  };

  return (
    <EditTranscriptModalContext.Provider
      value={{
        currentTranscriptName,
        updateTranscriptName,
        editTranscriptModal: {
          isOpen: isModalOpen,
          open: openEditTranscriptModal,
          close: closeEditTranscriptModal,
        },
        hasCheckedTranscript,
        checkTranscriptModal: {
          isOpen: hasCheckedTranscriptModalOpen,
          open: openHasCheckedTranscriptModal,
          close: closeHasCheckedTranscriptModal,
          validate: validateTranscript,
        },
      }}
    >
      <CheckTranscriptModal />
      <EditTranscriptModal />
      {children}
    </EditTranscriptModalContext.Provider>
  );
};

export const useEditTranscriptModal = () =>
  useContext(EditTranscriptModalContext);
