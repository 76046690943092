import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Selector} from '@autocut/designSystem/components/atoms/Selector/Selector';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {getAnimatedEmojis} from '@autocut/utils/emojis/getAnimatedEmojis';
import {getEmojisCategories} from '@autocut/utils/emojis/getEmojisCategories';
import {getStaticEmojis} from '@autocut/utils/emojis/getStaticEmojis';
import {useCallback, useState} from 'react';

import {EmojiPicker} from './EmojiPicker';
import css from './EmojiPickerVariant.module.scss';

type EmojiPickerVariantOptions = 'animated' | 'static';

export const STATIC_EMOJI_SIZE = 64;
export const ANIMATED_EMOJI_SIZE = 512;

export const EmojiPickerVariant = ({
  onEmojiClick,
  width,
  maxHeight,
}: {
  onEmojiClick: (emoji: {name: string; url: string; size: number}) => void;
  width?: string;
  maxHeight?: string;
}) => {
  const hostName = useAutoCutStore(state => state.ui.host);
  const [variant, setVariant] = useState<EmojiPickerVariantOptions>('static');

  const getCategories = useCallback(
    () => getEmojisCategories(variant === 'animated'),
    [variant],
  );

  const getEmojis =
    variant === 'animated' ? getAnimatedEmojis : getStaticEmojis;

  return (
    <FlexContainer
      className={css.container}
      flexDirection="column"
      style={{
        width,
        ...{
          '--container-max-height': maxHeight ?? '100%',
        },
      }}
    >
      <Selector
        value={variant}
        options={[
          {
            content: (
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_transcription_emojis_static'
                  }
                  defaultMessage="Static"
                />
              </div>
            ),
            value: 'static',
          },
          ...(hostName === 'ppro'
            ? ([
                {
                  content: (
                    <div
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <TranslatedMessage
                        id={
                          'modes_caption_steps_customization_parts_transcription_emojis_animated'
                        }
                        defaultMessage="Animated"
                      />
                    </div>
                  ),
                  value: 'animated',
                },
              ] as const)
            : []),
        ]}
        onChange={variant => setVariant(variant)}
      />

      <EmojiPicker
        getEmojis={getEmojis}
        getCategories={getCategories}
        onEmojiClick={emoji =>
          onEmojiClick({
            ...emoji,
            size:
              variant === 'animated' ? ANIMATED_EMOJI_SIZE : STATIC_EMOJI_SIZE,
          })
        }
      />
    </FlexContainer>
  );
};
