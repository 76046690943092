import LogoUrl from '@autocut/assets/images/logo.svg';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
const Logo = new URL(LogoUrl, import.meta.url).href;
import {OldButton} from '@autocut/components/Button/Button';
import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLocale} from '@autocut/hooks/useLocale';
import {useLogger} from '@autocut/hooks/useLogger';
import {preload} from '@autocut/types/ElectronPreload';
import {getHostNameForTranslation} from '@autocut/utils/languages.utils';
import {loginFromLocalStorage} from '@autocut/utils/license/login';
import {
  getSeenTrialPromo,
  setSeenTrialPromo,
  startTrialPromo,
} from '@autocut/utils/localStorage.utils';
import {autocutStoreVanilla} from '@autocut/utils/zustand';
import {IoCartOutline} from 'react-icons/io5';

import css from './Onboarding.module.css';

export const Onboarding = () => {
  return (
    // <TourContainer
    //   steps={onboardingSteps}
    //   afterOpen={async () => {
    //     await sendStats({
    //       type: StatType.ON_BOARDING_STARTED,
    //       value: 1,
    //       traits: {
    //         page: 'onboarding',
    //       },
    //     });
    //   }}
    //   beforeClose={() => {
    //     console.log('Onboarding tour closed');
    //     setLocalStorage(onboardingLocalStorageKeys.Onboarding, true);
    //     enableBodyScroll(document as unknown as HTMLElement);
    //   }}
    // >
    <OnboardingContent />
    // </TourContainer>
  );
};

export const OnboardingContent = () => {
  const navigate = useNavigate();
  const locale = useLocale();

  const [isLogged, license, key] = useAutoCutStore(state => [
    state.user.isLogged,
    state.user.license,
    state.user.key,
  ]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {logMessage} = useLogger('Onboarding');

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!autocutStoreVanilla().user.isLogged) await loginFromLocalStorage();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // const { setIsOpen: setIsTourOpen } = useTour();
  // useEffect(() => {
  //   if (!isLoading && !getLocalStorage(onboardingLocalStorageKeys.Onboarding)) {
  //     setTimeout(() => {
  //       setIsTourOpen(true);
  //     }, 1000);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    const startupCheck = async () => {
      logMessage(logLevel.notice, 'Check for FFMPEG.');
      //   TODO : Can ffmpeg even be unusable ?
      // if (redirectIfFFMPEGUnusable()) {
      // }
      if (isLogged) {
        if (license && license.type === 'trial') {
          if (
            !getSeenTrialPromo() &&
            license.left &&
            license.left >= 3 &&
            license.left <= 12
          ) {
            setSeenTrialPromo(true);
            startTrialPromo();
          }
        }

        navigate('/homepage');
      }
      if (!isLogged && !key) {
        setIsLoading(false);
      }
    };
    if (isLogged === undefined) return;

    void startupCheck();
  }, [isLogged, license, key]);

  // TODO : Can ffmpeg even be unusable ?
  //   const redirectIfFFMPEGUnusable = (): boolean => {
  //     if (!isFFMPEGUsable()) {
  //       logMessage(logLevel.error, 'FFMPEG is not available.');
  //       navigate('/no-ffmpeg');
  //       return false;
  //     }
  //     return true;
  //   };

  setTimeout(async () => {
    if (isLoading) {
      setIsLoading(false);
      if (!autocutStoreVanilla().user.isLogged) await loginFromLocalStorage();
    }
  }, 10000);

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      id="onboarding-step1"
      flex="1"
      className={css.container}
      gap={48}
    >
      {isLoading ? (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{width: '100%'}}
        >
          <LoaderInfinity height={300} />
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            className={css.header}
            flexDirection="column"
            alignItems="center"
          >
            <h1 className={css.title}>
              <TranslatedMessage
                id="onboarding_title"
                defaultMessage="Welcome on AutoCut"
              />
            </h1>
            <p className={css.subtitle}>
              <TranslatedMessage
                id="onboarding_description"
                defaultMessage="Your ultimate {host} extension"
                values={{
                  host: getHostNameForTranslation(),
                }}
              />
            </p>
            <img
              className={css.logo}
              src={Logo}
              alt=""
            />
          </FlexContainer>
          <FlexContainer
            className={css.buttonContainer}
            flexDirection="column"
            gap={16}
          >
            <OldButton
              id="onboarding-step2"
              onClickFunction={() => navigate('trial')}
              size={'100%'}
              buttonType="primary"
              weight="bold"
            >
              <TranslatedMessage
                id="onboarding_cta_freeTrial"
                defaultMessage="Get a free trial"
              />
            </OldButton>
            <div className={css.glassButtonContainer}>
              <OldButton
                id="onboarding-step3"
                onClickFunction={() => navigate('login')}
                size={'100%'}
                buttonType="glass"
                weight="bold"
              >
                <TranslatedMessage
                  id="onboarding_cta_useLicenceKey"
                  defaultMessage="Use a licence key"
                />
              </OldButton>
              <div className={css.blobContainer}>
                <div className={css.blob} />
              </div>
            </div>

            <div className={css.glassButtonContainer}>
              <OldButton
                id="onboarding-step4"
                onClickFunction={async () => {
                  logMessage(
                    logLevel.info,
                    "Open AutoCut's prices website page.",
                  );
                  await preload().electron.openLinkInBrowser(
                    locale.locale === 'FR'
                      ? `${preload().electron.getSharedVariables().WEBSITE_URL}/fr/#pricing`
                      : `${preload().electron.getSharedVariables().WEBSITE_URL}/#pricing`,
                  );
                }}
                size={'100%'}
                buttonType="glass"
                weight="bold"
              >
                <FlexContainer
                  alignItems="center"
                  justifyContent="center"
                  gap={8}
                  className={css.buyButton}
                >
                  <IoCartOutline size={18} />
                  <TranslatedMessage
                    id="onboarding_cta_buyLicenceKey"
                    defaultMessage="Buy a license key"
                  />
                </FlexContainer>
              </OldButton>
              <div className={css.blobContainer}>
                <div className={css.blob} />
              </div>
            </div>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};
