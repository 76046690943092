import type {exec, spawn} from 'child_process';
import type {randomUUID} from 'crypto';
import type {StatSyncFn} from 'fs';
import type {
  access,
  close as close$1,
  copyFileSync,
  existsSync,
  mkdirSync,
  open as open$1,
  read,
  readdir,
  readdirSync,
  readFileSync,
  renameSync,
  rmSync,
  symlinkSync,
  unlinkSync,
  writeFileSync,
} from 'fs';
import type {homedir, platform, tmpdir, type} from 'os';
import type {PlatformPath} from 'path';

declare const buffer: {
  from: {
    (
      arrayBuffer: WithImplicitCoercion<ArrayBuffer | SharedArrayBuffer>,
      byteOffset?: number,
      length?: number,
    ): Buffer;
    (data: Uint8Array | readonly number[]): Buffer;
    (
      data: WithImplicitCoercion<Uint8Array | readonly number[] | string>,
    ): Buffer;
    (
      str:
        | WithImplicitCoercion<string>
        | {
            [Symbol.toPrimitive](hint: 'string'): string;
          },
      encoding?: BufferEncoding,
    ): Buffer;
  };
  subarray: (buffer: Buffer, start: number, end?: number) => Buffer;
  concat: (list: readonly Uint8Array[], totalLength?: number) => Buffer;
  alloc: (
    size: number,
    fill?: string | Uint8Array | number,
    encoding?: BufferEncoding,
  ) => Buffer;
  encodeToBase64: (buffer: Uint8Array[]) => string;
};
declare const childProcess: {
  spawn: typeof spawn;
  node_exec_async: typeof exec.__promisify__;
  sudo_exec: (command: string) => Promise<boolean>;
};
declare const computeNodeIpc: {
  startTask: <T>(task: ComputeTask) => {
    requestId: string;
    promise: Promise<T>;
  };
  getTaskProgress: (requestId: string) => {
    requestId: string;
    promise: Promise<ProgressStep[]>;
  };
  connect: () => Promise<void>;
  isConnected: () => boolean;
  setVerboseLevel: (verboseLevel: number) => number;
};
declare const electron: {
  getVersion: () => string;
  openLinkInBrowser: (url: string) => Promise<void>;
  reload: () => void;
  getPath: (name: string) => any;
  getClipboard: () => string;
  setClipboard: (text: string) => void;
  minimize: () => void;
  togglePinWindow: () => boolean;
  getSharedVariables: () => {
    FRONT_URL: string;
    COMPUTE_URL: string;
    HOST_SERVER_URL: string;
    API_URL: string;
    WEBSITE_URL: string;
    WEBAPP_URL: string;
    ACADEMY_URL: string;
    PPRO_DOWNLOAD_URL: string;
    DAVINCI_DOWNLOAD_URL: string;
    RESOURCES_PATH: string;
    TEMP_FOLDER_PATH: string;
    LOG_FILE_PATH: string;
  };
};
declare const ffmpegSetup: {
  getFFMPEGPath: () => string | false;
  downloadFFMPEG: (version: string, buildDate: string) => any;
};
declare const fs: {
  access: typeof access;
  existsSync: typeof existsSync;
  mkdirSync: typeof mkdirSync;
  readdir: typeof readdir;
  readdirSync: typeof readdirSync;
  statSync: StatSyncFn;
  lstatSync: StatSyncFn;
  isFile: (filePath: string) => boolean;
  unlinkSync: typeof unlinkSync;
  writeFileSync: typeof writeFileSync;
  readFileSync: typeof readFileSync;
  open: typeof open$1;
  read: typeof read;
  close: typeof close$1;
  Buffer: BufferConstructor;
  rmSync: typeof rmSync;
  symlinkSync: typeof symlinkSync;
  renameSync: typeof renameSync;
  copyFileSync: typeof copyFileSync;
};
declare const hostNodeIpc: {
  request: <T>(
    routeName: string,
    args: any,
  ) => {
    requestId: string;
    promise: Promise<T>;
  };
  connect: () => Promise<void>;
  isConnected: () => boolean;
  setVerboseLevel: (verboseLevel: number) => number;
};
declare const https: {
  pingFront: () => Promise<unknown>;
};
declare const logger: {
  init: (logFilePath: string) => void;
  log: (...args: any[]) => void;
  info: (...args: any[]) => void;
  warn: (...args: any[]) => void;
  error: (...args: any[]) => void;
};
declare const nodeCrypto: {
  verifySignature: (
    stringToSign: string,
    publicKey: string,
    signature: string,
  ) => boolean;
  randomUUID: typeof randomUUID;
};
declare const os: {
  homedir: typeof homedir;
  tmpdir: typeof tmpdir;
  platform: typeof platform;
  type: typeof type;
};
declare const path: {
  join: (...paths: string[]) => string;
  joinUrl: (...args: string[]) => string;
  basename: (path: string, suffix?: string) => string;
  dirname: (path: string) => string;
  posix: PlatformPath;
  extname: (path: string) => string;
};
declare const resources: {
  download: (
    url: string,
    options?: {
      outputDir?: string;
    },
  ) => Promise<string>;
  unzip: (filePath: string, unzipPath?: string) => Promise<void>;
};
declare const sudo: {
  createFolder: (folderPath: string) => Promise<boolean>;
  unzip: (filePath: string, unzipPath?: string) => Promise<boolean>;
};
declare const updater: {
  getInfos: () => UpdateInfo;
  quitAndInstall: () => void;
};
export type ComputeTask = {
  domain: {
    slug: string;
    target: string;
  };
  tokens?: {
    accessToken: string;
    refreshToken: string;
  };
  priority: number;
  data?: any;
  debug?: boolean;
};
export type ProgressStep = {
  id: string;
  progress: {
    finished: boolean;
    currentStep: number;
    nbSteps: number;
  };
};
export type UpdateInfo = {
  status: 'checking' | 'downloading' | 'ready' | 'error' | 'up-to-date';
  releaseNotes?: string;
  releaseName?: string;
};

declare global {
  interface Window {
    __electron_preload__buffer?: typeof buffer;
    __electron_preload__childProcess?: typeof childProcess;
    __electron_preload__computeNodeIpc?: typeof computeNodeIpc;
    __electron_preload__electron?: typeof electron;
    __electron_preload__ffmpegSetup?: typeof ffmpegSetup;
    __electron_preload__fs?: typeof fs;
    __electron_preload__hostNodeIpc?: typeof hostNodeIpc;
    __electron_preload__https?: typeof https;
    __electron_preload__logger?: typeof logger;
    __electron_preload__nodeCrypto?: typeof nodeCrypto;
    __electron_preload__os?: typeof os;
    __electron_preload__path?: typeof path;
    __electron_preload__resources?: typeof resources;
    __electron_preload__sudo?: typeof sudo;
    __electron_preload__updater?: typeof updater;
  }
}

let preloadReady = false;

export const preload = () => {
  const errorMessage =
    "Preload not ready ! Ensure you've called `waitForPreload` before using 'preload().*' functions or manage the undefined case with `isPreloadReady`";
  if (!preloadReady) {
    const stack = new Error().stack;
    console.error(`${errorMessage}\n\nCall stack:\n${stack}`);
  }
  if (window.__electron_preload__electron) {
    return {
      buffer: window.__electron_preload__buffer!,
      childProcess: window.__electron_preload__childProcess!,
      computeNodeIpc: window.__electron_preload__computeNodeIpc!,
      electron: window.__electron_preload__electron!,
      ffmpegSetup: window.__electron_preload__ffmpegSetup!,
      fs: window.__electron_preload__fs!,
      hostNodeIpc: window.__electron_preload__hostNodeIpc!,
      https: window.__electron_preload__https!,
      logger: window.__electron_preload__logger!,
      nodeCrypto: window.__electron_preload__nodeCrypto!,
      os: window.__electron_preload__os!,
      path: window.__electron_preload__path!,
      resources: window.__electron_preload__resources!,
      sudo: window.__electron_preload__sudo!,
      updater: window.__electron_preload__updater!,
    };
  }
  throw new Error(errorMessage);
};

export const isPreloadReady = () => preloadReady;

export const waitForPreload = async (source?: string) =>
  new Promise<void>(async (resolve, reject) => {
    const checkPreload = () =>
      !!window?.__electron_preload__electron?.getPath('userData');

    let i = 0;
    while (i < 100 && !checkPreload()) {
      await new Promise(resolve => setTimeout(resolve, 100));
      i++;
    }
    if (i === 100) {
      console.log('waitForPreload fail ' + (source || ''));
      reject(new Error('Failed to load preload'));
    } else {
      console.log('Preload ready ' + (source || ''));
      preloadReady = true;
      resolve();
    }
  });
