import type {PodcastParameters} from '@autocut/validationSchema/podcast.validationSchema';

import {AutocutModeIds} from '@autocut/enums/modes.enum';
import {parametersValidationSchema} from '@autocut/validationSchemas/parameters.validationSchema';
import {z as zod} from 'zod';

import {autocutStoreVanilla} from './zustand';

export type AutocutParameters = {
  [key in Exclude<AutocutModeIds, 'podcast'>]: zod.infer<
    (typeof parametersValidationSchema)[key]
  >;
} & {
  podcast: PodcastParameters;
};

export const getCurrentModeId = (): AutocutModeIds => {
  const mode = autocutStoreVanilla().ui.process.mode;
  return mode.id as AutocutModeIds;
};

export const getParametersForMode = <K extends AutocutModeIds>(
  modeId: K = 'current' as K,
): AutocutParameters[K] => {
  const parameters = autocutStoreVanilla().ui.parameters;

  modeId = modeId === ('current' as K) ? (getCurrentModeId() as K) : modeId;

  return parameters[modeId];
};
