import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {host} from '@autocut/utils/host';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import {LoadingRing} from '../LoadingRing/LoadingRing';

export const SelectedAudioTrackStep = () => {
  const intl = useIntl();
  const {deactivatedTrackIndexes} = useAutoCutStore(state => ({
    deactivatedTrackIndexes: state.ui.deactivatedTrackIndexes,
  }));
  const [numberOfTracks, setNumberOfTracks] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setAutocutStore('ui.deactivatedTrackIndexes', []);
      setNumberOfTracks(await host.timeline.getTrackCount('audio'));
      setIsLoading(false);
    };

    void init();
  }, []);

  const trackDisplay = [];
  for (let iterTrack = 0; iterTrack < numberOfTracks; iterTrack++) {
    trackDisplay.push(
      <FlexContainer
        flexDirection="row"
        gap={Spacing.s2}
      >
        <CheckBox
          size={20}
          checked={!deactivatedTrackIndexes.includes(iterTrack + 1)}
          onChange={v => {
            if (!v) {
              deactivatedTrackIndexes.push(iterTrack + 1);
              setAutocutStore('ui.deactivatedTrackIndexes', [
                ...deactivatedTrackIndexes,
              ]);
            } else {
              const newDeactivatedTrackIndexes = deactivatedTrackIndexes.filter(
                trackIndex => trackIndex != iterTrack + 1,
              );
              setAutocutStore('ui.deactivatedTrackIndexes', [
                ...newDeactivatedTrackIndexes,
              ]);
            }
          }}
        />
        <Text
          variant="textXs.medium"
          color="gray.300"
        >
          {`A${iterTrack + 1}`}
        </Text>
      </FlexContainer>,
    );
  }

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_general_steps_audioTracks_title',
        defaultMessage: 'Selected audio tracks',
      })}
      description={intl.formatMessage({
        id: 'modes_general_steps_audioTracks_description',
        defaultMessage: 'Exclude some tracks from audio processing.',
      })}
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          {isLoading ? <LoadingRing size={32} /> : trackDisplay}
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
