import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import React, {CSSProperties} from 'react';

import {LoadingRing} from '../LoadingRing/LoadingRing';
import styleConfig from '../styleconfig';
import css from './Button.module.scss';

export type ButtonSizes = 'sm' | 'md' | 'lg';
export const isButtonSize = (size: any): size is ButtonSizes =>
  typeof size === 'string' && ['lg', 'md', 'sm'].includes(size);

export type ButtonProps = {
  size?: 'sm' | 'md' | 'lg' | CSSProperties['width'];
  weight?: 'bold' | 'normal';
  compact?: boolean;
  children: any;
  onClickFunction: any;
  id?: string;
  isLoading?: boolean;
  disabled?: boolean;
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'glass';
  percentage?: number;
  className?: string;
  color?: CSSProperties['color'];
  style?: CSSProperties;
};

export const OldButton = forwardRef<any, ButtonProps>(
  (
    {
      size,
      weight = 'normal',
      compact,
      children,
      onClickFunction,
      isLoading,
      disabled,
      id,
      buttonType = 'primary',
      percentage = 0,
      className,
      color,
      style,
    },
    ref?,
  ) => {
    const [displayLoader, setdisplayLoader] = useState<boolean>(false);
    const [handledInternally, setHandledInternally] = useState<boolean>(true);

    if (ref) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useImperativeHandle(ref, () => ({
        async handleClick() {
          setdisplayLoader(true);
          await onClickFunction();
          setdisplayLoader(false);
        },
      }));
    }

    const handleClick = async () => {
      setdisplayLoader(true);
      await onClickFunction();
      setdisplayLoader(false);
    };

    useEffect(() => {
      setdisplayLoader(isLoading as boolean);
    }, [isLoading]);

    useEffect(() => {
      if (isLoading != null) {
        setHandledInternally(false);
      }
    }, []);

    return (
      <button
        id={id}
        ref={ref}
        disabled={disabled || displayLoader}
        onClick={handledInternally ? handleClick : onClickFunction}
        style={
          {
            '--button-progress':
              percentage > 0 && isLoading ? `${percentage.toFixed(1)}` : 0,
            '--button-progress-content':
              percentage > 0 && isLoading ? `'${percentage.toFixed(1)}%'` : '',
            fontFamily: 'Inter, sans-serif',
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
            border:
              buttonType === 'tertiary'
                ? undefined
                : `1px solid ${
                    color
                      ? color
                      : buttonType === 'primary' || buttonType === 'secondary'
                        ? styleConfig.primaryColor
                        : buttonType === 'glass'
                          ? '#ffffff30'
                          : 'none'
                  }`,
            backgroundColor:
              buttonType === 'primary'
                ? color
                  ? color
                  : `${styleConfig.primaryColor}`
                : buttonType === 'glass'
                  ? '#ffffff0a'
                  : 'transparent',
            color:
              buttonType === 'primary' || buttonType === 'glass'
                ? '#ffffff'
                : color
                  ? color
                  : buttonType === 'secondary'
                    ? '#ffffff'
                    : styleConfig.primaryColor,
            textDecoration: buttonType === 'tertiary' ? 'underline' : 'none',
            fontWeight:
              buttonType === 'tertiary' || weight === 'bold'
                ? 'bold'
                : 'normal',
            width: compact
              ? 'fit-content'
              : size
                ? isButtonSize(size)
                  ? `${styleConfig.buttonSizes[size]}px`
                  : size
                : `100%`,
            height: compact
              ? size
                ? isButtonSize(size)
                  ? `${styleConfig.compactButtonHeight[size || 'sm']}px`
                  : undefined
                : undefined
              : undefined,
            padding: compact
              ? '0'
              : size === 'sm'
                ? '5px 10px'
                : size === 'md'
                  ? '10px 15px'
                  : '8px 16px',
            ...style,
          } as CSSProperties
        }
        className={`${
          buttonType === 'tertiary'
            ? `${css.buttonPropertyTertiary}`
            : `${css.buttonProperty}`
        } ${className}`}
      >
        {percentage > 0 && isLoading ? (
          ' '
        ) : displayLoader ? (
          <LoadingRing />
        ) : (
          children
        )}
      </button>
    );
  },
);
