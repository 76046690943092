import repeatThumbnail from '@autocut/assets/images/modes/repeat.png?url';
import {SelectionScreen} from '@autocut/components/SelectionScreen/SelectionScreen';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {ModeWrapper} from '@autocut/pages/modes/ModeWrapper';
import React from 'react';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {MarkerSelectionScreen} from '../MarkerSelectionScreen/MarkerSelectionScreen';
import RepeatLanguage from './Steps/Language/RepeatLanguage';
import {RepeatTranscriptStep} from './Steps/Transcript/RepeatTranscriptStep';

export const RepeatMode = () => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <ModeWrapper
      Icon={AutocutModes.Repeat.icon}
      text={AutocutModes.Repeat.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);
        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/repeat"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            path: 'inOut',
            element: (
              <SelectionScreen
                image={{src: repeatThumbnail, alt: 'Repeat mode'}}
                title={{
                  id: 'modes_general_steps_select_title',
                  defaultMessage: 'Set In/Out points',
                }}
                texts={[
                  {
                    id: 'modes_general_steps_select_descriptions_1',
                    defaultMessage: '',
                  },
                  {
                    id: 'modes_general_steps_select_descriptions_2',
                    defaultMessage: '',
                  },
                ]}
                cta={
                  <Button
                    color={colors.primary600}
                    onClick={() => {
                      navigate('./language');
                    }}
                  >
                    <TranslatedMessage
                      id="modes_general_steps_select_cta"
                      defaultMessage="Confirm In/Out points"
                    />
                  </Button>
                }
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_repeat_steps_language_title',
              defaultMessage: 'Select clips & language',
            }),
            clickable: true,
            path: 'language',
            element: <RepeatLanguage />,
          },
          {
            label: intl.formatMessage({
              id: 'repeat_step_transcription' as any,
              defaultMessage: 'Check transcription',
            }),
            path: 'transcript',
            element: <RepeatTranscriptStep />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
