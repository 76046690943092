import FlexContainer, {
  FlexContainerProps,
} from '@autocut/designSystem/components/molecules/FlexContainer';
import {HTMLAttributes} from 'react';
import React from 'react';

import css from './TranscriptChunkContainer.module.css';

export const TranscriptChunkContainer = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement> & FlexContainerProps) => {
  return (
    <FlexContainer
      className={`${css.container} ${className}`}
      {...props}
    >
      {children}
    </FlexContainer>
  );
};
