import maskThumbnail from '@autocut/assets/images/mask.png?url';
import {DiscordCta} from '@autocut/components/DiscordCta/DiscordCta';
import {
  HomepageCardAutoZoom,
  HomepageCardBRoll,
  HomepageCardCaptions,
  HomepageCardChapter,
  HomepageCardLegacy,
  HomepageCardPodcast,
  HomepageCardRepeat,
  HomepageCardResize,
  HomepageCardSwearWords,
  HomepageCardViralClips,
} from '@autocut/components/HomepageCard/HomepageCards';
import {ModeTitle} from '@autocut/components/ModeTitle/ModeTitle';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import {useBetaOnboarding} from '@autocut/modals/BetaOnboardingModal/useBetaOnboarding';
import {NoLinkModal} from '@autocut/modals/NoLinkModal/NoLinkModal';
import {useNoLink} from '@autocut/modals/NoLinkModal/useNoLink';
import {getLocalStorage} from '@autocut/utils/localStorage.utils';
import {useEffect, useRef, useState} from 'react';
import {AiOutlineHome} from 'react-icons/ai';

import css from './Homepage.module.scss';
import {calculateColumnNumbers, CARD_SIZE, GRID_GAP} from './utils';

export const Homepage = () => {
  // TODO: zustand
  // const { additionalInformations, openedTimestamp } = useAutoCutStore(
  //   state => ({
  //     additionalInformations: state.user.additionalInformations,
  //     openedTimestamp: state.utils.openedTimestamp,
  //   })
  // );

  // useEffect(() => {
  //   if (additionalInformations && additionalInformations.displayOnboarding)
  //     setAutocutStore('ui.openedModalName', ModalType.Onboarding);
  // }, [additionalInformations]);

  // useEffect(() => {
  //   setAutocutStore('ui.process.isPaused', true);
  //   setAutocutStore('sequence.infos', undefined);

  //   return () => {
  //     setAutocutStore('ui.process.isPaused', false);
  //     void manuallyRefreshSequence();
  //   };
  // }, []);

  // TODO: zustand & modal
  // useEffect(() => {
  //   const openReloadAutoCutModal = () => {
  //     setAutocutStore('ui.openedModalName', ModalType.RestartAutoCut);
  //   };
  //   const timeout = setTimeout(
  //     openReloadAutoCutModal,
  //     Math.max(0, openedTimestamp + RELOAD_MODAL_TIMEOUT - Date.now())
  //   );

  //   return () => clearTimeout(timeout);
  // }, [openedTimestamp]);

  return (
    // <TourContainer
    //   steps={modeSelectionSteps}
    //   beforeClose={() => {
    //     setLocalStorage(onboardingLocalStorageKeys.ModeSelection, true);
    //     enableBodyScroll(document as unknown as HTMLElement);
    //   }}
    //   afterOpen={async () => {
    //     await sendStats({
    //       type: StatType.ON_BOARDING_STARTED,
    //       value: 1,
    //       traits: {
    //         page: 'homepage',
    //       },
    //     });
    //   }}
    // >
    <HomepageContent />
    // </TourContainer>
  );
};

// in pixel

const HomepageCards = [
  HomepageCardLegacy,
  HomepageCardPodcast,
  HomepageCardCaptions,
  HomepageCardAutoZoom,
  HomepageCardRepeat,
  HomepageCardSwearWords,
  HomepageCardResize,
  HomepageCardBRoll,
  HomepageCardChapter,
  HomepageCardViralClips,
];

const HomepageContent = () => {
  const intl = useOldIntl();
  const {host} = useAutoCutStore(state => ({
    host: state.ui.host,
  }));
  const cardsContainerRef = useRef<HTMLDivElement>(null);
  useBetaOnboarding();
  useNoLink();

  // const { setIsOpen: setIsTourOpen } = useTour();
  // useEffect(() => {
  //   if (!getLocalStorage(onboardingLocalStorageKeys.ModeSelection)) {
  //     setIsTourOpen(true);
  //   }
  // }, []);

  useEffect(() => {
    const cardsContainerElement = cardsContainerRef.current;

    const columnNumbers = calculateColumnNumbers({
      cardsContainerElement: cardsContainerElement,
      cardsNumber: HomepageCards.length,
    });
    cardsContainerElement?.style.setProperty(
      '--column-numbers',
      columnNumbers.toString(),
    );

    window.addEventListener('resize', () => {
      const columnNumbers = calculateColumnNumbers({
        cardsContainerElement: cardsContainerElement,
        cardsNumber: HomepageCards.length,
      });
      cardsContainerElement?.style.setProperty(
        '--column-numbers',
        columnNumbers.toString(),
      );
    });

    return () =>
      window.removeEventListener('resize', () => {
        const columnNumbers = calculateColumnNumbers({
          cardsContainerElement: cardsContainerElement,
          cardsNumber: HomepageCards.length,
        });
        cardsContainerElement?.style.setProperty(
          '--column-numbers',
          columnNumbers.toString(),
        );
      });
  });

  return (
    <>
      <DiscordCta />
      <ModeTitle
        Icon={AiOutlineHome}
        text={intl.formatMessage({
          id: 'homepage_title',
          defaultMessage: 'Homepage',
        })}
        withHomeButton={false}
      />
      <div
        className={`dashboardContainer ${css.mainContainer}`}
        style={{height: 'fit-content'}}
      >
        <div
          className={css.mask}
          style={{
            maskImage: `url(${maskThumbnail})`,
          }}
        >
          <div className={css.maskColor} />
        </div>
        <div
          ref={cardsContainerRef}
          id="modeSelection-step1"
          className={css.cardsContainer}
          style={{
            gridTemplateColumns: `repeat(var(--column-numbers), ${CARD_SIZE}px)`,
            gridAutoRows: CARD_SIZE,
            gap: GRID_GAP,
          }}
        >
          {host
            ? HomepageCards.map((Card, index) => {
                return (
                  <Card
                    key={index}
                    host={host}
                  />
                );
              })
            : null}
        </div>

        <div
          className={css.mask}
          style={{
            maskImage: `url(${maskThumbnail})`,
            bottom: 0,
            top: 'auto',
            transform: 'rotate(180deg)',
          }}
        >
          <div className={css.maskColor} />
        </div>
      </div>
    </>
  );
};
