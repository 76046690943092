import {OldButton} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {autocutApi} from '@autocut/utils/http.utils';
import {manageError} from '@autocut/utils/manageError';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {TiArrowLeft} from 'react-icons/ti';

import css from './ExchangeRateModal.module.css';

const ExchangeRateModal = () => {
  const intl = useOldIntl();
  const license = useAutoCutStore(state => state.user.license);
  const {logMessage} = useLogger('ExchangeRateModal');

  const goBack = () => closeModal();

  const handleClick = async () => {
    logMessage(logLevel.notice, "Click on 'Go rate on Adobe Exchange' button", {
      license,
    });
    if (license === undefined) {
      logMessage(logLevel.warn, 'No key found.');
      return;
    }

    const key = license.key;
    if (key === '') {
      logMessage(logLevel.warn, 'No key found.');
      return;
    }

    try {
      const res = await autocutApi.post(`/modals/adobe-exchange-grade`);
      logMessage(
        logLevel.debug,
        'Response from server when adding extra-trial.',
        res,
      );
    } catch (error: any) {
      manageError({
        error: new IncrementalError(error, 'ExchangeRateModal'),
        disableModal: true,
      });
    }

    logMessage(logLevel.notice, 'Open Adobe Exchange in default browser');
    // TODO : openLinkInBrowser
    // openLinkInBrowser(
    //   'https://exchange.adobe.com/apps/cc/106665/autocut-auto-remove-silence',
    // );
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'exchange_title',
        defaultMessage: 'Thanks for those 5 stars!',
      })}
      icon={
        <TiArrowLeft
          onClick={goBack}
          size={40}
          color="#FFFFFF"
        />
      }
      footer={
        <OldButton
          buttonType="tertiary"
          compact
          onClickFunction={handleClick}
        >
          <OldTranslatedMessage
            id="exchange_button"
            defaultMessage="Go to Adobe Exchange"
          />
        </OldButton>
      }
    >
      <FlexContainer
        flexDirection="column"
        alignItems="flex-start"
      >
        <p>
          <OldTranslatedMessage
            id="exchange_text"
            defaultMessage="Go to Adobe Exchange and rate AutoCut to get an extra 7 days trial!"
          />
        </p>
        <p className={css.exchangeRateModal}>
          <OldTranslatedMessage
            id="exchange_info"
            defaultMessage="If you have installed AutoCut from our website, you do not need to install the extension again."
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};

export default ExchangeRateModal;
