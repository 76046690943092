import {IconCheck} from '@autocut/designSystem/components/atoms/Icon/general/IconCheck';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';

import css from './ColorStep.module.scss';

const MARKER_COLORS = [
  {hex: '#718637', name: 'Green'},
  {hex: '#D22C36', name: 'Red'},
  {hex: '#AF8BB1', name: 'Purple'},
  {hex: '#E96F24', name: 'Orange'},
  {hex: '#D0A12B', name: 'Yellow'},
  {hex: '#FFFFFF', name: 'White'},
  {hex: '#428DFC', name: 'Blue'},
  {hex: '#19F4D6', name: 'Cyan'},
];

export const ColorStep = () => {
  const intl = useIntl();
  const chaptersColor = useAutoCutStore(
    state => state.ui.parameters.chapters.color,
  );

  return (
    <FormSection
      className={css.root}
      title={intl.formatMessage({
        id: 'modes_chapters_steps_markersColor_title',
        defaultMessage: 'Markers Color',
      })}
    >
      <FlexContainer
        alignItems="center"
        gap={Spacing.s3}
      >
        {Object.entries(MARKER_COLORS).map(([index, color]) => {
          const isActive = color.name === chaptersColor;

          return (
            <div
              key={index}
              className={`${css.colorContainer} ${isActive ? css.active : ''}`}
            >
              <div
                className={css.colorItem}
                style={
                  {
                    '--current-color': color.hex,
                  } as React.CSSProperties
                }
                onClick={() =>
                  setAutocutStore('ui.parameters.chapters.color', color.name)
                }
              />
              {isActive && (
                <FlexContainer
                  className={css.checkmark}
                  alignItems="center"
                  justifyContent="center"
                >
                  <IconCheck
                    color="white"
                    size={12}
                  />
                </FlexContainer>
              )}
            </div>
          );
        })}
      </FlexContainer>
    </FormSection>
  );
};
