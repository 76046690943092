import {formatVersion} from '@autocut/components/VersionDisplay/VersionDisplay';
import {GET_MAC_ID_COMMAND, OS_MAC} from '@autocut/constants/constants';
import logLevel from '@autocut/enums/logLevel.enum';
import {SentryFingerPrintEnum} from '@autocut/enums/sentryFingerPrint.enum';
import {preload} from '@autocut/types/ElectronPreload';
import IsKeyValidResponse from '@autocut/types/IsKeyValidResponse';
import {getHostName} from '@autocut/utils/system/hostname.system.utils';
import {getOS} from '@autocut/utils/system/os.system.utils';
import {getUUID} from '@autocut/utils/system/uuid.system.utils';
import {AxiosResponse} from 'axios';

import {AutoCutApiError} from './errors/AutoCutApiError';
import {IncrementalError} from './errors/IncrementalError';
import {exec} from './exec.utils';
import {host} from './host';
import {autocutApi} from './http.utils';
import {logger} from './logger';
import md5 from './md5.utils';
import {logSentryUser} from './sentry.utils';
import {autocutStoreVanilla} from './zustand';

const verifySignature = (
  data: {body: string; timeStamp: string; requestId: string},
  publicKey: string,
  signature: string,
): boolean => {
  const bodyHash: string = md5(data.body);
  const stringToSign: string =
    bodyHash + '|' + data.timeStamp + '|' + data.requestId;

  return preload().nodeCrypto.verifySignature(
    stringToSign,
    publicKey,
    signature,
  );
};

const retreivePublicKey = async (publicKeyId: string): Promise<string> => {
  const {data} = await autocutApi.get(`/keys/pubkey/${publicKeyId}`);
  return data;
};

const isResponseValid = async (
  response: AxiosResponse<any, any>,
): Promise<boolean> => {
  const responseId: string = response.headers['autocut-response-id'];
  const timeStamp: string = response.headers['autocut-response-timestamp'];
  const publicKeyId: string = response.headers['autocut-response-pubkey'];
  const responseSign: string = response.headers['autocut-response-sign'];

  if (
    !responseId ||
    !timeStamp ||
    !publicKeyId ||
    !responseSign ||
    new Date(timeStamp).getTime() + 24 * 60 * 60 * 1000 < new Date().getTime()
  ) {
    return false;
  }

  const publicKey = await retreivePublicKey(publicKeyId);

  if (!publicKey) {
    return false;
  }

  const isValid = verifySignature(
    {
      body: JSON.stringify(response.data),
      timeStamp: timeStamp,
      requestId: responseId,
    },
    publicKey,
    responseSign,
  );

  return isValid;
};

//TODO : Split
const isKeyValid = async (
  key: string,
  isManual = false,
): Promise<IsKeyValidResponse> => {
  const trimmedKey = key?.trim();

  if (trimmedKey === 'none' || trimmedKey === undefined || trimmedKey === '') {
    logger('authUtil', logLevel.notice, 'Key empty or undefined');
    return {result: false, message: 'No key provided'};
  }

  logger('authUtil', logLevel.notice, 'Checking if key is valid...');

  const uuid = await getUUID(); //TODO : Move error in getUUID function
  if (uuid === undefined) {
    logger('authUtil', logLevel.error, "Can't read the computer's UUID");
    throw new Error('auth_errors_cant_read_uuid_error');
  }

  let pcName = await getHostName();

  const platform = getOS();
  if (platform === OS_MAC) {
    const {stdout} = await exec({
      command: GET_MAC_ID_COMMAND,
      sentryData: {
        fingerPrint: SentryFingerPrintEnum.EXEC.GET_MAC_ID,
        context: {shellCommand: GET_MAC_ID_COMMAND},
      },
    });
    const serialNumber = stdout.trim();

    pcName = serialNumber;
  }

  if (pcName === undefined) {
    logger('authUtil', logLevel.error, "Can't read the computer's name");
    throw new Error('auth_errors_cant_read_host_error');
  }

  const autocutStore = autocutStoreVanilla();
  const autocutVersion = formatVersion(autocutStore.ui);
  const hostVersion = await host.misc.hostVersion();
  let paidDavinciSuffix = '';
  if (autocutStore.ui.host === 'davinci') {
    if ((await host.misc.getProductName()) === 'DaVinci Resolve Studio') {
      paidDavinciSuffix = '_Studio';
    }
  }

  const postData = {
    pc_uuid: uuid,
    pc_name: pcName,
    key: trimmedKey,
    software: autocutStore.ui.host + paidDavinciSuffix,
    hostVersion: hostVersion.join('.'),
    autocutVersion: autocutVersion,
    os: platform,
    isManual,
  };

  const response = await autocutApi
    .post(`/keys/is-key-valid`, postData)
    .catch((error: AutoCutApiError | IncrementalError) => {
      logger('authUtil', logLevel.error, `An error has occured.`, {
        error,
        postData,
      });

      throw error;
    });

  let email = 'Unable to get email';
  if (
    response &&
    response.data &&
    response.data.licence &&
    response.data.licence.associatedEmail
  ) {
    email = response.data.licence.associatedEmail;
  }

  logSentryUser({
    id: trimmedKey,
    pcName: pcName,
    uuid: uuid,
    email,
  });

  if (!(await isResponseValid(response))) {
    logger('authUtil', logLevel.crit, "Response doesn't come from server");
    throw new Error('auth_errors_invalid_response');
  }

  logger(
    'authUtil',
    logLevel.notice,
    `Key is${response.data.result ? '' : ' not'} valid`,
    {response: response.data},
  );

  return response.data;
};

export {isKeyValid};
