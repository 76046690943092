import {InfoText} from '@autocut/components/InfoText/InfoText';
import {InputRangeParameter} from '@autocut/components/InputRangeParamater/InputRangeParameter';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {closeModal as modalCloseModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import {MdOutlineModeEditOutline} from 'react-icons/md';

import {processCaptionsChunksState} from '../../../utils/processCaptionsChunks';
import CaptionsPreview from '../CustomizationModal/Parts/ExampleCaption/CaptionsPreview';

export const CaptionsPreviewModal = () => {
  const {
    fontSize,
    maxWidth,
    nbLines,
    chunks = [],
  } = useAutoCutStore(state => ({
    fontSize: state.ui.parameters.caption.text.fontSize,
    nbLines: state.ui.parameters.caption.formating.nbLines,
    maxWidth: state.ui.parameters.caption.formating.maxWidth,
    chunks: state.onGoingProcess.caption?.chunks,
  }));

  const parseFontSize = (value: string) => {
    const r = parseInt(value);
    return isNaN(r) ? 0 : r;
  };

  const closeModal = async () => {
    await processCaptionsChunksState(
      chunks?.flatMap(chunk => chunk.lines.flat()) ?? [],
    );
    modalCloseModal();
  };

  return (
    <Modal
      title={
        <TranslatedMessage
          id={'modes_caption_steps_customization_parts_preview_modal_title'}
          defaultMessage="Position & size"
        />
      }
      closeModalFunction={closeModal}
      withCloseButton={false}
      closeOnClickOutside={false}
      icon={<MdOutlineModeEditOutline size="1.5rem" />}
      fullWidth
      shouldScroll={false}
      footer={
        <FlexContainer
          flexDirection="column"
          gap={4}
          style={{
            marginTop: -8,
          }}
        >
          <CaptionsPreview maxHeight="240px" />

          <FlexContainer
            flexDirection="row"
            gap={12}
            style={{
              paddingInline: 16,
            }}
          >
            <Button
              onClick={closeModal}
              variant="secondary"
            >
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_preview_modal_apply'
                }
                defaultMessage="Apply style"
              />
            </Button>
          </FlexContainer>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={6}
      >
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_preview_modal_fontSize'
              }
              defaultMessage="Font size"
            />
          }
          grow
          wrap={true}
        >
          <InputRangeParameter
            value={fontSize}
            step={1}
            min={10}
            max={400}
            unit="px"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.text.fontSize',
                parseFontSize(value),
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_preview_modal_nbLines'
              }
              defaultMessage="Number of lines"
            />
          }
          wrap={true}
        >
          <InputRangeParameter
            value={nbLines}
            min={1}
            max={5}
            step={1}
            unit="lines"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.formating.nbLines',
                parseInt(value),
              )
            }
          />
        </ParameterLayout>
        <ParameterLayout
          title={
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_preview_modal_maxWidth'
              }
              defaultMessage="Maximum width"
            />
          }
          wrap={true}
        >
          <InputRangeParameter
            value={maxWidth}
            min={20}
            max={100}
            step={1}
            unit="%"
            onChange={value =>
              setAutocutStore(
                'ui.parameters.caption.formating.maxWidth',
                parseInt(value),
              )
            }
          />
        </ParameterLayout>

        <InfoText style="warning">
          <TranslatedMessage
            id={'modes_caption_steps_customization_parts_preview_modal_help'}
            defaultMessage="The preview is still in development and may not be accurate."
          />
        </InfoText>
      </FlexContainer>
    </Modal>
  );
};
