import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import React, {ChangeEvent} from 'react';

import {FormSection} from '../../layout/FormSection/FormSection';
import {InputVariant} from '../Input/variants';
import css from './TextArea.module.scss';

export namespace TextArea {
  export type Props = {
    variant?: InputVariant;
    className?: string;
    style?: React.CSSProperties;
    onChange: (value: string) => void;
    label?: {
      text: FormSection.Props['title'];
      helper: FormSection.Props['description'];
    };
    rows?: number;
  } & Omit<
    React.InputHTMLAttributes<HTMLTextAreaElement>,
    'className' | 'style' | 'onChange'
  >;
}

export const TextArea = ({
  variant = 'primary',
  className,
  style,
  onChange = (value: string) => console.log(value),
  label,
  ...props
}: TextArea.Props) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value);
  };

  return (
    <ConditionalWrap
      condition={!!label}
      wrap={children => (
        <FormSection
          title={label!.text}
          description={label!.helper}
        >
          {children}
        </FormSection>
      )}
    >
      <div className={css.container}>
        <textarea
          data-variant={variant}
          type="text"
          className={`${css.root} ${className}`}
          onChange={handleChange}
          style={style}
          draggable={false}
          {...props}
        />
      </div>
    </ConditionalWrap>
  );
};
