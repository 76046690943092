import React, {CSSProperties, useRef} from 'react';

import css from './StepperPoint.module.scss';

export type StepperPointProps = {
  size?: number;
  label?: string | React.ReactNode;
  active?: boolean;
  current?: boolean;
  color?: CSSProperties['color'];
  activeColor?: CSSProperties['color'];
  onClick?: () => void;
  className?: string;
  style?: CSSProperties;
};

const StepperPoint = ({
  size,
  label,
  active,
  current,
  color = 'white',
  activeColor = 'white',
  onClick,
  className,
  style,
}: StepperPointProps) => {
  const uniqueId = useRef(Math.random().toString(36).substring(7));

  return (
    <div
      className={`${css.container} ${className ?? ''} ${current ? css.animated : ''}`}
      style={
        {
          '--size': `${size ?? 24}px`,
          '--color': active && !current ? activeColor : color,
          '--active-color': activeColor,
          '--path': `url(#${uniqueId.current})`,
          marginBottom: label ? '1.5rem' : '0px',
          cursor: onClick ? 'pointer' : undefined,
          ...style,
        } as CSSProperties
      }
      onClick={onClick}
    >
      <svg style={{position: 'absolute', width: 0, height: 0}}>
        <clipPath
          id={uniqueId.current}
          clipPathUnits="objectBoundingBox"
        >
          <path d="M 0.7545 0.2463 L 0.3787 0.609 L 0.279 0.5024 C 0.2606 0.4851 0.2317 0.484 0.2107 0.4987 C 0.1903 0.514 0.1845 0.5407 0.1971 0.5622 L 0.3152 0.7544 C 0.3267 0.7722 0.3467 0.7832 0.3692 0.7832 C 0.3908 0.7832 0.4112 0.7722 0.4228 0.7544 C 0.4417 0.7297 0.8023 0.2998 0.8023 0.2998 C 0.8495 0.2515 0.7923 0.209 0.7545 0.2457 V 0.2463 Z"></path>
        </clipPath>
      </svg>
      <div
        className={
          `${css.stepperPoint} ${active ? css.active : ''} ${
            onClick ? css.activable : ''
          }` + (current ? ` ${css.animated}` : '')
        }
      />
      {label ? (
        <p className={`${css.label} ${current ? css.animated : ''}`}>{label}</p>
      ) : null}
    </div>
  );
};

export default StepperPoint;
