import logLevel from '@autocut/enums/logLevel.enum';
import {reportSentryError} from '@autocut/utils/sentry.utils';

import {AutoCutApiError} from './AutoCutApiError';

export class IncrementalError extends Error {
  error: Error | AutoCutApiError | undefined;
  fingerprint = '';
  status: number | undefined;
  type: string;

  constructor(
    baseError: Error | AutoCutApiError | string,
    fingerprint: string,
  ) {
    const error =
      typeof baseError === 'string' ? new Error(baseError) : baseError;

    super(error.message);
    this.cause = error.cause;
    this.name = error.name;
    this.type = error.name;
    this.stack = error.stack;
    this.message = error.message;

    if (error instanceof IncrementalError) {
      this.status = error.status;
      this.error = error.error;
      this.fingerprint = `${fingerprint} - ${error.fingerprint}`;
    } else {
      this.error = error;
      this.message = error.message;
      this.fingerprint = (error as any).fingerprint
        ? `${fingerprint} - ${(error as any).fingerprint}`
        : fingerprint;

      if (error instanceof AutoCutApiError) {
        this.status = error.status;
        this.cause = error.description;
      } else {
        this.status = (baseError as any)?.response?.status;
      }

      console.trace(
        'NEW IncrementalError : ',
        this.fingerprint,
        this.message,
        this.stack,
      );
    }
  }

  reportToSentry() {
    // const tags = this.breadcrumb
    //   .split(' - ')
    //   .reduce((acc, fingerprint) => ({...acc, [fingerprint]: true}), {
    //     breadcrumb: this.breadcrumb,
    //   });

    console.log('REPORTING SENTRY ERROR', this.fingerprint, this.toJSON());

    reportSentryError(
      this.fingerprint
        .replace(/\(/g, '( - ')
        .replace(/\)/g, ' - )')
        .split(' - ')
        .filter(Boolean)
        .map(fingerprint => fingerprint.trim() + ' '),
      this,
      this.toJSON(),
      logLevel.error,
      scope => {
        // scope.setTags(tags);
        scope.setTransactionName(this.name);
      },
    );
  }

  toJSON() {
    return {
      error: this.error,
      fingerprint: this.fingerprint,
      cause: this.cause,
      name: this.name,
      stack: this.stack,
      message: this.message,
    };
  }

  toString() {
    return `IncrementalError : [${this.fingerprint}] ${this.message}\n${this.stack}`;
  }
}
