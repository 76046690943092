import {preload, waitForPreload} from '@autocut/types/ElectronPreload';

export const isDvAppStore = async () => {
  await waitForPreload();

  const {fs, path, os} = preload();

  if (os.platform() !== 'darwin') return false;

  const appStorePath = path.join('/', 'Applications', 'DaVinci Resolve.app');
  return fs.existsSync(appStorePath);
};
