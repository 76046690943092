import {NotificationContext} from '@autocut/contexts/NotificationProvider.tsx/NotificationProvider';
import {colors} from '@autocut/designSystem/colors';
import {Badge} from '@autocut/designSystem/components/atoms/Badge/Badge';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Card} from '@autocut/designSystem/components/atoms/Card/Card';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import {IconAlertCircle} from '@autocut/designSystem/components/atoms/Icon/alerts/IconAlertCircle';
import {IconArrowRight} from '@autocut/designSystem/components/atoms/Icon/arrows/IconArrowRight';
import {IconAngelList} from '@autocut/designSystem/components/atoms/Icon/social/IconAngelList';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {RadioButton} from '@autocut/designSystem/components/atoms/RadioButton/RadioButton';
import {
  Select,
  SelectOption,
} from '@autocut/designSystem/components/atoms/Select/Select';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {Container} from '@autocut/designSystem/components/layout/Container/Container';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {Accordion} from '@autocut/designSystem/components/molecules/Accordion/Accordion';
import {BadgeSelector} from '@autocut/designSystem/components/molecules/BadgeSelector/BadgeSelector';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {
  RadioForm,
  RadioFormProps,
} from '@autocut/designSystem/components/molecules/RadioForm/RadioForm';
import {FontSize} from '@autocut/designSystem/enums/fontSize.enum';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
//import {openLinkInBrowser} from '@autocut/lib/utils/bolt';
import {useContext, useState} from 'react';

const BADGE_SELECTOR_OPTIONS = [
  {
    value: 'test',
    label: 'Badge 1',
  },
  {
    value: 'selected',
    label: 'selected',
  },
  {
    value: 'test1',
    label: 'Badge 2',
  },
  {
    value: 'test2',
    label: 'Badge 3',
  },
  {
    value: 'test3',
    label: 'Badge 4',
  },
];

const RADIO_FORM_OPTIONS: RadioFormProps<string>['options'] = [
  {
    value: 'remove-silences',
    name: 'remove-silences',
    title: 'Remove silences',
    description: 'Cut and delete all silences',
  },
  {
    name: 'remove-silences-keep-spaces',
    value: 'remove-silences-keep-spaces',
    title: 'Remove silences but keep spaces',
    description: 'Cut and delete all silences but keep spaces',
    defaultChecked: true,
  },
];

const SELECT_OPTIONS: SelectOption[] = [
  {
    label: 'First value',
    value: 'first',
  },
  {
    label: 'Second value',
    value: 'second',
  },
  {
    label:
      'Third value very long the should overflow skjdhfiu ishdfkshdfsifh4khi h  si6khdi',
    value: 'third',
  },
];

export const DesignSystemExample = () => {
  const [badgeValue, setBadgeValue] = useState<string | undefined>('selected');

  const [checkBoxPillValue, setCheckBoxPillValue] = useState(false);
  const [checkBoxBox1Value, setCheckBoxBox1Value] = useState(false);
  const [checkBoxBox2Value, setCheckBoxBox2Value] = useState(false);

  const [simpleInputValue, setSimpleInputValue] = useState<string | undefined>(
    undefined,
  );
  const [unitInputValue, setUnitInputValue] = useState(0);

  const [singleSelectValue, setSingleSelectValue] = useState<
    string | undefined
  >(undefined);
  const [multiSelectValue, setMultiSelectValue] = useState<
    string[] | undefined
  >(undefined);

  const {displayNotification} = useContext(NotificationContext);

  return (
    <Container>
      <FlexContainer
        style={{paddingTop: Spacing.s4, paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <FormSection
          title="This is a FormSection"
          description="It has a description, perfect for helper texts"
        >
          <PaddedSection>
            <FormSection
              variant="secondary"
              title="It can even be nested"
              description='Using the variant="secondary" prop'
            >
              <PaddedSection>
                The vertical bar on the left is a PaddedSection
              </PaddedSection>
            </FormSection>
          </PaddedSection>
        </FormSection>

        <FormSection
          title="Text"
          description='Text component uses variant and color props. The variant is the same as in the Figma. For exemple if in the Figma a text is a bold displaySm, then the Text component will have "displaySm.bold" as variant'
        >
          <PaddedSection>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s2}
            >
              <Text
                variant="displaySm.bold"
                color={colors.primary600}
              >
                displaySm.bold primary600 text
              </Text>
              <Text
                variant="textMd"
                color={colors.gray200}
              >
                textMd gray200 text
              </Text>
              <Text
                variant="textXs"
                color={colors.gray500}
              >
                textXs gray500 text
              </Text>
            </FlexContainer>
          </PaddedSection>
        </FormSection>

        <FormSection title="Badges">
          <PaddedSection>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s2}
            >
              <FormSection
                variant="secondary"
                title="A single badge"
              >
                <Badge>Badge 1</Badge>
              </FormSection>
              <FormSection
                variant="secondary"
                title="Multiple selectable badges"
              >
                <BadgeSelector
                  selected={badgeValue}
                  onChange={setBadgeValue}
                  options={BADGE_SELECTOR_OPTIONS}
                />
              </FormSection>
            </FlexContainer>
          </PaddedSection>
        </FormSection>
        <FormSection title="Cards">
          <PaddedSection>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s2}
            >
              <FormSection
                variant="secondary"
                title="A single card"
              >
                <Card>Card 1</Card>
              </FormSection>
            </FlexContainer>
          </PaddedSection>
        </FormSection>
        <FormSection
          title="Buttons"
          description="Buttons have variants (primary, secondary, seconday.dashed, tertiary), sizes (sm, md, lg) and colors (any css color or colors value)"
        >
          <FlexContainer
            flexDirection="column"
            gap={Spacing.s2}
          >
            <Button
              size="lg"
              color={colors.primary600}
            >
              Primary large button
            </Button>
            <Button
              size="md"
              color={colors.primary600}
            >
              Primary medium button
            </Button>
            <Button
              size="sm"
              color={colors.primary600}
            >
              Primary small button
            </Button>
            <Button
              variant="secondary"
              size="md"
            >
              Secondary medium button
            </Button>
            <Button
              variant="secondary.dashed"
              size="md"
            >
              Secondary dashed medium button
            </Button>
            <Button variant="tertiary">Tertiary button</Button>
            <Button
              variant="secondary"
              size="md"
              fullWidth={false}
            >
              <FlexContainer
                gap={Spacing.s4}
                justifyContent="space-between"
                alignItems="center"
              >
                <IconAlertCircle
                  size={20}
                  color="white"
                />
                It can even have an icon
              </FlexContainer>
            </Button>
          </FlexContainer>
        </FormSection>
        <FormSection
          title={
            <FlexContainer
              gap={Spacing.s2}
              alignItems="center"
            >
              <div>CheckBox</div>
              <CheckBox
                variant="switch"
                size={20}
                checked={checkBoxPillValue}
                onChange={setCheckBoxPillValue}
              />
            </FlexContainer>
          }
          description="The above checkbox is a switch variant, it opens an Accordion when checked"
        >
          <Accordion open={checkBoxPillValue}>
            <PaddedSection>
              <FormSection
                variant="secondary"
                title="Box variant"
                description="This variant should be used when you can check multiple values, otherwise the RadioButton is more suited"
              >
                <FlexContainer
                  flexDirection="column"
                  gap={Spacing.s2}
                >
                  <CheckBox
                    size={20}
                    variant="box"
                    checked={checkBoxBox1Value}
                    onChange={setCheckBoxBox1Value}
                    title="Example checkbox"
                  />
                  <CheckBox
                    size={20}
                    variant="box"
                    checked={checkBoxBox2Value}
                    onChange={setCheckBoxBox2Value}
                    title="This is an InputLabel title"
                    description="This is an InputLabel description"
                  />
                </FlexContainer>
              </FormSection>
            </PaddedSection>
          </Accordion>
        </FormSection>
        <FormSection title="RadioButton">
          <PaddedSection>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s2}
            >
              <RadioButton
                name="single"
                value="single"
                checked
                title="Single RadioButton"
              />
              <FormSection
                variant="secondary"
                title="Multiple RadioButton"
                description="Use RadioForm to force selecting only one button"
              >
                <RadioForm
                  onChange={value => console.log(value)}
                  options={RADIO_FORM_OPTIONS}
                />
              </FormSection>
            </FlexContainer>
          </PaddedSection>
        </FormSection>
        <Divider />
        <FormSection
          title="Icons"
          description={
            <div>
              You have access to a lot of icons, you can mainly choose their
              size and color. List of all icons :{' '}
              <Button
                onClick={
                  () => console.log('')
                  // openLinkInBrowser(
                  //   'https://www.figma.com/design/5DN6860JLec2e0kf4G8Bz7/AutoCut---Untilted-UI?node-id=3463-407484&t=rMjYFwWJwzVYDKY9-0',
                  // )
                }
                variant="tertiary"
              >
                <Text
                  variant="textXs"
                  color="white"
                  style={{lineHeight: FontSize.textXs}}
                >
                  Figma
                </Text>
              </Button>
              . If there is one that is not in the design system, feel free add
              it the Icons folder. Naming convention is Icon + Pascal case of
              the name.
            </div>
          }
        >
          <PaddedSection>
            <FlexContainer gap={Spacing.s2}>
              <IconAlertCircle
                size={24}
                color="white"
              />{' '}
              <IconAngelList
                size={24}
                color="white"
              />
              <IconArrowRight
                size={24}
                color="white"
              />
            </FlexContainer>
          </PaddedSection>
        </FormSection>
        <FormSection title="Inputs">
          <PaddedSection>
            <FlexContainer
              gap={Spacing.s2}
              flexDirection="column"
            >
              <FormSection
                variant="secondary"
                title="Simple"
              >
                <Input
                  value={simpleInputValue}
                  onChange={setSimpleInputValue}
                  defaultValue="Default value"
                />
              </FormSection>
              <FormSection
                variant="secondary"
                title="With unit"
              >
                <Input
                  value={unitInputValue}
                  onChange={value => {
                    const numberValue = Number(value);
                    setUnitInputValue(isNaN(numberValue) ? 0 : numberValue);
                  }}
                  defaultValue="Default value"
                  unit="unit"
                />
              </FormSection>
            </FlexContainer>
          </PaddedSection>
        </FormSection>
        <FormSection title="Select">
          <PaddedSection>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s2}
            >
              <FormSection
                variant="secondary"
                title="Single value select"
              >
                <Select
                  options={SELECT_OPTIONS}
                  selected={singleSelectValue}
                  onChange={setSingleSelectValue}
                />
              </FormSection>
              <FormSection
                variant="secondary"
                title="Single value select fullWidth"
              >
                <Select
                  options={SELECT_OPTIONS}
                  selected={singleSelectValue}
                  onChange={setSingleSelectValue}
                  fullWidth
                />
              </FormSection>
              <FormSection
                variant="secondary"
                title="Multiple values select fullWidth"
              >
                <Select
                  options={SELECT_OPTIONS}
                  selected={multiSelectValue}
                  onChange={setMultiSelectValue}
                  allowMultiple
                  fullWidth
                />
              </FormSection>
              <FormSection
                variant="secondary"
                title="Multiple values select fullWidth clearable allowSelectAll"
              >
                <Select
                  options={SELECT_OPTIONS}
                  selected={multiSelectValue}
                  onChange={setMultiSelectValue}
                  allowMultiple
                  fullWidth
                  clearable
                  allowSelectAll
                />
              </FormSection>
            </FlexContainer>
          </PaddedSection>
        </FormSection>
        <FormSection title="Notifications">
          <Button
            variant="primary"
            color={colors.primary600}
            onClick={() =>
              displayNotification(<Text color="white">Hello World</Text>)
            }
          >
            <Text>Display Notif</Text>
          </Button>
        </FormSection>
      </FlexContainer>
    </Container>
  );
};
