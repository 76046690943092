import {setAutocutStore} from '../zustand';

/**
 * This function closes the current modal.
 * It will display the next modal in the queue if there is one.
 */
export const closeModal = () => {
  console.log('[MODAL] Closing modal');
  setAutocutStore('ui.openedModalName', '');
};
