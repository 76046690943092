import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {SelectedAudioTrackStep} from '@autocut/components/SelectedAudioTracksStep/SelectedAudioTracksStep';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useCheckDeactivatedTracks} from '@autocut/hooks/useCheckDeactivatedTracks';
import {SilenceStep} from '@autocut/pages/modes/legacy/Parts/SilenceStep/SilenceStep';
import {TransitionStep} from '@autocut/pages/modes/legacy/Parts/TransitionStep/TransitionStep';
import {compute} from '@autocut/utils/compute.utils';
import {host} from '@autocut/utils/host';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {autocutStoreVanilla} from '@autocut/utils/zustand';
import {useEffect} from 'react';
import {useIntl} from 'react-intl';

import {NoiseLevelStep} from './Parts/NoiseLevelStep/NoiseLevelStep';
import {PacingStep} from './Parts/PacingStep/PacingStep';
import {PaddingStep} from './Parts/PacingStep/PaddingStep/PaddingStep';
import {SilenceSizeStep} from './Parts/PacingStep/SilenceSizeStep/SilenceSizeStep';
import {PreviewStep} from './Parts/PreviewStep/PreviewStep';
import {SilencesFooterButton} from './Parts/SilencesFooterButton';

export const SilenceCustomizationStep = () => {
  const {allTracksDeactivated, isInitialized} = useCheckDeactivatedTracks();
  const intl = useIntl();
  const {parameters} = useAutoCutStore(state => ({
    parameters: state.ui.parameters.silence,
  }));

  const handleClick = handleProcessBase(
    {
      executeProcess: async (_, progress) => {
        const podcastProcess = compute.process.silence(parameters);

        updateProcessStep(progress, 'silences_mainProcess', {
          progress: {
            computeTaskId: podcastProcess.requestId,
          },
          countFor: 12, // Number of steps in compute
        });

        const result = await podcastProcess;

        endProcessStep(progress, 'silences_mainProcess');

        return result;
      },
      // handleProcessFinallyAdditional: async () => {
      //   if (autocutStoreVanilla().ui.host === 'davinci') return;
      //   await host.timeline.deleteAutocutMarkers();
      // },
    },
    {
      processTitleNameKey: 'modes_silence_title',
      processSteps: [
        {
          id: 'silences_mainProcess',
          translationKey: 'progress_steps_silences_mainProcess',
          progress: '',
        },
      ],
    },
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <SilencesFooterButton
              ref={buttonRef}
              isLoading={isLoading || !isInitialized}
              isDisabled={isDisabled || allTracksDeactivated}
              onClick={() => handleClickWithValidation(() => handleClick(intl))}
            />
          )}
          errorId={
            allTracksDeactivated
              ? 'modes_general_steps_transcription_noTrackSelected'
              : undefined
          }
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <NoiseLevelStep modeId="silence" />
        <Divider />
        <PacingStep />
        <Divider />
        <SilenceStep />
        <Divider />
        <TransitionStep modeId="silence" />
        <Divider />
        <SelectedAudioTrackStep />
        <Divider />
        <PreviewStep />
      </FlexContainer>
    </ModeLayout>
  );
};
