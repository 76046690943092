import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {ZoomTypes} from '@autocut/enums/zoomTypes.enum';
import {z as zod} from 'zod';

const anchorValidationSchema = zod.object({
  xPercentage: zod
    .number()
    .min(0, 'modes_zoom_error_validation_anchor' as TranslationKeys)
    .max(1, 'modes_zoom_error_validation_anchor' as TranslationKeys),
  yPercentage: zod
    .number()
    .min(0, 'modes_zoom_error_validation_anchor' as TranslationKeys)
    .max(1, 'modes_zoom_error_validation_anchor' as TranslationKeys),
});

export const zoomValidationSchema = zod
  .object({
    autoZoomMinCoef: zod
      .number()
      .min(
        1,
        'modes_zoom_error_validation_autoZoomMinCoef_min' as TranslationKeys,
      )
      .max(
        2,
        'modes_zoom_error_validation_autoZoomMinCoef_max' as TranslationKeys,
      ),
    autoZoomMaxCoef: zod
      .number()
      .min(
        1,
        'modes_zoom_error_validation_autoZoomMaxCoef_min' as TranslationKeys,
      )
      .max(
        2,
        'modes_zoom_error_validation_autoZoomMaxCoef_max' as TranslationKeys,
      ),
    pproAnchorPosMinCoef: anchorValidationSchema,
    pproAnchorPosMaxCoef: anchorValidationSchema,
    davinciAnchorPosPercentage: anchorValidationSchema,
    totalZoomPercentage: zod
      .number()
      .gt(
        0,
        'modes_zoom_error_validation_totalZoomPercentage' as TranslationKeys,
      )
      .max(
        1,
        'modes_zoom_error_validation_totalZoomPercentage' as TranslationKeys,
      ),
    dynamicZoomPercentage: zod
      .number()
      .min(
        0,
        'modes_zoom_error_validation_dynamicZoomPercentage_min' as TranslationKeys,
      )
      .max(
        1,
        'modes_zoom_error_validation_dynamicZoomPercentage_max' as TranslationKeys,
      ),
    dynamicMinZoomTime: zod
      .number()
      .min(0, 'modes_zoom_error_validation_minZoomTime' as TranslationKeys),
    dynamicMaxZoomTime: zod
      .number()
      .min(0, 'modes_zoom_error_validation_maxZoomTime' as TranslationKeys),
    dynamicMinZoomIntensity: zod
      .number()
      .min(
        0,
        'modes_zoom_error_validation_dynamicMinZoomIntensity' as TranslationKeys,
      )
      .max(
        1,
        'modes_zoom_error_validation_dynamicMinZoomIntensity' as TranslationKeys,
      ),
    constraintZoom: zod.boolean(),
    smoothZoomPercentage: zod
      .number()
      .min(
        0,
        'modes_zoom_error_validation_smoothZoomPercentage' as TranslationKeys,
      )
      .max(
        1,
        'modes_zoom_error_validation_smoothZoomPercentage' as TranslationKeys,
      ),
    smoothMinZoomTime: zod
      .number()
      .min(0, 'modes_zoom_error_validation_minZoomTime' as TranslationKeys),
    smoothMaxZoomTime: zod
      .number()
      .min(0, 'modes_zoom_error_validation_minZoomTime' as TranslationKeys),
    smoothZoomSpeed: zod.number(),
    nervousness: zod
      .number()
      .min(0, 'modes_zoom_error_validation_nervousness' as TranslationKeys)
      .max(1, 'modes_zoom_error_validation_nervousness' as TranslationKeys),
    zoomTypes: zod.object({
      [ZoomTypes.DYNAMIC]: zod.boolean(),
      [ZoomTypes.SMOOTH]: zod.boolean(),
    }),
  })
  .refine(
    schema => schema.dynamicMinZoomTime < schema.dynamicMaxZoomTime,
    'modes_zoom_error_validation_time' as TranslationKeys,
  )
  .refine(
    schema => schema.smoothMinZoomTime < schema.smoothMaxZoomTime,
    'modes_zoom_error_validation_time' as TranslationKeys,
  )
  .refine(
    data => Object.values(data.zoomTypes).some(value => value === true),
    'modes_zoom_error_validation_types_atLeast_one_true' as TranslationKeys,
  );
