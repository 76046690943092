export function rotateToMouse(
  e: MouseEvent,
  cardElement: HTMLDivElement,
  bounds: DOMRect,
) {
  e.preventDefault();

  if (!cardElement) return;

  const [w, h] = [bounds.width, bounds.height];
  const mousePosition = [e.offsetX - w / 2, -(e.offsetY - h / 2)];
  const normalized = [(mousePosition[0] / w) * 2, (mousePosition[1] / h) * 2];

  cardElement.style.transform = `
  rotateX(${-normalized[1] * 10}deg)
  rotateY(${-normalized[0] * 10}deg)
  `;

  const glowElement = cardElement.querySelector(
    'span.glow',
  ) as HTMLDivElement | null;

  if (!glowElement) return;

  const mouseX = e.clientX;
  const mouseY = e.clientY;
  const leftX = mouseX - bounds.x;
  const topY = mouseY - bounds.y;
  const center = {
    x: leftX - bounds.width / 2,
    y: topY - bounds.height / 2,
  };

  glowElement.style.backgroundImage = `
    radial-gradient(
      circle at
      ${center.x * 2 + bounds.width / 2}px
      ${center.y * 2 + bounds.height / 2}px,
      rgba(246,97,42,0.15) 0%,
      #0000000f
    )
  `;
}
