import {REPEAT_ALGORITHM} from '@autocut/enums/repeatAlgorithm.enum';
import {ProcessTranscriptFunction} from '@autocut/types/Transcription';
import {compute} from '@autocut/utils/compute.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {setAutocutStore} from '@autocut/utils/zustand';

export const handleRepeatProcessTranscript: ProcessTranscriptFunction = async (
  intl,
  usedModel = 0,
) => {
  try {
    const processProgress = initProcessProgress(
      'repeat_transcript',
      'progress_steps_transcript_mainProcess',
    );
    addProcessSteps(processProgress, [
      {
        id: 'repeat_transcript_getLanguage',
        translationKey: 'progress_steps_repeat_transcript_getLanguage',
        progress: 1,
      },
      {
        id: 'repeat_transcript_mainProcess',
        translationKey: 'progress_steps_transcript_mainProcess',
        progress: '',
        countFor: 4,
      },
    ]);

    startProcessProgress(processProgress);

    const parameters = getParametersForMode('repeat');

    const language = getTranscriptionLanguage({
      languageOfTranscriptionValue: parameters.languageOfTranscription.value,
      usedModel,
    });

    endProcessStep(processProgress, 'repeat_transcript_getLanguage');

    const transcriptProcess = compute.process.repeat.getRepeatUtterances({
      language,
      type:
        parameters.selectedAlgorithm === REPEAT_ALGORITHM.FAST
          ? 'utterances'
          : 'delimited-repetitions',
    });

    updateProcessStep(processProgress, 'repeat_transcript_mainProcess', {
      progress: {
        computeTaskId: transcriptProcess.requestId,
      },
    });

    const {delimitedUtterances: result, filePath} = await transcriptProcess;
    const timelineInfos = await compute.timeline.getInfos();

    setAutocutStore('onGoingProcess.repeatUtterances', result);
    setAutocutStore('onGoingProcess.audioFilepath', filePath);
    setAutocutStore('onGoingProcess.timelineInfos', timelineInfos);

    endProcessStep(processProgress, 'repeat_transcript_mainProcess');
  } catch (error: any) {
    throw new IncrementalError(error, 'handleChaptersProcessTranscript');
  }
};
