import {ModalType} from '@autocut/enums/modals.enum';
import {preload, waitForPreload} from '@autocut/types/ElectronPreload';
import {initApi} from '@autocut/utils/http.utils';

import {isKeyValid} from './auth.utils';
import {IncrementalError} from './errors/IncrementalError';
import {loadUserLocalFonts} from './fonts.utils';
import {getScoreFromApi} from './game/getScoreFromApi';
import {checkPeriodicKey} from './init/checkPeriodicKey';
import {markAutoCutV2} from './init/markV2';
import {login} from './license/login';
import {getKey} from './localStorage.utils';
import {manageError} from './manageError';
import {addModalToQueue} from './modal/addModalToQueue';
import {refreshClientInfos} from './system/system.utils';
import {autocutStoreVanilla, setAutocutStore} from './zustand';

export const initAutocut = async () => {
  let errorCode = 0;

  try {
    await waitForPreload('initAutoCut');
    errorCode = 120;

    initApi();

    await markAutoCutV2();
    errorCode = 175;

    await refreshClientInfos();
    errorCode = 185;

    await loadUserLocalFonts(); // Load user local fonts
    errorCode = 195;

    //LOGIN
    let key = autocutStoreVanilla().user.key;
    if (!key) key = getKey(false);
    errorCode = 200;
    if (key) {
      const keyCheckResponse = await isKeyValid(key);
      errorCode = 201;

      if (keyCheckResponse.result) {
        errorCode = 202;
        const license = {
          ...keyCheckResponse.licence,
          key,
        };
        errorCode = 203;
        await login({
          ...keyCheckResponse.accessTokens,
          key,
          license,
          additionalInformations: keyCheckResponse.additionalInformations,
        });
        errorCode = 204;
        if (license.type === 'trial') addModalToQueue(ModalType.GamingTrial);
        await initAfterLogged().catch(e => {
          errorCode = 205;
          throw e;
        });
      } else {
        errorCode = 206;
      }
    }
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'initAutocut'),
      additionalData: `errorCode: ${errorCode}`,
    });
  }
};

export const initAfterLogged = async () => {
  let errorCode = 0;
  const autocutState = autocutStoreVanilla();

  try {
    //Setup periodic key check (each 10 minutes)
    const checkPeriodicKeyInterval = setInterval(async () => {
      await checkPeriodicKey();
    }, 600000);
    setAutocutStore('utils.intervals', state => [
      ...state.utils.intervals,
      checkPeriodicKeyInterval,
    ]);

    // await loadStartUpModals();

    //Fetch and restore the game related state variables
    await getScoreFromApi().catch(e => {
      errorCode = 300;
      throw e;
    });

    errorCode = 700;
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'initAfterLogged'),
      additionalData: `errorCode: ${errorCode}`,
    });
  }
};
