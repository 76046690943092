import {OldButton} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import React from 'react';
import {RiCoinsLine} from 'react-icons/ri';

import css from './KeyExpiredModal.module.css';

export const KeyExpiredModal = () => {
  const intl = useOldIntl();

  const keyAdditionalInfos = useAutoCutStore(
    state => state.user.additionalInformations,
  );

  const isRecoverable = keyAdditionalInfos?.latestInvoice || false;

  return (
    <Modal
      fullWidth
      title={intl.formatMessage({
        id: 'key_expired_modal_title',
        defaultMessage: 'Lack of payment',
      })}
      icon={<RiCoinsLine size={24} />}
      footer={
        <OldButton
          onClickFunction={() => {
            // TODO : openLinkInBrowser
            // openLinkInBrowser(
            //   isRecoverable ? isRecoverable : 'https://autocut.fr/#pricing'
            // );
          }}
        >
          {isRecoverable
            ? intl.formatMessage({
                id: 'key_expired_modal_button_recover',
                defaultMessage: 'Recover subscription',
              })
            : intl.formatMessage({
                id: 'key_expired_moda_button_new',
                defaultMessage: 'Get a new subscription',
              })}
        </OldButton>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap="4px"
      >
        <p>
          <OldTranslatedMessage
            id="key_expired_modal_to_continue"
            defaultMessage="Your current subscription has been <b>{status}</b> due to a payment issue. To continue using AutoCut, please:"
            values={{
              status: isRecoverable
                ? intl.formatMessage({
                    id: 'key_expired_modal_deactivated',
                    defaultMessage: 'deactivated',
                  })
                : intl.formatMessage({
                    id: 'key_expired_modal_deleted',
                    defaultMessage: 'deleted',
                  }),
            }}
          />
        </p>
        {isRecoverable ? (
          <ul>
            <li>
              <p>
                <OldTranslatedMessage
                  id="key_expired_modal_recover_list_click"
                  defaultMessage="<b>Click on the button bellow</b> to recover your subscription or check your email invoice for an email from us or stripe asking to pay your latest invoice"
                />
              </p>
            </li>
            <li>
              <p>
                <OldTranslatedMessage
                  id="key_expired_modal_recover_list_re_activated"
                  defaultMessage="Your key will be <b>re-activated immediately</b> after the payment update"
                />
              </p>
            </li>
          </ul>
        ) : (
          <ul>
            <li>
              <p>
                <OldTranslatedMessage
                  id="key_expired_modal_new_list_website"
                  defaultMessage="Visit the AutoCut website: <span>https://www.autocut.fr/#pricing</span>"
                  //VALUE LINK
                  values={{
                    span: chunks => (
                      <span
                        className={css.link}
                        onClick={() => {
                          // TODO : openLinkInBrowser
                          // openLinkInBrowser('https://www.autocut.fr/#pricing')
                        }}
                      >
                        <b>{chunks}</b>
                      </span>
                    ),
                  }}
                />
              </p>
            </li>
            <li>
              <p>
                <OldTranslatedMessage
                  id="key_expired_modal_new_list_subscribe"
                  defaultMessage="Subscribe to a new license key"
                />
              </p>
            </li>
          </ul>
        )}
        <p>
          <OldTranslatedMessage
            id="key_expired_modal_support"
            defaultMessage="Reach out to our support team if you need help: <span>contact@autocut.fr</span>"
            //VALUE LINK
            values={{
              span: chunks => (
                <span
                  className={css.link}
                  onClick={() => {
                    // TODO : openLinkInBrowser
                    // openLinkInBrowser('mailto:contact@autocut.fr')
                  }}
                >
                  <b>{chunks}</b>
                </span>
              ),
            }}
          />
        </p>
        <p>
          <OldTranslatedMessage
            id="key_expired_modal_thanks"
            defaultMessage="Thank you!"
          />
        </p>
      </FlexContainer>
    </Modal>
  );
};
