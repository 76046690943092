import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {debounce} from '@autocut/utils/debounce';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

export type PaddingStepProps = {
  modeId?: 'silence';
  isAnimated?: boolean;
};

export const PaddingStep = ({
  modeId = 'silence',
  isAnimated = false,
}: PaddingStepProps) => {
  const intl = useIntl();
  const {marginBefore: marginBeforeParam, marginAfter: marginAfterParam} =
    useAutoCutStore(state => state.ui.parameters[modeId]);

  const [marginBefore, setMarginBefore] = useState(marginBeforeParam);
  const [marginAfter, setMarginAfter] = useState(marginAfterParam);

  useEffect(() => {
    setMarginBefore(marginBeforeParam);
  }, [marginBeforeParam]);

  useEffect(() => {
    setMarginAfter(marginAfterParam);
  }, [marginAfterParam]);

  const debouncedSetMarginBefore = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].marginBefore)
        setAutocutStore(`ui.parameters.${modeId}.marginBefore`, value);
    }, 1000),
    [],
  );

  const debouncedSetMarginAfter = useCallback(
    debounce((value: number) => {
      if (value !== autocutStoreVanilla().ui.parameters[modeId].marginAfter)
        setAutocutStore(`ui.parameters.${modeId}.marginAfter`, value);
    }, 1000),
    [],
  );

  return (
    <FormSection
      variant="secondary"
      title={intl.formatMessage({
        id: 'modes_silence_steps_paddingSize_title',
        defaultMessage: 'Padding',
      })}
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          <FormSection
            variant="secondary"
            title={intl.formatMessage({
              id: 'modes_silence_steps_paddingSize_items_before_title',
              defaultMessage: 'Before padding',
            })}
            description={intl.formatMessage({
              id: 'modes_silence_steps_paddingSize_items_before_description',
              defaultMessage:
                'Silent time before a noise - to avoid sudden resumption of speech',
            })}
          >
            <Input
              type="number"
              animate={isAnimated}
              style={{width: '50%'}}
              value={`${marginBefore * 1000}`}
              onChange={async (value: string) => {
                const numberValue = Number(value) / 1000;
                setMarginBefore(numberValue);
                await debouncedSetMarginBefore(numberValue);
              }}
              unit={intl.formatMessage({
                id: 'modes_silence_steps_paddingSize_items_before_unit',
                defaultMessage: 'milliseconds',
              })}
            />
          </FormSection>
          <FormSection
            variant="secondary"
            title={intl.formatMessage({
              id: 'modes_silence_steps_paddingSize_items_after_title',
              defaultMessage: 'After padding',
            })}
            description={intl.formatMessage({
              id: 'modes_silence_steps_paddingSize_items_after_description',
              defaultMessage:
                'Silent time after a noise - to avoid harsh speech interruptions',
            })}
          >
            <Input
              type="number"
              animate={isAnimated}
              style={{width: '50%'}}
              value={`${marginAfter * 1000}`}
              onChange={async (value: string) => {
                const numberValue = Number(value) / 1000;
                setMarginAfter(numberValue);
                await debouncedSetMarginAfter(numberValue);
              }}
              unit={intl.formatMessage({
                id: 'modes_silence_steps_paddingSize_items_after_unit',
                defaultMessage: 'milliseconds',
              })}
            />
          </FormSection>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
