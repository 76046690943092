import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {preload} from '@autocut/types/ElectronPreload';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {setAutocutStore} from '@autocut/utils/zustand';

import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import css from './licenceInfos.module.scss';

const LicenceInfos = () => {
  const [isLogged, license] = useAutoCutStore(state => [
    state.user.isLogged,
    state.user.license,
  ]);
  const {logMessage} = useLogger('LicenceInfos');

  if (!isLogged || !license) return null;

  const isTrial = license?.type === 'trial';
  const licenceText = isTrial ? (
    <TranslatedMessage
      id="globals_defaults_components_LicenseInfos_timeLeft"
      defaultMessage="Unkown timeleft"
      values={{daysLeft: license.left}}
    />
  ) : (
    <TranslatedMessage
      id="globals_defaults_components_LicenseInfos_info"
      defaultMessage="Unknown licence"
      values={{licenceType: license.key_type?.toUpperCase() || 'UNKNOWN'}}
    />
  );

  return (
    <FlexContainer
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="nowrap"
      gap={12}
      className={css.container}
    >
      <p className={css.text}>{licenceText}</p>
      <button
        className={css.buyButton}
        onClick={() => {
          if (isTrial) {
            addModalToQueue(ModalType.GamingTrial);
            return;
          } else {
            logMessage(logLevel.info, 'User went to AutoCut website.');
            void preload().electron.openLinkInBrowser(
              preload().electron.getSharedVariables().WEBAPP_URL,
            );
          }
        }}
      >
        <p className={css.text}>
          {isTrial && (
            <TranslatedMessage
              id="globals_defaults_components_LicenseInfos_trialButton"
              defaultMessage="Get more"
            />
          )}
          {!isTrial && (
            <TranslatedMessage
              id="globals_defaults_components_LicenseInfos_paidButton"
              defaultMessage="Manage"
            />
          )}
        </p>
        <div className={css.border} />
        <div className={css.blob} />
      </button>
    </FlexContainer>
  );
};

export {LicenceInfos};
