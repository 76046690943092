import {LoadingRing} from '@autocut/components/LoadingRing/LoadingRing';
import {Card} from '@autocut/designSystem/components/atoms/Card/Card';
import {IconTrash01} from '@autocut/designSystem/components/atoms/Icon/general/IconTrash01';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useState} from 'react';

import css from './TimecodesStepItem.module.scss';

export type TimecodesStepItemProps = {
  chapter: Chapter;
  previewFrame: string;
  onChange: (chapter: Chapter) => void;
  onDelete: () => void;
};

export const TimecodesStepItem = ({
  chapter,
  previewFrame,
  onChange,
  onDelete,
}: TimecodesStepItemProps) => {
  const [localChapter, setLocalChapter] = useState(chapter);

  const fireChange = () => onChange(localChapter);

  return (
    <Card className={css.root}>
      <FlexContainer
        gap={Spacing.s2}
        alignItems="center"
      >
        {previewFrame ? (
          <img
            className={css.previewFrame}
            src={previewFrame}
          />
        ) : (
          <FlexContainer
            className={css.loaderContainer}
            alignItems="center"
            justifyContent="center"
          >
            <LoadingRing size={32} />
          </FlexContainer>
        )}

        <FlexContainer
          className={css.rightContainer}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FlexContainer
            className={css.textContainer}
            flexDirection="column"
            justifyContent="space-between"
          >
            <FlexContainer
              gap={Spacing.s1}
              alignItems="center"
            >
              <Card className={css.timecodeContainer}>
                <Input
                  className={css.input}
                  variant="secondary"
                  onBlur={fireChange}
                  onChange={value =>
                    setLocalChapter({
                      ...localChapter,
                      timecodes: [value, chapter.timecodes[1]],
                    })
                  }
                  value={localChapter.timecodes[0]}
                />
              </Card>
              <Text variant="textSm.semibold">-</Text>
              <Card className={css.timecodeContainer}>
                <Input
                  className={css.input}
                  variant="secondary"
                  onBlur={fireChange}
                  onChange={value =>
                    setLocalChapter({
                      ...localChapter,
                      timecodes: [chapter.timecodes[0], value],
                    })
                  }
                  value={localChapter.timecodes[1]}
                />
              </Card>
            </FlexContainer>
            <Input
              className={css.input}
              variant="secondary"
              onBlur={fireChange}
              onChange={value =>
                setLocalChapter({...localChapter, title: value})
              }
              value={localChapter.title}
            />
          </FlexContainer>
          <IconTrash01
            onClick={onDelete}
            className={css.trashIcon}
            size={18}
          />
        </FlexContainer>
      </FlexContainer>
    </Card>
  );
};
