import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {IconGift01} from '@autocut/designSystem/components/atoms/Icon/general/IconGift01';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {createPortal} from 'react-dom';

import css from './GamingTrialAffix.module.css';

export const GamingTrialAffix = () => {
  const {openedModalName, license} = useAutoCutStore(state => ({
    openedModalName: state.ui.openedModalName,
    license: state.user.license,
  }));

  if (!license || license.type !== 'trial') return null;

  return openedModalName.length
    ? null
    : createPortal(
        <div className={css.container}>
          <ActionIcon
            variant="primary"
            size={40}
            onClick={() => addModalToQueue(ModalType.GamingTrial)}
          >
            <IconGift01
              size={24}
              color="white"
            />
          </ActionIcon>
        </div>,
        document.body,
      );
};
