import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React from 'react';
// import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {IconType} from 'react-icons';
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';

import css from './ModeTitle.module.scss';

export type ModeTitleProps = {
  Icon?: IconType;
  text: string;
  withHomeButton?: boolean;
  customHomeButtonHandler?: () => void;
};

export const ModeTitle = ({
  Icon,
  text,
  withHomeButton = true,
  customHomeButtonHandler,
}: ModeTitleProps) => {
  const navigate = useNavigate();
  const {isProcessing} = useAutoCutStore(state => ({
    isProcessing: state.ui.process.isProcessing,
  }));

  const handleOnClick = () => {
    if (isProcessing) return;
    navigate('/homepage');
  };

  return (
    <FlexContainer
      className={css.mainContainer}
      alignItems="center"
      justifyContent="space-between"
    >
      {withHomeButton && (
        <AiOutlineArrowLeft
          onClick={customHomeButtonHandler || handleOnClick}
          className={`${css.homeButton} ${
            isProcessing && !customHomeButtonHandler ? css.disabled : ''
          }`}
          size={24}
        />
      )}

      <FlexContainer
        className={css.titleContainer}
        alignItems="center"
        justifyContent="center"
        gap={16}
      >
        {Icon && <Icon size={24} />}
        <div className={css.title}>{text}</div>
      </FlexContainer>

      <div className={css.placeHolder} />
    </FlexContainer>
  );
};
