import React from 'react';

import {IconComponent, IconComponentProps} from '../Icon';

export const IconCheck = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20 6 9 17l-5-5"
      />
    </IconComponent>
  );
};
