import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {BRoll} from '@autocut/types/BRolls';
import {compute} from '@autocut/utils/compute.utils';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router-dom';

import {BRollsChoiceModal} from './Parts/BRollsChoiceModal/BRollsChoiceModal';
import {BRollsTranscriptItem} from './Parts/BrollsTranscriptItem/BRollsTranscriptItem';

export const BRollsTranscript = () => {
  const navigate = useNavigate();
  const intl = useIntl();

  const {bRolls} = useAutoCutStore(state => ({
    bRolls: state.onGoingProcess.bRolls,
  }));

  const [currentBRoll, setCurrentBroll] = useState<BRoll | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!bRolls) {
      navigate('../parameters');
    }
  }, [bRolls, navigate]);

  const handleBRollsProcess = handleProcessBase(
    {
      executeProcess: async (_, progress) => {
        if (!bRolls) return;

        const filteredBrolls = bRolls.filter(broll => broll.video);
        const brollsProcess = compute.process.bRolls({
          bRolls: filteredBrolls,
        });

        updateProcessStep(progress, 'broll_mainProcess', {
          progress: {
            computeTaskId: brollsProcess.requestId,
          },
          countFor: 3, // Number of steps in compute
        });

        const result = (await brollsProcess) as any;

        endProcessStep(progress, 'broll_mainProcess');

        setAutocutStore('ui.broll.usersToCredit', result);

        return result;
      },
    },
    {
      processTitleNameKey: 'modes_broll_title',
      processSteps: [
        {
          id: 'broll_mainProcess',
          translationKey: 'progress_steps_broll_mainProcess',
          progress: '',
        },
      ],
    },
  );

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <Button
              ref={buttonRef}
              color={colors.primary600}
              isLoading={isLoading}
              disabled={isDisabled}
              onClick={async () => {
                handleClickWithValidation(async () => {
                  await handleBRollsProcess(intl);
                  await handleTrialTask('broll');
                });
              }}
            >
              <TranslatedMessage
                id="modes_broll_steps_transcript_cta"
                defaultMessage="Add B-Rolls"
              />
            </Button>
          )}
        />
      }
    >
      <FormSection
        title={
          <TranslatedMessage
            id="modes_broll_steps_transcript_selection"
            defaultMessage={'B-Rolls selection'}
          />
        }
      >
        {currentBRoll && (
          <BRollsChoiceModal
            bRoll={currentBRoll}
            closeModalFunction={() => {
              setCurrentBroll(undefined);
            }}
          />
        )}
        <FlexContainer
          flexDirection="column"
          gap={8}
        >
          {bRolls
            ? bRolls.map(bRoll => (
                <BRollsTranscriptItem
                  openChoiceModal={(bRoll: BRoll) => {
                    setCurrentBroll(bRoll);
                  }}
                  key={bRoll.id}
                  bRoll={bRoll}
                />
              ))
            : null}
        </FlexContainer>
      </FormSection>
    </ModeLayout>
  );
};
