export type SelectionCoordinatesType = {
  startWordCoordinates: WordCoordinatesType;
  endWordCoordinates: WordCoordinatesType;
};

export type WordCoordinatesType = {
  groupIndex: number;
  utteranceIndex: number;
  wordIndex: number;
};

/**
 *
 * @param element
 * @returns The absolute position of the element in the page, accounting for scrolling
 */
export const getDocumentOffsetPosition = (element: HTMLElement) => {
  let top = 0;
  let left = 0;
  let nextElement: HTMLElement | null = element;
  while (nextElement !== null) {
    top += nextElement.offsetTop;
    left += nextElement.offsetLeft;
    nextElement = nextElement.offsetParent as HTMLElement;
  }
  return {top, left};
};

export enum SelectionDirection {
  FORWARD,
  BACKWARD,
}

export const getSelectionDirection = ({
  startWordCoordinates: {
    groupIndex: startGroupIndex,
    utteranceIndex: startUtteranceIndex,
    wordIndex: startWordIndex,
  },
  endWordCoordinates: {
    groupIndex: endGroupIndex,
    utteranceIndex: endUtteranceIndex,
    wordIndex: endWordIndex,
  },
}: SelectionCoordinatesType) => {
  if (startGroupIndex < endGroupIndex) {
    return SelectionDirection.FORWARD;
  } else if (startGroupIndex > endGroupIndex) {
    return SelectionDirection.BACKWARD;
  }

  if (startUtteranceIndex < endUtteranceIndex) {
    return SelectionDirection.FORWARD;
  } else if (startUtteranceIndex > endUtteranceIndex) {
    return SelectionDirection.BACKWARD;
  }

  if (startWordIndex < endWordIndex) {
    return SelectionDirection.FORWARD;
  } else {
    return SelectionDirection.BACKWARD;
  }
};

export const isAfterCoordinates = (
  {
    groupIndex: startGroupIndex,
    utteranceIndex: startUtteranceIndex,
    wordIndex: startWordIndex,
  }: WordCoordinatesType,
  {
    groupIndex: wordGroupIndex,
    utteranceIndex: wordUtteranceIndex,
    wordIndex,
  }: WordCoordinatesType,
) => {
  if (wordGroupIndex > startGroupIndex) return true;
  else if (wordGroupIndex < startGroupIndex) return false;

  if (wordUtteranceIndex > startUtteranceIndex) return true;
  else if (wordUtteranceIndex < startUtteranceIndex) return false;
  if (wordIndex >= startWordIndex) return true;

  return false;
};

export const isBeforeCoordinates = (
  {
    groupIndex: endGroupIndex,
    utteranceIndex: endUtteranceIndex,
    wordIndex: endWordIndex,
  }: WordCoordinatesType,
  {
    groupIndex: wordGroupIndex,
    utteranceIndex: wordUtteranceIndex,
    wordIndex,
  }: WordCoordinatesType,
) => {
  if (wordGroupIndex < endGroupIndex) return true;
  else if (wordGroupIndex > endGroupIndex) return false;

  if (wordUtteranceIndex < endUtteranceIndex) return true;
  else if (wordUtteranceIndex > endUtteranceIndex) return false;
  if (wordIndex <= endWordIndex) return true;

  return false;
};
