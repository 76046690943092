import {range} from '@autocut/utils/math.utils';

const LINEGAP = 2;

const GREEN_COLOR = 'rgba(0, 255, 0, 0.25)';
const BLACK_COLOR = 'rgba(0, 0, 0, 1)';
const GRAY_COLOR = 'rgba(0, 0, 0, 0.5)';
const RED_COLOR = 'rgba(255, 0, 0, 0.75)';

export const resetCanvas = ({
  ctx,
  canvas,
}: {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
}) => ctx.clearRect(0, 0, canvas.width, canvas.height);

export const drawDbValues = ({
  ctx,
  canvas,
  dbValues,
  valueCount,
}: {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  dbValues: number[];
  valueCount: number;
}) => {
  const lineSegmentWidth =
    (canvas.offsetWidth - (valueCount - 1) * LINEGAP) / valueCount;

  ctx.lineWidth = 1;
  ctx.strokeStyle = 'rgba(255, 255, 255, 0.8)';
  ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';

  for (const [dbIndex, dbValue] of dbValues.entries()) {
    const lineSegmentHeightDb = -(200 / 60) * (dbValue + 60);

    ctx.beginPath();
    ctx.rect(
      lineSegmentWidth * dbIndex + LINEGAP * dbIndex,
      200,
      lineSegmentWidth,
      lineSegmentHeightDb,
    );
    ctx.stroke();
    ctx.fill();
  }
};

export const drawDbLimit = ({
  ctx,
  canvas,
  dbLimit,
}: {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  dbLimit: number;
}) => {
  const lineHeight = (200 / 60) * dbLimit;
  const lineWidth = 1;

  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = 'red';

  ctx.beginPath();
  ctx.moveTo(0, -lineHeight);
  ctx.lineTo(canvas.width, -lineHeight);

  ctx.stroke();
};

export const drawRegions = ({
  ctx,
  canvas,
  silences,
  margins: {marginBefore, marginAfter},
  previewBoundaries,
  duration,
}: {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  silences: number[][];
  margins: {marginBefore: number; marginAfter: number};
  previewBoundaries: {startTime: number; endTime: number};
  duration: number;
}) => {
  const marginBeforePx = marginBefore * canvas.width;
  const marginAfterPx = marginAfter * canvas.width;

  ctx.beginPath();
  ctx.rect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = GREEN_COLOR;
  ctx.fill();

  for (const [index, [start, end]] of Object.entries(silences)) {
    const silenceStart = canvas.width * start;
    const silenceEnd = canvas.width * end;

    if (silenceStart !== 0) {
      ctx.beginPath();
      ctx.rect(silenceStart - marginAfterPx, 0, marginAfterPx, canvas.height);
      ctx.fillStyle = GREEN_COLOR;
      ctx.fill();
    }

    if (silenceEnd !== canvas.width) {
      ctx.beginPath();
      ctx.rect(silenceEnd, 0, marginBeforePx, canvas.height);
      ctx.fillStyle = GREEN_COLOR;
      ctx.fill();
    }
    ctx.beginPath();
    ctx.rect(
      canvas.width * start,
      0,
      canvas.width * (end - start),
      canvas.height,
    );
    ctx.fillStyle = RED_COLOR;
    ctx.fill();
  }

  if (previewBoundaries.endTime > duration) {
    ctx.beginPath();
    ctx.rect(
      canvas.width *
        ((previewBoundaries.endTime - previewBoundaries.startTime) / 10),
      0,
      canvas.width * ((duration - previewBoundaries.endTime) / 10),
      canvas.height,
    );
    ctx.fillStyle = BLACK_COLOR;
    ctx.fill();
  }
};

export const drawMousePosition = ({
  ctx,
  canvas,
  offsetX,
}: {
  ctx: CanvasRenderingContext2D;
  canvas: HTMLCanvasElement;
  offsetX: number;
}) => {
  ctx.lineWidth = 2;
  ctx.strokeStyle = 'black';

  ctx.beginPath();
  ctx.moveTo(offsetX, -canvas.height);
  ctx.lineTo(offsetX, canvas.height);
  ctx.stroke();
};

export const getHoveredDbValue = ({
  canvas,
  cursorPosition,
  dbValues,
}: {
  canvas: HTMLCanvasElement;
  cursorPosition: number;
  dbValues: number[];
}) => {
  const index = Math.floor(
    range(
      0,
      canvas.getBoundingClientRect().width,
      0,
      dbValues.length,
      cursorPosition,
    ),
  );

  if (index < 0 || index >= dbValues.length) {
    return dbValues[dbValues.length - 1];
  }

  return dbValues[index];
};
