import ModalManager from '@autocut/modals/ModalManager';
import {LaunchManager} from '@autocut/pages/LaunchManager/LaunchManager';
import SafeApp from '@autocut/SafeApp';
import React from 'react';
import '@autocut/designSystem/styles/index';

import css from './App.module.scss';
import {Header} from './components/Header/Header';
import {ModeProvider} from './contexts/ModeProvider';
import {NotificationProvider} from './contexts/NotificationProvider.tsx/NotificationProvider';
import FlexContainer from './designSystem/components/molecules/FlexContainer';
import {BetaFeedbackAffix} from './modals/BetaFeedbackModal/BetaFeedbackAffix/BetaFeedbackAffix';
import {GamingTrialAffix} from './modals/GamingTrialModal/GamingTrialAffix/GamingTrialAffix';

export default function App() {
  const [isInitialized, setIsInitialized] = React.useState(false);

  return (
    <>
      <ModalManager />
      <FlexContainer
        id="app"
        flexDirection="column"
        className={css.rootContainer}
      >
        <Header />

        <BetaFeedbackAffix />
        <GamingTrialAffix />

        <ModeProvider>
          <NotificationProvider>
            {!isInitialized ? (
              <LaunchManager onComplete={() => setIsInitialized(true)} />
            ) : (
              <SafeApp />
            )}
          </NotificationProvider>
        </ModeProvider>
      </FlexContainer>
    </>
  );
}
