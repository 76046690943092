import {EditableText} from '@autocut/components/EditableText/EditableText';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {ParametersGroupLayout} from '@autocut/components/ParametersGroupLayout/ParametersGroupLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Switch} from '@autocut/designSystem/components/atoms/CheckBox/Variants/Switch/Switch';
import {Tooltip} from '@autocut/designSystem/components/atoms/Tooltip/Tooltip';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {usePlayback} from '@autocut/hooks/usePlayback';
import {CaptionChunk} from '@autocut/types/Captions';
import ConditionalWrap from '@autocut/utils/conditional-wrapping';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {
  getCurrentTranscript,
  updateCurrentTranscriptName,
} from '@autocut/utils/transcript/transcriptFiles.utils';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import React, {useRef, useState} from 'react';
import {LuPencil} from 'react-icons/lu';

import {mergeChunks} from '../../../utils/mergeChunks.utils';
import {saveChunks} from '../../../utils/processCaptionsChunks';
import {CaptionsTranscriptButtons} from '../CaptionsTranscriptButtons/CaptionsTranscriptButtons';
import {Chunk} from '../Chunk/Chunk';
import {CustomizeChunkCard} from '../CustomizeChunkCard/CustomizeChunkCard';
import css from './EditTranscriptModal.module.scss';
import {useEditTranscriptModal} from './EditTranscriptModalContext';

export const EditTranscriptModal = () => {
  const transcriptNameRef = useRef<HTMLDivElement>(null);
  const {editTranscriptModal, currentTranscriptName, updateTranscriptName} =
    useEditTranscriptModal();

  const {
    captionsChunks = [],
    enableSoundPlay = true,
    audioFilepath,
  } = useAutoCutStore(state => ({
    captionsChunks: state.onGoingProcess.caption?.chunks,
    audioFilepath: state.onGoingProcess.audioFilepath!,
    enableSoundPlay: state.ui.parameters.caption.enableSoundPlay,
  }));

  const [localeCaptionChunks, setLocaleCaptionChunks] =
    useState(captionsChunks);
  const [selectedChunkIndex, setSelectedChunkIndex] = React.useState<
    null | number
  >(0);

  const {playbackChunk} = usePlayback({audioFilepath});

  if (!localeCaptionChunks || !editTranscriptModal.isOpen) {
    return null;
  }

  const isRtl: boolean =
    autocutStoreVanilla().ui.parameters.caption?.languageOfTranscription
      .textDirection === 'rtl';

  return (
    <Modal
      title={
        currentTranscriptName ? (
          <FlexContainer
            flexDirection="row"
            gap={8}
            alignItems="center"
            flexWrap="nowrap"
          >
            <EditableText
              ref={transcriptNameRef}
              initialValue={currentTranscriptName}
              onChange={updateTranscriptName}
              validate={value => value.length > 0}
            />

            <ActionIcon
              onClick={() => transcriptNameRef.current?.focus()}
              variant="subtle"
              style={{padding: 8}}
            >
              <LuPencil />
            </ActionIcon>
          </FlexContainer>
        ) : (
          <TranslatedMessage
            id={'modes_caption_steps_customization_parts_transcription_title'}
            defaultMessage="Edit transcription"
          />
        )
      }
      closeModalFunction={editTranscriptModal.close}
      fullScreen
      footer={
        <Button
          onClick={() => {
            saveChunks(localeCaptionChunks);
            editTranscriptModal.close();
          }}
          color={colors.primary600}
        >
          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_transcription_modal_save'
            }
            defaultMessage="Save"
          />
        </Button>
      }
      withCloseButton={false}
      closeOnClickOutside={false}
    >
      <FlexContainer
        flexDirection="column"
        gap={8}
      >
        <FlexContainer
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <CaptionsTranscriptButtons
            captionChunks={localeCaptionChunks}
            updateChunks={async (captionChunks: CaptionChunk[]) => {
              setLocaleCaptionChunks(captionChunks);
            }}
          />

          <ConditionalWrap
            condition={audioFilepath === undefined}
            wrap={children => (
              <Tooltip
                variant="warning"
                tooltipComponent={
                  <TranslatedMessage
                    id="modes_caption_steps_customization_parts_style_modal_enableSound_tooltip"
                    defaultMessage="This feature is not available from a loaded transcript"
                  />
                }
              >
                {children}
              </Tooltip>
            )}
          >
            <Switch
              checked={enableSoundPlay && audioFilepath !== undefined}
              onChange={value =>
                setAutocutStore('ui.parameters.caption.enableSoundPlay', value)
              }
              size={18}
              leftLabel={
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_enableSound_label'
                  }
                  defaultMessage="Enable sound playback"
                />
              }
            />
          </ConditionalWrap>
        </FlexContainer>

        <CustomizeChunkCard
          selectedChunkIndex={selectedChunkIndex}
          chunks={localeCaptionChunks ?? []}
          onChange={async newChunks => {
            if (selectedChunkIndex === null) return;

            setLocaleCaptionChunks(newChunks);
          }}
        />

        <ParametersGroupLayout
          title={
            <div className={css.title}>
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_transcription_modal_select'
                }
                defaultMessage="Select captions you want to edit"
              />
            </div>
          }
        >
          <FlexContainer
            flexDirection={isRtl ? 'row-reverse' : 'row'}
            flexWrap="wrap"
            gap={2}
          >
            {(localeCaptionChunks || []).map((chunk, index) => {
              if (!chunk) return null;

              return (
                <Chunk
                  key={index}
                  chunk={chunk}
                  onSelect={async () => {
                    setSelectedChunkIndex(index);

                    if (enableSoundPlay && audioFilepath !== undefined) {
                      playbackChunk({
                        startSecond: chunk.start,
                        endSecond: chunk.end,
                      });
                    }
                  }}
                  onMerge={async () => {
                    if (!localeCaptionChunks) return;
                    const newChunks = await mergeChunks(
                      localeCaptionChunks,
                      index,
                    );
                    if (selectedChunkIndex && selectedChunkIndex > index) {
                      setSelectedChunkIndex(selectedChunkIndex - 1);
                    }

                    setLocaleCaptionChunks(newChunks);
                  }}
                  selected={index === selectedChunkIndex}
                  isRtl={isRtl}
                  isLast={index === localeCaptionChunks.length - 1}
                />
              );
            })}
          </FlexContainer>
        </ParametersGroupLayout>
      </FlexContainer>
    </Modal>
  );
};
