import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';
import {getResourceConfig} from '@autocut/utils/resources/downloadableRessources.utils';
import {getCaptionResources} from '@autocut/utils/resources/items/getCaptionResources.resource';
import {getDavinciExtension} from '@autocut/utils/resources/items/getDavinciExtension.resource';
import {getLuaScripts} from '@autocut/utils/resources/items/getLuaScripts.resource';
import {getPproExtension} from '@autocut/utils/resources/items/getPproExtension.resource';

export const CAPTIONS_FOLDER_PATH = 'captions';
export const CAPTIONS_RESOURCES_FOLDER_PATH = 'resources';
export const CAPTIONS_EMOJIS_FOLDER_PATH = 'emojis';

export const resourcesConfigs = () =>
  ({
    ppro_extension: getPproExtension,
    lua_scripts: getLuaScripts,
    da_vinci_lua_script: getDavinciExtension,
    bleep_sound_1_wav: getResourceConfig({
      fileName: 'bleep_sound_1.wav',
      folderPath: 'audio',
    }),
    bleep_sound_2_wav: getResourceConfig({
      fileName: 'bleep_sound_2.wav',
      folderPath: 'audio',
    }),
    adjustment_layer_prproj: getResourceConfig({
      fileName: 'AutoCutBin.prproj',
    }),
    captions_xml_project: getResourceConfig({
      fileName: 'captions_xml_project_2.73.xml',
      folderPath: CAPTIONS_FOLDER_PATH,
    }),
    caption_resources: getCaptionResources,
    wav_epr: getResourceConfig({
      fileName: 'WAV_48_kHz_16_bits.epr',
    }),
    davinci_render_template: getResourceConfig({
      fileName: 'AutoCutRender.xml',
    }),
  }) as const satisfies Record<string, () => ResourceManagerElementConfig>;

export type ResourceManagerKeys = keyof ReturnType<typeof resourcesConfigs>;
