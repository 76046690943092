import type {getSelectedTransitions} from './_getSelectedTransitions';
import type {ResizedXMLTemplates} from './_resizeTemplate';

import {secondToTick} from '../temp/_ticks.temp';

export const handleTextTransitions = ({
  secondTimes,
  chunkIndex,
  templates,
  selectedTransitions,
}: {
  secondTimes: {start: number; end: number};
  chunkIndex: number;
  templates: ResizedXMLTemplates;
  selectedTransitions: ReturnType<typeof getSelectedTransitions>;
}) => {
  if (!selectedTransitions.length) return;

  const {start, end} = secondTimes;

  templates.effectsClip.clone({
    startTick: secondToTick(start).toString(),
    endTick: secondToTick(end).toString(),
    index: chunkIndex,
    copiedVideoFilterComponents: selectedTransitions,
  });
};
