const translations = {
  progress: {
    footer: {
      warning:
        "Veuillez éviter d'utiliser {host} pendant le travail d'AutoCut pour prévenir d'éventuels problèmes.",
    },
    steps: {
      global: {
        starting: 'Démarrage...',
        processing: 'Traitement...',
        ending: 'Finalisation...',
      },
      dev: {
        ping: 'Ping vers {host}...',
        pong: 'Pong vers {host}...',
      },
      common: {
        getSilences: 'Analyse des silences...',
        computeRMS: 'Analyse du fichier audio...',
        getDbValuesBetween: {
          getAudioInfos: 'Collecte des détails audio...',
          readAudioFile: 'Chargement du fichier audio...',
          convertRMSToDB: 'Analyse du fichier audio...',
        },
        timeline: {
          prepare: 'Préparation de la timeline...',
          import: {
            writeXml: 'Importation de la timeline mise à jour...',
            updateDrt: 'Importation de la timeline mise à jour...',
            importXml: 'Importation de la timeline mise à jour...',
          },
        },
        appendAdjustmentLayers: "Ajout de calques d'ajustement...",
      },
      repeat: {
        mainProcess: 'Chargement...',
        transcript: {
          getLanguage: 'Détection de la langue...',
        },
        getRepeatUtterances: 'Calcul des points de coupe...',
        cutAtTimecodes: 'Exécution des coupes...',
      },
      resize: {
        mainProcess: 'Redimensionnement de la Séquence...',
        prepareTimeline: 'Préparation de la timeline...',
        createNewTimeline: "Création d'une nouvelle timeline...",
        reframeTimeline: 'Re-cadrage de la timeline...',
        resizeTimeline: 'Redimensionnement de la timeline...',
        handleWatermark: 'Ajout du filigrane...',
      },
      podcast: {
        mainProcess: 'Montage du podcast...',
        getVideoTracksWeight:
          'Configuration des pistes vidéo des intervenants...',
        getAudioTracks: 'Configuration des pistes audio...',
        getPodcastInterval: 'Estimation de la durée du podcast...',
        getPodcastDuration: 'Calcul de la durée du podcast...',
        getVideoTrack: 'Analyse des pistes vidéo...',
        renders:
          "Rendu de l'audio dans {host}\n(peut prendre quelques minutes)",
        getTalkingTimelines: 'Allocation des temps de parole...',
        getTransitions: 'Calcul des transitions de caméra...',
        getCutTimecodes: 'Préparation des cuts...',
        cutAtTimecodes: 'Cut de la timeline...',
      },
      silences: {
        mainProcess: 'Suppression des silences...',
        getTimelineInfos: 'Obtention des infos de la timeline...',
        renders: 'Rendu audio dans {host}\n(peut prendre quelques minutes)',
        convertAudioToMono: 'Traitement du fichier audio...',
        getCutTimecodes: 'Configuration des cuts...',
        cutAtTimecodes: 'Cut de la timeline...',
        deleteSilentTrackItems: 'Suppression des silences...',
        applyTransitions: 'Application de la transition...',
      },
      swearWords: {
        mainProcess: 'Censure des profanités...',
        prepareTimeline: 'Préparation de la timeline...',
        getSwearWords: 'Analyse de la transcription...',
        cutAndApplyVolume: 'Application du filtre de volume...',
        applyBleeps: 'Insertion des bip...',
        getTranscript: 'Génération de la transcription...',
      },
      zoom: {
        mainProcess: 'Application des effets de zoom...',
        getTimelineInfos: 'Récupération des informations de la timeline...',
        getWavData: 'Rendu audio dans {host}\n(peut prendre quelques minutes)',
        computeBestZoomIntervals: 'Calcul des intervalles de zoom optimaux...',
        createVideoTrack: 'Création de nouvelles pistes vidéo...',
        createZoomFusionComps: 'Importation des composants de zoom...',
      },
      chapters: {
        transcript: {
          get_language: 'Détection de la langue...',
          generateChapters:
            'Génération des chapitres...\n(cela peut prendre quelques minutes)',
        },
        generation: {
          mainProcess: 'Ajout de chapitres...',
          importMarkers: 'Importation des marqueurs...',
        },
      },
      transcript: {
        mainProcess: 'Génération de la transcription...',
        renderWav: 'Rendu audio dans {host} (peut prendre quelques minutes)',
        uploadFile: 'Téléchargement du fichier...',
        getTranscript:
          "Transcription de l'audio avec IA (cela peut prendre quelques minutes)",
      },
      caption: {
        transcript: {
          get_language: 'Détection de la langue...',
          split_chunks: 'Division de la transcription en segments...',
        },
        generation: {
          mainProcess: 'Ajout des sous-titres...',
          initialization: 'Initialisation de la génération de sous-titres...',
          readTranscript: 'Chargement de la transcription...',
          getTimelineInfos: 'Récupération des détails de la timeline...',
          appendAdjustmentLayer: "Ajout d'un calque d'ajustement...",
          importFusionComp: 'Importation des composants de sous-titres...',
          prepareTimeline: 'Préparation du chronologie...',
          generateSequence: 'Génération de la séquence...',
          importSequence: 'Importation de la séquence...',
          getSequenceParameters:
            'Récupération des paramètres de la séquence...',
          createFiles: 'Création de fichiers...',
        },
      },
      viralClips: {
        mainProcess: 'Génération de clips viraux...',
        transcript: {
          generateViralClips: 'Génération de clips viraux...',
        },
        importingClips: 'Importation de clips viraux...',
        generation: {
          viralClips: 'Génération de clips viraux...',
        },
      },
      broll: {
        mainProcess: 'Ajout des B-Rolls...',
        getBRolls: 'Récupération des B-Rolls...',
        downloadingBrolls: 'Téléchargement des B-Rolls...',
        importingBrolls: 'Importation des B-Rolls...',
        insertingBrolls: 'Insertion des B-Rolls...',
      },
    },
  },
  launchManager: {
    title: 'Configuration / Mise à jour',
    steps: {
      preload: {
        title: 'Chargement',
      },
      versionValidation: {
        title: 'Vérification de la version',
        modal: {
          title: 'Vous devez mettre à jour manuellement',
          body: "Nous détectons que vous utilisez une version ancienne de AutoCut qui n'est plus fonctionnelle.{br}Veuillez mettre à jour vers la nouvelle version en téléchargeant et en installant AutoCut :",
          link1:
            'Si vous utilisez DaVinci, vous pouvez la télécharger ici: https://www.autocut.com/en/download/',
          link2:
            'Si vous utilisez Premiere Pro avec AutoCut v2, vous pouvez la télécharger ici: https://www.autocut.com/en/download-beta/',
        },
      },
      update: {
        title: 'Vérification des mises à jour',
        sudoPrompt:
          "Si vous utilisez la version App Store de DaVinci Resolve, nous devrons vous demander des privilèges d'administrateur temporaires pour installer ou mettre à jour l'extension.",
        modal: {
          title: 'AutoCut Mis à Jour',
          subtitle: 'AutoCut a été mis à jour',
          body: 'Nous avons mis à jour AutoCut pour une expérience améliorée. Veuillez redémarrer AutoCut en utilisant le bouton ci-dessous.',
          cta: 'Redémarrer AutoCut',
        },
      },
      ffmpeg: {
        title: 'Installation de FFMPEG',
      },
      resources: {
        title: 'Installation des ressources',
        modal: {
          title: 'Quelque chose a mal tourné',
          body: "AutoCut ne peut pas démarrer, certaines ressources ne peuvent pas être installées. Vérifiez l'espace disque et assurez-vous de permettre à AutoCut d'accéder aux données d'autres applications pour continuer. Si le problème persiste, veuillez nous contacter à ",
          cta: 'Redémarrer AutoCut',
        },
      },
      computeIPC: {
        title: 'Connexion aux services IA',
      },
      hostIPC: {
        title: 'Lancer AutoCut sur',
        subtitle: 'Vous ne le trouvez pas ? Redémarrez Premiere ou DaVinci',
        stillNot:
          'Toujours introuvable après avoir redémarré Premiere Pro, DaVinci & AutoCut ?{br}Contactez-nous à <copy><embedTranslation>globals_contactMail</embedTranslation></copy>',
        instructions: {
          ppro: {
            part1: "Lancez l'extension AutoCut depuis :",
            part2: 'Fenêtre > extensions > AutoCut V2 Beta',
          },
          davinci: {
            part1: 'Lancez le script depuis :',
            part2: 'Espace de travail > Scripts > AutoCut',
          },
        },
        modals: {
          ppro: {
            title: 'Extension mise à jour',
            subtitle: "L'extension AutoCut pour Premiere Pro a été actualisée",
            body: "Nous avons mis à jour AutoCut pour une expérience améliorée. Veuillez redémarrer Premiere Pro pour actualiser l'extension.",
          },
          davinci: {
            title: 'Script mis à jour',
            subtitle: 'Le script AutoCut pour DaVinci a été actualisé',
            body: 'Nous avons mis à jour AutoCut pour une expérience améliorée. Veuillez redémarrer le script AutoCut.',
          },
        },
      },
    },
    footer: {
      discord: 'Rejoignez notre communauté Discord !',
      learnMore:
        "Découvrez des astuces pour maximiser les fonctionnalités d'AutoCut !",
    },
    errors: {
      compute:
        'Impossible de se connecter aux services IA. Veuillez contacter le support.',
      davinci:
        "Impossible de se connecter à DaVinci Resolve ou Premiere Pro. Assurez-vous qu'il est en cours d'exécution et que le script AutoCut est actif.",
      cta: 'Réessayer',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: 'Essai Gratuit',
        upgrade: 'Mettre à niveau maintenant',
        coming_soon: 'Bientôt disponible !',
        version_not_supported: 'Version {host} non supportée',
        upgrade_to: 'Mettre à Niveau vers {plan}',
      },
    },
  },
  onboarding: {
    title: 'Bienvenue sur AutoCut',
    description: 'Votre extension ultime {host}',
    cta: {
      freeTrial: 'Obtenez un essai gratuit',
      useLicenceKey: 'Utilisez une clé de licence',
      buyLicenceKey: 'Achetez une clé de licence',
    },
    auth: {
      login: {
        title: 'Entrez la clé de licence pour activer AutoCut',
        subtitle: 'Utilisez votre clé de licence',
        placeholder: 'Entrez votre clé de licence',
        useTrial: 'Vous venez de recevoir un mail sur votre email ({email})',
        resendEmail:
          "Vous ne l'avez pas reçu ? Renvoyer l'email à {trialEmail}",
        cta: 'Activer',
      },
      trial: {
        title: "Commencez l'essai gratuit",
        steps: {
          email: {
            title: 'Entrez votre adresse email',
            subtitle: "Recevez une licence d'essai par email",
            placeholder: 'email@exemple.com',
            cta: 'Suivant',
          },
          sendingEmail: {
            title: "Envoi de l'email",
            success: 'Email envoyé avec succès à {email}',
          },
        },
      },
      errors: {
        no_key: 'La clé de licence est requise',
        is_key_valid_error:
          'Échec de la validation de la clé. Veuillez réessayer plus tard.',
        invalid_response: 'Réponse inattendue reçue du serveur.',
        cant_read_host_error: "Impossible de récupérer le nom de l'ordinateur.",
        cant_read_uuid_error: "Impossible de récupérer l'UUID de l'ordinateur.",
      },
    },
  },
  modes: {
    chapters: {
      title: 'AutoChapters',
      description:
        'Détecter et ajouter automatiquement des marqueurs de chapitre',
      steps: {
        language: {
          cta: 'Générer les Chapitres',
        },
        editChapters: {
          title: 'Éditer les chapitres',
          description:
            'Modifiez les chapitres de votre vidéo, vous pouvez changer les timecodes et les titres.',
        },
        exportChapters: {
          cta: 'Exporter les chapitres pour Youtube',
        },
        markersColor: {
          title: 'Couleur des marqueurs',
        },
        cta: 'Ajouter des chapitres',
      },
    },
    repeat: {
      title: 'AutoCut Repeat',
      description: "Supprimer les prises répétitives avec l'IA",
      steps: {
        language: {
          title: 'Sélectionner les clips et la langue',
          cta: 'Générer la transcription',
          algorithm: {
            select: "Sélectionner l'algorithme",
            options: {
              precise: 'Précis',
              fast: 'Rapide',
            },
            info: "L'algorithme précis est le plus récent et le plus efficace mais nécessite environ 1 minute de traitement pour 6 minutes de vidéo.",
          },
        },
        transcript: {
          title: 'Éditer la transcription',
          cta: 'Couper la séquence',
          contextual: {
            cut: 'Couper/Ne pas couper',
            play: 'Écouter',
          },
        },
      },
      error: {
        validation: {
          algorithm: 'Algorithme invalide sélectionné.',
        },
      },
    },
    resize: {
      title: 'Redimensionnement Automatique',
      description: 'Redimensionner les séquences pour différentes plateformes',
      steps: {
        inOutPoints: {
          title: 'Points Entrée/Sortie',
          description:
            "Veuillez vérifier vos points d'entrée et de sortie dans votre timeline. Ils doivent être identiques aux valeurs ci-dessous.",
          items: {
            in: 'Entrée : {inPoint} secondes',
            out: 'Sortie : {outPoint} secondes',
            duration: 'Durée : {duration} secondes',
          },
        },
        presets: {
          title: 'Préréglages',
          description:
            'Sélectionnez un préréglage pour redimensionner votre séquence. Vous pouvez également créer votre propre préréglage. Les dimensions de votre nouvelle séquence sont affichées ci-dessous.',
        },
        reframe: {
          title: 'Suivre la Vitesse du Sujet',
          description:
            'Vous pouvez choisir la vitesse à laquelle la caméra suivra le sujet.',
          items: {
            slower: 'Lente',
            default: 'Normale',
            faster: 'Rapide',
            none: 'Aucune',
          },
        },
        watermark: {
          title: 'Filigrane',
          description:
            'Vous pouvez ajouter un filigrane à votre séquence. Il sera ajouté dans le coin inférieur droit de votre séquence.',
          items: {
            choose: 'Choisir le filigrane',
            noFile: 'Aucun filigrane choisi',
          },
        },
        cta: 'Redimensionner la séquence',
      },
      error: {
        validation: {
          autoReframePreset: {
            required: 'Le préréglage de recadrage automatique est requis',
            invalid:
              'Options de préréglage : plus lent, par défaut ou plus rapide',
          },
          watermark: 'Formats de filigrane valides : JPEG, PNG ou WebP',
          preset: {
            required: 'Sélectionnez un préréglage',
            name: 'Le nom du préréglage est requis.',
            width: "La largeur doit être d'au moins 1px.",
            height: "La hauteur doit être d'au moins 1px.",
          },
        },
      },
    },
    podcast: {
      title: 'Auto Podcast',
      description: 'Éditez instantanément les podcasts',
      steps: {
        select: {
          title:
            'Choisissez une timeline avec au moins 2 pistes vidéo et audio',
          label: 'Sélectionner la Timeline',
          descriptions: {
            '1': "Naviguez vers l'onglet Édition",
            '2': 'Ouvrez la timeline que vous souhaitez éditer',
          },
          cta: 'Sélectionner la Timeline Active',
        },
        customization: {
          label: 'Paramètres du Podcast',
          steps: {
            speakers: {
              title: 'Intervenants',
              description:
                'Assignez à chaque intervenant une piste audio et nommez-les. Utilisez une piste par intervenant. Pour les intervenants partagés, utilisez les deux noms (par exemple, "Alice & Bob").',
              header: {
                audio: 'Piste Audio',
                name: "Nom de l'Intervenant",
              },
              textInputPlaceholder: "Entrez le nom de l'intervenant...",
              trackTemplate: 'Piste A{index}',
              cta: 'Ajouter un Intervenant',
            },
            cameras: {
              title: 'Caméras',
              description:
                'Assignez à chaque caméra une piste vidéo et sélectionnez les intervenants visibles. Une piste par caméra, plusieurs intervenants autorisés.',
              header: {
                audio: 'Piste Vidéo',
                name: 'Intervenant(s)',
              },
              speakerSelectPlaceholder: "Sélectionner l'intervenant(s)...",
              trackTemplate: 'Piste V{index}',
              cta: 'Ajouter une Caméra',
            },
            cameraTime: {
              title: "Durées d'Affichage des Caméras",
              description:
                'Définissez la durée minimale et maximale pendant laquelle une caméra reste affichée avant un changement.',
              presets: {
                title: 'Préréglages par Défaut',
                items: {
                  calm: 'Calme',
                  paced: 'Rythmé',
                  energetic: 'Énergétique',
                  hyperactive: 'Hyperactif',
                },
              },
              minimumCamTime: {
                title: 'Durée Minimale',
                description:
                  "Temps minimum (en secondes) qu'une caméra reste affichée avant de changer.",
                unit: 'secondes',
              },
              maximumCamTime: {
                title: 'Durée Maximale',
                description:
                  "Temps maximum (en secondes) qu'une caméra reste affichée avant de changer.",
                unit: 'secondes',
              },
            },
            silentVideoClips: {
              title: 'Gestion des Clips Vidéo Inutilisés',
              description:
                'Choisissez de supprimer ou désactiver les parties inutilisées du podcast.',
              items: {
                remove: 'Supprimer',
                disable: 'Désactiver',
              },
            },
          },
          cta: {
            disable:
              'Commencer le montage du Podcast - Désactiver les Clips Inutilisés',
            delete:
              'Commencer le montage du Podcast - Supprimer les Clips Inutilisés',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: "Le temps de caméra minimum doit être d'au moins 0 secondes",
            higherThanMax:
              'Le temps de caméra minimum doit être inférieur au temps de caméra maximum',
          },
          maximumCamTime: {
            min: "Le temps de caméra maximum doit être d'au moins 0 secondes",
          },
          deleteUnusedClips: {
            required:
              'Sélectionnez une option pour supprimer ou désactiver les clips inutilisés',
          },
          speakers: {
            videoTracks: {
              min: 'Sélectionnez au moins une piste vidéo pour chaque intervenant',
            },
            audioTrack: {
              min: 'Attribuez une piste audio à chaque intervenant',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      description: 'Supprimer les silences de la timeline',
      steps: {
        noiseLevel: {
          title: 'Niveau de Bruit',
          description:
            'Définissez le niveau sonore pour identifier les silences.',
          calculate: 'Calculer par IA',
        },
        pacing: {title: 'Rythme'},
        presets: {
          title: 'Préréglages par défaut',
          items: {
            calm: 'Calme',
            measured: 'Mesuré',
            paced: 'Rythmé',
            energetic: 'Énergique',
            jumpy: 'Nerveux',
          },
        },
        silenceSize: {
          title: 'Durée du Silence',
          items: {
            silence: {
              title: 'Supprimer les silences supérieurs à',
              description: 'Durée minimale (ms) pour supprimer les silences.',
              unit: 'millisecondes',
            },
            talk: {
              title: 'Garder les paroles supérieures à',
              description:
                'Durée minimale (ms) pour conserver les segments de parole.',
              unit: 'millisecondes',
            },
          },
        },
        paddingSize: {
          title: 'Marges',
          items: {
            before: {
              title: 'Marge Avant',
              description:
                'Temps de silence avant le bruit pour assurer un début de parole fluide.',
              unit: 'millisecondes',
            },
            after: {
              title: 'Marge Après',
              description:
                'Temps de silence après le bruit pour assurer une fin de parole fluide.',
              unit: 'millisecondes',
            },
          },
        },
        silenceManagement: {
          title: 'Gestion des Silences',
          choices: {
            delete: 'Supprimer les silences',
            keep: 'Garder les silences',
            mute: 'Désactiver les silences',
            delete_but_keep: 'Supprimer les silences mais garder les espaces',
          },
        },
        transition: {
          params: {
            helper:
              'Tous les coupes peuvent être J-Cut, L-Cut ou les deux, si désiré. Cela crée un effet de transition plus dynamique.',
            title: 'Transitions',
            warning: 'Les transitions nécessitent la suppression des silences.',
            default: {
              title: 'Défaut',
              choices: {
                none: 'Aucun',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'Ajoute une marge autour des coupes pour des transitions fluides et crée une nouvelle piste de transition.',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: 'Les deux',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'Puissance Constante',
              },
            },
          },
        },
        preview: {
          title: 'Aperçu',
          description:
            "Aperçu des segments conservés et supprimés. Utilisez les flèches pour naviguer ou centrez l'aperçu sur la position du curseur.",
          legend: {
            kept: 'Conservé',
            deleted: 'Supprimé',
            margin: 'Marges',
          },
          cta: "Générer l'Aperçu",
          center: 'Centrer sur le Curseur',
        },
        cta: {
          delete: 'Couper et supprimer les silences {withTransition}',
          keep: 'Couper et conserver les silences {withTransition}',
          mute: 'Couper et mettre en sourdine les silences {withTransition}',
          delete_keep_spaces:
            'Couper et supprimer les silences mais conserver les espaces {withTransition}',
          withTransition: 'et appliquer des transitions',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'Le niveau de bruit doit être une valeur négative',
            int: 'Le niveau de bruit doit être un nombre',
          },
          minTimeOfSilence: {
            required: 'La durée minimale de silence est requise',
            min: "La durée minimale de silence doit être d'au moins 0 ms",
          },
          minTimeOfTalk: {
            required: 'La durée minimale de parole est requise',
            min: "La durée minimale de parole doit être d'au moins 0 ms",
          },
          marginBefore: {
            min: "La marge avant doit être d'au moins 0 ms",
          },
          marginAfter: {
            min: "La marge après doit être d'au moins 0 ms",
          },
          transitions: 'Sélectionnez un type de transition valide',
          silences: 'Sélectionnez une option de traitement de silence valide',
        },
      },
      transition: {
        params: {
          title: 'Transitions',
          warning: 'Les transitions nécessitent la suppression des silences.',
          default: {
            title: 'Défaut',
            choices: {
              none: 'Aucun',
            },
          },
          progress: {
            insert: 'Ajout de nouvelles pistes audio...',
            move: "Ajustement de l'audio pour les transitions...",
            handling: 'Application de la transition : {transition}',
          },
        },
      },
    },
    swearWords: {
      title: 'Filtre de profanités automatique',
      description: 'Censurer automatiquement le langage indésirable',
      parameters: {
        bleepSoundSelector: {
          mute: 'Muet',
          helper: "Sélectionnez l'effet sonore du bip",
        },
        bleepSoundManager: {
          button: 'Gérer et ajouter des sons',
          title: 'Gérer les sons',
          noSounds: "Vous n'avez encore ajouté aucun son.",
          addButton: 'Ajouter un nouveau son',
          delete: 'Supprimer',
        },
      },
      footerButton: 'Supprimer les profanités',
      infoText:
        "Le bip de mots offensants, grâce à l'IA, analyse votre audio et ajoute automatiquement {br} des sons de bip sur les profanités.",
      error: {
        validation: {
          bleepFile: 'Sélectionnez un effet sonore de beep',
        },
      },
    },
    zoom: {
      title: 'AutoZoom',
      description: 'Ajouter automatiquement des effets de zoom',
      parameters: {
        preview: {
          title: 'Aperçu du Zoom',
          description: 'Définissez le point focal du zoom',
          maximumZoom: 'Niveau maximum de zoom',
          errorMessage: "Erreur lors de la génération de l'aperçu",
        },
        zoomTypes: 'Styles de Zoom',
        defaultsPresets: {
          title: 'Préréglages par Défaut',
          calm: 'Calme',
          paced: 'Rythmée',
          energetic: 'Énergétique',
          hyperactive: 'Hyperactive',
        },
        zoomCoverage: 'Couverture Totale du Zoom',
        constraint: {
          title: 'Limiter le zoom aux clips individuels',
          helper:
            'Si activé, les effets de zoom ne franchiront pas les limites des clips. Si désactivé, le zoom couvrira toute la sélection.',
        },
        maximumZoomDuration: 'Durée Maximale du Zoom',
        smoothZooms: {
          title: 'Zooms Fluides',
          coverage: 'Couverture des Zooms Fluides',
          nervousness: 'Vitesse et Intensité du Zoom',
        },
        dynamicZooms: {
          title: 'Zooms Dynamiques',
          coverage: 'Couverture des Zooms Dynamiques',
        },
      },
      types: {
        smooth: 'Fluide',
        dynamic: 'Dynamique',
      },
      footerButton: 'Appliquer le Zoom',
      infoText:
        'AutoZoom ajoute automatiquement des effets de zoom basés sur les niveaux audio.',
      error: {
        validation: {
          anchor: "L'ancre doit être dans la zone d'aperçu",
          autoZoomMinCoef: {
            min: 'Le coefficient de zoom minimum doit être supérieur à 1',
            max: 'Le coefficient de zoom minimum doit être inférieur à 2',
          },
          autoZoomMaxCoef: {
            min: 'Le coefficient de zoom maximum doit être supérieur à 1',
            max: 'Le coefficient de zoom maximum doit être inférieur à 2',
          },
          minZoomTime:
            'La durée minimale de zoom doit être en secondes positives.',
          maxZoomTime:
            'La durée maximale de zoom doit être en secondes positives.',
          dynamicZoomPercentage: {
            min: 'Le pourcentage de zoom doit être supérieur à 0%',
            max: 'Le pourcentage de zoom doit être inférieur à 100%',
          },
          coef: 'Le coefficient de zoom maximum doit être supérieur au minimum',
          time: 'Le temps maximum de zoom doit être supérieur au temps minimum.',
          dynamicMinZoomIntensity:
            "L'intensité de zoom dynamique doit être comprise entre 0 et 100%",
          smoothZoomPercentage:
            'La couverture de zoom fluide doit être comprise entre 0 et 100%',
          nervousness:
            'Le niveau de nervosité doit être compris entre 0 et 100%',
          totalZoomPercentage:
            'La couverture de zoom totale doit être comprise entre 0 et 100%',
          types: {
            atLeast: {
              one: {
                true: 'Sélectionnez au moins un type de zoom',
              },
            },
          },
        },
      },
    },
    caption: {
      title: 'AutoCaptions',
      description: "Ajouter des sous-titres animés à l'aide de l'IA",
      steps: {
        language: {
          button: "Ouvrir l'éditeur de sous-titres (Style & Transcription)",
          previousTranscription: {
            title: 'Charger une transcription précédente',
            showMore: 'Afficher plus',
            showLess: 'Afficher moins',
            noTranscription: 'Aucune transcription précédente disponible',
            load: 'Charger la transcription',
            edit: 'Modifier le nom',
            delete: 'Supprimer',
            confirmModal: {
              text: 'Cette action est irréversible. Vous ne pourrez plus utiliser la transcription une fois supprimée.',
              confirm: 'Supprimer la transcription',
            },
          },
        },
        customization: {
          button: 'Ajouter des sous-titres',
          parts: {
            style: {
              title: 'Style des Sous-titres',
              modal: {
                title: 'Modifier le Style',
                apply: 'Appliquer le Style',
                save: 'Appliquer et Enregistrer comme Préréglage',
                text: {
                  title: 'Police',
                  font: 'Nom de la Police',
                  help: 'Comment ajouter une nouvelle famille de polices ?',
                  color: 'Couleur du Texte',
                },
                enableSound: {
                  label: 'Activer la lecture du son',
                  tooltip:
                    'Cette option est indisponible pour une transcription chargée',
                },
                animation: {
                  title: 'Animation',
                  wordBox: 'Boîte de Texte',
                  floatingText: 'Texte flottant',
                  highlight: {
                    title: 'Surlignage coloré',
                    color: 'Surligner <color>couleur</color>',
                    advancedColorOptions: {
                      title: 'Options de couleur avancées',
                      revealedText: 'Couleur <i>avant</i> surlignage',
                      unrevealedText: 'Couleur <i>après</i> surlignage',
                    },
                  },
                },
                outline: {
                  title: 'Contour',
                  width: 'Épaisseur du Contour',
                },
                box: {
                  title: 'Boîte de Texte',
                  paddingX: 'Marge Horizontale',
                  paddingY: 'Marge Verticale',
                  opacity: 'Opacité de la Boîte',
                  radius: 'Arrondis',
                  disabled:
                    'La police sélectionnée ne supporte pas la fonctionnalité de boîte pour cette langue.',
                  tips: 'Choisissez une police supportant la langue sélectionnée pour utiliser cette fonctionnalité.',
                },
                formating: {
                  title: 'Format de texte',
                  removePunctuation: 'Supprimer la ponctuation',
                  uppercase: 'Majuscules',
                  italic: 'Italique',
                  glow: {
                    title: 'Lueur',
                    color: 'Couleur de la lueur',
                    intensity: 'Intensité de la lueur',
                  },
                },
                transition: {
                  title: 'Transition',
                  zoomIn: 'Zoom avant',
                  blurIn: 'Flou avant',
                },
              },
            },
            preview: {
              title: 'Aperçu',
              modal: {
                title: 'Position & Taille',
                apply: 'Appliquer le Style',
                fontSize: 'Taille de la Police',
                nbLines: 'Nombre de Lignes',
                maxWidth: 'Largeur Maximale',
                help: "La fonctionnalité d'aperçu est en cours de développement et peut ne pas être précise.",
              },
            },
            transcription: {
              title: 'Éditer la Transcription',
              modal: {
                save: 'Sauvegarder',
                select: 'Sélectionner les sous-titres à éditer',
                placeholder:
                  'Choisissez le segment de transcription à éditer ou personnaliser',
                formatNumbers: 'Formater les Nombres',
                checkTranscript: {
                  title: 'Transcription Vérifiée ?',
                  description:
                    "Vous n'avez pas encore vérifié la transcription.",
                  dontShowAgain: 'Ne plus rappeler',
                },
              },
              tooltips: {
                lowConfidence: 'Mots à faible confiance',
                merge: 'Fusionner les segments',
                split: 'Diviser les segments',
              },
              emojis: {
                title: "Quel type d'émojis voulez-vous générer ?",
                animated: 'Animé',
                static: 'Statique',
              },
            },
            presets: {
              noPresetSelected: "Sélectionner un préréglage pour l'aperçu",
              noPreviewAvailable: 'Aucune aperçu disponible',
              title: 'Préréglages',
              error:
                'Une erreur est survenue lors de la récupération des préréglages, veuillez nous contacter sur Discord.',
              delete: {
                title: 'Supprimer les Préréglages',
                cancel: 'Annuler',
                confirm: 'Supprimer le Préréglage',
              },
              search: {
                title: 'Préréglages de la Communauté',
                input: 'Rechercher des Préréglages',
                cancel: 'Annuler',
                confirm: 'Importer le Préréglage',
                usage: 'Utilisé par {count} utilisateurs',
                error:
                  'Erreur lors de la copie du préréglage. Contactez-nous via Discord ou à contact@autocut.fr',
              },
              create: {
                title: 'Créer un nouveau préréglage',
                placeholder: 'Nom du préréglage (minimum deux mots)',
                community: 'Partager avec la communauté',
                public: 'Publique',
                cancel: 'Annuler',
                confirm: 'Créer le préréglage',
                alreadyExists:
                  'Le préréglage existe déjà. Choisissez un nom différent.',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'Les préréglages utilisateur sont manquants. Réinitialisez vos paramètres dans le menu en tête.',
            titleTooShort: 'Le titre doit contenir au moins 5 caractères',
            titleTooLong: 'Le titre doit contenir au maximum 25 caractères',
            titleNoSpace: 'Le titre doit contenir au moins deux mots',
          },
          position: {
            xPercentage: {
              min: "La position X doit être d'au moins 0",
              max: 'La position X doit être au maximum 1',
            },
            yPercentage: {
              min: "La position Y doit être d'au moins 0",
              max: 'La position Y doit être au maximum 1',
            },
            emojiXPercentage: {
              min: "La position X de l'emoji doit être d'au moins 0",
              max: "La position X de l'emoji doit être au maximum 1",
            },
            emojiYPercentage: {
              min: "La position Y de l'emoji doit être d'au moins 0",
              max: "La position Y de l'emoji doit être au maximum 1",
            },
          },
          text: {
            fontSize: {
              int: 'La taille de la police doit être un nombre',
              min: "La taille de la police doit être d'au moins 1",
            },
            font: {
              missing:
                'La police sélectionnée est incomplète. Sélectionnez-la à nouveau ou choisissez une autre.',
            },
          },
          outline: {
            width: {
              int: 'La largeur du contour doit être un nombre',
              min: "La largeur du contour doit être d'au moins 0",
            },
          },
          formating: {
            nbLines: {
              int: 'Le nombre de lignes doit être un nombre',
              min: "Le nombre de lignes doit être d'au moins 1",
            },
            maxWidth: {
              int: 'La largeur maximale doit être un nombre',
              min: "La largeur maximale doit être d'au moins 0",
            },
          },
          textBox: {
            xPadding: {
              int: 'La marge horizontal doit être un nombre',
              min: "La marge horizontal doit être d'au moins 0",
            },
            yPadding: {
              int: 'La marge vertical doit être un nombre',
              min: "La marge vertical doit être d'au moins 0",
            },
            opacity: {
              int: "L'opacité doit être un nombre",
              min: "L'opacité doit être d'au moins 0",
              max: "L'opacité ne peut pas dépasser 100",
            },
            radius: {
              int: 'Le rayon doit être un nombre',
              min: "Le rayon doit être d'au moins 0",
            },
          },
        },
      },
    },
    viralClips: {
      title: 'AutoViral',
      description:
        'Identifier facilement les clips à potentiel viral dans les longues vidéos',
      steps: {
        language: {
          button: 'Trouver des clips viraux',
        },
      },
      stepSelector: 'Sélectionner des clips viraux',
      sorter: {
        viralScore: 'Score viral',
        start: 'Démarrer',
      },
      viralPotential: 'Score de potentiel viral',
      detectedClips: 'Clips viraux potentiels détectés {viralClipsLength}',
      footerButton: 'Générer des clips viraux',
    },
    broll: {
      title: 'AutoBRolls',
      description: "Ajoute automatiquement des B-Roll avec l'IA",
      steps: {
        language: {
          button: 'Suivant',
        },
        parameters: {
          title: 'Paramètres des B-Rolls',
          cta: 'Suivant',
          duration: {
            title: 'Durées des B-Rolls',
            minimumDuration: 'Durée minimale',
            minimumDurationHelper: "Chaque B-Roll sera d'au moins cette durée.",
            maximumDuration: 'Durée maximale',
            maximumDurationHelper: "Chaque B-Roll sera d'au plus cette durée.",
          },
        },
        transcript: {
          title: 'Modifier la sélection de B-Rolls',
          cta: 'Ajouter des B-Rolls',
          selection: 'Sélection de B-Rolls',
          choiceModal: {
            title: 'Choisissez un B-Roll',
            inputPlaceholder: 'Tapez pour rechercher des B-Rolls',
            searching: 'Recherche de B-Rolls...',
          },
        },
      },
      error: {
        validation: {
          minMaxDuration:
            'Le temps maximum de B-Roll doit être supérieur au minimum',
          minimumBRollTime: 'Le temps minimum de B-Roll doit être positif',
          maximumBRollTime: 'Le temps maximum de B-Roll doit être positif',
        },
      },
    },
    general: {
      steps: {
        select: {
          // title: 'Définir les marqueurs',
          description:
            "Veuillez définir la position des marqueurs pour votre sélection. Vous pouvez les définir manuellement ou utiliser l'un des boutons ci-dessous.",
          // cta: 'Valider les marqueurs',
          auto: 'Nous avons placé des marqueurs dans votre chronologie. Veuillez vérifier que ces marqueurs ont été correctement placés.',
          entire: 'Chronologie complète',
          inOut: 'Points d’entrée/sortie',
          selected: 'Clips sélectionnés',
          // vvv A retirer pour nouvelle selection vvv
          title: "Définir les Points d'Entrée/Sortie",
          descriptions: {
            '1': "Sans définir les points d'entrée et de sortie, AutoCut s'appliquera à toute la timeline.",
            '2': "Coupez manuellement les clips ou pistes pour les exclure de l'analyse audio.",
          },
          cta: "Confirmer les Points d'Entrée/Sortie",
        },
        transcription: {
          title: "Sélectionner la Langue de l'Audio",
          description:
            'Choisissez la langue dans laquelle vous souhaitez générer votre transcription.',
          noTrackSelected: 'Sélectionnez au moins une piste audio',
        },
        customization: {
          title: 'Personnaliser les Paramètres',
        },
        audioTracks: {
          title: 'Pistes audio sélectionnées',
          description:
            'Inclure des pistes spécifiques pour le traitement audio.',
        },
      },
      error: {
        validation: {
          languageOfTranscription: 'Sélectionnez une langue de transcription',
          rgb: 'Les valeurs RGB doivent être comprises entre 0 et 255.',
        },
      },
    },
  },
  modals: {
    notEnoughTracks: {
      title: 'Pas assez de pistes dans votre timeline',
      text: "Vous avez besoin d'au moins 2 pistes vidéo et 2 pistes audio pour utiliser cette fonctionnalité.",
      cta: 'Je comprends',
    },
    appStoreDv: {
      title:
        "AutoCut n'est pas compatible avec DaVinci Resolve installé depuis l'App Store",
      text: {
        firstLine: {
          text: 'Pour utiliser AutoCut avec DaVinci Resolve, vous devez installer DaVinci Resolve depuis leur site officiel : ',
          cta: 'Site officiel de DaVinci Resolve',
        },
        secondLine:
          "De plus, DaVinci Resolve offre plus de fonctionnalités sur leur version officielle de leur site web que sur la version de l'App Store.",
      },
      cta: 'Accéder au site officiel de DaVinci Resolve',
    },
    confirmModal: {
      title: 'Êtes-vous sûr ?',
      text: 'Cette action ne peut pas être annulée.',
      cancel: 'Annuler',
      confirm: 'Confirmer',
    },
    noLinkModal: {
      title: 'Veuillez mettre à jour DaVinci Resolve',
      text: "Bien que vous puissiez toujours utiliser AutoCut, vous ne pourrez pas conserver les liens entre les éléments de piste dans votre timeline après l'exécution d'un processus.",
      cta: 'Fermer',
      checkBox: 'Ne plus afficher ce message',
    },
    gamifiedTrial: {
      title: 'Commencez votre aventure AutoCut',
      description: {
        firstLine:
          "Votre voyage créatif commence avec un essai gratuit de 14 jours d'AutoCut, vous avez débloqué {completedElements} éléments supplémentaires et il vous reste <b>{daysLeft} jours</b> pour explorer !",
        secondLine:
          "Mais pourquoi s'arrêter là ? Lancez-vous dans une quête pour débloquer <b>{bonusDays} jours supplémentaires</b> et libérez pleinement la puissance d’AutoCut.",
      },
      timeline: {
        left: 'Essai gratuit de 14 jours',
        right: 'Essai gratuit de 24 jours',
      },
      taskList: {
        title: 'Maîtrise des tâches : {completed}/{maximum} terminées',
        tasks: {
          firstCaption: 'Utilisez AutoCaptions : <link>tutoriel</link>',
          firstPodcast: 'Utilisez AutoCut Podcast : <link>tutoriel</link>',
          firstZoom: 'Utilisez AutoZoom : <link>tutoriel</link>',
          firstRepeat: 'Utilisez AutoCut Repeat : <link>tutoriel</link>',
          firstBroll: 'Utilisez AutoB-Roll : <link>tutoriel</link>',
          firstSwearWord:
            'Utilisez le filtre AutoProfanity : <link>tutoriel</link>',
          firstPreset:
            'Créez un nouveau préréglage pour AutoCaptions, AutoZoom ou AutoResize',
          newTestimonial:
            'Laissez-nous un témoignage (en utilisant le même e-mail) : <link>témoignage</link>',

          // TODO À retirer à partir du 15 janvier 2025
          trial: {
            task: {
              captions: 'Utiliser AutoCaptions : <link>tutoriel</link>',
              podcast: 'Utiliser AutoCut Podcast : <link>tutoriel</link>',
              zoom: 'Utiliser AutoZoom : <link>tutoriel</link>',
              repeat: 'Utiliser AutoCut Repeat : <link>tutoriel</link>',
              broll: 'Utiliser AutoB-Roll : <link>tutoriel</link>',
              swear: {
                word: 'Utiliser le filtre de grossièretés AutoProfanity : <link>tutoriel</link>',
              },
              preset:
                'Créer un nouveau préréglage pour AutoCaptions, AutoZoom ou AutoResize',
              testimonial:
                'Utilisons un témoignage (en utilisant le même email) : <link>témoignage</link>',
            },
          },
        },
      },
    },
    deepgramError: {
      title: 'Erreur de Traduction',
      text: "Une erreur s'est produite lors de la traduction audio. Cela pourrait être dû à la qualité audio ou au support linguistique. {br}Signalez via Discord ou par email contact@autocut.com.",
      retry: "Réessayer la transcription avec un autre modèle d'IA.",
    },
    contactUs: {
      title: 'Désolé pour le désagrément',
      text: 'Si AutoCut ne fonctionne pas correctement et que vous souhaitez signaler le problème :',
      non_editing:
        "Pour les problèmes non liés à l'édition (compte, clé, paiement, etc.) :",
      contact: 'Contactez-nous :',
      contactClicked: 'Informations de contact copiées !',
      cta: 'Signaler un problème',
    },
    author: '{author} de AutoCut',
    feedback: {
      steps: {
        welcome: {
          title: "Aidez-nous à façonner l'avenir du montage vidéo 🎬",
          footer: 'Envoyer mon retour 🫡',
          text1:
            "Vos idées sont inestimables pour nous et avec vos commentaires, vous pouvez jouer un rôle clé dans la création de l'avenir d'AutoCut. 💪",
          text2:
            'Dites-nous ce qui fonctionne, ce qui ne fonctionne pas, quelles nouvelles fonctionnalités vous aimeriez voir - aidez-nous à construire votre assistant de montage vidéo AI.',
          text3:
            'Enfin, si vous souhaitez rejoindre la communauté, vous pouvez rejoindre notre <a>Discord</a> de plus de 2000 éditeurs vidéo sympathiques comme vous 🌍',
        },
        report: {
          title: 'Votre commentaire',
          footer: 'Soumettre mon commentaire',
          text1:
            "Plus vous nous donnez d'informations sur votre problème, plus il est facile de le résoudre 💡",
          form: {
            textInput: {
              placeholder:
                'Décrivez votre retour. Précisez le mode et la langue utilisée. \n\nSi vous signalez un problème, mettez les étapes pour le reproduire.\n\nSi vous avez essayé une solution de contournement (une autre vidéo, une autre langue, etc.), veuillez nous le faire savoir.',
            },
            fileInput: {
              title:
                "Vous pouvez ajouter une capture d'écran de vos paramètres AutoCut et de votre chronologie",
              cta: "Ajouter une capture d'écran",
            },
          },
          error: {
            feedbackTooShort:
              'Votre retour est trop court. Veuillez fournir plus de détails.',
          },
        },
        submitted: {
          title: 'Merci 🙏',
          footer: 'Fermer le modal',
          text1: "Merci pour votre retour, nous l'apprécions vraiment. 🙏",
          text2:
            "Nous examinerons attentivement vos commentaires et tiendrons la communauté informée de l'évolution.",
          text3:
            "Restez à l'écoute pour plus de mises à jour et n'hésitez pas à nous contacter à tout moment. Vos contributions sont inestimables, et nous sommes reconnaissants de vous avoir à nos côtés dans cette aventure.",
        },
      },
    },
    onboarding: {
      steps: {
        welcome: {
          title: "Bienvenue dans la version Beta d'AutoCut",
          stepper: 'Bienvenue',
          footer: 'Suivant',
          text1:
            "Merci de faire partie de cette aventure ! 🚀 Nous sommes ravis de vous présenter cette version améliorée d'AutoCut.{br}Nous avons hâte de vous laisser tester cette version remaniée de notre assistant de montage vidéo par IA.",
          text2:
            "<b>Interface utilisateur améliorée</b> pour aider AutoCut à s'intégrer dans votre flux de travail d'édition 🎬{br}<b>Augmentation de 80 % de la stabilité</b> - dites adieu aux plantages ou interruptions inattendus 🧘{br}<b>Amélioration des performances de 50 %</b> avec des temps de réponse plus rapides, des résultats plus fluides et une plus grande efficacité 🚀",
          text3:
            "Et ce n'est pas tout, mais nous vous laisserons le découvrir en cours de route. Commençons !",
        },
        whyAreYouHere: {
          title: 'Pourquoi êtes-vous ici ?',
          stepper: 'Pourquoi êtes-vous ici ?',
          footer: 'Suivant',
          text1:
            "Si vous êtes ici, c'est parce que nous avons <b>besoin</b> de votre aide 🫵",
          text2:
            "Vous avez prouvé que vous êtes l'un de nos utilisateurs les plus fidèles, et nous vous en remercions. C'est pourquoi nous vous avons choisi pour faire partie de cette bêta exclusive.",
          text3:
            'Mais <i>un grand pouvoir implique de grandes responsabilités.</i>',
          text4:
            "Nous avons besoin de vos retours pour faire d'AutoCut l'outil ultime à avoir dans votre éventail de compétences en montage vidéo. Construisons ensemble l'outil pour mettre de côté toutes les tâches répétitives et vous laisser vous concentrer sur ce que vous faites de mieux : votre créativité.",
        },
        weNeedYou: {
          title: 'Nous avons besoin de vous 🫵',
          stepper: 'Nous avons besoin de vous',
          footer: 'Allons-y',
          text1:
            "Vous trouverez en bas à droite de la page d'accueil une nouvelle bulle de rétroaction qui vous permettra de signaler tout bug survenant dans cette nouvelle version.",
          text2:
            "La résolution de ces problèmes est notre priorité numéro un, c'est pourquoi chacun de vos commentaires sera dûment examiné.",
          text3:
            "C'est aussi un canal par lequel vous pourrez suggérer des fonctionnalités qui vous semblent indispensables.",
          text4:
            "Vous voudrez peut-être aussi rejoindre notre communauté <a>Discord</a> de plus de 2000 monteurs vidéo sympathiques comme vous et obtenir un aperçu exclusif de l'avenir d'AutoCut. 🌍",
        },
      },
    },
    usageStats: {
      title: "Statistiques d'Utilisation",
      select: {
        label: 'Choisissez un mode',
      },
      stats: {
        thisMonth: 'Ce Mois',
        allTime: 'Depuis le Début',
        mostUsed: 'Fonctionnalité la Plus Utilisée',
      },
    },
    endProcess: {
      title: 'Processus Terminé',
      time: 'Terminé en {minutes} minutes et {seconds} secondes !',
      timeSaved:
        'Temps Économisé : <b>{minutesSaved}</b> minutes et <b>{secondsSaved}</b> secondes',
      timeSavedAllTime:
        'Temps Total Économisé avec AutoCut : {hourSaved} heures et {minutesSaved} minutes ! 🎉',
      feedback: 'Nous valorisons vos retours sur les résultats :',
      review: 'Évaluez AutoCut !',
      restoreBackup: 'Restaurer la sauvegarde',
      caption: {
        title: 'Sous-titres Ajoutés !',
        footer:
          "Vous avez besoin d'ajuster les sous-titres ou la transcription ? Revenez à l'éditeur de sous-titres.",
        button: "Retour à l'éditeur de transcription",
        text_1: 'Des sous-titres animés ont été ajoutés ! 🎉',
        text_2: 'Éditez les sous-titres si nécessaire :',
        list_1: 'Placez le curseur de la timeline sur les sous-titres désirés',
        list_2: 'Allez à la page Fusion',
        list_3: 'Éditez manuellement les sous-titres dans le nœud TextPlus',
        text_3: "Pour plus d'astuces, regardez ce <a>tutoriel</a>.",
      },
      broll: {
        title:
          'Voici les crédits pour les B-Rolls utilisés dans votre vidéo : ',
        providedByPexels: 'fourni par Pexels',
        copyToClipboard: 'Copier dans le presse-papiers',
        copied: 'Copié',
        link: 'lien',
      },

      nextMode: {
        silence: {
          text: 'Pour un son fluide, utilisez AutoCut Silences pour éliminer sans effort les pauses gênantes.',
          button: 'Aller à AutoCut Silences',
        },
        podcast: {
          text: 'Améliorez votre podcast avec AutoCut Podcast pour un montage simplifié et des transitions de caméra automatiques.',
          button: 'Aller à AutoCut Podcast',
        },
        repeat: {
          text: 'Captivez votre audience ! Utilisez AutoCut Repeat pour ne garder que les parties les plus engageantes de votre contenu.',
          button: 'Aller à AutoCut Repeat',
        },
        zoom: {
          text: 'Donnez vie à vos vidéos avec AutoZoom ! Ajoutez des effets de zoom dynamiques qui captivent votre audience.',
          button: 'Aller à AutoZoom',
        },
        caption: {
          text: 'Connectez-vous avec vos spectateurs grâce à AutoCaptions. Ajoutez des sous-titres élégants et synchronisés pour enrichir le récit.',
          button: 'Aller à AutoCaptions',
        },
        resize: {
          text: 'Optimisez vos vidéos pour n’importe quelle plateforme avec AutoResize.',
          button: 'Aller à AutoResize',
        },
        broll: {
          text: "Enrichissez votre narration avec AutoB-Roll, en utilisant l'IA pour ajouter sans couture des séquences de stock.",
          button: 'Aller à AutoB-Roll',
        },
        swear: {
          word: {
            text: 'Maintenez un dialogue professionnel avec le filtre AutoProfanity. Censurez automatiquement le langage indésirable.',
            button: 'Aller à AutoProfanity Filter',
          },
        },
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'Version de {host} Non Supportée',
      currentVersion: 'Version détectée de {host} : {version}',
      text: 'AutoCut nécessite la version 18.6 ou supérieure de {host}.',
      update: 'Mettez à jour sur : <a>Site Web de {host}</a>',
    },
  },
  gamification: {
    savedHours: "Vous venez d'économiser {hourSavedText} heure(s),",
    haveABreak: 'Prenez une pause, vous le méritez!',
  },
  errors: {
    modal: {
      title: {
        noSelectedAudioClip: 'Aucun clip audio sélectionné',
        noTimelineFound: 'Aucune timeline trouvée',
        subsequenceNotReachable: 'Sous-séquence inaccessible',
        unknown: 'Erreur inconnue survenue',
        noSpace: 'Erreur de stockage insuffisant',
        exportUnknown: "Erreur d'exportation dans {host}",
        invalidInputData: 'Erreur de génération de fichier temporaire',
        fileNotFound: 'Erreur de lecture de fichier',
        scriptNotLoaded: 'Erreur de chargement du script AutoCut',
        ffmpegError: 'Erreur de lecture de fichier.',
        exportCancelled: 'Exportation annulée',
        timelineMuted: 'Aucune piste audible',
        noAudibleAudio: 'Aucun audio audible trouvé',
        offline: 'État hors ligne détecté',
        resourceTimeout: "Délai d'attente des ressources",
        backupNotFound: 'Sauvegarde non trouvée',
        setCursorOutInterval: 'Hors des points In/Out',
        emptyPath: "Erreur d'analyse de sélection",
        sequenceMismatch: 'Opération impossible à compléter',
      },
      text: {
        noSelectedAudioClip:
          "Aucun clip audio n'existe entre vos points d'entrée et de sortie sélectionnés",
        noTimelineFound: 'Aucune séquence active trouvée',
        subsequenceNotReachable:
          "Impossible d'atteindre les sous-séquences sélectionnées.",
        unknown: 'Erreur AutoCut avec cause inconnue.',
        noSpace: 'Le stockage de votre appareil est insuffisant.',
        exportUnknown:
          "Une réduction de l'espace de stockage a causé cette erreur.",
        invalidInputData:
          'La génération de fichier temporaire a rencontré une erreur.',
        fileNotFound:
          "Vérifiez que {filename} est à l'emplacement : {filepath}",
        scriptNotLoaded:
          'Fermez toutes les autres extensions, {host} et réessayez.',
        ffmpegError: 'Le fichier {filepath} est probablement corrompu.',
        exportCancelled:
          "L'analyse audio a été arrêtée si l'encodage a été annulé. Réessayez.",
        timelineMuted: 'Les pistes audio sélectionnées sont muettes.',
        noAudibleAudio: "L'audio détectable n'est pas disponible.",
        offline: 'Les serveurs sont inaccessibles.',
        resourceTimeout:
          'Impossible de télécharger les ressources dans les 30 secondes',
        backupNotFound:
          'Impossible de trouver la séquence de sauvegarde à restaurer.',
        setCursorOutInterval:
          'La section sélectionnée est en dehors des points In/Out.',
        emptyPath:
          'Les clips sélectionnés peuvent ne pas avoir de chemins sur votre ordinateur.',
        sequenceMismatch:
          'Évitez les changements de séquence pendant que AutoCut traite.',
      },
      tips: {
        noSelectedAudioClip:
          "Veuillez configurer correctement vos points d'entrée et de sortie",
        noTimelineFound: 'Veuillez sélectionner une timeline valide',
        subsequenceNotReachable:
          'Assurez-vous que les sous-séquences dans la timeline partagent des noms identiques avec les séquences originales dans le panneau de projet.',
        unknown:
          'Essayez de modifier la sélection/réglages. Problèmes persistants ? Contactez-nous.',
        noSpace: "Libérez de l'espace et relancez AutoCut.",
        exportUnknown: "Libérez de l'espace et relancez AutoCut.",
        invalidInputData:
          'Réessayez avec la même sélection. Contactez-nous avec votre fichier si le problème persiste.',
        fileNotFound:
          'Réessayez avec la même sélection. Contactez-nous avec votre fichier si le problème persiste.',
        scriptNotLoaded: 'Problème persistant ? Contactez-nous.',
        ffmpegError: 'Problème persistant ? Contactez-nous.',
        exportCancelled:
          "Vous avez annulé l'exportation au milieu du processus. AutoCut ne peut pas continuer sans l'analyse complète de l'audio. Veuillez réessayer.",
        timelineMuted: "Désactivez le mute d'au moins une piste.",
        noAudibleAudio:
          'Assurez-vous que les clips sont actifs et audibles. Problème persistant ? Contactez-nous sur Discord ou à contact@autocut.fr.',
        offline: 'Vérifiez la connexion Internet et réessayez.',
        resourceTimeout: 'Vérifiez la connexion Internet et réessayez.',
        backupNotFound:
          'Vérifiez le dossier "AutoCut-Backup" à la racine du projet pour la présence de sauvegarde.',
        setCursorOutInterval:
          'Supprimez les points In/Out pour accéder à cette section de transcription.',
        noSoundDetected:
          'Aucun son détecté. Vérifiez que la langue correspond aux clips, que les pistes ne sont pas muettes et que les points In/Out incluent des clips audio. {br}Problème persistant ? Contactez-nous sur Discord ou à contact@autocut.fr.',
        emptyPath:
          'Assurez-vous que les clips sont des médias atomiques ou des sous-séquences.',
        sequenceMismatch:
          "Évitez les changements et modifications de séquence jusqu'à l'achèvement du processus AutoCut.",
      },
    },
  },

  globals: {
    ok: 'OK',
    copied: 'Copié',
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: 'Traitement',
        },
        KebabMenu: {
          account: 'Gérer la Clé de Licence',
          usageStatistics: "Statistiques d'Utilisation",
          bug: 'Signaler un Bug',
          review: 'Évaluer AutoCut',
          tuto: 'Regarder le Tutoriel',
          logout: 'Déconnexion',
          settings: 'Réinitialiser les Paramètres',
          featureRequest: 'Demander une Fonctionnalité',
          academy: "Centre d'Aide",
        },
        LicenseInfos: {
          timeLeft: '{daysLeft} jours restants',
          info: 'Licence {licenceType}',
          trialButton: 'En Savoir Plus',
          paidButton: 'Gérer la Licence',
        },
        PromoBanner: {
          promo: "Économisez jusqu'à 20% dans les prochaines 24 heures !",
          shop: 'Acheter Maintenant',
        },
        FileInput: {
          uploadScreenshot: "Télécharger une Capture d'Écran de l'Issue",
        },
        LinkToUserFileInput: {
          sendLogLinkToUserFile: {
            title: 'Fournissez un lien vers votre fichier vidéo & audio.',
            description:
              '(Recommandé : SwissTransfer : https://www.swisstransfer.com/)',
            helper:
              'Pour télécharger via Swisstransfer, visitez swisstransfer.com, cliquez sur "Télécharger des Fichiers", obtenez un lien, et collez-le ici.',
            end: 'Nécessaire pour le dépannage et la résolution.',
          },
        },
        Select: {
          'no-more': "Plus d'options",
          'select-all': 'Tout Sélectionner',
          placeholder: 'Choisissez une option',
          search: 'Recherche',
        },
        PreviewFrame: {
          previewOnIndicator: "Aperçu Centré sur l'Indicateur",
        },
        FontNotFoundModal: {
          title: 'Police introuvable',
          description:
            "La police n'a pas été trouvée sur votre système, veuillez sélectionner une nouvelle police pour ajouter des sous-titres.",
          help: 'Vous pouvez ajouter une nouvelle police en suivant ce tutoriel.',
        },
        TimeBadge: {
          errors: {
            wrong_format: 'Format de temps incorrect. Utilisez HH:MM:SS.',
          },
        },
      },
    },
    misc: {
      retry: 'Réessayer',
      since_your_last_visit: 'Depuis votre dernière visite',
      back_to_autocut: 'Retour à AutoCut',
    },
    units: {
      second: 'Secondes',
      millisecond: 'Millisecondes',
      percentage: '%',
      px: 'Pixels',
    },
    language: {
      french: 'Français',
      english: 'Anglais',
      englishUS: 'Anglais - États-Unis',
      arabicDZ: 'Arabe - Algérie',
      arabicBH: 'Arabe - Bahreïn',
      arabicEG: 'Arabe - Égypte',
      arabicIQ: 'Arabe - Irak',
      arabicIL: 'Arabe - Israël',
      arabicJO: 'Arabe - Jordanie',
      arabicKW: 'Arabe - Koweït',
      arabicLB: 'Arabe - Liban',
      arabicMR: 'Arabe - Mauritanie',
      arabicMA: 'Arabe - Maroc',
      arabicOM: 'Arabe - Oman',
      arabicQA: 'Arabe - Qatar',
      arabicSA: 'Arabe - Arabie Saoudite',
      arabicPS: 'Arabe - Palestine',
      arabicTN: 'Arabe - Tunisie',
      arabicAE: 'Arabe - Émirats Arabes Unis',
      arabicYE: 'Arabe - Yémen',
      german: 'Allemand',
      hindi: 'Hindi',
      portugal: 'Portugais',
      brazil: 'Portugais - Brésil',
      spanish: 'Espagnol',
      spanish419: 'Espagnol - Amérique Latine',
      danish: 'Danois',
      dutch: 'Néerlandais',
      flamish: 'Flamand',
      italian: 'Italien',
      japanese: 'Japonais',
      korean: 'Coréen',
      norwegian: 'Norvégien',
      polish: 'Polonais',
      swedish: 'Suédois',
      tamil: 'Tamoul',
      chinese: 'Chinois',
      russian: 'Russe',
      turkish: 'Turc',
      catalan: 'Catalan',
      indonesian: 'Indonésien',
      finnish: 'Finnois',
      vietnamese: 'Vietnamien',
      hebrew: 'Hébreu',
      ukrainian: 'Ukrainien',
      greec: 'Grec',
      malay: 'Malais',
      czech: 'Tchèque',
      romanian: 'Roumain',
      hungarian: 'Hongrois',
      thai: 'Thaïlandais',
      urdu: 'Ourdou',
      croatian: 'Croate',
      bulgarian: 'Bulgare',
      lithuanian: 'Lituanien',
      maori: 'Maori',
      malayalam: 'Malayalam',
      welsch: 'Gallois',
      slovak: 'Slovaque',
      telugu: 'Télougou',
      persian: 'Persan',
      latvian: 'Letton',
      bengali: 'Bengali',
      serbian: 'Serbe',
      azerbaijani: 'Azerbaïdjanais',
      slovenian: 'Slovène',
      kannada: 'Kannada',
      estonian: 'Estonien',
      macedonian: 'Macédonien',
      breton: 'Breton',
      basque: 'Basque',
      icelandic: 'Islandais',
      armenian: 'Arménien',
      nepali: 'Népalais',
      mongolian: 'Mongol',
      bosnia: 'Bosniaque',
      kazakh: 'Kazakh',
      albanian: 'Albanais',
      swahili: 'Swahili',
      galician: 'Galicien',
      marathi: 'Marathi',
      punjabi: 'Pendjabi',
      sinhala: 'Singhalais',
      khmer: 'Khmer',
      shona: 'Shona',
      yoruba: 'Yoruba',
      somali: 'Somali',
      afrikaans: 'Afrikaans',
      occitan: 'Occitan',
      georgian: 'Géorgien',
      belarusian: 'Biélorusse',
      tajik: 'Tadjik',
      sindhi: 'Sindhi',
      gujarati: 'Gujarati',
      amharic: 'Amharique',
      yiddish: 'Yiddish',
      lao: 'Lao',
      uzbeck: 'Ouzbek',
      faroese: 'Féroïen',
      haitian: 'Créole haïtien',
      pashto: 'Pachto',
      turkmen: 'Turkmène',
      norwegianNynorsk: 'Norvégien Nynorsk',
      maltese: 'Maltais',
      sanskrit: 'Sanskrit',
      luxembourgish: 'Luxembourgeois',
      burmese: 'Birman',
      tibetan: 'Tibétain',
      tagalog: 'Tagalog',
      malagasay: 'Malgache',
      assamese: 'Assamais',
      tatar: 'Tatar',
      hawaiian: 'Hawaïen',
      lingala: 'Lingala',
      hausa: 'Haoussa',
      bashkir: 'Bachkir',
      javanese: 'Javanais',
      sundanese: 'Soundanais',
    },
  },
  old: {
    error: {
      UseLicence: {
        __text__: 'Dissociez les clés des autres appareils via le',
        link: 'portail client AutoCut.',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'Sous-séquence Inaccessible',
          unknown: 'Erreur Inconnue',
          noSpace: 'Erreur de Stockage Insuffisant',
          exportUnknown: "Erreur d'Exportation dans DaVinci Resolve",
          invalidInputData: 'Erreur de génération de fichier temporaire',
          fileNotFound: 'Erreur de Lecture du Fichier',
          scriptNotLoaded: 'Erreur de Chargement du Script AutoCut',
          ffmpegError: 'Erreur de lecture du fichier.',
          exportCancelled: 'Exportation Annulée',
          timelineMuted: 'Aucune Piste Audible',
          noAudibleAudio: 'Aucun Audio Audible Trouvé',
          offline: 'État Hors Ligne Détecté',
          resourceTimeout: "Délai d'Attente des Ressources",
          backupNotFound: 'Sauvegarde Inconnue',
          setCursorOutInterval: 'En dehors des Points In/Out',
          emptyPath: "Erreur d'Analyse de Sélection",
          sequenceMismatch: 'Opération Impossible à Compléter',
        },
        text: {
          subsequenceNotReachable:
            "Impossible d'atteindre les sous-séquences sélectionnées.",
          unknown: "Erreur AutoCut d'origine inconnue.",
          noSpace: 'Le stockage de votre appareil est insuffisant.',
          exportUnknown: 'Le manque de stockage disque a causé cette erreur.',
          invalidInputData:
            'Une erreur a été rencontrée lors de la génération du fichier temporaire.',
          fileNotFound:
            "Vérifiez que {filename} se trouve à l'emplacement : {filepath}",
          scriptNotLoaded:
            'Fermez toutes les autres extensions, DaVinci Resolve et réessayez.',
          ffmpegError: 'Le fichier {filepath} est possiblement corrompu.',
          exportCancelled:
            "L'analyse audio a été interrompue si l'encodage a été annulé. Réessayez.",
          timelineMuted: 'Les pistes audio sélectionnées sont muettes.',
          noAudibleAudio: "Aucun audio détectable n'est disponible.",
          offline: 'Les serveurs sont inaccessibles.',
          resourceTimeout:
            'Impossible de télécharger les ressources dans les 30 secondes',
          backupNotFound:
            'Impossible de trouver la séquence de sauvegarde à restaurer.',
          setCursorOutInterval:
            'La section sélectionnée est en dehors des Points In/Out.',
          emptyPath:
            'Les clips sélectionnés pourraient ne pas avoir de chemins sur votre ordinateur.',
          sequenceMismatch:
            'Évitez les changements de séquence pendant que AutoCut traite.',
        },
        tips: {
          subsequenceNotReachable:
            'Assurez-vous que les sous-séquences dans la timeline portent exactement les mêmes noms que les séquences originales dans le panneau de projet.',
          unknown:
            'Essayez de modifier la sélection/les paramètres. Problème persistant ? Contactez-nous.',
          noSpace: "Libérez de l'espace et relancez AutoCut.",
          exportUnknown: "Libérez de l'espace et relancez AutoCut.",
          invalidInputData:
            'Réessayez avec la même sélection. Contactez-nous avec votre fichier si le problème persiste.',
          fileNotFound:
            'Réessayez avec la même sélection. Contactez-nous avec votre fichier si le problème persiste',
          scriptNotLoaded: 'Problème persistant ? Contactez-nous.',
          ffmpegError: 'Problème persistant ? Contactez-nous.',
          exportCancelled: ' ',
          timelineMuted: 'Démugez au moins une piste.',
          noAudibleAudio:
            'Assurez-vous que les clips sont actifs et audibles. Problème persistant ? Contactez-nous sur Discord ou à contact@autocut.fr.',
          offline: 'Vérifiez votre connexion Internet et réessayez.',
          resourceTimeout: 'Vérifiez votre connexion Internet et réessayez.',
          backupNotFound:
            'Vérifiez la présence de sauvegardes dans le dossier "AutoCut-Backup" à la racine du projet.',
          setCursorOutInterval:
            'Supprimez les Points In/Out pour accéder à cette section de transcription.',
          noSoundDetected:
            'Aucun son détecté. Vérifiez que la langue correspond aux clips, que les pistes ne sont pas muettes et que les Points In/Out incluent des clips sonores. {br}Problème persistant ? Contactez-nous sur Discord ou à contact@autocut.fr.',
          emptyPath:
            'Assurez-vous que les clips sont des médias atomiques ou des sous-séquences.',
          sequenceMismatch:
            "Évitez les modifications de séquence jusqu'à la fin du processus AutoCut.",
        },
        lost: {
          cep: {
            title: 'Erreur de Perte de Connexion',
            text: {
              '1': "Connexion perdue avec DaVinci Resolve. Redémarrez l'extension.",
              '2': 'Échec ? Redémarrez DaVinci Resolve.',
            },
          },
        },
      },
      language: {
        not: {
          supported:
            'La prise en charge de la langue est indisponible pour ce mode.',
        },
      },
    },
    retour: "La clé n'existe pas !",
    countdown: {
      days: "Jours Restants d'Essai",
    },
    saveas: {
      default: {
        settings: 'Enregistrer les Paramètres Actuels',
      },
    },
    button: {
      no_silences: 'Aucun Silence Trouvé',
      no_audios: 'Clip Audio Sélectionné Absent',
      SavingBackup: 'Sauvegarde du Projet...',
      RestoringBackup: 'Restauration du Projet...',
      ExportingAudio: "Exportation de l'Audio...",
      UploadingAudio: "Téléchargement de l'Audio...",
      SearchingSilences: 'Recherche des Silences...',
      CuttingStep: {
        Audio:
          'Exécution des Coupes Audio sur la Piste {numTrack} : {current} sur {max}',
        Video:
          'Exécution des Coupes Vidéo sur la Piste {numTrack} : {current} sur {max}',
      },
      PostCuttingStep: 'Coupes Terminées...',
      PreDeletingStep: 'Recherche des Clips Silencieux à Supprimer...',
      PostDeletingStep: 'Silences Supprimés...',
      finalizing: 'Finalisation...',
      DeletingStart: 'Suppression des Silences...',
      AssociatingStep: "Réalisation de l'Association {current} sur {max}",
      Success: 'Processus Réussi !',
      Failure: 'Échec... Problème Rencontré',
      NoSilences: 'Aucun Silence Détecté',
      undo: {
        modal: 'Inverse',
      },
      not: {
        supported: {
          modal: 'Fermer',
        },
      },
      PreZoomingStep: 'Obtention des Sous-Clips pour le Zoom...',
      ZoomingStep: 'Ajout du Zoom : {current} sur {max}',
      Autozoom: "Préparation d'AutoZoom...",
      no_video: {
        to_zoom: 'Aucune Vidéo à Zoomer.',
      },
      getSubclip: {
        step: 'Récupération du Sous-Clip pour AutoZoom...',
      },
      checkSilenceSuppression: {
        step: 'Vérification de la Suppression des Silences...',
      },
      back: 'Retour',
      DeletingStep: {
        delete: {
          Audio:
            'Suppression du Silence sur la Piste Audio {numTrack} : {current} sur {max}',
          Video:
            'Suppression du Silence sur la Piste Vidéo {numTrack} : {current} sur {max}',
        },
        mute: {
          Audio:
            'Muet du Silence sur la Piste Audio {numTrack} : {current} sur {max}',
          Video:
            'Muet du Silence sur la Piste Vidéo {numTrack} : {current} sur {max}',
        },
      },
      skip: 'Passer',
      save: 'Sauvegarder',
    },
    warning: {
      DontTouchTimeline: 'Évitez de Modifier la Timeline Pendant le Processus',
      undo: {
        modal:
          "Métadonnées manquantes détectées dans l'analyse du fichier audio.",
      },
      modal: {
        title: {
          ffmpeg: 'Problème Potentiel avec FFMPEG',
        },
        text: {
          ffmpeg:
            'Métadonnées manquantes détectées dans le fichier {filename}.',
        },
        tips: {
          ffmpeg:
            'Envisagez de réencoder le fichier si les problèmes persistent.',
        },
      },
      beta: {
        version:
          'Version Bêta de DaVinci Resolve détectée. Attendez-vous à des problèmes potentiels avec AutoCut. Il est recommandé de tester sur une version stable récente de DaVinci Resolve.',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: 'Validation de la sélection...',
          parsing: {
            step: 'Récupération des données : clip {current} sur {max}...',
          },
        },
        silence: {
          detection: {
            step: 'Détection du silence : clip {current} sur {max}...',
          },
        },
        drawing: "Génération de l'aperçu...",
      },
      frame: {
        no_sequence: 'Démarrez une séquence pour voir un aperçu',
      },
    },
    contact: {
      us_title: 'Désolé pour le désagrément',
      us_text: {
        '2': 'Pour les problèmes non liés au montage (compte, clé, paiement, etc.) :',
        __text__:
          'Si AutoCut ne fonctionne pas correctement et que vous souhaitez signaler le problème :',
      },
      us_button: 'Signaler le Problème',
      us_contact: 'Nous Contacter :',
      us_copy: 'Informations de contact copiées !',
    },
    switch: {
      to_send: {
        log: {
          button: 'Envoyer un fichier journal en alternative',
        },
      },
    },
    send: {
      log: {
        title: 'Nous Nous Excusons pour le Problème',
        details: 'Ce fichier journal montre comment vos médias sont traités.',
        select:
          'Choisissez un fichier journal quotidien pour aider à résoudre le problème.',
        text: {
          area: 'Décrivez votre problème en détail :',
        },
        button: 'Soumettre le Fichier Journal',
        success: {
          title: 'Fichier Reçu ! Nous Investiguerons.',
        },
        thank: {
          text: 'Continuez votre montage.',
          text2: 'Nous vous tiendrons informé une fois résolu.',
        },
        message: {
          error: 'Le message doit contenir au moins 10 caractères',
        },
        screen: {
          timeline: {
            __text__:
              "Incluez une capture d'écran de votre timeline DaVinci Resolve.",
            before: "Incluez une capture d'écran avant la coupe.",
            after: "Incluez une capture d'écran après la coupe.",
          },
          error: {
            message: "Incluez une capture d'écran du message d'erreur.",
          },
        },
        trial: {
          mail: "Fournissez l'email associé à l'essai.",
        },
        other:
          'Fournissez toute information supplémentaire pour aider à résoudre le problème.',
      },
    },
    select: {
      issue: {
        title: 'Sélectionnez Votre Problème Ci-dessous.',
        subtitle: 'Cela nous aide à résoudre rapidement votre problème.',
      },
      one: {
        audios:
          'Sélectionnez un ou plusieurs clips audio pour utiliser AutoCut.',
      },
    },
    upload: {
      screenshot: "Soumettre une Capture d'Écran",
    },
    modal: {
      feedback: {
        title: 'Retour',
        input: {
          feedback: {
            title: 'Vos Avis sur AutoCut ?',
            placeholder: 'Entrez vos retours...',
          },
          improve: {
            title: "Suggestions d'Amélioration ?",
            placeholder: 'Entrez vos suggestions...',
          },
        },
        button: {
          text: 'Soumettre',
        },
        sent: {
          title: 'Merci pour vos Retours !',
          text: 'Avez-vous des idées de fonctionnalités ? Faites-le nous savoir !',
          button: {
            text: 'Demander une Fonctionnalité',
          },
        },
      },
      bug: {
        report: {
          title: 'Rapport de Bug',
          description: {
            placeholder: 'Décrivez le problème...',
            error:
              'La description du bug doit contenir au moins 20 caractères.',
          },
          reproduce: {
            placeholder: 'Étapes pour reproduire le problème...',
            error:
              'Les détails de reproduction doivent contenir au moins 20 caractères.',
          },
          blocking: "S'agit-il d'un problème bloquant ?",
          button: {
            text: {
              __text__: 'Soumettre le Rapport de Bug',
              success: 'Merci pour votre rapport !',
            },
          },
          link: {
            error: 'Fournissez un lien de fichier valide.',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'Des problèmes avec les coupes ?',
      },
    },
    star: {
      review: {
        title: 'Laissez un Avis sur AutoCut !',
        caption: {
          title: 'Évaluez AutoCaptions !',
        },
        text: 'Partagez votre expérience avec AutoCut !',
      },
    },
    review: {
      title: 'Fournissez Plus de Détails',
      text: 'Heureux des coupes ? Donnez des retours pour aider à améliorer AutoCut !',
      form: {
        placeholder: 'Entrez vos retours...',
      },
      button: 'Soumettre',
      thank: {
        title: 'Merci pour vos Retours !',
        text: 'Continuez votre montage.',
      },
    },
    exchange: {
      title: 'Obtenez 7 Jours de Test Supplémentaires !',
      text: 'Évaluez AutoCut sur Adobe Exchange pour recevoir 7 jours de test supplémentaires !',
      button: 'Évaluez AutoCut sur Adobe Exchange',
      info: "Installé directement depuis notre site ? Pas besoin de réinstaller. Cliquez simplement sur 'Gratuit' pour commencer l'évaluation.",
    },
    licence: {
      trial: 'Essai',
      paid: 'Payant',
      info: 'Licence {licenceType}',
      time: {
        left: '{daysLeft} Jours Restants',
      },
    },
    trial: {
      promo: {
        text: "Économisez jusqu'à 20% en 24 Heures",
      },
      resend: {
        error:
          "Erreur lors de la renvoyée de la clé d'essai. Veuillez réessayer plus tard.",
      },
      expired: {
        modal: {
          title: 'Essai Expiré.',
          text1:
            'Votre essai a expiré. Abonnez-vous pour continuer à utiliser AutoCut.',
          text2: 'Visitez votre tableau de bord AutoCut à :',
          text3: 'Abonnez-vous pour une clé de licence.',
          text4: 'Pour le support, contactez : {supportEmail}',
          button: "S'abonner avec 20% de Réduction",
        },
      },
      title: 'Essai Gratuit',
      stepper: {
        onboarding: 'Aidez-Nous à Améliorer AutoCut',
        email: 'Entrez Votre Email',
        sending: {
          email: "Envoi de l'Email",
        },
        login: 'Entrez la Clé de Licence',
      },
      onboarding: {
        subtitle:
          'Partagez des insights pour aider à développer de nouvelles fonctionnalités DaVinci Resolve',
        discover: 'Comment avez-vous trouvé AutoCut ?',
        job: {
          status: 'Statut Professionnel Actuel',
        },
        type: {
          creation: 'Types de Projets DaVinci Resolve',
        },
      },
      sending: {
        email: {
          success: 'Email envoyé avec succès à {email}',
        },
      },
      task: {
        caption: 'Utilisez AutoCaptions : <link>En Savoir Plus</link>',
        podcast: 'Utilisez AutoCut Podcast : <link>En Savoir Plus</link>',
        zoom: 'Utilisez AutoZoom : <link>En Savoir Plus</link>',
        repeat: 'Utilisez AutoCut Repeat : <link>En Savoir Plus</link>',
        broll: 'Utilisez AutoB-Roll : <link>En Savoir Plus</link>',
        swear: {
          word: 'Utilisez AutoProfanity Filter : <link>En Savoir Plus</link>',
        },
        preset:
          'Créez un nouveau préréglage pour AutoCaptions, AutoZoom ou AutoResize',
        testimonial:
          'Laissez un Témoignage (utilisant le même email) : <link>Donner un Témoignage</link>',
      },
    },
    shop: {
      now: 'Acheter Maintenant',
    },
    back: {
      to_autocut: 'Retourner à AutoCut',
    },
    'discover-option-youtube-video': 'Vidéo YouTube',
    'discover-option-youtube-comment': 'Commentaire YouTube',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': 'Ami',
    'discover-option-school': 'École',
    'discover-option-other': 'Autre',
    link: {
      not: {
        supported: {
          modal: {
            __text__: "Pour plus d'informations et de solutions, visitez ",
            no_solution: "Pour des clarifications d'erreur, consultez ",
            end: ', notre site de documentation et de tutoriels',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: 'Un email a été envoyé à ({email})',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            "Vérifiez votre dossier spam ou promotions si l'email est manquant.",
        },
      },
    },
    placeholder: {
      use: {
        licence: 'Entrez Votre Clé de Licence',
      },
    },
    "can't": {
      reach: {
        server: {
          error:
            "Impossible d'atteindre le serveur. Veuillez réessayer plus tard.",
        },
      },
      read: {
        uuid: {
          error: "Impossible de lire l'UUID de l'ordinateur.",
        },
        host: {
          error: "Impossible de lire le nom de l'ordinateur.",
        },
      },
    },
    asking: {
      key: {
        error:
          'Erreur lors de la vérification de la clé avec le serveur. Veuillez réessayer plus tard.',
      },
    },
    sending: {
      log: {
        file: {
          error:
            "Échec de l'envoi du journal quotidien. Veuillez réessayer plus tard.",
        },
      },
    },
    is_key: {
      valid: {
        error:
          'Erreur lors de la validation de la clé. Veuillez réessayer plus tard.',
      },
    },
    base: {
      error: {
        message: "Une erreur s'est produite ",
      },
    },
    invalid: {
      response: {
        error: 'Réponse inattendue du serveur reçue.',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: 'En développement ; pour des problèmes ou des suggestions, contactez-nous à contact@autocut.com',
        },
      },
    },
    header: {
      trial: {
        button: 'En Savoir Plus',
      },
      paid: {
        button: 'Gérer',
      },
      speakers: {
        name: "Nom de l'Intervenant",
        track: "Piste Audio de l'Intervenant",
      },
      cameras: {
        name: {
          __text__: 'Piste Vidéo de la Caméra',
          helper:
            'Sélectionnez la piste vidéo pour chaque caméra, une piste par caméra.',
        },
        speakers: {
          __text__: 'Intervenant(s) Visible(s)',
          helper:
            'Sélectionnez quels intervenants sont visibles sur cette caméra. Plusieurs intervenants autorisés.',
        },
      },
    },
    game: {
      saved: {
        hours: 'Temps Économisé {hourSavedText} heure(s),',
      },
      have: {
        a_break: 'Prenez une pause bien méritée !',
      },
    },
    onboarding: {
      skip: "Passer l'Onboarding",
      close: 'Fermer',
      previous: 'Précédent',
      next: 'Suivant ({current}/{max})',
      onboarding: {
        step1: {
          title: 'Bienvenue à AutoCut !',
          body: 'Lors de votre première utilisation, AutoCut vous guidera à travers diverses fonctionnalités pour un montage sans faille. Commençons !',
        },
        step2: {
          title: "Clé d'Essai Gratuit",
          body: "Explorez toutes les fonctionnalités avec une clé d'essai, valide pendant 14 jours.",
        },
        step3: {
          title: 'Clé de Licence',
          body: "Entrez votre clé de licence (d'essai ou payante) pour accéder à tous les outils AutoCut.",
        },
        step4: {
          title: 'Acheter une Clé de Licence',
          body: "Achetez une clé de licence via le bouton, qui vous redirige vers notre site avec les détails d'achat.",
        },
      },
      modeSelection: {
        step1: {
          title: 'Modes AutoCut',
          body: 'Choisissez un mode adapté à vos besoins de montage parmi les options disponibles.',
        },
        step2: {
          title: "Besoin d'Aide ?",
          body: "Survolez les éléments de l'interface pour voir des info-bulles et en savoir plus.",
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2 Beta',
          body: 'AutoCut V2 détecte les silences en fonction des niveaux de bruit audio. Une configuration initiale est requise.',
        },
        step2: {
          title: 'Niveau de Bruit',
          body: 'Utilisez le curseur pour définir le niveau sonore minimum classé comme silence.',
        },
        step3: {
          title: 'Étapes Suivantes',
          body: 'Naviguez à travers les étapes en utilisant les flèches et explorez plus de fonctionnalités AutoCut.',
        },
        step4: {
          title: "Génération de l'Aperçu",
          body: 'Exportez l\'audio avant le traitement. Cliquez sur "Générer l\'Aperçu" pour visualiser les coupes audio.',
        },
        step6: {
          title: 'Temps de Coupe',
          body: "Examinez l'aperçu et procédez aux coupes si vous êtes satisfait.",
        },
        step7: {
          title: 'Temps de Coupe',
          body: 'Sélectionnez vos options de coupe préférées.',
        },
        step8: {
          title: 'Temps de Coupe',
          body: 'Détendez-vous et laissez AutoCut gérer le montage !',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast',
          body: 'Montez rapidement vos vidéos de podcast avec AutoCut Podcast.',
        },
        step2: {
          title: 'Intervenants',
          body: 'Ajoutez des intervenants en utilisant le bouton "Ajouter un Intervenant". Attribuez une piste par intervenant.',
        },
        step3: {
          title: 'Intervenants',
          body: 'Configurez la configuration de chaque intervenant selon les besoins.',
        },
        step4: {
          title: "Nom de l'Intervenant",
          body: 'Attribuez un nom à chaque intervenant pour identification.',
        },
        step5: {
          title: 'Sélection de la Piste Vidéo',
          body: 'Sélectionnez les pistes vidéo qui affichent chaque intervenant.',
        },
        step6: {
          title: 'Sélection de la Piste Audio',
          body: 'Attribuez une piste audio à chaque intervenant. Une seule piste par intervenant.',
        },
        step7: {
          title: "Temps d'Édition",
          body: 'Définissez les intervenants et poursuivez le montage.',
        },
        step8: {
          title: "Temps d'Édition",
          body: 'Utilisez les flèches pour sélectionner les options de montage.',
        },
        step9: {
          title: "Temps d'Édition",
          body: 'Détendez-vous et laissez AutoCut gérer le processus de montage.',
        },
      },
      title: 'Bienvenue à AutoCut',
      subtitle: 'Votre Extension Ultime pour DaVinci Resolve',
    },
    start: {
      transcription: 'Commencer la Transcription',
    },
    no_more: {
      credits: 'Crédits Épuisés',
    },
    file: {
      too: {
        long: 'Le fichier audio est trop long',
      },
      sent: 'Fichier téléchargé, en attente de transcription...',
    },
    please: {
      wait1: 'En attente de la transcription audio...',
      wait2: 'Ce processus peut prendre quelques minutes, selon la longueur.',
      wait3: 'Téléchargement du fichier :',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'Langue Audio',
            helper: 'Spécifiez la langue du clip audio',
          },
        },
        french: {
          __text__: 'Français',
          beta: 'Français (Bêta)',
        },
        danish: 'Danois',
        dutch: 'Néerlandais',
        english: {
          __text__: 'Anglais',
          united: {
            states: 'Anglais - États-Unis',
          },
        },
        flemish: 'Flamand',
        german: {
          __text__: 'Allemand',
          beta: 'Allemand (Bêta)',
        },
        hindi: {
          __text__: 'Hindi',
          beta: 'Hindi (Bêta)',
        },
        italian: 'Italien',
        japanese: 'Japonais',
        korean: 'Coréen',
        norwegian: {
          __text__: 'Norvégien',
          nynorsk: 'Norvégien Nynorsk',
        },
        polish: 'Polonais',
        portuguese: {
          __text__: 'Portugais',
          brazil: 'Portugais - Brésil',
          'brazil—beta': 'Portugais - Brésil (Bêta)',
        },
        'portuguese—beta': 'Portugais (Bêta)',
        spanish: {
          __text__: 'Espagnol',
          latin: {
            america: 'Espagnol - Amérique Latine',
            'america—beta': 'Espagnol - Amérique Latine (Bêta)',
          },
        },
        'spanish—beta': 'Espagnol (Bêta)',
        swedish: 'Suédois',
        tamil: 'Tamoul',
        chinese: 'Chinois',
        russian: 'Russe',
        turkish: 'Turc',
        catalan: 'Catalan',
        arabic: {
          __text__: 'Arabe',
          algeria: 'Arabe - Algérie',
          bahrain: 'Arabe - Bahreïn',
          egypt: 'Arabe - Égypte',
          iraq: 'Arabe - Iraq',
          israel: 'Arabe - Israël',
          jordan: 'Arabe - Jordanie',
          kuwait: 'Arabe - Koweït',
          lebanon: 'Arabe - Liban',
          mauritania: 'Arabe - Mauritanie',
          morocco: 'Arabe - Maroc',
          oman: 'Arabe - Oman',
          qatar: 'Arabe - Qatar',
          saudi: {
            arabia: 'Arabe - Arabie Saoudite',
          },
          palestine: 'Arabe - Palestine',
          syria: 'Arabe - Syrie',
          tunisia: 'Arabe - Tunisie',
          united: {
            arab: {
              emirates: 'Arabe - Émirats Arabes Unis',
            },
          },
          yemen: 'Arabe - Yémen',
        },
        indonesian: 'Indonésien',
        finnish: 'Finnois',
        vietnamese: 'Vietnamien',
        hebrew: 'Hébreu',
        ukrainian: 'Ukrainien',
        greek: 'Grec',
        malay: 'Malay',
        czech: 'Tchèque',
        romanian: 'Roumain',
        hungarian: 'Hongrois',
        thai: 'Thaï',
        urdu: 'Ourdou',
        croatian: 'Croate',
        bulgarian: 'Bulgare',
        lithuanian: 'Lituanien',
        latin: 'Latin',
        maori: 'Maori',
        malayalam: 'Malayalam',
        welsh: 'Gallois',
        slovak: 'Slovaque',
        telugu: 'Télougou',
        persian: 'Persan',
        latvian: 'Letton',
        bengali: 'Bengali',
        serbian: 'Serbe',
        azerbaijani: 'Azéri',
        slovenian: 'Slovène',
        kannada: 'Kannada',
        estonian: 'Estonien',
        macedonian: 'Macédonien',
        breton: 'Breton',
        basque: 'Basque',
        icelandic: 'Islandais',
        armenian: 'Arménien',
        nepali: 'Népalais',
        mongolian: 'Mongolien',
        bosnian: 'Bosnien',
        kazakh: 'Kazakh',
        albanian: 'Albanais',
        swahili: 'Swahili',
        galician: 'Galicien',
        marathi: 'Marathi',
        punjabi: 'Punjabi',
        sinhala: 'Sinhala',
        khmer: 'Khmer',
        shona: 'Shona',
        yoruba: 'Yoruba',
        somali: 'Somali',
        afrikaans: 'Afrikaans',
        occitan: 'Occitan',
        georgian: 'Géorgien',
        belarusian: 'Biélorusse',
        tajik: 'Tadjik',
        sindhi: 'Sindhi',
        gujarati: 'Gujarati',
        amharic: 'Amharique',
        yiddish: 'Yiddish',
        lao: 'Laotien',
        uzbek: 'Ouzbek',
        faroese: 'Féroïen',
        haitian: {
          creole: 'Créole Haïtien',
        },
        pashto: 'Pashto',
        turkmen: 'Turkmène',
        maltese: 'Maltais',
        sanskrit: 'Sanskrit',
        luxembourgish: 'Luxembourgeois',
        burmese: 'Birman',
        tibetan: 'Tibétain',
        tagalog: 'Tagalog',
        malagasy: 'Malgache',
        assamese: 'Assamais',
        tatar: 'Tatar',
        hawaiian: 'Hawaïen',
        lingala: 'Lingala',
        hausa: 'Hausa',
        bashkir: 'Bachkir',
        javanese: 'Javanais',
        sundanese: 'Soundanais',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            'Votre séquence contient de nombreux clips ! Cela peut prendre plus de temps à charger. Sélectionnez uniquement les clips nécessaires et coupez-les dans une séquence séparée.',
        },
      },
    },
    homepage: {
      title: "Page d'Accueil",
      comming: {
        soon: 'Bientôt Disponible !',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'Dynamisme',
        normal: 'Normal',
        high: 'Élevé',
        extreme: 'Extrême',
        custom: 'Personnalisé :',
      },
      button: {
        __text__: 'Appliquer le Zoom',
        message: {
          step1: "Préparation d'AutoZoom...",
          step2: 'Extraction des Données Audio...',
          step3: 'Calcul des Intervalles de Zoom...',
          step4: 'Application du Zoom {current} de {max}...',
        },
      },
      success: 'Succès ! Effets de zoom appliqués avec succès.',
      info: {
        text: 'AutoZoom ajoute des effets de zoom dynamiques aux clips sélectionnés basés sur les niveaux audio.',
      },
      invalid: {
        selection: {
          screen: {
            title: 'Sélectionnez les clips audio et vidéo dans votre timeline',
            text1:
              'Sélectionnez les clips de la timeline pour appliquer AutoZoom.',
          },
        },
      },
      anchor: {
        title: "Définir le Point d'Ancrage",
        helper:
          "Le point d'ancrage est l'endroit où le zoom se concentre. Les boîtes colorées montrent les effets de zoom maximal et minimal.",
      },
      dual: {
        range: {
          title: 'Définir la Plage de Coefficient de Zoom',
          helper:
            'Les niveaux de zoom sont basés sur ces valeurs et le volume maximal du clip.',
        },
      },
      preview: {
        on_indicator: "Aperçu Centré sur l'Indicateur",
        error: {
          message: "Erreur lors du chargement de l'aperçu",
          button: 'Réessayer',
        },
      },
      min: {
        zoom: {
          title: 'Durée Minimale du Zoom',
          helper:
            'Les effets de zoom ne seront pas plus courts que cette durée.',
        },
      },
      max: {
        zoom: {
          title: 'Durée Maximale du Zoom',
          helper: 'Les effets de zoom ne dépasseront pas cette durée.',
        },
      },
      constraint: {
        zoom: {
          title: 'Limiter le Zoom aux Clips',
          helper:
            "Si activé, les effets de zoom sont confinés aux clips individuels. Si désactivé, ils s'étendent sur toute la sélection.",
        },
      },
      zoom: {
        types: {
          __text__: 'Styles de Zoom',
          helper:
            'Choisissez entre Fluide (zoom progressif) et Dynamique (zoom immédiat).',
        },
        coverage: {
          __text__: 'Couverture Totale du Zoom',
          helper: 'Pourcentage de zoom appliqué.',
        },
      },
      smooth: {
        __text__: 'Fluide',
        zoom: {
          __text__: 'Zooms Fluides',
          coverage: 'Couverture du Zoom Fluide',
          nervousness: {
            __text__: 'Nervosité',
            helper:
              'Valeurs plus basses pour des zooms plus lents et plus doux ; valeurs plus élevées pour des zooms plus rapides et intenses.',
          },
        },
      },
      dynamic: {
        __text__: 'Dynamique',
        zoom: {
          __text__: 'Zooms Dynamiques',
          coverage: 'Couverture du Zoom Dynamique',
        },
      },
    },
    zoom: {
      preset: {
        title: 'Préréglages par Défaut',
        calm: 'Calme',
        paced: 'Rythmé',
        energetic: 'Énergétique',
        hyperactive: 'Hyperactif',
      },
    },
    repeat: {
      info: {
        banner:
          'AutoCut Repeat supprime les segments répétitifs. Utilisez les ciseaux pour basculer entre la coupe ou la conservation des parties pour une précision.',
      },
      text: {
        step: {
          edit: {
            transcript: 'Corriger la Transcription',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'Sélectionnez un clip audio de timeline (et éventuellement vidéo) pour AutoCut Repeat.',
            text1:
              'Sélectionnez un clip audio de timeline (et éventuellement vidéo) pour AutoCut Repeat.',
          },
        },
      },
      select: {
        algorithm: "Choisissez l'Algorithme",
      },
      precise: {
        algorithm: {
          info: 'Notre nouvel algorithme efficace traite environ une minute de vidéo par minute.',
        },
      },
      fast: {
        algorithm: 'Rapide',
      },
      toggle: {
        cut: {
          button: {
            text: 'Basculer la Coupe',
          },
        },
      },
      play: {
        button: {
          text: 'Lire',
        },
      },
    },
    caption: {
      step: {
        customization: 'Personnaliser les Sous-titres',
        script: 'Sélectionner les Clips et la Langue',
        transcription: 'Modifier la Transcription',
      },
      script: {
        transcript: {
          part: 'Transcription',
        },
        sequence: {
          part: 'Séquence',
          name: 'Nom',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips} Clips | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'Ajouter des Sous-titres',
        coming: {
          soon: "Éditeur de Sous-titres bientôt disponible ! Actuellement, éditez les sous-titres via le panneau Essential Graphics. Sélectionnez les sous-titres dans la timeline et modifiez-les dans l'onglet édition du panneau.",
        },
        transcription: {
          part: 'Transcription',
        },
        info: "Bienvenue dans l'Éditeur de Sous-titres – éditez optionnellement les sous-titres directement dans la timeline DaVinci Resolve après les avoir ajoutés.",
        add: {
          emojis: {
            cta: 'Créer des Emojis',
          },
        },
      },
      text: {
        noClip: {
          title: 'Sélectionnez des Clips pour les Sous-titres',
          no_selection:
            'Si AutoCut ne répond pas, essayez de redémarrer DaVinci Resolve.',
        },
      },
      process: {
        transcript: {
          button: 'Traiter',
        },
      },
      customization: {
        info: "AutoCaption est en version bêta et devrait se stabiliser d'ici le 10 novembre.",
        back: {
          button: 'Supprimer la Transcription et Retourner',
        },
        example: {
          __text__:
            'Aperçu <opacity50><i>Glissez pour ajuster la position des sous-titres/emoji</i></opacity50>',
          text: "AutoCaption ajoute des sous-titres visibles dans l'aperçu. Personnalisez les options dans AutoCaptions ; modifiez la transcription si nécessaire. Les sous-titres sont ajoutés instantanément à la timeline.",
        },
        animation: {
          __text__: 'Animation',
          helper: "Contrôlez les effets d'animation du texte",
          color: {
            __text__: 'Mettre en évidence avec <color>Audio</color>',
            text: {
              color: 'Couleur du Texte',
            },
            before: {
              highlight: {
                color: 'Couleur de Pré-Mise en Évidence',
              },
            },
            after: {
              highlight: {
                color: 'Couleur de Post-Mise en Évidence',
              },
            },
          },
          advanced: {
            color: {
              options: 'Options Avancées de Couleur',
            },
          },
          word: {
            box: 'Texte avec <color>Boîte</color>',
          },
          bold: {
            with: {
              text: 'Texte en <b>Gras</b>',
            },
          },
          floating: {
            text: 'Texte Flottant',
          },
        },
        transition: {
          __text__: 'Transition',
          helper:
            "Gérez les transitions des sous-titres ; la durée peut ne pas correspondre à l'exemple.",
          effect: {
            zoomIn: 'Zoom-In',
            blurIn: 'Flou-In',
          },
        },
        outline: {
          __text__: 'Contour',
          helper: 'Gérez les styles de contour',
          color: 'Couleur du Contour',
          width: 'Épaisseur du Contour',
        },
        font: {
          __text__: 'Police',
          helper: 'Gérez les styles de police',
          font: 'Nom de la Police',
          help: 'Comment ajouter une nouvelle famille de polices ?',
          size: 'Taille de la Police',
          weight: 'Épaisseur de la Police',
          color: 'Couleur de la Police',
        },
        formating: {
          __text__: 'Formatage du Texte',
          helper: 'Contrôlez les options de formatage du texte',
          ponctuation: 'Supprimer la Ponctuation',
          uppercase: 'Majuscules',
          bold: 'Gras',
          italic: 'Italique',
          glow: {
            __text__: 'Lueur',
            color: 'Couleur de la Lueur',
            intensity: 'Intensité de la Lueur',
          },
        },
        transform: {
          __text__: 'Transformation',
          x: 'Position X',
          y: 'Position Y',
          helper: 'Ajustez la position et la rotation des sous-titres',
          position: {
            __text__: 'Position Verticale',
            emoji: "Position Verticale de l'Emoji",
            info: "Déplacez le texte dans l'aperçu",
          },
          rotation: 'Rotation',
        },
        multiple: {
          lines: 'Lignes Multiples',
        },
        box: {
          __text__: 'Boîtes',
          helper: 'Options de style pour les boîtes de texte',
        },
        boxes: {
          padding: {
            x: 'Remplissage Horizontal',
            y: 'Remplissage Vertical',
          },
          opacity: 'Opacité de la Boîte',
          radius: 'Rayon des Coins',
          color: 'Couleur de la Boîte',
        },
        emojis: {
          __text__: 'Emojis',
          helper: 'Emojis générés par IA',
          size: "Taille de l'Emoji",
        },
        shadow: 'Ombre',
        transcript: 'Transcription',
        action: 'Avancer',
        select: {
          chunk:
            'Choisissez un segment de transcription à modifier ou personnaliser',
        },
        delete: {
          caption: {
            modal: {
              title: 'Confirmer la Suppression du Sous-titre',
              cancel: 'Non, le Garder',
              confirm: 'Oui, le Supprimer',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'Erreur lors de la récupération du nombre des préréglages. Contactez-nous via Discord ou à contact@autocut.com',
            },
            error:
              'Erreur lors de la récupération des préréglages. Contactez-nous via Discord ou à contact@autocut.com',
          },
          public: {
            presets: {
              error:
                'Erreur lors de la récupération des préréglages publics. Contactez-nous via Discord ou à contact@autocut.com',
            },
          },
        },
        presets: {
          title: 'Préréglages',
          save: {
            new: {
              preset: 'Enregistrer les Paramètres Actuels comme Préréglage',
            },
          },
          view: {
            more: 'Voir Plus',
            less: 'Voir Moins',
          },
        },
        save: {
          preset: {
            info: {
              text: 'Enregistrez ces paramètres comme un préréglage pour une utilisation future !',
            },
          },
        },
      },
      editor: {
        title: 'Éditeur de Sous-titres',
        description: {
          title: 'Sélectionnez les Sous-titres à Modifier',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: 'Mots de faible confiance',
            },
          },
          merge: {
            tooltip: 'Fusionner les Segments',
          },
          split: {
            tooltip: 'Diviser les Segments',
          },
        },
        sound: {
          play: 'Activer la Lecture Sonore',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'Police Manquante',
          message:
            'La police <i><b>{fontLabel}</b></i> est indisponible. Une police par défaut sera utilisée.',
          helper: 'Ajoutez de nouvelles polices en utilisant ce tutoriel.',
        },
      },
    },
    version: {
      not: {
        supported: 'Version Non Supportée',
      },
    },
    transcript: {
      usage: {
        unit: 'Minutes',
      },
      upgrade: {
        button: "Mettre à Niveau vers l'IA pour {mode}",
      },
      too: {
        short: {
          error:
            'La transcription peut être trop courte ou la langue non supportée. Si le problème persiste, contactez-nous à contact@autocut.com.',
        },
      },
    },
    feature: {
      request: {
        __text__: 'Demande de Fonctionnalité',
        form: {
          placeholder:
            'Décrivez votre demande de fonctionnalité pour une meilleure considération.',
        },
        screenshot:
          "Joindre des captures d'écran ou des vidéos pour plus de clarté :",
        button: 'Soumettre la Demande de Fonctionnalité',
        success: {
          title: 'Soumis ! 🥳',
          body: "Merci d'aider AutoCut à s'améliorer ! Nous examinerons votre demande rapidement.",
        },
        error: {
          title: 'Oups, quelque chose a mal tourné... 🤕',
          body: 'Nous sommes désolés pour le désagrément. Veuillez réessayer plus tard ou contactez-nous à contact@autocut.com.',
        },
      },
    },
    with: {
      emojis: 'Avec Emojis',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'Définissez les Points In & Out dans Votre Timeline',
            text1:
              'Pour utiliser AutoResize, définissez les Points In & Out dans votre timeline.',
            cta: "Définir les Points In & Out pour l'Entière Séquence",
          },
        },
      },
      text: {
        info: 'AutoResize ajuste automatiquement et recadre votre séquence pour des formats optimaux sur les réseaux sociaux.',
      },
      in_out: {
        step: {
          title: 'Points In/Out',
          helper:
            'Assurez-vous que les Points In & Out correspondent à la section désirée.',
        },
      },
      preset: {
        step: {
          title: 'Préréglages',
          helper:
            'Choisissez un préréglage pour redimensionner votre séquence ou créez un personnalisé. Voir les dimensions ci-dessous.',
        },
        undefined: 'Sélectionnez un préréglage.',
      },
      watermark: {
        step: {
          title: 'Filigrane',
          helper:
            'Ajoutez un filigrane à votre séquence dans le coin inférieur droit.',
          text: 'Télécharger Votre Filigrane',
        },
      },
      button: 'Générer un Clip Social',
      reframe: {
        undefined: 'Sélectionnez la vitesse "Suivre le Sujet".',
        step: {
          title: 'Suivre le Sujet',
          helper: 'Choisissez la vitesse à laquelle la caméra suit le sujet.',
          slower: 'Lent',
          default: 'Normal',
          faster: 'Rapide',
        },
      },
      backup: 'Sauvegarde de Votre Séquence...',
      create: {
        new: {
          sequence: "Création d'une Nouvelle Séquence...",
        },
      },
      resizing: {
        sequence: 'Redimensionnement de la Nouvelle Séquence...',
      },
      adding: {
        watermark: 'Ajout du Filigrane...',
      },
      cleaning: {
        sequence: 'Nettoyage de la Séquence...',
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: 'Sélectionnez les Clips de Base pour B-Roll',
            text1:
              'Pour AutoB-Roll, sélectionnez au moins un clip audio incluant de la parole.',
          },
        },
      },
      text: {
        info: "AutoB-Roll utilise l'IA pour analyser l'audio et ajouter automatiquement des B-Rolls pertinents de Pexels.",
      },
      duration: {
        step: {
          title: 'Paramètres de B-Roll',
          minimum: {
            title: 'Durée Minimale de B-Roll',
            helper: 'Durée minimale pour chaque B-Roll.',
          },
          maximum: {
            title: 'Durée Maximale de B-Roll',
            helper: 'Durée maximale pour chaque B-Roll.',
          },
        },
      },
      choice: {
        step: {
          title: 'Sélection de B-Roll',
        },
      },
      no_broll: {
        screen: {
          title: 'Transcription traitée, mais aucun B-Roll trouvé.',
          text1:
            'Pour utiliser AutoB-Roll, sélectionnez un clip audio avec de la parole.',
          text2:
            'Vérifiez la langue de la transcription et assurez-vous que les pistes audio ne sont pas muettes.',
        },
      },
      button: {
        __text__: 'Ajouter des B-Rolls',
        next: 'Suivant',
      },
      exporting: 'Exportation des Clips Audio Sélectionnés...',
      analyse: "Analyse de l'Audio...",
      getting: {
        broll: 'Récupération des B-Rolls... (peut prendre quelques minutes)',
      },
      transcript: {
        finished: 'Transcription Générée',
      },
      step: {
        parameters: 'Paramètres de B-Roll',
        language: 'Sélectionner les Clips et la Langue',
        brolls: 'Modifier la Sélection de B-Roll',
      },
      adding: {
        broll: 'Ajout des B-Rolls...',
      },
      restore: 'Restauration de Votre Séquence...',
      backup: 'Sauvegarde de Votre Séquence...',
      credit: {
        modal: {
          title: 'Crédits B-Roll :',
          provided: {
            by: 'Fournis par Pexels',
          },
          link: 'Lien',
          button: 'Copier dans le Presse-papiers',
        },
      },
      search: {
        modal: {
          title: 'Sélectionner un B-Roll',
          input: {
            placeholder: 'Rechercher des B-Rolls',
          },
          searching: 'Recherche de B-Rolls...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: 'Double-cliquez pour Modifier',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: 'Sélectionnez des Clips pour le Filtrage de Profanités',
              text1:
                "Sélectionnez un clip avec de l'audio pour AutoProfanity Filter.",
            },
          },
        },
        text: {
          info: "AutoProfanity Filter utilise l'IA pour détecter et censurer automatiquement les profanités avec des bips.",
        },
        mute: 'Muet',
        button: 'Supprimer les Profanités',
        audio: {
          export: "Exportation de l'Audio...",
          analyse: "Analyse de l'Audio...",
          insert: 'Insertion des Bips...',
          filter: 'Application des Ajustements de Volume...',
        },
        script: {
          transcript: {
            part: 'Transcription',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: "Sélectionner l'Effet Sonore de Bleep",
          helper: "Sélectionnez l'effet sonore de bips",
        },
      },
    },
    create: {
      format: {
        modal: {
          title: 'Créer un Nouveau Format',
          button: {
            __text__: 'Créer',
            disabled: 'Nom déjà utilisé',
          },
          name: 'Nom',
          width: 'Largeur',
          height: 'Hauteur',
        },
      },
    },
    process: {
      modal: {
        warning:
          "Veuillez éviter d'apporter des modifications dans DaVinci Resolve pour prévenir des problèmes.",
        generating: {
          emojis: 'Création des emojis à partir de la transcription...',
        },
        formatting: {
          numbers: 'Formatage des numéros dans la transcription...',
        },
      },
    },
    backup: {
      modal: {
        title: 'Sauvegarde AutoCut',
        text: {
          '1': 'Les sauvegardes se produisent avant le traitement. Retrouvez-les dans le dossier "AutoCut-Backup".',
          '2': 'Utilisez-les selon les besoins.',
        },
      },
    },
    confirm: {
      modal: {
        title: 'Êtes-Vous Sûr ?',
        confirm: {
          label: 'Confirmer',
        },
        cancel: {
          label: 'Annuler',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: "Entrez le Nom de l'Intervenant...",
        },
      },
    },
    login: {
      resend: {
        email: "Vous n'avez pas reçu ? Renvoyer l'email à {trialEmail}",
      },
    },
    typeofcreation: {
      option: {
        interview: 'Interviews',
        podcast: 'Podcasts',
        youtube: 'Vidéos YouTube',
        short: 'TikTok/Shorts/Reels',
        internal: 'Vidéos Internes',
        other: 'Autre',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'Créateur de Contenu',
        },
        video: {
          editor: {
            professional: 'Monteur Vidéo Professionnel',
            semi: {
              professional: 'Monteur Vidéo Semi-Professionnel',
            },
            beginner: 'Monteur Vidéo Débutant',
          },
        },
        student: 'Étudiant',
        other: 'Autre',
      },
    },
    progress: {
      caption: {
        modal: {
          message: {
            initialization: 'Configuration des Sous-titres...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal: 'Super travail ! Fini en {minutes} min {seconds} sec.',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: 'Temps Économisé : <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b> et <b>{secondsSaved} {secondsSaved, plural, one {second} other {seconds}}</b>.',
        },
        all: {
          cuts: 'Temps Total Économisé : Un énorme <b>{hourSaved} {hourSaved, plural, one {heure} other {heures}}</b> et <b>{minutesSaved} {minutesSaved, plural, one {minute} other {minutes}}</b> ! 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'Désactivez les pistes audio de la détection des silences pour garder la musique de fond ou les sons de jeu. Pour laisser les silences intacts dans certains clips, ne sélectionnez pas ces clips.',
        video: 'Pistes Vidéo Sélectionnées :',
        audio: 'Pistes Audio Sélectionnées :',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: "Limite de l'Essai Gratuit Atteinte",
            cta: "S'abonner Maintenant",
            text: 'Limite de 1000 minutes de fonctionnalités IA dépassée.{br}Abonnez-vous pour continuer à utiliser AutoCut IA.',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'Limite de Transcription Atteinte',
          text: 'Limite de 6000 minutes de fonctionnalités IA dépassée.{br}Contactez-nous pour assistance.',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          'Sélectionnez un type de transition valide (Voir <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
        ai_silences:
          'Sélectionnez une option de traitement des silences valide (Voir <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        broll: {
          min: {
            max: {
              duration:
                'Le temps de B-Roll maximum doit être supérieur au minimum (Voir <a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>).',
            },
          },
          minimumBRollTime:
            'Le temps de B-Roll minimal doit être positif (Voir <a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>).',
          maximumBRollTime:
            'Le temps de B-Roll maximal doit être positif (Voir <a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>).',
        },
        caption: {
          position:
            "Les sous-titres doivent rester dans la zone d'aperçu (Voir <a><b><embedTranslation>caption_customization_transform_position</embedTranslation></b></a>).",
          emoji: {
            position:
              "Les emojis doivent rester dans la zone d'aperçu (Voir <a><b><embedTranslation>caption_customization_transform_position_emoji</embedTranslation></b></a>).",
          },
          text: {
            fontSize:
              'La taille de la police doit être un nombre positif (Voir <a><b><embedTranslation>caption_customization_font_size</embedTranslation></b></a>).',
          },
          outline: {
            width:
              "L'épaisseur du contour doit être un nombre positif (Voir <a><b><embedTranslation>caption_customization_outline_width</embedTranslation></b></a>).",
          },
          transition: {
            effects:
              'Choisissez un effet de transition de sous-titre valide (Voir <a><b><embedTranslation>caption_customization_transition</embedTranslation></b></a>).',
          },
          emojis: {
            size: 'La taille des emojis doit être positive (Voir <a><b><embedTranslation>caption_customization_font_size</embedTranslation></b></a>).',
          },
          formating: {
            nbLines:
              'Au moins une ligne doit être sélectionnée pour le formatage (Voir <a><b><embedTranslation>caption_customization_formating_nbLines</embedTranslation></b></a>).',
          },
          title: {
            too: {
              short: 'Le titre doit contenir au moins 5 caractères.',
              long: 'Le titre ne peut pas dépasser 25 caractères.',
            },
            no_space:
              'Le titre doit contenir au moins deux mots séparés par un espace.',
          },
        },
        font: {
          fontFamily:
            'La famille de polices est requise (Voir <a><b><embedTranslation>caption_customization_font_font</embedTranslation></b></a>).',
          label:
            'Sélectionnez une police (Voir <a><b><embedTranslation>caption_customization_font_font</embedTranslation></b></a>).',
        },
        languageOfTranscription:
          'Sélectionnez une langue de transcription (Voir <a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>).',
        cameras: {
          speakerIds:
            'Chaque caméra doit avoir au moins un intervenant assigné (Voir <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          label:
            'Chaque caméra doit avoir une piste assignée (Voir <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
        },
        podcast: {
          minimum: {
            cam: {
              time: 'Le temps de caméra minimum doit être positif (Voir <a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>).',
            },
          },
          maximum: {
            cam: {
              time: 'Le temps de caméra maximum doit être positif (Voir <a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>).',
            },
          },
          deleteUnusedClips:
            'Choisissez si vous souhaitez supprimer ou muet les silences (Voir <a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>).',
          speakers: {
            cameras:
              'Chaque intervenant doit être assigné à une caméra (Voir <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            audio:
              'Chaque intervenant doit avoir une piste audio assignée (Voir <a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>).',
          },
          cameras: {
            __text__:
              'Chaque caméra doit avoir sa propre piste (Voir <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
            length:
              'Au moins deux caméras séparées sont requises (Voir <a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>).',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Le préréglage de réencadrage automatique est requis (Voir <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
            invalid:
              'Options de préréglage : plus lent, par défaut, ou rapide (Voir <a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>).',
          },
          watermark:
            'Formats de filigrane valides : JPEG, PNG, ou WebP. (Voir <a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>).',
          preset: {
            name: 'Le nom du préréglage est requis.',
            width: "La largeur doit être d'au moins 1px.",
            height: "La hauteur doit être d'au moins 1px.",
          },
        },
        rgb: 'Les valeurs RGB doivent être comprises entre 0 et 255.',
        silence: {
          noiseLevel: {
            type: 'Le niveau de bruit doit être un nombre (Voir <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            nonpositive:
              'Le niveau de bruit ne peut pas être positif (Voir <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
            integer:
              'Le niveau de bruit doit être un entier (Voir <a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>).',
          },
          minTimeOfSilence: {
            type: 'Le temps de silence doit être un nombre (Voir <a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>).',
          },
          maxTimeOfSilence: {
            type: 'Le temps de silence doit être un nombre (Voir <a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>).',
          },
          marginBefore: {
            type: 'Le remplissage avant doit être un nombre (Voir <a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>).',
          },
          marginAfter: {
            type: 'Le remplissage après doit être un nombre (Voir <a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>).',
          },
          transitions:
            'Sélectionnez un type de transition valide (Voir <a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>).',
          silences:
            'Sélectionnez une option de traitement des silences valide (Voir <a><b><embedTranslation>title_silences_step</embedTranslation></b></a>).',
        },
        swearWord: {
          bleepFile:
            'Sélectionnez un effet sonore de bips (Voir <a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>).',
        },
        textBox: {
          xPadding:
            'Le remplissage horizontal doit être un nombre (Voir <a><b><embedTranslation>caption_customization_boxes_padding_x</embedTranslation></b></a>).',
          yPadding:
            'Le remplissage vertical doit être un nombre (Voir <a><b><embedTranslation>caption_customization_boxes_padding_y</embedTranslation></b></a>).',
          opacity: {
            min: "L'opacité doit être d'au moins 0% (Voir <a><b><embedTranslation>caption_customization_boxes_opacity</embedTranslation></b></a>).",
            max: "L'opacité ne peut pas dépasser 100% (Voir <a><b><embedTranslation>caption_customization_boxes_opacity</embedTranslation></b></a>).",
          },
        },
        anchor:
          "L'ancre doit être dans la zone d'aperçu (Voir <a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>).",
        zoom: {
          autoZoomMinCoef: {
            min: 'Le coefficient de zoom minimum doit être supérieur à 1 (Voir <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'Le coefficient de zoom minimum doit être inférieur à 2 (Voir <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          autoZoomMaxCoef: {
            min: 'Le coefficient de zoom maximum doit être supérieur à 1 (Voir <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
            max: 'Le coefficient de zoom maximum doit être inférieur à 2 (Voir <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          },
          minZoomTime:
            'La durée de zoom minimale doit être positive en secondes.',
          maxZoomTime:
            'La durée de zoom maximale doit être positive en secondes.',
          dynamicZoomPercentage: {
            min: 'Le pourcentage de zoom doit être supérieur à 0%',
            max: 'Le pourcentage de zoom doit être inférieur à 100%',
          },
          coef: 'Le coefficient de zoom maximum doit être supérieur au minimum (Voir <a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>).',
          time: 'Le temps de zoom maximum doit être supérieur au temps minimum.',
          dynamicMinZoomIntensity:
            "L'intensité du zoom dynamique doit être entre 0-100% (Voir <a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>).",
          smoothZoomPercentage:
            'La couverture du zoom fluide doit être entre 0-100% (Voir <a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>).',
          nervousness:
            'Le niveau de nervosité doit être entre 0-100% (Voir <a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>).',
          totalZoomPercentage:
            'La couverture totale du zoom doit être entre 0-100% (Voir <a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>).',
          types: {
            at_least: {
              one: {
                true: 'Sélectionnez au moins un type de zoom (Voir <a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>).',
              },
            },
          },
        },
        repeat: {
          algorithm: 'Algorithme sélectionné invalide.',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: 'Problème de Paiement',
          button: {
            recover: "Récupérer l'Abonnement",
          },
          to_continue:
            'Votre abonnement a été <b>{status}</b> en raison de problèmes de paiement. Pour continuer :',
          deactivated: 'désactivé',
          deleted: 'supprimé',
          recover: {
            list: {
              click:
                'Cliquez sur le bouton ci-dessous pour récupérer votre abonnement ou vérifiez votre email pour les détails de paiement.',
              re_activated:
                'Votre clé sera <b>réactivée immédiatement</b> après la mise à jour de votre paiement.',
            },
          },
          new: {
            list: {
              website:
                'Visitez AutoCut : <span>https://www.autocut.com/#pricing</span>',
              subscribe: 'Achetez une nouvelle clé de licence',
            },
          },
          support:
            "Besoin d'aide ? Contactez-nous à : <span>contact@autocut.com</span>",
          thanks: 'Merci !',
        },
        moda: {
          button: {
            new: 'Acheter un Nouveau Abonnement',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'Commencez Votre Parcours avec AutoCut',
          header: {
            days: {
              left: 'Commencez votre essai de 14 jours avec {completedElements} fonctionnalités supplémentaires. {daysLeft} jours restants pour explorer !',
            },
            more: {
              days: 'Continuez avec <b>{bonusDays} jours supplémentaires</b> pour pleinement expérimenter AutoCut.',
            },
          },
          timeline: {
            left: 'Essai Gratuit de 14 Jours',
            right: 'Essai Gratuit de 24 Jours',
          },
          task: {
            list: {
              title: 'Progression des Tâches : {completed}/{maximum} Terminées',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'Redémarrage Nécessaire',
          cta: 'Fermer Toutes les Extensions',
          text: "Multiples instances d'AutoCut détectées. Cliquez ci-dessous pour les fermer et éviter les problèmes.",
        },
      },
    },
    'key-already-activated':
      'La clé est active sur un autre appareil. Dissociez-la sur le tableau de bord AutoCut (https://www.autocut.com/en/) et réessayez.',
    'key-does-not-exist':
      'Clé non trouvée. Veuillez vérifier votre clé et réessayer.',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'Version de DaVinci Resolve Non Supportée',
            '24.4.0':
              'Rétrogradez vers DaVinci Resolve 24.3 ou upgradez vers 24.5',
          },
          cta: 'Je comprends',
          text: {
            __text__:
              "Version de DaVinci Resolve non supportée ({version}) détectée. L'expérience peut être affectée. Utilisez la <b>dernière version stable</b> pour des performances optimales d'AutoCut.",
            '24.4.0':
              'Version de DaVinci Resolve non supportée ({version}). Mettez à jour vers 24.5 ou rétrogradez vers 24.3 pour utiliser AutoCut.',
          },
        },
      },
      rendering: {
        engine: {
          title: 'Moteur de Rendu Non Supporté',
          modal: {
            text: "Le moteur de rendu actuel n'est pas supporté. AutoCaptions et AutoZoom ne fonctionneront pas. {br}{br}Sélectionnez l'accélération GPU dans les paramètres du projet.",
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: 'Redémarrage Requis',
          cta: 'Fermer Toutes les Extensions',
          text: "Multiples instances d'AutoCut détectées. Cliquez ci-dessous pour les fermer et éviter les problèmes.",
        },
      },
    },
    disabled: {
      features: {
        info: 'La combinaison langue/police sélectionnée désactive : <b>{features}</b>. Ces fonctionnalités ne seront pas disponibles dans les sous-titres.',
        tips: 'Problème potentiel de support des caractères. Changez de police ou de langue pour activer ces fonctionnalités.',
      },
    },
    upgrade: {
      to: 'Mettre à Niveau vers {plan}',
    },
    deepgram: {
      error: {
        retry: 'Réessayez la transcription avec un modèle IA différent.',
        modal: {
          title: 'Erreur de Traduction',
          cta: 'Rejoindre Discord',
          text: "Une erreur est survenue lors de la traduction de l'audio. Cela peut être dû à la qualité de l'audio ou au support de la langue. {br}Signalez via Discord ou par email contact@autocut.com.",
        },
      },
    },
    completing: {
      preset: {
        preview: "Finalisation de l'aperçu du préréglage... Presque terminé.",
      },
    },
    chapters: {
      preview: {
        error:
          "Erreur lors de la génération de l'aperçu. Ajustez les horodatages ou redémarrez AutoCut si le problème persiste.",
      },
      step: {
        language: 'Sélectionner les Clips et la Langue',
        editor: 'Éditeur de Chapitres',
      },
      button: {
        chapters: 'Ajouter des Chapitres',
      },
      editor: {
        chapters: {
          title: 'Chapitres Détectés',
        },
        parameters: {
          title: 'Paramètres des Chapitres',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'Télécharger les Chapitres YouTube',
                success: 'Chapter.txt sauvegardé dans le projet Premiere Pro',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'Sélectionnez les Clips pour la Base des Chapitres',
            text1: 'Sélectionnez au moins un clip audio incluant de la parole.',
          },
        },
      },
      process: {
        transcript: {
          button: 'Générer les Chapitres',
        },
      },
      adding: {
        chapters: 'Ajout des Chapitres...',
      },
      backup: 'Sauvegarde de Votre Séquence...',
      analyse: "Analyse de l'Audio...",
      getting: {
        chapters: 'Génération des Chapitres...',
      },
      transcript: {
        finished: 'Chapitres Générés',
      },
      disabled: {
        language:
          'La langue sélectionnée ne supporte pas la détection des chapitres.',
        file: {
          too: {
            long: 'Les clips dépassent la longueur maximale. Utilisez des clips inférieurs à {limit} minutes.',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: 'Format de temps incorrect. Utilisez HH:MM:SS.',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'Animé',
        },
        static: {
          option: 'Statique',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title:
                'Sélectionnez des clips de timeline pour la Détection de Clips Viraux',
              text1:
                'Sélectionnez au moins un clip audio incluant de la parole.',
            },
          },
        },
        disabled: {
          language:
            "La Détection de Clips Viraux n'est pas supportée dans cette langue.",
          file: {
            too: {
              long: 'Les clips sont trop longs. Sélectionnez des clips de moins de {limit} minutes.',
            },
          },
        },
        process: {
          transcript: {
            button: 'Générer des Clips Viraux',
          },
        },
        button: {
          process: 'Créer des Clips',
        },
        selector: {
          title: 'Détecté {number} Clips Potentiellement Viraux',
        },
        virality: {
          score: 'Score Potentiel Viral',
        },
        step: {
          language: 'Sélectionner les Clips et la Langue',
          selector: 'Sélectionner les Clips Viraux',
        },
        backup: 'Sauvegarde de Votre Séquence...',
        analyse: "Analyse de l'Audio...",
        getting: {
          clips: 'Génération des Clips Viraux...',
        },
        transcript: {
          finished: 'Clips Viraux Générés',
        },
        adding: {
          creating: {
            clips: 'Création des Clips...',
          },
        },
      },
      score: 'Score Viral',
    },
    formatted: {
      numbers: {
        title: 'Nombres Formatés',
        modal: {
          cta: 'Fermer la Fenêtre',
          text: '<b>{number} mots</b> ont été convertis en nombres dans la transcription.',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'Choisir le Modèle de Transcription',
          model: {
            '1': 'Modèle 1 (par défaut)',
            '2': 'Modèle 2',
          },
        },
      },
    },
  },
} as const;

export default translations;
