import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {Sorter} from '@autocut/components/Sorter/Sorter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {usePlayback} from '@autocut/hooks/usePlayback';
import {usePreviewFrames} from '@autocut/hooks/usePreviewFrames';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useIntl} from 'react-intl';

import {Item} from './Parts/ViralClipItem/ViralClipItem';
import {ViralClipsFooterButton} from './Parts/ViralClipsFooterButton/ViralClipsFooterButton';

export const ViralClipsSelector = () => {
  const intl = useIntl();

  const {viralClips, audioFilepath} = useAutoCutStore(state => ({
    viralClips: state.onGoingProcess.viralClips,
    audioFilepath: state.onGoingProcess.audioFilepath!,
  }));

  const {previewFrames} = usePreviewFrames(
    (viralClips ?? []).map(viralClip => viralClip.timecodes),
  );
  const {playbackChunk, stopPlaybackChunk} = usePlayback({audioFilepath});

  if (!viralClips) return null;

  const noClipSelected = viralClips.every(viralClip => !viralClip.selected);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <ViralClipsFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled || noClipSelected}
              handleClickWithValidation={handleClickWithValidation}
            />
          )}
        />
      }
    >
      <FormSection
        title={
          <TranslatedMessage
            id={'modes_viralClips_title'}
            defaultMessage="AutoViral"
          />
        }
        description={
          <TranslatedMessage
            id={'modes_viralClips_detectedClips'}
            defaultMessage={`Detected ${viralClips.length} potential viral clips`}
            values={{viralClipsLength: viralClips.length}}
          />
        }
      >
        <FlexContainer justifyContent="flex-end">
          <Sorter
            list={viralClips}
            criterias={[
              {
                label: intl.formatMessage({
                  id: 'modes_viralClips_sorter_viralScore',
                  defaultMessage: 'Viral score',
                }),
                sortFunction: (a: ViralClip, b: ViralClip) => b.score - a.score,
              },
              {
                label: intl.formatMessage({
                  id: 'modes_viralClips_sorter_start',
                  defaultMessage: 'Start',
                }),
                sortFunction: (a: ViralClip, b: ViralClip) => a.start - b.start,
              },
            ]}
            onUpdate={(sortedViralClips: ViralClip[]) =>
              setAutocutStore('onGoingProcess.viralClips', sortedViralClips)
            }
          />
        </FlexContainer>
        {viralClips.map((viralClip, index) => (
          <Item
            key={index}
            previewFrame={previewFrames[index]}
            viralClip={viralClip}
            onClick={() => {
              const clips = [...viralClips];
              clips[index].selected = !clips[index].selected;
              setAutocutStore('onGoingProcess.viralClips', clips);
            }}
            playbackChunk={playbackChunk}
            stopPlaybackChunk={stopPlaybackChunk}
          />
        ))}
      </FormSection>
    </ModeLayout>
  );
};
