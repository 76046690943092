const translations = {
  progress: {
    footer: {
      warning:
        'AutoCut作業中は可能性のある問題を避けるために{host}を使用しないでください。',
    },
    steps: {
      global: {
        starting: '開始中...',
        processing: '処理中...',
        ending: '締めくくり中...',
      },
      dev: {
        ping: '{host}へのPing...',
        pong: '{host}へのPong...',
      },
      common: {
        getSilences: '無音区間を特定中...',
        computeRMS: '音声ファイルを分析中...',
        getDbValuesBetween: {
          getAudioInfos: '音声情報を収集中...',
          readAudioFile: '音声ファイルを読み込んでいます...',
          convertRMSToDB: '音声ファイルを分析中...',
        },
        timeline: {
          prepare: 'タイムラインの準備中...',
          import: {
            writeXml: '更新されたタイムラインをインポート中...',
            updateDrt: '更新されたタイムラインをインポート中...',
            importXml: '更新されたタイムラインをインポート中...',
          },
        },
        appendAdjustmentLayers: '調整レイヤーの追加...',
      },
      repeat: {
        mainProcess: '読み込み中...',
        transcript: {
          getLanguage: '言語を検出中...',
        },
        getRepeatUtterances: 'カットタイムコードを処理中...',
        cutAtTimecodes: 'カットを実行中...',
      },
      resize: {
        mainProcess: 'シーケンスのサイズ変更...',
        prepareTimeline: 'タイムラインの準備中...',
        createNewTimeline: '新しいタイムラインの作成...',
        reframeTimeline: 'タイムラインの再構築...',
        resizeTimeline: 'タイムラインのサイズ変更...',
        handleWatermark: '透かしの追加...',
      },
      podcast: {
        mainProcess: 'ポッドキャストを編集中...',
        getVideoTracksWeight: 'ビデオトラックを設定中...',
        getAudioTracks: 'オーディオトラックを設定中...',
        getPodcastInterval: 'ポッドキャストの持続時間を推定中...',
        getPodcastDuration: 'ポッドキャストの長さを計算中...',
        getVideoTrack: 'ビデオトラックを分析中...',
        renders: '{host}で音声をレンダリング中\n（数分かかる場合があります）',
        getTalkingTimelines: '発話時間を割り当て中...',
        getTransitions: 'カメラのトランジションを計画中...',
        getCutTimecodes: '切り取りポイントを準備中...',
        cutAtTimecodes: '切り取りを実行中...',
      },
      silences: {
        mainProcess: '無音を除去中...',
        getTimelineInfos: 'タイムライン情報を取得中...',
        renders: '{host}で音声をレンダリング中\n（数分かかる場合があります）',
        convertAudioToMono: 'オーディオを設定中...',
        getCutTimecodes: '切り取りポイントを設定中...',
        cutAtTimecodes: '切り取りを実行中...',
        deleteSilentTrackItems: '無音のセグメントを削除中...',
        applyTransitions: 'トランジションを適用中...',
      },
      swearWords: {
        mainProcess: '不適切用語を検閲中...',
        prepareTimeline: 'タイムラインの準備中...',
        getSwearWords: 'トランスクリプトを分析中...',
        cutAndApplyVolume: 'ボリュームフィルターを適用中...',
        applyBleeps: 'ビープ音を挿入中...',
        getTranscript: 'トランスクリプトを生成中...',
      },
      zoom: {
        mainProcess: 'ズーム効果を適用中...',
        getTimelineInfos: 'タイムライン情報を取得中...',
        getWavData:
          '{host}で音声をレンダリング中\n（数分かかる場合があります）',
        computeBestZoomIntervals: '最適なズームインターバルを計算中...',
        createVideoTrack: '新しいビデオトラックを作成中...',
        createZoomFusionComps: 'ズームコンポーネントをインポート中...',
      },
      chapters: {
        transcript: {
          get_language: '言語を検出しています...',
          generateChapters:
            'チャプターを生成しています...\n（少し時間がかかる場合があります）',
        },
        generation: {
          mainProcess: 'チャプターを追加しています...',
          importMarkers: 'マーカーをインポートしています...',
        },
      },
      transcript: {
        mainProcess: 'トランスクリプトを生成中...',
        renderWav: '{host}で音声をレンダリング中（数分かかる場合があります）',
        uploadFile: 'ファイルをアップロード中...',
        getTranscript:
          'AIで音声をトランスクリプト中（数分かかる場合があります）',
      },
      caption: {
        transcript: {
          get_language: '言語を検出中...',
          split_chunks: 'トランスクリプトをセグメントに分割中...',
        },
        generation: {
          mainProcess: 'キャプションを追加中...',
          initialization: 'キャプションを初期化中...',
          readTranscript: 'トランスクリプトを読み込み中...',
          getTimelineInfos: 'タイムラインの詳細を取得中...',
          appendAdjustmentLayer: 'アジャストメントレイヤーを追加中...',
          importFusionComp: 'キャプションコンポーネントをインポート中...',
          prepareTimeline: 'タイムラインを準備しています...',
          generateSequence: 'シーケンスを生成しています...',
          importSequence: 'シーケンスをインポートしています...',
          getSequenceParameters: 'シーケンスパラメータを取得しています...',
          createFiles: 'ファイルを作成しています...',
        },
      },
      viralClips: {
        mainProcess: 'バイラルクリップを生成しています...',
        transcript: {
          generateViralClips: 'バイラルクリップを生成しています...',
        },
        importingClips: 'バイラルクリップをインポートしています...',
        generation: {
          viralClips: 'バイラルクリップを生成中...',
        },
      },
      broll: {
        mainProcess: 'B-Rollを追加しています...',
        getBRolls: 'B-Rollを取得しています...',
        downloadingBrolls: 'B-Rollをダウンロードしています...',
        importingBrolls: 'B-Rollをインポートしています...',
        insertingBrolls: 'B-Rollを挿入しています...',
      },
    },
  },
  launchManager: {
    title: 'セットアップ / アップデート',
    steps: {
      preload: {
        title: '読み込み中',
      },
      versionValidation: {
        title: 'バージョンを確認中',
        modal: {
          title: '手動で更新する必要があります',
          body: '古いバージョンのAutoCutを使用していることを検出しました。{br}新しいバージョンをダウンロードしてインストールしてください。',
          link1:
            'DaVinciを使用している場合はこちらからダウンロードできます: https://www.autocut.com/en/download/',
          link2:
            'Premiere ProとAutoCut v2を使用している場合はこちらからダウンロードできます: https://www.autocut.com/en/download-beta/',
        },
      },
      update: {
        title: '更新を確認中',
        sudoPrompt:
          'App Store版のDaVinci Resolveを使用している場合、拡張機能のインストールやアップデートを行うために、一時的な管理者権限を要求する必要があります。',
        modal: {
          title: 'AutoCutが更新されました',
          subtitle: 'AutoCutが改善されました',
          body: 'より良い体験のためにAutoCutを更新しました。下のボタンを使ってAutoCutを再起動してください。',
          cta: 'AutoCutを再起動',
        },
      },
      ffmpeg: {
        title: 'FFMPEGをインストール中',
      },
      resources: {
        title: 'リソースをインストール中',
        modal: {
          title: '何かがうまくいきませんでした',
          body: 'AutoCutを起動できません。一部のリソースがインストールできません。ディスク容量を確認し、AutoCutが他のアプリのデータにアクセスできるように許可してください。問題が解決しない場合は、次のアドレスまでお問い合わせください。',
          cta: 'AutoCutを再起動',
        },
      },
      computeIPC: {
        title: 'AIサービスに接続中',
      },
      hostIPC: {
        title: 'AutoCutを起動',
        subtitle: '見つかりませんか? PremiereまたはDaVinciを再起動してください',
        stillNot:
          'Premiere Pro、DaVinci & AutoCutを再起動しても見つかりませんか?{br}<copy><embedTranslation>globals_contactMail</embedTranslation></copy>までご連絡ください',
        instructions: {
          ppro: {
            part1: 'AutoCut拡張機能の起動場所:',
            part2: 'ウィンドウ > 拡張機能 > AutoCut V2 Beta',
          },
          davinci: {
            part1: 'スクリプトの起動場所：',
            part2: 'ワークスペース > スクリプト > AutoCut',
          },
        },
        modals: {
          ppro: {
            title: '拡張機能が更新されました',
            subtitle: 'Premiere Pro用のAutoCut拡張機能が更新されました',
            body: 'より良い体験のためにAutoCutを更新しました。拡張機能を更新するためにPremiere Proを再起動してください。',
          },
          davinci: {
            title: 'スクリプトが更新されました',
            subtitle: 'DaVinci用のAutoCutスクリプトが更新されました',
            body: 'より良い体験のためにAutoCutを更新しました。AutoCutスクリプトを再起動してください。',
          },
        },
      },
    },
    footer: {
      discord: 'Discordコミュニティに参加しよう！',
      learnMore: 'AutoCutの機能を最大限に活用するコツを学ぶ！',
    },
    errors: {
      compute: 'AIサービスに接続できません。サポートにお問い合わせください。',
      davinci:
        'DaVinci ResolveまたはPremiere Proに接続できない。Resolveが起動し、AutoCutスクリプトがアクティブになっていることを確認してください。',
      cta: '再試行',
    },
  },
  homepage: {
    cards: {
      status: {
        trial: '無料トライアル',
        upgrade: '今すぐアップグレード',
        coming_soon: '近日公開！',
        version_not_supported: 'サポートされていない{host}バージョン',
        upgrade_to: '{plan}にアップグレード',
      },
    },
  },
  onboarding: {
    title: 'AutoCutへようこそ',
    description: 'あなたの究極の{host}拡張機能',
    cta: {
      freeTrial: '無料トライアルを開始',
      useLicenceKey: 'ライセンスキーを使用',
      buyLicenceKey: 'ライセンスキーを購入',
    },
    auth: {
      login: {
        title: 'AutoCutを有効化するライセンスキーを入力',
        subtitle: 'ライセンスキーを使用',
        placeholder: 'ライセンスキーを入力',
        useTrial: 'メール（{email}）にメールが届きました',
        resendEmail: '受け取れませんでしたか？{trialEmail}にメールを再送信',
        cta: '有効化',
      },
      trial: {
        title: '無料トライアルを開始',
        steps: {
          email: {
            title: 'メールアドレスを入力',
            subtitle: 'メールで試用ライセンスを受け取る',
            placeholder: 'email@example.com',
            cta: '次へ',
          },
          sendingEmail: {
            title: 'メールを送信中',
            success: '{email}にメールが正常に送信されました',
          },
        },
      },
      errors: {
        no_key: 'ライセンスキーが必要です',
        is_key_valid_error:
          'キーの検証に失敗しました。後でもう一度お試しください。',
        invalid_response: 'サーバーから予期しない応答を受信しました。',
        cant_read_host_error: 'コンピューターの名前を取得できません。',
        cant_read_uuid_error: 'コンピューターのUUIDを取得できません。',
      },
    },
  },
  modes: {
    chapters: {
      title: 'AutoChapters',
      description: '章のマーカーを自動検出して追加',
      steps: {
        language: {
          cta: 'チャプターを生成',
        },
        editChapters: {
          title: 'チャプターの編集',
          description:
            'ビデオのチャプターを編集します。タイムコードとタイトルを変更できます。',
        },
        exportChapters: {
          cta: 'Youtube用にチャプターをエクスポート',
        },
        markersColor: {
          title: 'マーカーの色',
        },
        cta: 'チャプターを追加',
      },
    },
    repeat: {
      title: 'AutoCut Repeat',
      description: 'AIで繰り返しのテイクを削除',
      steps: {
        language: {
          title: 'クリップと言語を選択',
          cta: 'トランスクリプトを生成',
          algorithm: {
            select: 'アルゴリズムを選択',
            options: {
              precise: '正確',
              fast: '速い',
            },
            info: '正確なアルゴリズムは最も最近で最も効率的ですが、約1分の処理が必要です。',
          },
        },
        transcript: {
          title: 'トランスクリプトを編集',
          cta: 'シーケンスを切り取る',
          contextual: {
            cut: '切り取る/切り取らない',
            play: '再生',
          },
        },
      },
      error: {
        validation: {
          algorithm: '無効なアルゴリズムが選択されました。',
        },
      },
    },
    resize: {
      title: '自動リサイズ',
      description: '異なるプラットフォーム向けにシーケンスをリサイズ',
      steps: {
        inOutPoints: {
          title: 'イン/アウトポイント',
          description:
            'タイムライン内のインポイントとアウトポイントを確認してください。以下の値と同じである必要があります。',
          items: {
            in: 'イン: {inPoint} 秒',
            out: 'アウト: {outPoint} 秒',
            duration: '持続時間: {duration} 秒',
          },
        },
        presets: {
          title: 'プリセット',
          description:
            'シーケンスをリサイズするためのプリセットを選択してください。独自のプリセットを作成することもできます。新しいシーケンスの寸法は以下に表示されます。',
        },
        reframe: {
          title: '被写体速度に追従',
          description: 'カメラが被写体を追う速度を選択できます。',
          items: {
            slower: '遅い',
            default: '標準',
            faster: '速い',
            none: 'なし',
          },
        },
        watermark: {
          title: '透かし',
          description:
            'シーケンスに透かしを追加できます。シーケンスの右下隅に追加されます。',
          items: {
            choose: '透かしを選択',
            noFile: '透かしが選択されていません',
          },
        },
        cta: 'シーケンスをリサイズ',
      },
      error: {
        validation: {
          autoReframePreset: {
            required: 'オートリフレームプリセットが必要です',
            invalid:
              'プリセットオプション：スロー、デフォルト、またはファスター',
          },
          watermark: '有効なウォーターマーク形式：JPEG、PNG、またはWebP',
          preset: {
            required: 'プリセットを選択してください',
            name: 'プリセット名が必要です。',
            width: '幅は最低1pxでなければなりません。',
            height: '高さは最低1pxでなければなりません。',
          },
        },
      },
    },
    podcast: {
      title: 'オートポッドキャスト',
      description: 'ポッドキャストを瞬時に編集',
      steps: {
        select: {
          title:
            '少なくとも2つのビデオとオーディオトラックを持つタイムラインを選択',
          label: 'タイムラインを選択',
          descriptions: {
            '1': '編集タブに移動する',
            '2': '編集したいタイムラインを開く',
          },
          cta: 'アクティブなタイムラインを選択',
        },
        customization: {
          label: 'ポッドキャスト設定',
          steps: {
            speakers: {
              title: 'スピーカー',
              description:
                '各スピーカーにオーディオトラックを割り当てて名前を付けます。1スピーカーにつき1トラックを使用します。共有スピーカーの場合、両方の名前を使用します（例："Alice & Bob"）。',
              header: {
                audio: 'オーディオトラック',
                name: 'スピーカー名',
              },
              textInputPlaceholder: 'スピーカーの名前を入力...',
              trackTemplate: 'トラック A{index}',
              cta: 'スピーカーを追加',
            },
            cameras: {
              title: 'カメラ',
              description:
                '各カメラにビデオトラックを割り当て、可視スピーカーを選択します。1カメラにつき1トラック、複数のスピーカーが可能です。',
              header: {
                audio: 'ビデオトラック',
                name: 'スピーカー（複数可）',
              },
              speakerSelectPlaceholder: 'スピーカーを選択...',
              trackTemplate: 'トラック V{index}',
              cta: 'カメラを追加',
            },
            cameraTime: {
              title: 'カメラの持続時間',
              description:
                'カメラの切り替えまでの最小および最大持続時間を設定します。',
              presets: {
                title: 'デフォルトプリセット',
                items: {
                  calm: '落ち着いている',
                  paced: 'ペースが良い',
                  energetic: 'エネルギッシュ',
                  hyperactive: 'ハイペース',
                },
              },
              minimumCamTime: {
                title: '最小持続時間',
                description: 'カメラをスイッチする前の最小時間（秒）。',
                unit: '秒',
              },
              maximumCamTime: {
                title: '最大持続時間',
                description: 'カメラをスイッチする前の最大時間（秒）。',
                unit: '秒',
              },
            },
            silentVideoClips: {
              title: '未使用クリップの処理',
              description:
                'ポッドキャストの未使用部分を削除または無効化するかを選択します。',
              items: {
                remove: '削除',
                disable: '無効化',
              },
            },
          },
          cta: {
            disable: 'ポッドキャスト編集開始 - 未使用クリップを無効化',
            delete: 'ポッドキャスト編集開始 - 未使用クリップを削除',
          },
        },
      },
      error: {
        validation: {
          minimumCamTime: {
            min: '最小カメラ時間は0秒以上である必要があります',
            higherThanMax:
              '最小カメラ時間は最大カメラ時間より小さくなければなりません',
          },
          maximumCamTime: {
            min: '最大カメラ時間は0秒以上である必要があります',
          },
          deleteUnusedClips: {
            required:
              '未使用クリップを削除するか無効化するかを選択してください',
          },
          speakers: {
            videoTracks: {
              min: '各スピーカーに少なくとも1つのビデオトラックを選択してください',
            },
            audioTrack: {
              min: '各スピーカーにオーディオトラックを割り当ててください',
            },
          },
        },
      },
    },
    silence: {
      title: 'AutoCut Silences',
      description: 'タイムラインから無音部分を削除',
      steps: {
        noiseLevel: {
          title: 'ノイズ閾値',
          description:
            '無音を特定するための音量の設定は、以下で見ることができます。',
          calculate: 'AIによる計算',
        },
        pacing: {title: 'ペーシング'},
        presets: {
          title: 'デフォルトプリセット',
          items: {
            calm: '落ち着いた',
            measured: '慎重な',
            paced: 'ペースの良い',
            energetic: 'エネルギッシュ',
            jumpy: '軽快な',
          },
        },
        silenceSize: {
          title: '無音の持続時間',
          items: {
            silence: {
              title: '無音が長い場合に削除',
              description: '無音を削除する最小期間（ミリ秒）。',
              unit: 'ミリ秒',
            },
            talk: {
              title: '会話が長い場合は保持',
              description: 'トークセグメントを残す最小期間（ミリ秒）。',
              unit: 'ミリ秒',
            },
          },
        },
        paddingSize: {
          title: 'パディング',
          items: {
            before: {
              title: '前のパディング',
              description: '会話開始をスムーズにするためのノイズ前の無音時間。',
              unit: 'ミリ秒',
            },
            after: {
              title: '後のパディング',
              description: '会話終了をスムーズにするためのノイズ後の無音時間。',
              unit: 'ミリ秒',
            },
          },
        },
        silenceManagement: {
          title: '無音の管理',
          choices: {
            delete: '無音を削除',
            keep: '無音を保持',
            mute: '無音を無効化',
            delete_but_keep: '無音を削除してスペースを保持',
          },
        },
        transition: {
          params: {
            helper:
              'すべてのカットはJ-Cut、L-Cut、または両方であることが望ましい場合があります。これにより、より動的な遷移効果が作成されます。',
            title: 'トランジション',
            warning: 'トランジションには無音を削除する必要があります。',
            default: {
              title: 'デフォルト',
              choices: {
                none: 'なし',
              },
            },
            j_l_cut: {
              title: 'J-Cut & L-Cut',
              helper:
                'スムーズなトランジションのためにカットの周りにパディングを追加し、新しいトランジショントラックを作成します。',
              choices: {
                j_cut: 'J-Cut',
                l_cut: 'L-Cut',
                both: '両方',
              },
            },
            premiere_pro: {
              title: 'DaVinci Resolve',
              choices: {
                constant_power: 'コンスタントパワー',
              },
            },
          },
        },
        preview: {
          title: 'プレビュー',
          description:
            '保持および削除されたセグメントを表示します。矢印を使用してナビゲートしたり、カーソル位置に中心を合わせることができます。',
          legend: {
            kept: '保持',
            deleted: '削除',
            margin: 'マージン',
          },
          cta: 'プレビューを生成',
          center: 'カーソル周辺を中心にする',
        },
        cta: {
          delete: '無音をカットして削除 {withTransition}',
          keep: '無音をカットして保持 {withTransition}',
          mute: '無音をカットしてミュート {withTransition}',
          delete_keep_spaces:
            '無音をカットして削除、スペースは保持 {withTransition}',
          withTransition: 'トランジションを適用',
        },
      },
      error: {
        validation: {
          noiseLevel: {
            negative: 'ノイズレベルは負の値でなければなりません',
            int: 'ノイズレベルは数値でなければなりません',
          },
          minTimeOfSilence: {
            required: '無音の最小期間は必須です',
            min: '無音の最小期間は0ms以上である必要があります',
          },
          minTimeOfTalk: {
            required: 'トークの最小期間は必須です',
            min: 'トークの最小期間は0ms以上である必要があります',
          },
          marginBefore: {
            min: '前のパディングは0ms以上である必要があります',
          },
          marginAfter: {
            min: '後のパディングは0ms以上である必要があります',
          },
          transitions: '有効なトランジションタイプを選択してください',
          silences: '有効なサイレンス処理オプションを選択してください',
        },
      },
      transition: {
        params: {
          title: 'トランジション',
          warning: 'トランジションには無音を削除する必要があります。',
          default: {
            title: 'デフォルト',
            choices: {
              none: 'なし',
            },
          },
          progress: {
            insert: '新しいオーディオトラックを追加しています...',
            move: 'トランジションのためにオーディオを調整しています...',
            handling: 'トランジションを適用中: {transition}',
          },
        },
      },
    },
    swearWords: {
      title: '自動不適切用語フィルター',
      description: '不要な言葉を自動的に検閲',
      parameters: {
        bleepSoundSelector: {
          mute: 'ミュート',
          helper: 'ビープ音効果を選択',
        },
        bleepSoundManager: {
          button: 'サウンドを管理・追加',
          title: 'サウンドを管理',
          noSounds: 'まだサウンドを追加していません。',
          addButton: '新しいサウンドを追加',
          delete: '削除',
        },
      },
      footerButton: '不適切用語を削除',
      infoText:
        '不適切用語ビーパーは、AIのおかげで音声を分析し、自動的に不適切用語の上に{br}ビープ音を追加します。',
      error: {
        validation: {
          bleepFile: 'ビープ音効果を選択してください',
        },
      },
    },
    zoom: {
      title: 'AutoZoom',
      description: 'ズーム効果を自動的に追加',
      parameters: {
        preview: {
          title: 'ズームプレビュー',
          description: 'ズームの焦点を設定',
          maximumZoom: '最大ズームレベル',
          errorMessage: 'プレビューの生成中にエラーが発生しました',
        },
        zoomTypes: 'ズームスタイル',
        defaultsPresets: {
          title: 'デフォルトプリセット',
          calm: '落ち着いている',
          paced: 'ペースが良い',
          energetic: 'エネルギッシュ',
          hyperactive: 'ハイペース',
        },
        zoomCoverage: 'ズーム全体のカバレッジ',
        constraint: {
          title: '各クリップにズームを限定',
          helper:
            '有効にした場合、ズーム効果はクリップの境界を越えません。無効にした場合、ズームはセレクション全体にわたります。',
        },
        maximumZoomDuration: '最大ズーム持続時間',
        smoothZooms: {
          title: 'スムーズズーム',
          coverage: 'スムーズズームカバレッジ',
          nervousness: 'ズームの速度と強さ',
        },
        dynamicZooms: {
          title: 'ダイナミックズーム',
          coverage: 'ダイナミックズームカバレッジ',
        },
      },
      types: {
        smooth: 'スムーズ',
        dynamic: 'ダイナミック',
      },
      footerButton: 'ズームを適用',
      infoText:
        'AutoZoomはオーディオレベルに基づいてズーム効果を自動的に追加します。',
      error: {
        validation: {
          anchor: 'アンカーはプレビューエリア内にある必要があります',
          autoZoomMinCoef: {
            min: '最小ズーム係数は1より大きくなければなりません',
            max: '最小ズーム係数は2より小さくなければなりません',
          },
          autoZoomMaxCoef: {
            min: '最大ズーム係数は1より大きくなければなりません',
            max: '最大ズーム係数は2より小さくなければなりません',
          },
          minZoomTime: '最小ズーム継続時間は正の秒数でなければなりません。',
          maxZoomTime: '最大ズーム継続時間は正の秒数でなければなりません。',
          dynamicZoomPercentage: {
            min: 'ズームパーセントは0%以上でなければなりません',
            max: 'ズームパーセントは100%未満でなければなりません',
          },
          coef: '最大ズーム係数は最小値より大きくなければなりません',
          time: '最大ズーム時間は最小時間より大きくなければなりません。',
          dynamicMinZoomIntensity:
            'ダイナミックズーム強度は0〜100%の範囲でなければなりません',
          smoothZoomPercentage:
            'スムーズなズームカバレッジは0〜100%の範囲でなければなりません',
          nervousness: '緊張のレベルは0〜100%の範囲でなければなりません',
          totalZoomPercentage:
            '合計ズームカバレッジは0〜100%の範囲でなければなりません',
          types: {
            atLeast: {
              one: {
                true: '少なくとも1つのズームタイプを選択してください',
              },
            },
          },
        },
      },
    },
    caption: {
      title: 'AutoCaptions',
      description: 'AIを使ってアニメーションキャプションを追加',
      steps: {
        language: {
          button:
            'キャプションエディタを開く（スタイル＆トランスクリプション）',
          previousTranscription: {
            title: '前のトランスクリプトを読み込む',
            showMore: 'もっと見る',
            showLess: '折りたたむ',
            noTranscription: '利用可能な前のトランスクリプトはありません',
            load: 'トランスクリプトを読み込む',
            edit: 'トランスクリプト名を編集',
            delete: '削除',
            confirmModal: {
              text: 'この操作は取り消せません。削除するとトランスクリプトを使用できなくなります。',
              confirm: 'トランスクリプトを削除',
            },
          },
        },
        customization: {
          button: 'キャプションを追加',
          parts: {
            style: {
              title: 'キャプションスタイル',
              modal: {
                title: 'スタイルを編集',
                apply: 'スタイルを適用',
                save: 'プリセットとして適用して保存',
                text: {
                  title: 'フォント',
                  font: 'フォント名',
                  help: '新しいフォントファミリーを追加する方法',
                  color: '文字色',
                },
                enableSound: {
                  label: 'サウンド再生を有効にする',
                  tooltip:
                    'この機能は読み込まれたトランスクリプトからは利用できません',
                },
                animation: {
                  title: 'アニメーション',
                  wordBox: 'テキストボックス',
                  floatingText: '浮かぶテキスト',
                  highlight: {
                    title: 'カラー強調表示',
                    color: '強調表示 <color>色</color>',
                    advancedColorOptions: {
                      title: '高度なカラーオプション',
                      revealedText: '強調表示<i>前</i>の色',
                      unrevealedText: '強調表示<i>後</i>の色',
                    },
                  },
                },
                outline: {
                  title: 'アウトライン',
                  width: 'アウトラインの厚さ',
                },
                box: {
                  title: 'テキストボックス',
                  paddingX: '水平のパディング',
                  paddingY: '垂直のパディング',
                  opacity: 'ボックスの不透明度',
                  radius: 'ボックスの角の半径',
                  disabled:
                    '選択されたフォントはこの言語のボックス機能をサポートしていません。',
                  tips: '選択された言語に対応するフォントを選択してください。',
                },
                formating: {
                  title: 'テキスト形式',
                  removePunctuation: '句読点を削除',
                  uppercase: '大文字',
                  italic: 'イタリック',
                  glow: {
                    title: '輝き',
                    color: '輝きの色',
                    intensity: '輝きの強度',
                  },
                },
                transition: {
                  title: 'トランジション',
                  zoomIn: 'ズームイン',
                  blurIn: 'ブラーイン',
                },
              },
            },
            preview: {
              title: 'プレビュー',
              modal: {
                title: '位置とサイズ',
                apply: 'スタイルを適用',
                fontSize: 'フォントサイズ',
                nbLines: '行の数',
                maxWidth: '最大幅',
                help: 'プレビュ機能は開発中で、正確でない可能性があります。',
              },
            },
            transcription: {
              title: 'トランスクリプトを編集',
              modal: {
                save: '保存',
                select: '編集するキャプションを選択',
                placeholder:
                  '編集またはカスタマイズするトランスクリプトセグメントを選択してください',
                formatNumbers: '数字をフォーマット',
                checkTranscript: {
                  title: 'トランスクリプトの確認済み？',
                  description: 'トランスクリプトをまだ確認していません。',
                  dontShowAgain: 'もう表示しない',
                },
              },
              tooltips: {
                lowConfidence: '低い信頼度の単語',
                merge: 'セグメントをマージ',
                split: 'セグメントを分割',
              },
              emojis: {
                title: 'どのタイプの絵文字を生成しますか？',
                animated: 'アニメーション',
                static: '静的',
              },
            },
            presets: {
              noPresetSelected: 'プリセットを選択してプレビュー',
              noPreviewAvailable: 'プレビューが利用できません',
              title: 'プリセット',
              error:
                'プリセットの取得中にエラーが発生しました。Discordでお問い合わせください。',
              delete: {
                title: 'プリセットを削除',
                cancel: 'キャンセル',
                confirm: 'プリセットを削除',
              },
              search: {
                title: 'コミュニティプリセット',
                input: 'プリセットを検索',
                cancel: 'キャンセル',
                confirm: 'プリセットをインポート',
                usage: '{count}ユーザーが使用',
                error:
                  'プリセットのコピーエラー。Discordまたはcontact@autocut.frまでご連絡ください',
              },
              create: {
                title: '新しいプリセットを作成',
                placeholder: 'プリセット名（最低2語）',
                community: 'コミュニティと共有',
                public: '公開',
                cancel: 'キャンセル',
                confirm: 'プリセットを作成',
                alreadyExists:
                  'プリセットはすでに存在します。別の名前を選んでください。',
              },
            },
          },
        },
      },
      error: {
        validation: {
          userPresets: {
            missing:
              'ユーザーのプリセットが見つかりません。ヘッダーメニューで設定をリセットしてください。',
            titleTooShort: 'タイトルは少なくとも5文字でなければなりません',
            titleTooLong: 'タイトルは最大25文字でなければなりません',
            titleNoSpace:
              'タイトルには少なくとも2つの単語が含まれている必要があります',
          },
          position: {
            xPercentage: {
              min: 'X位置は少なくとも0であるべきです',
              max: 'X位置は最大で1ですべきです',
            },
            yPercentage: {
              min: 'Y位置は少なくとも0であるべきです',
              max: 'Y位置は最大で1ですべきです',
            },
            emojiXPercentage: {
              min: 'Emoji X位置は少なくとも0であるべきです',
              max: 'Emoji X位置は最大で1ですべきです',
            },
            emojiYPercentage: {
              min: 'Emoji Y位置は少なくとも0であるべきです',
              max: 'Emoji Y位置は最大で1ですべきです',
            },
          },
          text: {
            fontSize: {
              int: 'フォントサイズは数値である必要があります',
              min: 'フォントサイズは少なくとも1である必要があります',
            },
            font: {
              missing: '選択されたフォントは不完全です。再選択してください。',
            },
          },
          outline: {
            width: {
              int: 'アウトラインの幅は数値である必要があります',
              min: 'アウトラインの幅は0以上でなければなりません',
            },
          },
          formating: {
            nbLines: {
              int: '行数は数値である必要があります',
              min: '行数は少なくとも1である必要があります',
            },
            maxWidth: {
              int: '最大幅は数値である必要があります',
              min: '最大幅は少なくとも0である必要があります',
            },
          },
          textBox: {
            xPadding: {
              int: '水平パディングは数値である必要があります',
              min: '水平パディングは少なくとも0である必要があります',
            },
            yPadding: {
              int: '垂直パディングは数値である必要があります',
              min: '垂直パディングは少なくとも0である必要があります',
            },
            opacity: {
              int: '不透明度は数値である必要があります',
              min: '不透明度は少なくとも0である必要があります',
              max: '不透明度は100を超えてはいけません',
            },
            radius: {
              int: '半径は数値である必要があります',
              min: '半径は少なくとも0である必要があります',
            },
          },
        },
      },
    },
    viralClips: {
      title: 'オートバイラル',
      description: '長いビデオの中でバイラルの可能性があるクリップを簡単に特定',
      steps: {
        language: {
          button: 'バイラルクリップを見つける',
        },
      },
      stepSelector: 'バイラルクリップを選択',
      sorter: {
        viralScore: 'バイラルスコア',
        start: '開始',
      },
      viralPotential: 'バイラルポテンシャルスコア',
      detectedClips:
        '{viralClipsLength}件の潜在的なバイラルクリップが検出されました',
      footerButton: 'バイラルクリップを生成',
    },
    broll: {
      title: 'AutoBRolls',
      description: 'AIでB-Rollを自動的に追加',
      steps: {
        language: {
          button: '次へ',
        },
        parameters: {
          title: 'B-Rollsのパラメータ',
          cta: '次へ',
          duration: {
            title: 'B-Rollsの長さ',
            minimumDuration: '最小長さ',
            minimumDurationHelper: '各B-Rollは少なくともこの長さになります。',
            maximumDuration: '最大長さ',
            maximumDurationHelper: '各B-Rollはこの長さを超えないようにします。',
          },
        },
        transcript: {
          title: 'B-Rollsの選択を編集',
          cta: 'B-Rollsを追加',
          selection: 'B-Rollsの選択',
          choiceModal: {
            title: 'B-Rollを選択',
            inputPlaceholder: 'B-Rollsを検索するには入力してください',
            searching: 'B-Rollsを検索中...',
          },
        },
      },
      error: {
        validation: {
          minMaxDuration: '最大B-Roll時間は最小よりも大きくなければなりません',
          minimumBRollTime: '最小B-Roll時間は正の値でなければなりません',
          maximumBRollTime: '最大B-Roll時間は正の値でなければなりません',
        },
      },
    },
    general: {
      steps: {
        select: {
          // title: 'マーカーを設定',
          description:
            '選択のためにマーカーの位置を設定してください。手動で設定することも、以下のボタンのいずれかを使用することもできます。',
          // cta: 'マーカーを確認',
          auto: 'タイムラインにマーカーを配置しました。これらのマーカーが正しく配置されていることを確認してください。',
          entire: '全タイムライン',
          inOut: '入出力ポイント',
          selected: '選択したクリップ',
          // vvv A retirer pour nouvelle selection vvv
          title: 'インポイント/アウトポイントを設定',
          descriptions: {
            '1': 'インポイント/アウトポイントを設定しなければ、AutoCutはタイムライン全体に適用されます。',
            '2': 'オーディオ分析から除外するには、クリップやトラックを手動でミュートしてください。',
          },
          cta: 'インポイント/アウトポイントを確認',
        },
        transcription: {
          title: 'オーディオ言語の選択',
          description: 'トランスクリプトを作成する言語を選択してください。',
          noTrackSelected:
            '少なくとも1つのオーディオトラックを選択してください',
        },
        customization: {
          title: '設定をカスタマイズ',
        },
        audioTracks: {
          title: '選択したオーディオトラック',
          description: 'オーディオ処理用に特定のトラックを含める。',
        },
      },
      error: {
        validation: {
          languageOfTranscription: 'トランスクリプション言語を選択してください',
          rgb: 'RGB値は0から255の間でなければなりません。',
        },
      },
    },
  },
  modals: {
    notEnoughTracks: {
      title: 'タイムラインにトラックが足りません',
      text: 'この機能を使用するには、最低でも2つのビデオトラックと2つのオーディオトラックが必要です。',
      cta: '理解しました',
    },
    appStoreDv: {
      title:
        'AutoCutはApp StoreからインストールされたDaVinci Resolveと互換性がありません',
      text: {
        firstLine: {
          text: 'AutoCutをDaVinci Resolveと一緒に使用するには、DaVinci Resolveを公式ウェブサイトからインストールする必要があります: ',
          cta: '公式DaVinci Resolveウェブサイト',
        },
        secondLine:
          'さらに、DaVinci Resolveは公式ウェブサイトからのバージョンの方がApp Store版よりも多くの機能を提供しています。',
      },
      cta: 'DaVinci Resolveの公式ウェブサイトにアクセス',
    },
    confirmModal: {
      title: '本当に良いですか？',
      text: 'この操作は元に戻せません。',
      cancel: 'キャンセル',
      confirm: '確認',
    },
    noLinkModal: {
      title: 'DaVinci Resolveを更新してください',
      text: 'AutoCutは引き続き使用できますが、プロセスを実行した後では、タイムラインのトラックアイテム間のリンクを保持できなくなります。',
      cta: '閉じる',
      checkBox: 'このメッセージを再表示しない',
    },
    gamifiedTrial: {
      title: 'AutoCutの冒険を始めよう',
      description: {
        firstLine:
          'あなたのクリエイティブな旅は、AutoCutの14日間の無料トライアルから始まります。{completedElements}個を追加でアンロックし、あと<b>{daysLeft}日</b>で探索を続けることができます！',
        secondLine:
          'でも、そこで止まってしまいますか？<b>さらに{bonusDays}日</b>をアンロックして、AutoCutの力を完全に解き放ちましょう。',
      },
      timeline: {
        left: '14日間の無料トライアル',
        right: '24日間の無料トライアル',
      },
      taskList: {
        title: 'タスクマスター: {completed}/{maximum} 完了',
        tasks: {
          firstCaption: 'AutoCaptionsを使用する: <link>tutorial</link>',
          firstPodcast: 'AutoCut Podcastを使用する: <link>tutorial</link>',
          firstZoom: 'AutoZoomを使用する: <link>tutorial</link>',
          firstRepeat: 'AutoCut Repeatを使用する: <link>tutorial</link>',
          firstBroll: 'AutoB-Rollを使用する: <link>tutorial</link>',
          firstSwearWord:
            'AutoProfanityフィルターを使用する: <link>tutorial</link>',
          firstPreset:
            'AutoCaptions、AutoZoom、またはAutoResizeの新しいプリセットを作成する',
          newTestimonial:
            '同じメールを使用して、テストモニアルを残してください: <link>testimonial</link>',

          // TODO À retirer à partir du 15 janvier 2025
          trial: {
            task: {
              captions: 'AutoCaptionsを使用する: <link>チュートリアル</link>',
              podcast: 'AutoCut Podcastを使用する: <link>チュートリアル</link>',
              zoom: 'AutoZoomを使用する: <link>チュートリアル</link>',
              repeat: 'AutoCut Repeatを使用する: <link>チュートリアル</link>',
              broll: 'AutoB-Rollを使用する: <link>チュートリアル</link>',
              swear: {
                word: 'AutoProfanity フィルターを使用する: <link>チュートリアル</link>',
              },
              preset:
                'AutoCaptions, AutoZoom, AutoResize の新しいプリセットを作成する',
              testimonial:
                '同じメールを使用してフィードバックを送信する: <link>フィードバック</link>',
            },
          },
        },
      },
    },
    deepgramError: {
      title: '翻訳エラー',
      text: '音声翻訳中にエラーが発生しました。これは音声品質または言語サポートに起因する可能性があります。{br}Discordまたはメール（contact@autocut.com）で報告してください。',
      retry: '別のAIモデルで文字起こしを再試行します。',
    },
    contactUs: {
      title: 'ご不便をおかけして申し訳ありません',
      text: 'AutoCutが正しく動作していない場合、問題を報告したい場合は:',
      non_editing:
        '編集に関係のない問題（アカウント、キー、支払いなど）については:',
      contact: 'お問い合わせ:',
      contactClicked: '連絡先情報をコピーしました!',
      cta: '問題を報告',
    },
    author: '{author} from AutoCut',
    feedback: {
      steps: {
        welcome: {
          title: 'ビデオ編集の未来を形作る手助けをしてください 🎬',
          footer: 'フィードバックを報告 🫡',
          text1:
            'あなたの洞察は私たちにとって非常に貴重であり、フィードバックを通じて、AutoCutの未来を形作る上で重要な役割を果たすことができます。 💪',
          text2:
            'うまくいっている点、うまくいっていない点、見たい新機能を教えてください - AIビデオ編集アシスタントを構築するお手伝いをしてください。',
          text3:
            '最後に、コミュニティに参加したい場合は、<a>Discord</a>のコミュニティに参加して、あなたのような2000人以上の親切なビデオ編集者と交流できます 🌍',
        },
        report: {
          title: 'あなたのフィードバック',
          footer: 'フィードバックを送信',
          text1:
            'あなたの問題についてより多くの情報を提供していただくほど、解決が容易になります 💡',
          form: {
            textInput: {
              placeholder:
                'フィードバックを記述してください。使用したモードと言語を明確にしてください。 \n\n問題を報告する場合は、再現手順を記述してください。\n\n代替策（別のビデオ、別の言語など）を試した場合は、お知らせください。',
            },
            fileInput: {
              title:
                'AutoCutの設定とタイムラインのスクリーンショットを追加できます',
              cta: 'スクリーンショットを追加',
            },
          },
          error: {
            feedbackTooShort:
              'フィードバックが短すぎます。詳細を提供してください。',
          },
        },
        submitted: {
          title: 'ありがとうございます 🙏',
          footer: 'モーダルを閉じる',
          text1: 'フィードバックをいただき、心より感謝いたします。 🙏',
          text2:
            'あなたのコメントを慎重に検討し、進化についてコミュニティに知らせます。',
          text3:
            'さらなる更新をお楽しみに、いつでもお気軽にご連絡ください。あなたの意見は貴重であり、共にこの旅に参加してくださることに感謝しています。',
        },
      },
    },
    onboarding: {
      steps: {
        welcome: {
          title: 'AutoCut ベータ版へようこそ',
          stepper: 'ようこそ',
          footer: '次へ',
          text1:
            'この旅の一部になっていただき、ありがとうございます！ 🚀 AutoCut のこの強化版をご紹介できることを嬉しく思います.{br}このリニューアルされたバージョンをテストいただくのが待ちきれません。',
          text2:
            '<b>ユーザーインターフェースの強化</b>により、AutoCut をあなたの編集ワークフローの一部に🎬{br}<b>安定性が 80% 向上</b> - 予期しないクラッシュや中断にさよならを🧘{br><b>パフォーマンスが 50% 向上</b> - 応答時間が短縮され、スムーズな結果と効率性が向上🚀',
          text3:
            'これだけではありませんが、進行中に発見していただきます。それでは始めましょう！',
        },
        whyAreYouHere: {
          title: 'なぜここにいるのですか？',
          stepper: 'なぜここにいるのですか？',
          footer: '次へ',
          text1:
            'ここにいるということは、私たちは<b>お手伝い</b>が必要ということです🫵',
          text2:
            'あなたは私たちのより忠実なユーザーの一人であることを証明してくれたので、ありがとうございます。そのため、この特別なベータ版の一部として選ばれました。',
          text3: 'しかし、<i>大きな力には大きな責任が伴います。</i>',
          text4:
            'AutoCut をあなたのビデオ編集スキルコレクションに必需の道具にするために、フィードバックが必要です。リピートタスクを排除し、あなたが得意とすること、すなわち創造性に集中できるツールを一緒に作りましょう。',
        },
        weNeedYou: {
          title: '私たちはあなたを必要としています 🫵',
          stepper: '私たちはあなたを必要としています',
          footer: '始めましょう',
          text1:
            'この新しい版で発生するバグを報告するための新しいフィードバックバブルが、ホームページの右下にあります。',
          text2:
            'これらの問題の解決は私たちの最優先事項です。そのため、あなたのコメントはすべて適切にレビューされます。',
          text3:
            'また、あなたにとって不可欠と思われる機能を提案するチャンネルでもあります。',
          text4:
            'また、あなたのような2000人以上の親しみやすいビデオ編集者がいる私たちの<a>Discord</a>コミュニティに参加し、AutoCutの未来を独占的に覗いてみたいかもしれません。',
        },
      },
    },
    usageStats: {
      title: '使用統計',
      select: {
        label: 'モードを選択',
      },
      stats: {
        thisMonth: '今月',
        allTime: '全期間',
        mostUsed: '最も使用された機能',
      },
    },
    endProcess: {
      title: 'プロセスが完了しました',
      time: '{minutes}分{seconds}秒で完了しました！',
      timeSaved: '節約時間: <b>{minutesSaved}</b>分と<b>{secondsSaved}</b>秒',
      timeSavedAllTime:
        'AutoCutで総節約時間: {hourSaved}時間{minutesSaved}分！ 🎉',
      feedback: '結果に対するフィードバックをお聞かせください:',
      review: 'AutoCutを評価する！',
      restoreBackup: 'バックアップを復元する',
      caption: {
        title: 'キャプションが追加されました！',
        footer:
          '字幕やトランスクリプションを調整する必要がある場合は、キャプションエディタに戻ってください。',
        button: 'トランスクリプトエディタに戻る',
        text_1: 'アニメーション付きキャプションが追加されました！ 🎉',
        text_2: '必要に応じてキャプションを編集:',
        list_1: 'タイムラインカーソルを希望のキャプションに置く',
        list_2: 'Fusionページに移動',
        list_3: 'TextPlusノードでキャプションを手動で編集',
        text_3:
          'より多くのヒントについてこの<a>チュートリアル</a>を参照してください。',
      },
      broll: {
        title: 'あなたの動画で使用されたB-Rollのクレジットは次のとおりです : ',
        providedByPexels: 'Pexels提供',
        copyToClipboard: 'クリップボードにコピー',
        copied: 'コピーされました',
        link: 'リンク',
      },
      nextMode: {
        silence: {
          text: 'スムーズな音声のために、AutoCut Silencesを使用して不自然な間を簡単に排除しましょう。',
          button: 'AutoCut Silencesに移動',
        },
        podcast: {
          text: 'AutoCut Podcastを使用してポッドキャストを強化し、編集を効率化し、自動カメラ切り替えを実現しましょう。',
          button: 'AutoCut Podcastに移動',
        },
        repeat: {
          text: '視聴者を引きつけよう！AutoCut Repeatを使って、コンテンツの最も魅力的な部分だけを残しましょう。',
          button: 'AutoCut Repeatに移動',
        },
        zoom: {
          text: 'AutoZoomで動画に命を吹き込みましょう！視聴者を魅了するダイナミックなズーム効果を追加します。',
          button: 'AutoZoomに移動',
        },
        caption: {
          text: 'AutoCaptionsを使って視聴者とつながりましょう。物語性を高めるスタイリッシュで同期した字幕を追加します。',
          button: 'AutoCaptionsに移動',
        },
        resize: {
          text: 'AutoResizeを使用して、あらゆるプラットフォームに最適化された動画を作成しましょう。',
          button: 'AutoResizeに移動',
        },
        broll: {
          text: 'AutoB-Rollで物語を強化し、AIを活用してストック映像をシームレスに追加します。',
          button: 'AutoB-Rollに移動',
        },
        swear: {
          word: {
            text: 'AutoProfanity Filterでプロフェッショナルな対話を維持しましょう。不適切な言葉を自動的に検閲します。',
            button: 'AutoProfanity Filterに移動',
          },
        },
      },
    },
    discord: {
      title: 'Discord',
    },
    unsupportedVersion: {
      title: 'サポートされていない{host}バージョン',
      currentVersion: '検出された{host}のバージョン: {version}',
      text: 'AutoCutは{host}バージョン18.6以上が必要です。',
      update: '更新はこちら: <a>{host} Website</a>',
    },
  },
  gamification: {
    savedHours: 'あなたはちょうど {hourSavedText} 時間を節約しました、',
    haveABreak: '休憩を取ってください、あなたはそれに値します！',
  },
  errors: {
    modal: {
      title: {
        noSelectedAudioClip: '選択されたオーディオクリップはありません',
        noTimelineFound: 'タイムラインが見つかりません',
        subsequenceNotReachable: 'サブシーケンスに到達できません',
        unknown: '不明なエラーが発生しました',
        noSpace: 'ストレージ不足エラー',
        exportUnknown: '{host}でのエクスポートエラー',
        invalidInputData: '一時ファイル生成エラー',
        fileNotFound: 'ファイル読み取りエラー',
        scriptNotLoaded: 'AutoCutスクリプトの読み込みエラー',
        ffmpegError: 'ファイル読み取りエラーです。',
        exportCancelled: 'エクスポートがキャンセルされました',
        timelineMuted: '聴取可能なトラックがありません',
        noAudibleAudio: '聴取可能なオーディオが見つかりません',
        offline: 'オフライン状態が検出されました',
        resourceTimeout: 'リソースのタイムアウト',
        backupNotFound: 'バックアップが見つかりません',
        setCursorOutInterval: 'In/Outポイント範囲外',
        emptyPath: '選択分析エラー',
        sequenceMismatch: '操作を完了できません',
      },
      text: {
        noSelectedAudioClip:
          '選択した開始および終了ポイントの間にオーディオクリップが存在しません',
        noTimelineFound: 'アクティブなシーケンスが見つかりません',
        subsequenceNotReachable: '選択したサブシーケンスに到達できません。',
        unknown: '原因不明のAutoCutエラーです。',
        noSpace: 'デバイスのストレージが不足しています。',
        exportUnknown:
          'ディスクのストレージ不足が原因でこのエラーが発生しました。',
        invalidInputData: '一時ファイル生成中にエラーが発生しました。',
        fileNotFound:
          '次の場所に{filename}があるか確認してください: {filepath}',
        scriptNotLoaded:
          '他のすべての拡張機能と{host}を閉じて、再度試してください。',
        ffmpegError: '{filepath}ファイルが壊れている可能性があります。',
        exportCancelled:
          'エンコーディングがキャンセルされた場合、オーディオ分析が停止します。再試行してください。',
        timelineMuted: '選択したオーディオトラックがミュートされています。',
        noAudibleAudio: '検出可能なオーディオがありません。',
        offline: 'サーバーにアクセスできません。',
        resourceTimeout: '30秒以内にリソースをダウンロードできませんでした',
        backupNotFound: '復元するバックアップシーケンスが見つかりません。',
        setCursorOutInterval:
          '選択されたセクションがIn/Outポインタの範囲外です。',
        emptyPath:
          '選択されたクリップがコンピュータにパスを持っていない可能性があります。',
        sequenceMismatch:
          'AutoCutが処理している間、シーケンスの変更を避けてください。',
      },
      tips: {
        noSelectedAudioClip: '開始および終了ポイントを正しく設定してください',
        noTimelineFound: '有効なタイムラインを選択してください',
        subsequenceNotReachable:
          'タイムライン内のサブシーケンスがプロジェクトパネルの元のシーケンスと同じ名前であることを確認してください。',
        unknown:
          '選択/設定を変更してみてください。問題が解決しない場合はお問い合わせください。',
        noSpace: 'スペースを空けてAutoCutを再起動してください。',
        exportUnknown: 'スペースを空けてAutoCutを再起動してください。',
        invalidInputData:
          '同じ選択で再試行してください。問題が発生した場合は、ファイルをお送りください。',
        fileNotFound:
          '同じ選択で再試行してください。問題が発生した場合は、ファイルをお送りください。',
        scriptNotLoaded: '問題が解決しない場合はお問い合わせください。',
        ffmpegError: '問題が解決しない場合はお問い合わせください。',
        exportCancelled:
          '処理の途中でエクスポートをキャンセルしました。AutoCutは完全なオーディオ分析なしでは続行できません。もう一度お試しください。',
        timelineMuted: '少なくとも1つのトラックのミュートを解除してください。',
        noAudibleAudio:
          'クリップがアクティブで音を出していることを確認してください。問題が続く場合は、Discordまたはcontact@autocut.frまでお問い合わせください。',
        offline: 'インターネット接続を確認し、再試行してください。',
        resourceTimeout: 'インターネット接続を確認し、再試行してください。',
        backupNotFound:
          'プロジェクトのルートにある「AutoCut-Backup」ビンでバックアップの有無を確認してください。',
        setCursorOutInterval:
          'このトランスクリプトセクションにアクセスするには、In/Outポイントを削除してください。',
        noSoundDetected:
          '音が検出されませんでした。言語がクリップに一致していること、トラックがミュートされていないこと、In/Outポイントに音声クリップが含まれていることを確認してください。 {br}問題が続く場合は、Discordかcontact@autocut.frまでお問い合わせください。',
        emptyPath:
          'クリップが原子メディアまたはサブシーケンスであることを確認してください。',
        sequenceMismatch:
          'AutoCut処理の完了までシーケンスの変更や修正を避けてください。',
      },
    },
  },
  globals: {
    ok: 'OK',
    copied: 'Copiado',
    contactMail: 'contact@autocut.com',
    defaults: {
      components: {
        ProcessModal: {
          defaultStepName: '処理中',
        },
        KebabMenu: {
          account: 'ライセンスキーを管理',
          usageStatistics: '使用統計',
          bug: 'バグを報告',
          review: 'AutoCutを評価',
          tuto: 'チュートリアルを見る',
          logout: 'ログアウト',
          settings: '設定をリセット',
          featureRequest: '機能をリクエスト',
          academy: 'ヘルプセンター',
        },
        LicenseInfos: {
          timeLeft: '残り{daysLeft}日',
          info: '{licenceType}ライセンス',
          trialButton: 'もっと知る',
          paidButton: 'ライセンスを管理',
        },
        PromoBanner: {
          promo: '24時間以内に最大20％割引！',
          shop: '今すぐ購入',
        },
        FileInput: {
          uploadScreenshot: '発行スクリーンショットをアップロード',
        },
        LinkToUserFileInput: {
          sendLogLinkToUserFile: {
            title: 'ビデオとオーディオファイルへのリンクを提供する。',
            description:
              '(推奨: SwissTransfer: https://www.swisstransfer.com/)',
            helper:
              'Swisstransferを使用してアップロードするには、swisstransfer.comにアクセスして「ファイルをアップロード」し、リンクを取得してここに貼り付けてください。',
            end: 'トラブルシューティングと解決のために必要です。',
          },
        },
        Select: {
          'no-more': 'これ以上のオプションはありません',
          'select-all': 'すべてを選択',
          placeholder: 'オプションを選択',
          search: '検索',
        },
        PreviewFrame: {
          previewOnIndicator: 'インジケータに中心を合わせてプレビュー',
        },
        FontNotFoundModal: {
          title: 'フォントが見つかりません',
          description:
            'システム上にフォントが見つかりませんでした。キャプションを追加するために新しいフォントを選択してください。',
          help: 'このチュートリアルに従って新しいフォントファミリーを追加できます。',
        },
        TimeBadge: {
          errors: {
            wrong_format:
              '時間の形式が正しくありません。HH:MM:SS形式を使用してください。',
          },
        },
      },
    },
    misc: {
      retry: '再試行',
      since_your_last_visit: '前回の訪問から',
      back_to_autocut: 'AutoCutに戻る',
    },
    units: {
      second: '秒',
      millisecond: 'ミリ秒',
      percentage: '%',
      px: 'ピクセル',
    },
    language: {
      french: 'フランス語',
      english: '英語',
      englishUS: '英語 - アメリカ',
      arabicDZ: 'アラビア語 - アルジェリア',
      arabicBH: 'アラビア語 - バーレーン',
      arabicEG: 'アラビア語 - エジプト',
      arabicIQ: 'アラビア語 - イラク',
      arabicIL: 'アラビア語 - イスラエル',
      arabicJO: 'アラビア語 - ヨルダン',
      arabicKW: 'アラビア語 - クウェート',
      arabicLB: 'アラビア語 - レバノン',
      arabicMR: 'アラビア語 - モーリタニア',
      arabicMA: 'アラビア語 - モロッコ',
      arabicOM: 'アラビア語 - オマーン',
      arabicQA: 'アラビア語 - カタール',
      arabicSA: 'アラビア語 - サウジアラビア',
      arabicPS: 'アラビア語 - パレスチナ',
      arabicTN: 'アラビア語 - チュニジア',
      arabicAE: 'アラビア語 - アラブ首長国連邦',
      arabicYE: 'アラビア語 - イエメン',
      german: 'ドイツ語',
      hindi: 'ヒンディー語',
      portugal: 'ポルトガル語',
      brazil: 'ポルトガル語 - ブラジル',
      spanish: 'スペイン語',
      spanish419: 'スペイン語 - ラテンアメリカ',
      danish: 'デンマーク語',
      dutch: 'オランダ語',
      flamish: 'フラマン語',
      italian: 'イタリア語',
      japanese: '日本語',
      korean: '韓国語',
      norwegian: 'ノルウェー語',
      polish: 'ポーランド語',
      swedish: 'スウェーデン語',
      tamil: 'タミル語',
      chinese: '中国語',
      russian: 'ロシア語',
      turkish: 'トルコ語',
      catalan: 'カタロニア語',
      indonesian: 'インドネシア語',
      finnish: 'フィンランド語',
      vietnamese: 'ベトナム語',
      hebrew: 'ヘブライ語',
      ukrainian: 'ウクライナ語',
      greec: 'ギリシャ語',
      malay: 'マレー語',
      czech: 'チェコ語',
      romanian: 'ルーマニア語',
      hungarian: 'ハンガリー語',
      thai: 'タイ語',
      urdu: 'ウルドゥー語',
      croatian: 'クロアチア語',
      bulgarian: 'ブルガリア語',
      lithuanian: 'リトアニア語',
      maori: 'マオリ語',
      malayalam: 'マラヤーラム語',
      welsch: 'ウェールズ語',
      slovak: 'スロバキア語',
      telugu: 'テルグ語',
      persian: 'ペルシャ語',
      latvian: 'ラトビア語',
      bengali: 'ベンガル語',
      serbian: 'セルビア語',
      azerbaijani: 'アゼルバイジャン語',
      slovenian: 'スロベニア語',
      kannada: 'カンナダ語',
      estonian: 'エストニア語',
      macedonian: 'マケドニア語',
      breton: 'ブルトン語',
      basque: 'バスク語',
      icelandic: 'アイスランド語',
      armenian: 'アルメニア語',
      nepali: 'ネパール語',
      mongolian: 'モンゴル語',
      bosnia: 'ボスニア語',
      kazakh: 'カザフ語',
      albanian: 'アルバニア語',
      swahili: 'スワヒリ語',
      galician: 'ガリシア語',
      marathi: 'マラーティー語',
      punjabi: 'パンジャブ語',
      sinhala: 'シンハラ語',
      khmer: 'クメール語',
      shona: 'ショナ語',
      yoruba: 'ヨルバ語',
      somali: 'ソマリ語',
      afrikaans: 'アフリカーンス語',
      occitan: 'オック語',
      georgian: 'ジョージア語',
      belarusian: 'ベラルーシ語',
      tajik: 'タジク語',
      sindhi: 'シンド語',
      gujarati: 'グジャラート語',
      amharic: 'アムハラ語',
      yiddish: 'イディッシュ語',
      lao: 'ラオ語',
      uzbeck: 'ウズベク語',
      faroese: 'フェロー語',
      haitian: 'ハイチ・クレオール語',
      pashto: 'パシュトゥ語',
      turkmen: 'トルクメン語',
      norwegianNynorsk: 'ノルウェー語ニーノシュク',
      maltese: 'マルタ語',
      sanskrit: 'サンスクリット語',
      luxembourgish: 'ルクセンブルク語',
      burmese: 'ビルマ語',
      tibetan: 'チベット語',
      tagalog: 'タガログ語',
      malagasay: 'マダガスカル語',
      assamese: 'アッサム語',
      tatar: 'タタール語',
      hawaiian: 'ハワイ語',
      lingala: 'リンガラ語',
      hausa: 'ハウサ語',
      bashkir: 'バシュキール語',
      javanese: 'ジャワ語',
      sundanese: 'スンダ語',
    },
  },
  old: {
    error: {
      UseLicence: {
        __text__: '他のデバイスからのキーのリンクを解除するには',
        link: 'AutoCutクライアントポータルで。',
      },
      modal: {
        title: {
          subsequenceNotReachable: 'サブシーケンスにアクセスできません',
          unknown: '不明なエラーが発生しました',
          noSpace: 'ストレージ不足エラー',
          exportUnknown: 'DaVinci Resolveのエクスポートエラー',
          invalidInputData: '一時ファイル生成エラー',
          fileNotFound: 'ファイル読み込みエラー',
          scriptNotLoaded: 'AutoCutスクリプトロードエラー',
          ffmpegError: 'ファイル読み込みエラー。',
          exportCancelled: 'エクスポートがキャンセルされました',
          timelineMuted: '聴覚可能なトラックがありません',
          noAudibleAudio: '聴覚可能なオーディオが見つかりません',
          offline: 'オフラインステータス',
          resourceTimeout: 'リソースタイムアウト',
          backupNotFound: 'バックアップが見つかりません',
          setCursorOutInterval: 'イン/アウトポイントを超えています',
          emptyPath: '選択分析エラー',
          sequenceMismatch: '完了できない操作',
        },
        text: {
          subsequenceNotReachable:
            '選択したサブシーケンスにアクセスできません。',
          unknown: 'AutoCutでの不明な原因によるエラー。',
          noSpace: 'デバイスのストレージが不十分です。',
          exportUnknown:
            'ディスクストレージ不足が原因でこのエラーが発生しました。',
          invalidInputData: '一時ファイル生成中にエラーが発生しました。',
          fileNotFound:
            '{filename}が{filepath}の位置にあることを確認してください。',
          scriptNotLoaded:
            '他のすべての拡張機能を閉じてDaVinci Resolveを再起動してください。',
          ffmpegError: '{filepath}ファイルが破損している可能性があります。',
          exportCancelled:
            'エンコードがキャンセルされた場合にオーディオ分析が停止されました。再試行してください。',
          timelineMuted: '選択されたオーディオトラックはミュートされています。',
          noAudibleAudio: '検出可能なオーディオがありません。',
          offline: 'サーバーにアクセスできません。',
          resourceTimeout: '30秒以内にリソースをダウンロードできませんでした',
          backupNotFound: 'バックアップシーケンスを見つけることができません。',
          setCursorOutInterval:
            '選択したセクションはイン/アウトポイントの範囲外です。',
          emptyPath:
            '選択したクリップには、コンピュータ上のパスが含まれていない可能性があります。',
          sequenceMismatch: 'AutoCut処理中はシーケンスの変更を避けてください。',
        },
        tips: {
          subsequenceNotReachable:
            'プロジェクトパネルの元のシーケンスと同じ名前を持つことを確保してください。',
          unknown:
            '選択/設定を変更してみてください。問題が続く場合はご連絡ください。',
          noSpace: 'スペースを解放し、AutoCutを再起動してください。',
          exportUnknown: 'スペースを解放し、AutoCutを再起動してください。',
          invalidInputData:
            '同じ選択を再試行してください。問題が続く場合はファイルをお送りください。',
          fileNotFound:
            '同じ選択を再試行してください。問題が続く場合はファイルをお送りください。',
          scriptNotLoaded: '問題が続く場合はご連絡ください。',
          ffmpegError: '問題が続く場合はご連絡ください。',
          exportCancelled: ' ',
          timelineMuted: '少なくとも1つのトラックをミュート解除してください。',
          noAudibleAudio:
            'クリップがアクティブで聴覚可能であることを確認してください。問題が続く場合は、Discordまたはcontact@autocut.frでご連絡ください。',
          offline: 'インターネット接続を確認して再試行してください。',
          resourceTimeout: 'インターネット接続を確認して再試行してください。',
          backupNotFound:
            'プロジェクトルートの"AutoCut-Backup"ビンでバックアップが存在するか確認してください。',
          setCursorOutInterval:
            'このトランスクリプトセクションにアクセスするにはイン/アウトポイントを削除してください。',
          noSoundDetected:
            '音が検出されませんでした。クリップと言語が一致していること、トラックがミュートされていないこと、イン/アウトポイントにサウンドクリップが含まれていることを確認してください。 {br}問題が続く場合は、Discordまたはcontact@autocut.frでご連絡ください。',
          emptyPath:
            'クリップが原子メディアまたはサブシーケンスであることを確認してください。',
          sequenceMismatch:
            'AutoCutプロセスが完了するまでシーケンスの変更と調整を避けてください。',
        },
        lost: {
          cep: {
            title: '接続ロストエラー',
            text: {
              '1': 'DaVinci Resolveとの接続が失われました。拡張を再起動してください。',
              '2': '失敗すれば、DaVinci Resolveを再起動してください。',
            },
          },
        },
      },
      language: {
        not: {
          supported: 'このモードでは言語サポートがありません。',
        },
      },
    },
    retour: 'キーが存在しません！',
    countdown: {
      days: '残りトライアル日数',
    },
    saveas: {
      default: {
        settings: '現在のパラメータを保存',
      },
    },
    button: {
      no_silences: '無音が見つかりません',
      no_audios: '選択されたオーディオクリップがありません',
      SavingBackup: 'プロジェクトをバックアップ中...',
      RestoringBackup: 'プロジェクトを復元中...',
      ExportingAudio: 'オーディオをエクスポート中...',
      UploadingAudio: 'オーディオをアップロード中...',
      SearchingSilences: '無音を検索中...',
      CuttingStep: {
        Audio:
          'トラック{numTrack}でオーディオカットを実行中: {current} / {max}',
        Video: 'トラック{numTrack}でビデオカットを実行中: {current} / {max}',
      },
      PostCuttingStep: 'カット完了...',
      PreDeletingStep: '削除する無音クリップを検索中...',
      PostDeletingStep: '無音が削除されました...',
      finalizing: '最終段階...',
      DeletingStart: '無音を削除中...',
      AssociatingStep: '関連付けを実行中{current} / {max}',
      Success: 'プロセス成功！',
      Failure: '失敗... 問題が発生しました',
      NoSilences: '無音が検出されません',
      undo: {
        modal: '元に戻す',
      },
      not: {
        supported: {
          modal: '閉じる',
        },
      },
      PreZoomingStep: 'ズーム用のサブクリップを取得中...',
      ZoomingStep: 'ズームを追加中: {current} / {max}',
      Autozoom: 'AutoZoomを準備中...',
      no_video: {
        to_zoom: 'ズームするビデオがありません。',
      },
      getSubclip: {
        step: 'AutoZoom用にサブクリップを取得中...',
      },
      checkSilenceSuppression: {
        step: '無音抑制を確認中...',
      },
      back: '戻る',
      DeletingStep: {
        delete: {
          Audio:
            'オーディオトラック{numTrack}で無音を削除中: {current} / {max}',
          Video: 'ビデオトラック{numTrack}で無音を削除中: {current} / {max}',
        },
        mute: {
          Audio:
            'オーディオトラック{numTrack}で無音をミュート中: {current} / {max}',
          Video:
            'ビデオトラック{numTrack}で無音をミュート中: {current} / {max}',
        },
      },
      skip: 'スキップ',
      save: '保存',
    },
    warning: {
      DontTouchTimeline: 'プロセス中はタイムラインを編集しないでください',
      undo: {
        modal:
          'オーディオファイル分析でメタデータが欠落していることが検出されました。',
      },
      modal: {
        title: {
          ffmpeg: 'FFMPEG問題の可能性',
        },
        text: {
          ffmpeg:
            'ファイル{filename}にメタデータが欠落していることが検出されました。',
        },
        tips: {
          ffmpeg: '問題が続く場合はファイルの再エンコードを検討してください。',
        },
      },
      beta: {
        version:
          'ベータ版DaVinci Resolveバージョンが検出されました。AutoCutの問題が発生する可能性があります。最近の安定したDaVinci Resolveバージョンでのテストが推奨されます。',
      },
    },
    preview: {
      loading: {
        selection: {
          validation: '選択を検証中...',
          parsing: {
            step: 'データを取得中: クリップ{current} / {max}...',
          },
        },
        silence: {
          detection: {
            step: '無音を検出中: クリップ{current} / {max}...',
          },
        },
        drawing: 'プレビューを生成中...',
      },
      frame: {
        no_sequence: 'シーケンスを開始してプレビューを表示',
      },
    },
    contact: {
      us_title: 'ご迷惑をおかけして申し訳ありません',
      us_text: {
        '2': '編集に関連しない問題（アカウント、キー、支払いなど）については:',
        __text__:
          'AutoCutが正しく機能しない場合は、次の手順で問題を報告してください:',
      },
      us_button: '問題を報告',
      us_contact: 'お問い合わせ:',
      us_copy: '連絡先情報をコピーしました！',
    },
    switch: {
      to_send: {
        log: {
          button: '別の方法でログファイルを送信',
        },
      },
    },
    send: {
      log: {
        title: '問題についてお詫び申し上げます',
        details: 'このログファイルは、メディアの処理状態を示しています。',
        select:
          '問題の解決に役立つために、日々のログファイルを選択してください。',
        text: {
          area: '問題の詳細について説明してください:',
        },
        button: 'ログファイルを提出',
        success: {
          title: 'ファイルを受け取りました！調査します。',
        },
        thank: {
          text: '編集を続行してください。',
          text2: '問題が解決されたらお知らせします。',
        },
        message: {
          error: 'メッセージは少なくとも10文字である必要があります',
        },
        screen: {
          timeline: {
            __text__:
              'DaVinci Resolveタイムラインのスクリーンショットを含めてください。',
            before: 'カット前のスクリーンショットを含めてください。',
            after: 'カット後のスクリーンショットを含めてください。',
          },
          error: {
            message: 'エラーメッセージのスクリーンショットを含めてください。',
          },
        },
        trial: {
          mail: 'トライアルと関連付けられているメールを提供してください。',
        },
        other: '問題の解決に役立つ追加情報を提供してください。',
      },
    },
    select: {
      issue: {
        title: '以下から問題を選択してください。',
        subtitle: '迅速に問題を解決するのに役立ちます。',
      },
      one: {
        audios:
          'AutoCutを使用するために1つ以上のオーディオクリップを選択してください。',
      },
    },
    upload: {
      screenshot: 'スクリーンショットを提出',
    },
    modal: {
      feedback: {
        title: 'フィードバック',
        input: {
          feedback: {
            title: 'AutoCutに関するご意見は？',
            placeholder: 'フィードバックを入力...',
          },
          improve: {
            title: '改善の提案はありますか？',
            placeholder: '提案を入力...',
          },
        },
        button: {
          text: '送信',
        },
        sent: {
          title: 'フィードバックありがとうございます！',
          text: '機能のアイデアがありますか？お知らせください！',
          button: {
            text: '機能をリクエスト',
          },
        },
      },
      bug: {
        report: {
          title: 'バグレポート',
          description: {
            placeholder: '問題を説明してください...',
            error: 'バグ説明は少なくとも20文字である必要があります。',
          },
          reproduce: {
            placeholder: '問題を再現する手順...',
            error: '再現手順は少なくとも20文字である必要があります。',
          },
          blocking: 'これはブロック問題ですか？',
          button: {
            text: {
              __text__: 'バグレポートを送信',
              success: '報告ありがとうございます！',
            },
          },
          link: {
            error: '有効なファイルリンクを提供してください。',
          },
        },
      },
    },
    issue: {
      undo: {
        modal: 'カットに問題がありますか？',
      },
    },
    star: {
      review: {
        title: 'AutoCutのレビューを残してください！',
        caption: {
          title: 'AutoCaptionsを評価！',
        },
        text: 'AutoCutの体験を共有してください！',
      },
    },
    review: {
      title: '詳細情報を提供',
      text: 'カットに満足していますか？フィードバックを送ってAutoCutの改善に貢献してください！',
      form: {
        placeholder: 'フィードバックを入力...',
      },
      button: '送信',
      thank: {
        title: 'フィードバックありがとうございます！',
        text: '編集を続けてください。',
      },
    },
    exchange: {
      title: '7日間のトライアル延長を受け取る！',
      text: 'Adobe ExchangeでAutoCutを評価して、7日間の追加トライアルを受け取る！',
      button: 'Adobe ExchangeでAutoCutを評価',
      info: 'ウェブサイトから直接インストールした場合は、再インストールの必要はありません。評価を開始するには「無料」をクリックするだけです。',
    },
    licence: {
      trial: 'トライアル',
      paid: '有料',
      info: '{licenceType}ライセンス',
      time: {
        left: '{daysLeft}日残り',
      },
    },
    trial: {
      promo: {
        text: '24時間以内に最大20%を節約',
      },
      resend: {
        error:
          'トライアルキーの再送信中にエラーが発生しました。後でもう一度お試しください。',
      },
      expired: {
        modal: {
          title: 'トライアルが終了しました。',
          text1:
            'トライアルが終了しました。継続してAutoCutを使用するにはサブスクリプションが必要です。',
          text2: 'AutoCutダッシュボードをご覧ください:',
          text3: 'ライセンスキーを購読して販売',
          text4: 'サポートは、{supportEmail}までお問い合わせください',
          button: '20％割引で購読',
        },
      },
      title: '無料トライアル',
      stepper: {
        onboarding: 'AutoCutの改善にご協力ください',
        email: 'メールアドレスを入力',
        sending: {
          email: 'メール送信中',
        },
        login: 'ライセンスキーを入力',
      },
      onboarding: {
        subtitle: '新しいDaVinci Resolve機能の開発に寄与する洞察を共有',
        discover: 'AutoCutをどのように見つけましたか？',
        job: {
          status: '現在の職業状況',
        },
        type: {
          creation: 'DaVinci Resolveプロジェクトの種類',
        },
      },
      sending: {
        email: {
          success: 'メールが正常に送信されました: {email}',
        },
      },
      task: {
        caption: 'AutoCaptionsを使用: <link>さらに詳しく</link>',
        podcast: 'AutoCut Podcastを使用: <link>さらに詳しく</link>',
        zoom: 'AutoZoomを使用: <link>さらに詳しく</link>',
        repeat: 'AutoCut Repeatを使用: <link>さらに詳しく</link>',
        broll: 'AutoB-Rollを使用: <link>さらに詳しく</link>',
        swear: {
          word: 'AutoProfanity Filterを使用: <link>さらに詳しく</link>',
        },
        preset:
          'AutoCaptions、AutoZoom、またはAutoResizeの新しいプリセットを作成',
        testimonial: '証言を残す（同じメールを使用）: <link>証言を送信</link>',
      },
    },
    shop: {
      now: '今すぐ購入',
    },
    back: {
      to_autocut: 'AutoCutに戻る',
    },
    'discover-option-youtube-video': 'YouTubeビデオ',
    'discover-option-youtube-comment': 'YouTubeコメント',
    'discover-option-reddit': 'Reddit',
    'discover-option-google': 'Google',
    'discover-option-facebook': 'Facebook',
    'discover-option-friend': '友達',
    'discover-option-school': '学校',
    'discover-option-other': 'その他',
    link: {
      not: {
        supported: {
          modal: {
            __text__: '詳細と解決策については、',
            no_solution: 'エラーについての説明は、',
            end: '、私たちのドキュメントとチュートリアルサイト',
          },
        },
      },
    },
    text1: {
      use: {
        trial: {
          licence: '（{email}）にメールが送信されました',
        },
      },
    },
    text2: {
      use: {
        trial: {
          licence:
            'メールが届かない場合は、迷惑メールまたはプロモーションフォルダを確認してください。',
        },
      },
    },
    placeholder: {
      use: {
        licence: 'ライセンスキーを入力',
      },
    },
    "can't": {
      reach: {
        server: {
          error: 'サーバーに接続できません。後でもう一度お試しください。',
        },
      },
      read: {
        uuid: {
          error: 'コンピュータのUUIDを読むことができません。',
        },
        host: {
          error: 'コンピュータの名前を読むことができません。',
        },
      },
    },
    asking: {
      key: {
        error:
          'サーバーとのキー確認中にエラーが発生しました。後でもう一度お試しください。',
      },
    },
    sending: {
      log: {
        file: {
          error:
            '日々のログを送信することができませんでした。後でもう一度お試しください。',
        },
      },
    },
    is_key: {
      valid: {
        error:
          'キーの検証中にエラーが発生しました。後でもう一度お試しください。',
      },
    },
    base: {
      error: {
        message: 'エラーが発生しました',
      },
    },
    invalid: {
      response: {
        error: 'サーバーから予期しない応答を受け取りました。',
      },
    },
    description: {
      banner: {
        podcast: {
          beta: '開発中;問題や提案がある場合は、contact@autocut.comまでご連絡ください',
        },
      },
    },
    header: {
      trial: {
        button: 'もっと詳しく知る',
      },
      paid: {
        button: '管理',
      },
      speakers: {
        name: 'スピーカー名',
        track: 'スピーカーのオーディオトラック',
      },
      cameras: {
        name: {
          __text__: 'カメラのビデオトラック',
          helper:
            'カメラごとにビデオトラックを選択します。ただし、一カメラにつき一トラック。',
        },
        speakers: {
          __text__: '可視スピーカー',
          helper:
            'このカメラで可視のスピーカーを選択します。複数のスピーカーが可能。',
        },
      },
    },
    game: {
      saved: {
        hours: '保存した時間 {hourSavedText}時間、',
      },
      have: {
        a_break: 'ゆっくり休んでください。',
      },
    },
    onboarding: {
      skip: 'オンボーディングをスキップ',
      close: '閉じる',
      previous: '前へ',
      next: '次へ ({current}/{max})',
      onboarding: {
        step1: {
          title: 'AutoCutへようこそ！',
          body: '初めてのご利用時には、AutoCutが様々な機能を紹介し、スムーズな編集をサポートします。始めましょう！',
        },
        step2: {
          title: '無料トライアルキー',
          body: 'すべての機能を14日間無料で試せます。',
        },
        step3: {
          title: 'ライセンスキー',
          body: 'ライセンスキー（トライアルまたは有料）を入力して、すべてのAutoCutツールにアクセス。',
        },
        step4: {
          title: 'ライセンスキーの購入',
          body: '購入ボタンからライセンスキーを購入し、詳細情報を確認。',
        },
      },
      modeSelection: {
        step1: {
          title: 'AutoCutモード',
          body: '編集のニーズに合ったモードを選択してください。',
        },
        step2: {
          title: 'ヘルプが必要ですか？',
          body: 'インターフェース要素の上にカーソルを合わせてヒントを見てください。',
        },
      },
      legacy: {
        step1: {
          title: 'AutoCut V2 Beta',
          body: 'AutoCut V2は、オーディオノイズレベルに基づいて無音を検出します。最初の設定が必要です。',
        },
        step2: {
          title: 'ノイズレベル',
          body: 'スライダーを使用して、無音として分類される最小ノイズレベルを設定。',
        },
        step3: {
          title: '次のステップ',
          body: '矢印を使用してステップをナビゲートし、他のAutoCut機能を探索してください。',
        },
        step4: {
          title: 'プレビューを生成中',
          body: '処理前にオーディオをエクスポートしてください。プレビューを生成してオーディオカットを可視化。',
        },
        step6: {
          title: 'カットの時が来た',
          body: 'プレビューを確認して満足ならカットを進めてください。',
        },
        step7: {
          title: 'カットの時が来た',
          body: 'お好みのカットオプションを選択してください。',
        },
        step8: {
          title: 'カットの時が来た',
          body: 'リラックスしてAutoCutに編集を任せましょう！',
        },
      },
      podcast: {
        step1: {
          title: 'AutoCut Podcast',
          body: 'AutoCut Podcastを使用してポッドキャストビデオを迅速に編集。',
        },
        step2: {
          title: 'スピーカー',
          body: '「スピーカーを追加」ボタンを使ってスピーカーを追加。各スピーカーに1トラックを割り当てます。',
        },
        step3: {
          title: 'スピーカー',
          body: '各スピーカーの設定を必要に応じて構成。',
        },
        step4: {
          title: 'スピーカーの名前',
          body: '各スピーカーに識別用の名前を付ける。',
        },
        step5: {
          title: 'ビデオトラックの選択',
          body: '各スピーカーを表示するビデオトラックを選択。',
        },
        step6: {
          title: 'オーディオトラックの選択',
          body: '各スピーカーにオーディオトラックを割り当てます。各スピーカーにつき1トラック。',
        },
        step7: {
          title: '編集時間',
          body: 'スピーカーを定義し、編集を進めます。',
        },
        step8: {
          title: '編集時間',
          body: '矢印を使用して編集オプションを選択ください。',
        },
        step9: {
          title: '編集時間',
          body: 'リラックスし、AutoCutに編集プロセスを任せます。',
        },
      },
      title: 'AutoCutへようこそ',
      subtitle: 'あなたの究極のDaVinci Resolve拡張機能',
    },
    start: {
      transcription: 'トランスクリプションを開始',
    },
    no_more: {
      credits: 'クレジットが使い果たされました',
    },
    file: {
      too: {
        long: 'オーディオファイルが長すぎます',
      },
      sent: 'ファイルがアップロードされ、トランスクリプションを待機中...',
    },
    please: {
      wait1: '音声トランスクリプションを待っています...',
      wait2: 'このプロセスには数分かかることがあります。',
      wait3: 'ファイルをアップロード中:',
    },
    language: {
      selection: {
        step: {
          title: {
            __text__: 'オーディオ言語',
            helper: 'オーディオクリップの言語を指定する',
          },
        },
        french: {
          __text__: 'フランス語',
          beta: 'フランス語（ベータ）',
        },
        danish: 'デンマーク語',
        dutch: 'オランダ語',
        english: {
          __text__: '英語',
          united: {
            states: '英語 - アメリカ',
          },
        },
        flemish: 'フラマン語',
        german: {
          __text__: 'ドイツ語',
          beta: 'ドイツ語（ベータ）',
        },
        hindi: {
          __text__: 'ヒンディー語',
          beta: 'ヒンディー語（ベータ）',
        },
        italian: 'イタリア語',
        japanese: '日本語',
        korean: '韓国語',
        norwegian: {
          __text__: 'ノルウェー語',
          nynorsk: 'ノルウェー語ニーノシュク',
        },
        polish: 'ポーランド語',
        portuguese: {
          __text__: 'ポルトガル語',
          brazil: 'ポルトガル語 - ブラジル',
          'brazil—beta': 'ポルトガル語 - ブラジル（ベータ）',
        },
        'portuguese—beta': 'ポルトガル語（ベータ）',
        spanish: {
          __text__: 'スペイン語',
          latin: {
            america: 'スペイン語 - ラテンアメリカ',
            'america—beta': 'スペイン語 - ラテンアメリカ（ベータ）',
          },
        },
        'spanish—beta': 'スペイン語（ベータ）',
        swedish: 'スウェーデン語',
        tamil: 'タミル語',
        chinese: '中国語',
        russian: 'ロシア語',
        turkish: 'トルコ語',
        catalan: 'カタルニア語',
        arabic: {
          __text__: 'アラビア語',
          algeria: 'アラビア語 - アルジェリア',
          bahrain: 'アラビア語 - バーレーン',
          egypt: 'アラビア語 - エジプト',
          iraq: 'アラビア語 - イラク',
          israel: 'アラビア語 - イスラエル',
          jordan: 'アラビア語 - ヨルダン',
          kuwait: 'アラビア語 - クウェート',
          lebanon: 'アラビア語 - レバノン',
          mauritania: 'アラビア語 - モーリタニア',
          morocco: 'アラビア語 - モロッコ',
          oman: 'アラビア語 - オマーン',
          qatar: 'アラビア語 - カタール',
          saudi: {
            arabia: 'アラビア語 - サウジアラビア',
          },
          palestine: 'アラビア語 - パレスチナ',
          syria: 'アラビア語 - シリア',
          tunisia: 'アラビア語 - チュニジア',
          united: {
            arab: {
              emirates: 'アラビア語 - アラブ首長国連邦',
            },
          },
          yemen: 'アラビア語 - イエメン',
        },
        indonesian: 'インドネシア語',
        finnish: 'フィンランド語',
        vietnamese: 'ベトナム語',
        hebrew: 'ヘブライ語',
        ukrainian: 'ウクライナ語',
        greek: 'ギリシャ語',
        malay: 'マレー語',
        czech: 'チェコ語',
        romanian: 'ルーマニア語',
        hungarian: 'ハンガリー語',
        thai: 'タイ語',
        urdu: 'ウルドゥー語',
        croatian: 'クロアチア語',
        bulgarian: 'ブルガリア語',
        lithuanian: 'リトアニア語',
        latin: 'ラテン語',
        maori: 'マオリ語',
        malayalam: 'マラヤーラム語',
        welsh: 'ウェールズ語',
        slovak: 'スロバキア語',
        telugu: 'テルグ語',
        persian: 'ペルシャ語',
        latvian: 'ラトビア語',
        bengali: 'ベンガル語',
        serbian: 'セルビア語',
        azerbaijani: 'アゼルバイジャン語',
        slovenian: 'スロベニア語',
        kannada: 'カンナダ語',
        estonian: 'エストニア語',
        macedonian: 'マケドニア語',
        breton: 'ブルターニュ語',
        basque: 'バスク語',
        icelandic: 'アイスランド語',
        armenian: 'アルメニア語',
        nepali: 'ネパール語',
        mongolian: 'モンゴル語',
        bosnian: 'ボスニア語',
        kazakh: 'カザフ語',
        albanian: 'アルバニア語',
        swahili: 'スワヒリ語',
        galician: 'ガリシア語',
        marathi: 'マラーティー語',
        punjabi: 'パンジャブ語',
        sinhala: 'シンハラ語',
        khmer: 'クメール語',
        shona: 'ショナ語',
        yoruba: 'ヨルバ語',
        somali: 'ソマリ語',
        afrikaans: 'アフリカーンス語',
        occitan: 'オック語',
        georgian: 'ジョージア語',
        belarusian: 'ベラルーシ語',
        tajik: 'タジク語',
        sindhi: 'シンド語',
        gujarati: 'グジャラート語',
        amharic: 'アムハラ語',
        yiddish: 'イディッシュ語',
        lao: 'ラオ語',
        uzbek: 'ウズベク語',
        faroese: 'フェロー語',
        haitian: {
          creole: 'ハイチ・クレオール語',
        },
        pashto: 'パシュトー語',
        turkmen: 'トルクメン語',
        maltese: 'マルタ語',
        sanskrit: 'サンスクリット語',
        luxembourgish: 'ルクセンブルク語',
        burmese: 'ビルマ語',
        tibetan: 'チベット語',
        tagalog: 'タガログ語',
        malagasy: 'マラガシ語',
        assamese: 'アッサム語',
        tatar: 'タタール語',
        hawaiian: 'ハワイ語',
        lingala: 'リンガラ語',
        hausa: 'ハウサ語',
        bashkir: 'バシキール語',
        javanese: 'ジャワ語',
        sundanese: 'スンダ語',
      },
    },
    big: {
      sequence: {
        info: {
          message:
            'シーケンスには多くのクリップが含まれています！読み込みに時間がかかる可能性があります。必要なクリップのみを選択し、別のシーケンスでカットしてください。',
        },
      },
    },
    homepage: {
      title: 'ホームページ',
      comming: {
        soon: '近日公開！',
      },
    },
    autozoom: {
      dynamism: {
        __text__: 'ダイナミズム',
        normal: 'ノーマル',
        high: '高',
        extreme: '極端',
        custom: 'カスタム:',
      },
      button: {
        __text__: 'ズームを適用',
        message: {
          step1: 'AutoZoomを準備中...',
          step2: '音声データを抽出中...',
          step3: 'ズーム間隔を計算中...',
          step4: 'ズーム適用中: {current} / {max}...',
        },
      },
      success: '成功！ズーム効果が正常に適用されました。',
      info: {
        text: 'AutoZoomは、選択したクリップに音声レベルに基づいた動的なズーム効果を追加します。',
      },
      invalid: {
        selection: {
          screen: {
            title: 'タイムライン内で音声とビデオクリップを選択してください',
            text1: 'タイムラインクリップを選択してAutoZoomを適用してください。',
          },
        },
      },
      anchor: {
        title: 'アンカーポイントを設定',
        helper:
          'アンカーポイントはズームが焦点を合わせる場所です。カラー付きボックスは最大および最小のズーム効果を示します。',
      },
      dual: {
        range: {
          title: 'ズーム係数範囲を定義',
          helper:
            'ズームレベルは、これらの値とクリップの最大音量に基づいています。',
        },
      },
      preview: {
        on_indicator: 'インジケータに中心を合わせてプレビュー',
        error: {
          message: 'プレビューの読み込みエラー',
          button: '再試行',
        },
      },
      min: {
        zoom: {
          title: '最小ズーム期間',
          helper: 'ズーム効果はこの期間より短くなりません。',
        },
      },
      max: {
        zoom: {
          title: '最大ズーム期間',
          helper: 'ズーム効果はこの期間を超えることがありません。',
        },
      },
      constraint: {
        zoom: {
          title: 'クリップにズームを限定',
          helper:
            '有効にした場合、ズーム効果は個々のクリップに限定されます。無効にした場合、選択全体にわたります。',
        },
      },
      zoom: {
        types: {
          __text__: 'ズームスタイル',
          helper:
            'スムーズ（緩やかなズーム）とダイナミック（即時ズーム）から選択します。',
        },
        coverage: {
          __text__: 'ズーム全体のカバレッジ',
          helper: '適用ズームの割合。',
        },
      },
      smooth: {
        __text__: 'スムーズ',
        zoom: {
          __text__: 'スムーズズーム',
          coverage: 'スムーズズームカバレッジ',
          nervousness: {
            __text__: 'ナーバスネス',
            helper:
              '低い値はゆっくりした穏やかなズームを、高い値は速い強烈なズームをもたらします。',
          },
        },
      },
      dynamic: {
        __text__: 'ダイナミック',
        zoom: {
          __text__: 'ダイナミックズーム',
          coverage: 'ダイナミックズームカバレッジ',
        },
      },
    },
    zoom: {
      preset: {
        title: 'デフォルトプリセット',
        calm: '落ち着いている',
        paced: 'ペースが良い',
        energetic: 'エネルギッシュ',
        hyperactive: 'ハイペース',
      },
    },
    repeat: {
      info: {
        banner:
          'AutoCut Repeatは繰り返しセグメントを削除します。ハサミを使ってカットまたはキープオプションを切り替えて、精度を高めます。',
      },
      text: {
        step: {
          edit: {
            transcript: 'トランスクリプトを修正',
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title:
              'AutoCut Repeat用にタイムラインオーディオ（およびオプションビデオ）クリップを選択。',
            text1:
              'AutoCut Repeat用にタイムラインオーディオ（およびオプションビデオ）クリップを選択。',
          },
        },
      },
      select: {
        algorithm: 'アルゴリズムを選択',
      },
      precise: {
        algorithm: {
          info: '私たちの新しい効率的なアルゴリズムは、ビデオ1分につき約1分で処理します。',
        },
      },
      fast: {
        algorithm: '迅速',
      },
      toggle: {
        cut: {
          button: {
            text: 'カットを切り替え',
          },
        },
      },
      play: {
        button: {
          text: '再生',
        },
      },
    },
    caption: {
      step: {
        customization: 'キャプションをカスタマイズ',
        script: 'クリップと言語を選択',
        transcription: 'トランスクリプトを編集',
      },
      script: {
        transcript: {
          part: 'トランスクリプト',
        },
        sequence: {
          part: 'シーケンス',
          name: '名前',
          track: {
            clip: {
              unit: {
                __text__: '{nbClips}クリップ | {from} → {to}',
                detail: '{clipName} | {from} → {to}',
              },
            },
          },
        },
      },
      transcript: {
        action: 'キャプションを追加',
        coming: {
          soon: 'キャプションエディタは近日中に登場！現在はタイムライン内のキャプションを選択し、Essential Graphicsパネルの編集タブで編集してください。',
        },
        transcription: {
          part: 'トランスクリプション',
        },
        info: 'ここはキャプションエディタです - オプションとしてダヴィンチリゾルブのタイムラインでキャプションを直接編集できます。',
        add: {
          emojis: {
            cta: 'Emojiを作成',
          },
        },
      },
      text: {
        noClip: {
          title: '字幕用にクリップを選択',
          no_selection:
            'AutoCutが反応しない場合、DaVinci Resolveを再起動してみてください。',
        },
      },
      process: {
        transcript: {
          button: '処理',
        },
      },
      customization: {
        info: 'AutoCaptionはベータ版です。2023年の11月10日までに完成を予定しています。',
        back: {
          button: 'トランスクリプトを削除して戻る',
        },
        example: {
          __text__:
            'プレビュー <opacity50><i>キャプション/Emojiの位置を調整するにはドラッグ</i></opacity50>',
          text: 'AutoCaptionは、プレビューで見える字幕を追加します。AutoCaptions内でオプションをカスタマイズし、必要に応じてトランスクリプトを編集してください。キャプションはすぐにタイムラインに追加されます。',
        },
        animation: {
          __text__: 'アニメーション',
          helper: 'テキストのアニメーション効果を制御',
          color: {
            __text__: 'ハイライト <color>オーディオと</color>',
            text: {
              color: 'テキストカラー',
            },
            before: {
              highlight: {
                color: 'プレハイライトカラー',
              },
            },
            after: {
              highlight: {
                color: 'ポストハイライトカラー',
              },
            },
          },
          advanced: {
            color: {
              options: '高度なカラーオプション',
            },
          },
          word: {
            box: 'テキスト <color>ボックス</color>',
          },
          bold: {
            with: {
              text: 'テキストは<b>太字</b>です',
            },
          },
          floating: {
            text: 'テキストは浮いています',
          },
        },
        transition: {
          __text__: 'トランジション',
          helper: 'キャプションの移行を管理する;例の通りの期間ではない。',
          effect: {
            zoomIn: 'ズームイン',
            blurIn: 'ブラーイン',
          },
        },
        outline: {
          __text__: 'アウトライン',
          helper: 'アウトラインスタイルを管理する',
          color: 'アウトラインカラー',
          width: 'アウトライン幅',
        },
        font: {
          __text__: 'フォント',
          helper: 'フォントスタイルを管理する',
          font: 'フォント名',
          help: '新しいフォントファミリーを追加する方法',
          size: 'フォントサイズ',
          weight: 'フォントの重さ',
          color: 'フォントカラー',
        },
        formating: {
          __text__: 'テキストフォーマット',
          helper: 'テキストフォーマットオプションを制御',
          ponctuation: '句読点を削除',
          uppercase: '大文字',
          bold: '太字',
          italic: 'イタリック',
          glow: {
            __text__: 'グロー',
            color: 'グローカラー',
            intensity: 'グローの強度',
          },
        },
        transform: {
          __text__: '変形',
          x: '位置X',
          y: '位置Y',
          helper: 'キャプションの位置と回転を調整する',
          position: {
            __text__: '垂直位置',
            emoji: 'Emojiの垂直位置',
            info: 'プレビューでテキストを移動',
          },
          rotation: '回転',
        },
        multiple: {
          lines: '複数行',
        },
        box: {
          __text__: 'ボックス',
          helper: 'テキストボックスのスタイリングオプション',
        },
        boxes: {
          padding: {
            x: '水平のパディング',
            y: '垂直のパディング',
          },
          opacity: 'ボックス不透明度',
          radius: '角の半径',
          color: 'ボックスカラー',
        },
        emojis: {
          __text__: 'Emoji',
          helper: 'AI生成のEmoji',
          size: 'Emojiサイズ',
        },
        shadow: '影',
        transcript: 'トランスクリプト',
        action: '進む',
        select: {
          chunk: '編集またはカスタマイズするトランスクリプトセグメントを選択',
        },
        delete: {
          caption: {
            modal: {
              title: 'キャプション削除の確認',
              cancel: 'いいえ、保持します',
              confirm: 'はい、削除します',
            },
          },
        },
        get: {
          presets: {
            number: {
              error:
                'プリセット数の取得エラー。Discordまたはcontact@autocut.comまでご連絡ください',
            },
            error:
              'プリセットの取得エラー。Discordまたはcontact@autocut.comまでご連絡ください',
          },
          public: {
            presets: {
              error:
                'パブリックプリセットの取得エラー。Discordまたはcontact@autocut.comまでご連絡ください',
            },
          },
        },
        presets: {
          title: 'プリセット',
          save: {
            new: {
              preset: '現在の設定をプリセットとして保存',
            },
          },
          view: {
            more: 'もっと見る',
            less: '少なくする',
          },
        },
        save: {
          preset: {
            info: {
              text: 'これらの設定をプリセットとして保存して、今後使用してください！',
            },
          },
        },
      },
      editor: {
        title: 'キャプションエディタ',
        description: {
          title: '編集するキャプションを選択',
        },
        chunk: {
          low: {
            confidence: {
              tooltip: '信頼度の低い単語',
            },
          },
          merge: {
            tooltip: 'チャンクをマージ',
          },
          split: {
            tooltip: 'チャンクを分割',
          },
        },
        sound: {
          play: 'サウンド再生を有効にする',
        },
      },
      fontSelect: {
        notFoundFontModal: {
          title: 'フォントが見つかりません',
          message:
            'フォント<i><b>{fontLabel}</b></i>は利用できません。デフォルトのフォントが使用されます。',
          helper:
            'このチュートリアルを使用して新しいフォントを追加してください。',
        },
      },
    },
    version: {
      not: {
        supported: 'サポートされていないバージョン',
      },
    },
    transcript: {
      usage: {
        unit: '分',
      },
      upgrade: {
        button: 'AIにアップグレードして{mode}',
      },
      too: {
        short: {
          error:
            'トランスクリプトが短すぎるか、言語がサポートされていない可能性があります。{/br}問題が続く場合は、contact@autocut.comにご連絡ください。',
        },
      },
    },
    feature: {
      request: {
        __text__: '機能リクエスト',
        form: {
          placeholder:
            '機能リクエストを説明し、より考慮されるようにしてください。',
        },
        screenshot: 'スクリーンショットやビデオを添付して明確にしてください:',
        button: '機能リクエストを送信',
        success: {
          title: '送信完了！ 🥳',
          body: 'AutoCutの改善にご協力ありがとうございます！リクエストを迅速に確認いたします。',
        },
        error: {
          title: 'おっと、問題が発生しました... 🤕',
          body: 'ご不便をおかけして申し訳ありません。後でもう一度お試しいただくか、contact@autocut.comまでお問い合わせください。',
        },
      },
    },
    with: {
      emojis: 'Emojis付き',
    },
    resize: {
      invalid: {
        selection: {
          screen: {
            title: 'タイムラインにイン＆アウトポイントを設定',
            text1:
              'AutoResizeを使用するには、タイムラインにイン＆アウトポイントを設定してください。',
            cta: 'シーケンス全体のためにイン＆アウトポイントを設定',
          },
        },
      },
      text: {
        info: 'AutoResizeは、シーケンスをソーシャルメディアフォーマットに最適化してリフレームします。',
      },
      in_out: {
        step: {
          title: 'イン/アウトポイント',
          helper:
            'イン＆アウトポイントが希望するセクションと一致していることを確認します。',
        },
      },
      preset: {
        step: {
          title: 'プリセット',
          helper:
            'シーケンスをリサイズするためのプリセットを選択するか、独自のプリセットを作成します。以下の寸法を表示します。',
        },
        undefined: 'プリセットを選択してください。',
      },
      watermark: {
        step: {
          title: '透かし',
          helper: 'シーケンスの右下隅に透かしを追加します。',
          text: '透かしをアップロード',
        },
      },
      button: 'ソーシャルクリップを生成',
      reframe: {
        undefined: '「被写体をフォロー」の速度を選択してください。',
        step: {
          title: '被写体をフォロー',
          helper: 'カメラの速度を選択して被写体を追従します。',
          slower: '遅め',
          default: '普通',
          faster: '速い',
        },
      },
      backup: 'シーケンスをバックアップ中...',
      create: {
        new: {
          sequence: '新しいシーケンスを作成中...',
        },
      },
      resizing: {
        sequence: '新しいシーケンスをリサイズ中...',
      },
      adding: {
        watermark: '透かしを追加中...',
      },
      cleaning: {
        sequence: 'シーケンスをクリーンアップ中...',
      },
    },
    broll: {
      invalid: {
        selection: {
          screen: {
            title: 'B-Rollのベースクリップを選択',
            text1:
              'AutoB-Roll用にオーディオを含むクリップを少なくとも1つ選択。',
          },
        },
      },
      text: {
        info: 'AutoB-RollはAIを使用して音声を分析し、関連するPexelsB-Rollsを自動的に追加します。',
      },
      duration: {
        step: {
          title: 'B-Rollの持続時間',
          minimum: {
            title: '最小B-Rollの持続時間',
            helper: '各B-Rollの最小持続時間。',
          },
          maximum: {
            title: '最大B-Rollの持続時間',
            helper: '各B-Rollの最大持続時間。',
          },
        },
      },
      choice: {
        step: {
          title: 'B-Rollの選択',
        },
      },
      no_broll: {
        screen: {
          title:
            'トランスクリプトが処理されましたが、B-Rollは見つかりませんでした。',
          text1:
            'AutoB-Rollを使用するには、音声を含むクリップを選択してください。',
          text2:
            'トランスクリプト言語を確認し、オーディオトラックがミュートされていないことを確認してください。',
        },
      },
      button: {
        __text__: 'B-Rollsを追加',
        next: '次へ',
      },
      exporting: '選択したオーディオクリップをエクスポート中...',
      analyse: '音声を分析中...',
      getting: {
        broll: 'B-Rollを取得中...（数分かかる場合があります）',
      },
      transcript: {
        finished: 'トランスクリプトが生成されました',
      },
      step: {
        parameters: 'B-Rollの設定',
        language: 'クリップと言語を選択',
        brolls: 'B-Rollの選択を編集',
      },
      adding: {
        broll: 'B-Rollsを追加中...',
      },
      restore: 'シーケンスを復元中...',
      backup: 'シーケンスをバックアップ中...',
      credit: {
        modal: {
          title: 'B-Roll クレジット:',
          provided: {
            by: '提供元: Pexels',
          },
          link: 'リンク',
          button: 'クリップボードにコピー',
        },
      },
      search: {
        modal: {
          title: 'B-Rollを選択',
          input: {
            placeholder: 'B-Rollsを検索',
          },
          searching: 'B-Rollsを検索中...',
        },
      },
    },
    discordCta: {
      title: 'Discord',
    },
    double: {
      click: {
        to_edit: '編集するにはダブルクリック',
      },
    },
    swear: {
      words: {
        invalid: {
          selection: {
            screen: {
              title: 'プロファニティフィルタリング用にクリップを選択',
              text1:
                'AutoProfanity Filterのために音声付きのクリップを選択してください。',
            },
          },
        },
        text: {
          info: 'AutoProfanity FilterはAIを使用して不適切な言語を検出し、ビープ音で自動的にフィルターします。',
        },
        mute: 'ミュート',
        button: '不適切な言葉を削除する',
        audio: {
          export: 'オーディオをエクスポート中...',
          analyse: 'オーディオを分析中...',
          insert: 'ビープ音を挿入中...',
          filter: '音量調整を適用中...',
        },
        script: {
          transcript: {
            part: 'トランスクリプト',
          },
        },
      },
    },
    bleep: {
      selection: {
        step: {
          title: 'ビープ音エフェクトを選択',
          helper: 'ビープ音エフェクトを選択',
        },
      },
    },
    create: {
      format: {
        modal: {
          title: '新しいフォーマットを作成',
          button: {
            __text__: '作成',
            disabled: '名前が使用されています',
          },
          name: '名前',
          width: '幅',
          height: '高さ',
        },
      },
    },
    process: {
      modal: {
        warning: '問題を防ぐためにDaVinci Resolveでの変更を避けてください。',
        generating: {
          emojis: 'トランスクリプトからemojisを作成中...',
        },
        formatting: {
          numbers: 'トランスクリプト内の数値をフォーマット中...',
        },
      },
    },
    backup: {
      modal: {
        title: 'AutoCut バックアップ',
        text: {
          '1': '処理前にバックアップが行われます。「AutoCut-Backup」フォルダ内にあります。',
          '2': '必要に応じて使用してください。',
        },
      },
    },
    confirm: {
      modal: {
        title: '本当にいいですか？',
        confirm: {
          label: '確認',
        },
        cancel: {
          label: 'キャンセル',
        },
      },
    },
    input: {
      speaker: {
        name: {
          placeholder: 'スピーカーの名前を入力...',
        },
      },
    },
    login: {
      resend: {
        email: 'メールが届いていませんか？ {trialEmail}に再送信',
      },
    },
    typeofcreation: {
      option: {
        interview: 'インタビュー',
        podcast: 'ポッドキャスト',
        youtube: 'YouTube動画',
        short: 'TikTok/ショーツ/リール',
        internal: '内部ビデオ',
        other: 'その他',
      },
    },
    jobstatus: {
      option: {
        content: {
          creator: 'コンテンツクリエーター',
        },
        video: {
          editor: {
            professional: 'プロフェッショナルビデオ編集者',
            semi: {
              professional: 'セミプロフェッショナルビデオ編集者',
            },
            beginner: '初級ビデオ編集者',
          },
        },
        student: '学生',
        other: 'その他',
      },
    },
    progress: {
      caption: {
        modal: {
          message: {
            initialization: 'キャプションを設定中...',
          },
        },
      },
    },
    stat: {
      undo: {
        modal: 'よくやった！ {minutes}分{seconds}秒で完了しました。',
      },
    },
    cancelCutModal: {
      timesaved: {
        this: {
          cut: '節約時間: <b>{minutesSaved} {minutesSaved, plural, one {分} other {分}}</b>と<b>{secondsSaved} {secondsSaved, plural, one {秒} other {秒}}</b>.',
        },
        all: {
          cuts: '総節約時間: 素晴らしい<b>{hourSaved} {hourSaved, plural, one {時間} other {時間}}</b>と<b>{minutesSaved} {minutesSaved, plural, one {分} other {分}}</b>！ 🎉',
        },
      },
    },
    selected: {
      clips: {
        helper:
          'バックグラウンドミュージックやゲームのサウンドを維持するためには、無音検出からオーディオトラックを非アクティブにしてください。特定のクリップで無音を触れずにするには、それらのクリップを選択しないでください。',
        video: '選択されたビデオトラック:',
        audio: '選択されたオーディオトラック:',
      },
    },
    free: {
      trial: {
        usage: {
          exceeded: {
            title: '無料トライアル制限に達しました',
            cta: '今すぐサブスクリプション',
            text: '1000 AIフィーチャー分を超えました。{br}AutoCut AIを使用し続けるにはサブスクライブしてください。',
          },
        },
      },
    },
    paid: {
      usage: {
        exceeded: {
          title: 'トランスクリプション制限に達しました',
          text: '6000 AIフィーチャー分を超えました。{br}サポートについてはお問い合わせください。',
        },
      },
    },
    validation: {
      error: {
        ai_transitions:
          '有効なトランジションタイプを選択してください（<a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>を参照）。',
        ai_silences:
          '有効な無音処理オプションを選択してください（<a><b><embedTranslation>title_silences_step</embedTranslation></b></a>を参照）。',
        broll: {
          min: {
            max: {
              duration:
                '最大B-Roll時間は最小より大きくする必要があります（<a><b><embedTranslation>broll_duration_step_title</embedTranslation></b></a>を参照）。',
            },
          },
          minimumBRollTime:
            '最小B-Roll時間は正の値でなければなりません（<a><b><embedTranslation>broll_duration_step_minimum_title</embedTranslation></b></a>を参照）。',
          maximumBRollTime:
            '最大B-Roll時間は正の値でなければなりません（<a><b><embedTranslation>broll_duration_step_maximum_title</embedTranslation></b></a>を参照）。',
        },
        caption: {
          position:
            'キャプションはプレビューエリア内に留める必要があります（<a><b><embedTranslation>caption_customization_transform_position</embedTranslation></b></a>を参照）。',
          emoji: {
            position:
              'Emojiはプレビューエリア内に留める必要があります（<a><b><embedTranslation>caption_customization_transform_position_emoji</embedTranslation></b></a>を参照）。',
          },
          text: {
            fontSize:
              'フォントサイズは正の数でなければなりません（<a><b><embedTranslation>caption_customization_font_size</embedTranslation></b></a>を参照）。',
          },
          outline: {
            width:
              'アウトラインの幅は正の数でなければなりません（<a><b><embedTranslation>caption_customization_outline_width</embedTranslation></b></a>を参照）。',
          },
          transition: {
            effects:
              'キャプションのトランジション効果を有効なものとして選択してください（<a><b><embedTranslation>caption_customization_transition</embedTranslation></b></a>を参照）。',
          },
          emojis: {
            size: 'Emojiサイズは正の値でなければなりません（<a><b><embedTranslation>caption_customization_font_size</embedTranslation></b></a>を参照）。',
          },
          formating: {
            nbLines:
              'フォーマットのために少なくとも1行を選択してみてください（<a><b><embedTranslation>caption_customization_formating_nbLines</embedTranslation></b></a>を参照）。',
          },
          title: {
            too: {
              short: 'タイトルは少なくとも5文字である必要があります。',
              long: 'タイトルは25文字を超えてはいけません。',
            },
            no_space:
              'タイトルには少なくとも単語2つ、スペースで分けられている必要があります。',
          },
        },
        font: {
          fontFamily:
            'フォントファミリーが必要です（<a><b><embedTranslation>caption_customization_font_font</embedTranslation></b></a>を参照）。',
          label:
            'フォントを選択してください（<a><b><embedTranslation>caption_customization_font_font</embedTranslation></b></a>を参照）。',
        },
        languageOfTranscription:
          'トランスクリプション言語を選択してください（<a><b><embedTranslation>language_selection_step_title_helper</embedTranslation></b></a>を参照）。',
        cameras: {
          speakerIds:
            '各カメラは少なくとも1つのスピーカーを割り当てなければなりません（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>を参照）。',
          label:
            '各カメラはビデオトラックを割り当てなければなりません（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>を参照）。',
        },
        podcast: {
          minimum: {
            cam: {
              time: '最小カメラ時間は正の値でなければなりません（<a><b><embedTranslation>text_step1_podcast_minimum</embedTranslation></b></a>を参照）。',
            },
          },
          maximum: {
            cam: {
              time: '最大カメラ時間は正の値でなければなりません（<a><b><embedTranslation>text_step1_podcast_maximum</embedTranslation></b></a>を参照）。',
            },
          },
          deleteUnusedClips:
            '無音を削除またはミュートするを選択してください（<a><b><embedTranslation>title_podcast_silences_step</embedTranslation></b></a>を参照）。',
          speakers: {
            cameras:
              '各スピーカーはカメラに1つ割り当てられていなければなりません（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>を参照）。',
            audio:
              '各スピーカーにはオーディオトラックが割り当てられていなければなりません（<a><b><embedTranslation>header_speakers_track</embedTranslation></b></a>を参照）。',
          },
          cameras: {
            __text__:
              '各カメラは自分自身のトラックを持たなければなりません（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>を参照）。',
            length:
              '少なくとも2つの個別のカメラが必要です（<a><b><embedTranslation>header_cameras_name</embedTranslation></b></a>を参照）。',
          },
        },
        resize: {
          autoReframePreset: {
            required:
              'Auto Reframe Presetが必要です（<a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>を参照）。',
            invalid:
              'プリセットオプション：遅め、デフォルト、速い（<a><b><embedTranslation>resize_reframe_step_title</embedTranslation></b></a>を参照）。',
          },
          watermark:
            '有効な透かし形式：JPEG、PNG、またはWebP。（<a><b><embedTranslation>resize_watermark_step_title</embedTranslation></b></a>を参照）。',
          preset: {
            name: 'プリセット名が必要です。',
            width: '幅は少なくとも1ピクセルである必要があります。',
            height: '高さは少なくとも1ピクセルである必要があります。',
          },
        },
        rgb: 'RGB値は0から255の間でなければなりません。',
        silence: {
          noiseLevel: {
            type: 'ノイズレベルは数値でなければなりません（<a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>を参照）。',
            nonpositive:
              'ノイズレベルは正であってはいけません（<a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>を参照）。',
            integer:
              'ノイズレベルは整数でなければなりません（<a><b><embedTranslation>text_Step3_Noise</embedTranslation></b></a>を参照）。',
          },
          minTimeOfSilence: {
            type: '無音の持続時間は数値でなければなりません（<a><b><embedTranslation>text_Step1_Silences</embedTranslation></b></a>を参照）。',
          },
          maxTimeOfSilence: {
            type: '無音の持続時間は数値でなければなりません（<a><b><embedTranslation>text_Step1_Talks</embedTranslation></b></a>を参照）。',
          },
          marginBefore: {
            type: '前のパディングは数値でなければなりません（<a><b><embedTranslation>text_Step2_Before</embedTranslation></b></a>を参照）。',
          },
          marginAfter: {
            type: '後のパディングは数値でなければなりません（<a><b><embedTranslation>text_Step2_After</embedTranslation></b></a>を参照）。',
          },
          transitions:
            '有効なトランジションタイプを選択してください（<a><b><embedTranslation>text_transition_step_title</embedTranslation></b></a>を参照）。',
          silences:
            '有効な無音処理オプションを選択してください（<a><b><embedTranslation>title_silences_step</embedTranslation></b></a>を参照）。',
        },
        swearWord: {
          bleepFile:
            'バーブ音効果を選択してください（<a><b><embedTranslation>bleep_selection_step_title_helper</embedTranslation></b></a>を参照）。',
        },
        textBox: {
          xPadding:
            '水平パディングは数値でなければなりません（<a><b><embedTranslation>caption_customization_boxes_padding_x</embedTranslation></b></a>を参照）。',
          yPadding:
            '垂直パディングは数値でなければなりません（<a><b><embedTranslation>caption_customization_boxes_padding_y</embedTranslation></b></a>を参照）。',
          opacity: {
            min: '不透明度は少なくとも0％である必要があります（<a><b><embedTranslation>caption_customization_boxes_opacity</embedTranslation></b></a>を参照）。',
            max: '不透明度は100%を超えてはいけません（<a><b><embedTranslation>caption_customization_boxes_opacity</embedTranslation></b></a>を参照）。',
          },
        },
        anchor:
          'アンカーはプレビューエリア内にある必要があります（<a><b><embedTranslation>autozoom_anchor_title</embedTranslation></b></a>を参照）。',
        zoom: {
          autoZoomMinCoef: {
            min: '最小ズーム係数は1より大きくなければなりません（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>を参照）。',
            max: '最小ズーム係数は2より小さい必要があります（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>を参照）。',
          },
          autoZoomMaxCoef: {
            min: '最大ズーム係数は1より大きくなければなりません（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>を参照）。',
            max: '最大ズーム係数は2より少ない必要があります（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>を参照）。',
          },
          minZoomTime: '最小ズーム期間は正の秒数でなければなりません。',
          maxZoomTime: '最大ズーム期間は正の秒数でなければなりません。',
          dynamicZoomPercentage: {
            min: 'ズーム割合は0％を超えなければなりません',
            max: 'ズーム割合は100％未満でなければなりません',
          },
          coef: '最大ズーム係数は最小より大きくなければなりません（<a><b><embedTranslation>autozoom_max_zoom</embedTranslation></b></a>を参照）。',
          time: '最大ズーム時間は最小時間より大きくなければなりません。',
          dynamicMinZoomIntensity:
            'ダイナミックズームの強度は0-100％でなければなりません（<a><b><embedTranslation>autozoom_dynamic_zoom_coverage</embedTranslation></b></a>を参照）。',
          smoothZoomPercentage:
            'スムーズズームカバレッジは0-100％でなければなりません（<a><b><embedTranslation>autozoom_smooth_zoom_coverage</embedTranslation></b></a>を参照）。',
          nervousness:
            'ナーバスネスのレベルは0-100％でなければなりません（<a><b><embedTranslation>autozoom_smooth_zoom_nervousness</embedTranslation></b></a>を参照）。',
          totalZoomPercentage:
            'ズーム全体のカバレッジは0-100％でなければなりません（<a><b><embedTranslation>autozoom_zoom_coverage</embedTranslation></b></a>を参照）。',
          types: {
            at_least: {
              one: {
                true: '少なくとも1つのズームタイプを選択してください（<a><b><embedTranslation>autozoom_zoom_types</embedTranslation></b></a>を参照）。',
              },
            },
          },
        },
        repeat: {
          algorithm: '無効なアルゴリズムが選択されました。',
        },
      },
    },
    key: {
      expired: {
        modal: {
          title: '支払い問題',
          button: {
            recover: 'サブスクリプションを回復する',
          },
          to_continue:
            'お支払いの問題でサブスクリプションが<b>{status}</b>されました。続行するには:',
          deactivated: '無効化された',
          deleted: '削除された',
          recover: {
            list: {
              click:
                '下のボタンをクリックしてサブスクリプションを回復するか、お支払いの詳細を含むメールを確認してください。',
              re_activated:
                'お支払いが更新されると<b>キーは即座に再アクティブ化されます</b>。',
            },
          },
          new: {
            list: {
              website:
                'AutoCutにアクセス: <span>https://www.autocut.com/#pricing</span>',
              subscribe: '新しいライセンスキーを購入',
            },
          },
          support:
            'ご不明な点がある場合は、<span>contact@autocut.com</span>にお問い合わせください',
          thanks: 'ありがとうございました！',
        },
        moda: {
          button: {
            new: '新しいサブスクリプションを購入',
          },
        },
      },
    },
    gaming: {
      trial: {
        modal: {
          title: 'AutoCutの旅を開始',
          header: {
            days: {
              left: '14日間のトライアルを{completedElements}の追加機能付きで開始。 {daysLeft}日を探索！',
            },
            more: {
              days: 'AutoCutを完全に体験するためにさらに<b>{bonusDays}日</b>を続行。',
            },
          },
          timeline: {
            left: '14日間の無料トライアル',
            right: '24日間の無料トライアル',
          },
          task: {
            list: {
              title: 'タスクの進行状況: {completed}/{maximum}が完了',
            },
          },
        },
      },
    },
    reload: {
      autocut: {
        modal: {
          title: 'リロードが必要',
          cta: 'すべての拡張を閉じる',
          text: '複数のAutoCutインスタンスが検出されました。下のボタンをクリックしてそれらを閉じ、問題を防ぎます。',
        },
      },
    },
    'key-already-activated':
      'キーは他のデバイスでアクティブです。AutoCutダッシュボード（https://www.autocut.com/en/）でリンクを解除して再試行してください。',
    'key-does-not-exist':
      'キーが見つかりません。キーを確認し、再試行してください。',
    unsupported: {
      version: {
        modal: {
          title: {
            __text__: 'サポートされていないDaVinci Resolveバージョン',
            '24.4.0':
              'DaVinci Resolve 24.3にダウングレードまたは24.5にアップグレード',
          },
          cta: '確認',
          text: {
            __text__:
              'サポートされていないDaVinci Resolveバージョン（{version}）が検出されました。 エクスペリエンスに影響する可能性があります。 合理的なAutoCut性能を得るには、<b>最新の安定したリリース</b>を使用。',
            '24.4.0':
              'サポートされていないDaVinci Resolveバージョン（{version}）。 AutoCutを使用するには24.5にアップグレードするか、24.3にダウングレードしてください。',
          },
        },
      },
      rendering: {
        engine: {
          title: 'サポートされていないレンダリングエンジン',
          modal: {
            text: '現在のレンダリングエンジンはサポートされていません。AutoCaptionsおよびAutoZoomが機能しません。{br}{br} プロジェクト設定でGPU加速を選択してください。',
          },
        },
      },
    },
    handshake: {
      restart: {
        modal: {
          title: '再起動が必要',
          cta: 'すべての拡張を閉じる',
          text: '複数のAutoCutインスタンスが検出されました。下のボタンをクリックしてそれらを閉じ、問題を防ぎます。',
        },
      },
    },
    disabled: {
      features: {
        info: '選択した言語/フォントの組み合わせが次の機能を無効にします: <b>{features}</b>。これらの機能はキャプションに使用できません。',
        tips: 'キャラクターサポートの問題の可能性があります。これらの機能を有効にするために、フォントまたは言語を変更してください。',
      },
    },
    upgrade: {
      to: '{plan}にアップグレード',
    },
    deepgram: {
      error: {
        retry: '異なるAIモデルでトランスクリプションを再試行してください。',
        modal: {
          title: '翻訳エラー',
          cta: 'Discordに参加',
          text: '音声翻訳中にエラーが発生しました。これは音声品質または言語サポートが原因である可能性があります。 {br}Discordまたはcontact@autocut.comへ報告してください。',
        },
      },
    },
    completing: {
      preset: {
        preview: 'プリセットプレビューを完了中... もう少しで完了です。',
      },
    },
    chapters: {
      preview: {
        error:
          'プレビューの生成エラー。タイムスタンプを調整するか、問題が続く場合はAutoCutを再起動してください。',
      },
      step: {
        language: 'クリップと言語を選択',
        editor: 'チャプターエディタ',
      },
      button: {
        chapters: 'チャプターを追加',
      },
      editor: {
        chapters: {
          title: '検出されたチャプター',
        },
        parameters: {
          title: 'チャプター設定',
        },
        download: {
          youtube: {
            chapters: {
              file: {
                download: 'YouTubeのチャプターファイルをダウンロード',
                success:
                  'チャプター.txtがPremiere Proプロジェクトに保存されました',
              },
            },
          },
        },
      },
      invalid: {
        selection: {
          screen: {
            title: 'チャプターベースのクリップを選択',
            text1: '音声を含むクリップを少なくとも1つ選択してください。',
          },
        },
      },
      process: {
        transcript: {
          button: 'チャプターを生成',
        },
      },
      adding: {
        chapters: 'チャプターを追加中...',
      },
      backup: 'シーケンスをバックアップ中...',
      analyse: '音声を分析中...',
      getting: {
        chapters: 'チャプターを生成中...',
      },
      transcript: {
        finished: 'チャプターが生成されました',
      },
      disabled: {
        language:
          '選択された言語はチャプターディテクションをサポートしていません。',
        file: {
          too: {
            long: 'クリップが制限を超えています。{limit} 分以下のクリップを使用してください。',
          },
        },
      },
    },
    time: {
      badge: {
        error: {
          wrong: {
            format: '間違った時間形式。HH：MM：SSを使用してください。',
          },
        },
      },
    },
    emoji: {
      picker: {
        animated: {
          option: 'アニメーション',
        },
        static: {
          option: '静止画',
        },
      },
    },
    viral: {
      clips: {
        invalid: {
          selection: {
            screen: {
              title: 'Viral Clip検出のためにタイムラインのクリップを選択',
              text1: '少なくとも1つの音声を含むクリップを選択。',
            },
          },
        },
        disabled: {
          language: 'この言語ではViral Clip検出はサポートされていません。',
          file: {
            too: {
              long: 'クリップが長すぎます。{limit} 分未満のクリップを選択。',
            },
          },
        },
        process: {
          transcript: {
            button: 'Viral Clipを生成',
          },
        },
        button: {
          process: 'クリップを生成',
        },
        selector: {
          title: '{number}のポテンシャル Viral Clipが検出されました',
        },
        virality: {
          score: 'Viralポテンシャルスコア',
        },
        step: {
          language: 'クリップと言語を選択',
          selector: 'Viral Clipを選択',
        },
        backup: 'シーケンスをバックアップ中...',
        analyse: '音声を分析中...',
        getting: {
          clips: 'Viral Clipを生成中...',
        },
        transcript: {
          finished: 'Viral Clipが生成されました',
        },
        adding: {
          creating: {
            clips: 'クリップを生成中...',
          },
        },
      },
      score: 'Viralスコア',
    },
    formatted: {
      numbers: {
        title: 'フォーマットされた数字',
        modal: {
          cta: 'モーダルを閉じる',
          text: '<b>{number}の単語</b>がトランスクリプト内で数値に変換されました。',
        },
      },
    },
    transcription: {
      provider: {
        step: {
          title: 'トランスクリプションモデルを選択',
          model: {
            '1': 'モデル1（デフォルト）',
            '2': 'モデル2',
          },
        },
      },
    },
  },
} as const;
export default translations;
