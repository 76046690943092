import {
  CAPTIONS_FOLDER_PATH,
  CAPTIONS_RESOURCES_FOLDER_PATH,
} from '@autocut/enums/resources.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';
import {getResourceFolderPath} from '@autocut/utils/electron/app.electron.utils';

import {getResourceConfig} from '../downloadableRessources.utils';

export const getCaptionResources = () => {
  const {path, resources} = preload();
  return {
    ...getResourceConfig({
      fileName: 'captions_resources_2.71.1.zip',
      folderPath: CAPTIONS_FOLDER_PATH,
    }),
    existCheck: async () => {
      const {existCheck} = getResourceConfig({
        fileName: CAPTIONS_RESOURCES_FOLDER_PATH,
        folderPath: CAPTIONS_FOLDER_PATH,
      })();

      return existCheck();
    },
    downloadMethod: async () => {
      const {downloadMethod} = getResourceConfig({
        fileName: 'captions_resources_2.71.1.zip',
        folderPath: CAPTIONS_FOLDER_PATH,
      })();
      const downloadedFilePath = await downloadMethod();

      const unzipPath = path.join(
        getResourceFolderPath(),
        CAPTIONS_FOLDER_PATH,
        CAPTIONS_RESOURCES_FOLDER_PATH,
      );
      await resources.unzip(downloadedFilePath, unzipPath);

      return unzipPath;
    },
  } as ResourceManagerElementConfig;
};
