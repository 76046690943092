import {ModalType} from '@autocut/enums/modals.enum';
import {setAutocutStore} from '@autocut/utils/zustand';

/**
 * This function adds a modal to the end of the queue.
 * It will not display the modal immediately, but will wait for the current modal to be closed.
 */
export const addModalToQueue = (modal: ModalType | ModalType[]) => {
  console.log('[MODAL] Adding modal to queue: ', modal);
  const modals = Array.isArray(modal) ? modal : [modal];
  setAutocutStore('ui.modalQueue', s => {
    return [...s.ui.modalQueue, ...modals];
  });
};
