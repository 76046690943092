import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {IoCreateOutline} from 'react-icons/io5';

import {useEditTranscriptModal} from '../EditTranscriptModal/EditTranscriptModalContext';
import css from './CaptionsTranscriptEditorStep.module.scss';

export const CaptionsTranscriptEditor = () => {
  const {chunks = [], textDirection} = useAutoCutStore(state => ({
    chunks: state.onGoingProcess.caption?.chunks,
    textDirection:
      state.ui.parameters.caption.languageOfTranscription.textDirection,
  }));
  const {editTranscriptModal} = useEditTranscriptModal();

  if (!chunks) return null;

  const isRtl = textDirection === 'rtl';

  return (
    <FlexContainer
      flexDirection="column"
      gap={12}
      justifyContent="center"
      alignItems="center"
      style={{
        width: '100%',
      }}
      onClick={editTranscriptModal.open}
    >
      <FlexContainer
        flexDirection={isRtl ? 'row-reverse' : 'row'}
        gap={8}
        flexWrap="wrap"
        className={css.transcriptContainer}
      >
        {chunks.map((chunk, index) => (
          <Text
            key={index}
            style={{
              display: 'flex',
              flexDirection: isRtl ? 'row-reverse' : 'row',
              alignItems: 'center',
              gap: '2px',
            }}
            variant="textXs"
            color={colors.gray300}
          >
            {chunk.text}
            {Boolean(chunk.emoji) && (
              <img
                src={chunk.emojiUrl}
                alt={chunk.emoji}
                style={{
                  width: '16px',
                  height: '16px',
                }}
              />
            )}
          </Text>
        ))}
      </FlexContainer>

      <Button
        variant="secondary"
        fullWidth={false}
      >
        <FlexContainer
          gap={4}
          alignItems="center"
        >
          <IoCreateOutline size="1.2rem" />
          <TranslatedMessage
            id={'modes_caption_steps_customization_parts_transcription_title'}
            defaultMessage="Edit transcription"
          />
        </FlexContainer>
      </Button>
    </FlexContainer>
  );
};
