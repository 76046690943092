import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {IconVolumeMax} from '@autocut/designSystem/components/atoms/Icon/media/IconVolumeMax';
import {IconVolumeMin} from '@autocut/designSystem/components/atoms/Icon/media/IconVolumeMin';
import {Slider} from '@autocut/designSystem/components/atoms/Slider/Slider';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {compute} from '@autocut/utils/compute.utils';
import {july, sendStats} from '@autocut/utils/stats.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import {debounce} from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {EventType} from 'tsparticles-engine';

export type NoiseLevelStepProps = {
  modeId?: 'silence';
};

export const NoiseLevelStep = ({modeId = 'silence'}: NoiseLevelStepProps) => {
  const intl = useIntl();
  const silenceParameters = useAutoCutStore(
    state => state.ui.parameters[modeId],
  );
  const [noiseLevel, setNoiseLevel] = useState(silenceParameters.noiseLevel);

  useEffect(() => {
    setNoiseLevel(silenceParameters.noiseLevel);
  }, [silenceParameters.noiseLevel]);

  const debouncedSetNoiseLimit = useCallback(
    debounce(v => {
      setAutocutStore(`ui.parameters.${modeId}.noiseLevel`, Number(v));
    }, 500),
    [],
  );

  const calculateNoiseLevel = async () => {
    const calculatedNoiseLevel =
      await compute.process.calculateNoiseLevel(silenceParameters);

    setAutocutStore(`ui.parameters.${modeId}.noiseLevel`, calculatedNoiseLevel);

    await sendStats({type: StatType.SILENCE_NOISE_LEVEL_CALCULATED, value: 1});
  };

  return (
    <FormSection
      title={intl.formatMessage({
        id: 'modes_silence_steps_noiseLevel_title',
        defaultMessage: 'Noise level',
      })}
      description={intl.formatMessage({
        id: 'modes_silence_steps_noiseLevel_description',
        defaultMessage: 'Adjust noise level',
      })}
    >
      <FlexContainer
        flexDirection="column"
        alignItems="center"
        gap={Spacing.s2}
      >
        <Slider
          max={0}
          min={-60}
          value={`${noiseLevel}`}
          unit="dB"
          onChange={value => {
            setNoiseLevel(Number(value));
            debouncedSetNoiseLimit(value);
          }}
          variant="valueOnKnob"
          iconLeft={() => (
            <IconVolumeMin
              size={24}
              color="gray.500"
            />
          )}
          iconRight={() => (
            <IconVolumeMax
              size={24}
              color="gray.500"
            />
          )}
        />
        <Button
          variant="tertiary"
          onClick={calculateNoiseLevel}
          fullWidth={false}
        >
          <TranslatedMessage
            id="modes_silence_steps_noiseLevel_calculate"
            defaultMessage="Calculate by AI"
          />
        </Button>
      </FlexContainer>
    </FormSection>
  );
};
