import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {compute} from '@autocut/utils/compute.utils';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {CamerasStep} from './steps/CamerasStep/CamerasStep';
import {CameraTimeStep} from './steps/CamTimeStep/CameraTimeStep';
import {PodcastFooterButton} from './steps/PodcastFooterButton/PodcastFooterButton';
import {SilentVideoClipsStep} from './steps/SilentVideoClipsStep/SilentVideoClipsStep';
import {SpeakersStep} from './steps/SpeakersStep/SpeakersStep';

export const PodcastCustomization = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [maxAudioTrackId, setMaxAudioTrackId] = useState(0);
  const [maxVideoTrackId, setMaxVideoTrackId] = useState(0);

  useEffect(() => {
    const fetchTimelineDisposition = async () => {
      const result = await compute.timeline.getDisposition();

      if (!result || result.maxAudioTrackId < 2 || result.maxVideoTrackId < 2) {
        addModalToQueue(ModalType.NotEnoughTracks);
        navigate('./timeline');
      }

      setMaxAudioTrackId(result.maxAudioTrackId);
      setMaxVideoTrackId(result.maxVideoTrackId);

      setIsLoading(false);
    };

    void fetchTimelineDisposition();
  }, []);

  if (isLoading) {
    return (
      <FlexContainer
        alignItems="center"
        justifyContent="center"
      >
        <LoaderInfinity
          isLoading
          height={400}
        />
      </FlexContainer>
    );
  }

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <PodcastFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
              handleClickWithValidation={handleClickWithValidation}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <SpeakersStep maxAudioTrackId={maxAudioTrackId} />
        <CamerasStep maxVideoTrackId={maxVideoTrackId} />
        <CameraTimeStep />
        <SilentVideoClipsStep />
      </FlexContainer>
    </ModeLayout>
  );
};
