import {OldButton} from '@autocut/components/Button/Button';
import {MessageIcon} from '@autocut/components/DiscordCta/MessageIcon/MessageIcon';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {OldTranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {useLogger} from '@autocut/hooks/useLogger';
import {useOldIntl} from '@autocut/hooks/useOldIntl';
import {autocutApi} from '@autocut/utils/http.utils';
import {displayPriorityModal} from '@autocut/utils/modal/displayPriorityModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import React, {useState} from 'react';

import css from './FeedbackModal.module.css';

export type FeedbackModalProps = {};

export const FeedbackModal = () => {
  const intl = useOldIntl();
  const {logMessage} = useLogger('FeedbackModal');

  const [feedback, setFeedback] = useState('');
  const [improve, setImprove] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);

    const postData = {
      feedback: feedback,
      improve: improve,
    };

    try {
      logMessage(logLevel.notice, 'Send review to server.', {postData});
      await autocutApi.post(`/statistics/feedback`, postData);
      logMessage(logLevel.notice, 'Review send to server.');
      setIsSent(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modal_feedback_title',
        defaultMessage: 'Feedback',
      })}
      icon={
        <div className={css.iconContainer}>
          <MessageIcon
            size={24}
            color="white"
          />
        </div>
      }
      footer={
        !isSent ? (
          <OldButton
            onClickFunction={async () => {
              await handleClick();
            }}
            isLoading={isLoading}
            disabled={feedback === '' && improve === ''}
          >
            <OldTranslatedMessage
              id="modal_feedback_button_text"
              defaultMessage="Send"
            />
          </OldButton>
        ) : (
          <OldButton
            onClickFunction={() =>
              displayPriorityModal(ModalType.FeatureRequest)
            }
          >
            <OldTranslatedMessage
              id="modal_feedback_sent_button_text"
              defaultMessage="Request feature"
            />
          </OldButton>
        )
      }
    >
      <FlexContainer
        flexDirection="column"
        gap="16px"
      >
        {!isSent ? (
          <>
            <FlexContainer
              flexDirection="column"
              gap="8px"
            >
              <h4>
                <OldTranslatedMessage
                  id="modal_feedback_input_feedback_title"
                  defaultMessage="What do you think of AutoCut?"
                />
              </h4>
              <textarea
                className="modal-text-area"
                onChange={e => {
                  setFeedback(e.target.value);
                }}
                rows={10}
                placeholder={intl.formatMessage({
                  id: 'modal_feedback_input_feedback_placeholder',
                  defaultMessage: 'Write your feedback here...',
                })}
              />
            </FlexContainer>

            <FlexContainer
              flexDirection="column"
              gap="8px"
            >
              <h4>
                <OldTranslatedMessage
                  id="modal_feedback_input_improve_title"
                  defaultMessage="Tell us what can be improved?"
                />
              </h4>
              <textarea
                className="modal-text-area"
                onChange={e => {
                  setImprove(e.target.value);
                }}
                rows={10}
                placeholder={intl.formatMessage({
                  id: 'modal_feedback_input_improve_placeholder',
                  defaultMessage: 'Write what could be improved here...',
                })}
              />
            </FlexContainer>
          </>
        ) : (
          <>
            <FlexContainer
              flexDirection="column"
              gap="8px"
            >
              <h4>
                <OldTranslatedMessage
                  id="modal_feedback_sent_title"
                  defaultMessage="Thank you for your feedback!"
                />
              </h4>
              <p>
                <OldTranslatedMessage
                  id="modal_feedback_sent_text"
                  defaultMessage="If you have any feature idea to implement in AutoCut, you can
                    send it to us :"
                />
              </p>
            </FlexContainer>
          </>
        )}
      </FlexContainer>
    </Modal>
  );
};
