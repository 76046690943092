import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {Badge} from '@autocut/designSystem/components/atoms/Badge/Badge';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {type Preset} from '@autocut/types/Preset';
import {setAutocutStore} from '@autocut/utils/zustand';

const podcastPresets: Preset<'podcast'>[] = [
  {
    id: 'calm',
    parameters: {
      minimumCamTime: 5,
      maximumCamTime: 60,
    },
  },
  {
    id: 'paced',
    parameters: {
      minimumCamTime: 4,
      maximumCamTime: 30,
    },
  },
  {
    id: 'energetic',
    parameters: {
      minimumCamTime: 3,
      maximumCamTime: 20,
    },
  },
  {
    id: 'hyperactive',
    parameters: {
      minimumCamTime: 1,
      maximumCamTime: 10,
    },
  },
];

export const CameraTimeStep = () => {
  const podcastParameters = useAutoCutStore(
    state => state.ui.parameters.podcast,
  );

  const applyPreset = (preset: Preset<'podcast'>) => {
    const updatedParameters = {
      ...podcastParameters,
      ...preset.parameters,
    };
    setAutocutStore('ui.parameters.podcast', updatedParameters);
  };

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="modes_podcast_steps_customization_steps_cameraTime_title"
          defaultMessage="Camera time durations"
        />
      }
      description={
        <TranslatedMessage
          id="modes_podcast_steps_customization_steps_cameraTime_description"
          defaultMessage="Define the minimum and maximum duration a camera will be displayed before changing."
        />
      }
    >
      <PaddedSection gap={20}>
        <FormSection
          title={
            <Text variant="textSm.semibold">
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_presets_title"
                defaultMessage="Default presets"
              />
            </Text>
          }
        >
          <FlexContainer
            flexDirection="row"
            gap={Spacing.s2}
          >
            {podcastPresets.map(preset => (
              <Badge
                hoverable={true}
                onClick={() => applyPreset(preset)}
                key={preset.id}
              >
                <TranslatedMessage
                  id={
                    `modes_podcast_steps_customization_steps_cameraTime_presets_items_${preset.id}` as TranslationKeys
                  }
                  defaultMessage="PRESET"
                />
              </Badge>
            ))}
          </FlexContainer>
        </FormSection>

        <FlexContainer
          flexDirection="row"
          alignItems="flex-end"
          gap={24}
        >
          <Input
            value={podcastParameters.minimumCamTime}
            onChange={value => {
              const numberValue = Number(value);
              const actualValue = isNaN(numberValue) ? 0 : numberValue;
              setAutocutStore(
                'ui.parameters.podcast.minimumCamTime',
                actualValue,
              );
            }}
            defaultValue="Default value"
            unit={
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_minimumCamTime_unit"
                defaultMessage="seconds"
              />
            }
            label={{
              text: (
                <Text variant="textSm.semibold">
                  <TranslatedMessage
                    id="modes_podcast_steps_customization_steps_cameraTime_minimumCamTime_title"
                    defaultMessage="Minimum camera time"
                  />
                </Text>
              ),
              helper: (
                <TranslatedMessage
                  id="modes_podcast_steps_customization_steps_cameraTime_minimumCamTime_description"
                  defaultMessage="Minimum duration (in seconds) a camera will be displayed without changing."
                />
              ),
            }}
          />
          <Input
            value={podcastParameters.maximumCamTime}
            onChange={value => {
              const numberValue = Number(value);
              const actualValue = isNaN(numberValue) ? 0 : numberValue;
              setAutocutStore(
                'ui.parameters.podcast.maximumCamTime',
                actualValue,
              );
            }}
            defaultValue="Default value"
            unit={
              <TranslatedMessage
                id="modes_podcast_steps_customization_steps_cameraTime_maximumCamTime_unit"
                defaultMessage="seconds"
              />
            }
            label={{
              text: (
                <Text variant="textSm.semibold">
                  <TranslatedMessage
                    id="modes_podcast_steps_customization_steps_cameraTime_maximumCamTime_title"
                    defaultMessage="Maximum camera time"
                  />
                </Text>
              ),
              helper: (
                <TranslatedMessage
                  id="modes_podcast_steps_customization_steps_cameraTime_maximumCamTime_description"
                  defaultMessage="Maximum duration (in seconds) a camera will be displayed before being forced to change."
                />
              ),
            }}
          />
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
