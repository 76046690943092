import hostScreenshotPath from '@autocut/assets/images/hostScriptBeta.png';
import davinciLogoPath from '@autocut/assets/images/logoDaVinci.png';
import pproLogoPath from '@autocut/assets/images/logoPPro.png';
import {DiscordIcon} from '@autocut/components/DiscordCta/DiscordIcon/DiscordIcon';
import {LoaderAutocut} from '@autocut/components/LoaderAutocut/LoaderAutocut';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {SpacingPx} from '@autocut/designSystem/enums/spacing.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {isDvAppStore} from '@autocut/utils/isDvAppStore';
import {manageError} from '@autocut/utils/manageError';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {ffmpegSetup} from '@autocut/utils/setup/ffmpeg.setup';
import {waitForHost} from '@autocut/utils/setup/host.setup';
import {preloadWait} from '@autocut/utils/setup/preload.setup';
import {resourcesSetup} from '@autocut/utils/setup/resources.setup';
import {checkForUpdates} from '@autocut/utils/setup/update.setup';
import {versionValidation} from '@autocut/utils/setup/versionValidation.setup';
import {sendStats} from '@autocut/utils/stats.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import * as Sentry from '@sentry/browser';
import {useEffect, useMemo, useState} from 'react';
import {HiOutlineAcademicCap} from 'react-icons/hi';
import {useIntl} from 'react-intl';
const hostScreenshotUrl = new URL(hostScreenshotPath, import.meta.url).href;
const pproLogoUrl = new URL(pproLogoPath, import.meta.url).href;
const davinciLogoUrl = new URL(davinciLogoPath, import.meta.url).href;
export type LaunchStepHandler = (
  setProgress?: (progress: number) => Promise<void>,
) => Promise<boolean | string>;

type LaunchStep = {
  title: TranslationKeys;
  handler: LaunchStepHandler;
  failHandler?: (error?: string) => void;
};

const getLaunchSteps = (
  showUpdateModal: () => void,
  showErrorModal: () => void,
  showInvalidVersionModal: () => void,
  setHostUpdateModal?: (modal: 'davinci' | 'ppro' | null) => void,
): LaunchStep[] => [
  {
    title: 'launchManager_steps_preload_title',
    handler: preloadWait,
  },
  {
    title: 'launchManager_steps_versionValidation_title',
    handler: versionValidation,
    failHandler: () => {
      showInvalidVersionModal();
      Sentry.captureException(
        new Error('launchManager_steps_versionValidation_fail'),
      );
    },
  },
  {
    title: 'launchManager_steps_update_title',
    handler: checkForUpdates,
    failHandler: () => {
      showUpdateModal();
      Sentry.captureException(new Error('launchManager_steps_update_fail'));
    },
  },
  {
    title: 'launchManager_steps_ffmpeg_title',
    handler: ffmpegSetup,
  },
  {
    title: 'launchManager_steps_resources_title',
    handler: resourcesSetup,
    failHandler: (error?: string) => {
      showErrorModal();
      manageError({
        error: new Error('launchManager_steps_resources_fail'),
        disableModal: true,
        additionalData: {error},
      });
    },
  },
  {
    title: 'launchManager_steps_computeIPC_title',
    handler: async setProgress => {
      // Fin install
      try {
        await preload().computeNodeIpc.connect();
        await setProgress?.(50);
        const computeVersion = await preload().computeNodeIpc.startTask<string>(
          {
            domain: {
              slug: 'version',
              target: 'common',
            },
            priority: 0,
          },
        ).promise;
        setAutocutStore('ui.versions.compute', computeVersion);
        await setProgress?.(95);
        await sendStats({
          type: StatType.SETUP_COMPLETED,
          value: 1,
        });
        await setProgress?.(100);
        return true;
      } catch {
        throw new Error('launchManager_errors_compute');
      }
    },
  },
  {
    title: 'launchManager_steps_hostIPC_title',
    handler: async setProgress => {
      // Fin install
      try {
        await preload().hostNodeIpc.connect();
        console.log('Connected to lua server');
        await setProgress?.(33);

        return waitForHost(setProgress, setHostUpdateModal);
      } catch {
        throw new Error('launchManager_errors_davinci');
      }
    },
  },
];

export const LaunchManager = ({onComplete}: {onComplete: () => void}) => {
  const intl = useIntl();
  const [stepIndex, setStepIndex] = useState(0);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showInvalidVersionModal, setShowInvalidVersionModal] = useState(false);
  const [showHostUpdateModal, setShowHostUpdateModal] = useState<
    'davinci' | 'ppro' | null
  >(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [error, setError] = useState<TranslationKeys | null>(null);

  const launchSteps = useMemo(() => {
    return getLaunchSteps(
      () => setShowUpdateModal(true),
      () => setShowErrorModal(true),
      () => {
        setShowInvalidVersionModal(true);
      },
      (modal: 'davinci' | 'ppro' | null) => {
        setShowHostUpdateModal(modal);
      },
    );
  }, []);

  useEffect(() => {
    const process = async () => {
      await sendStats({
        type: StatType.SETUP_STARTED,
        value: 1,
      });
      for (const [indexStr, step] of Object.entries(launchSteps)) {
        const index = parseInt(indexStr);
        const handlerResult = (await new Promise(async resolve =>
          resolve(
            await step.handler().catch(e => {
              manageError({
                error: e,
                disableModal: true,
                additionalData: {stepIndex: index, stepTitle: step.title},
              });
              setError(e.message);
              return JSON.stringify(e);
            }),
          ),
        )) as boolean | string;

        if (handlerResult === true) {
          if (index + 1 === launchSteps.length) {
            onComplete();
          }

          setStepIndex(index + 1);

          // Wait for the page to be updated
          await new Promise(resolve => setTimeout(() => resolve(true), 50));
        } else {
          step.failHandler?.(
            typeof handlerResult === 'string' ? handlerResult : undefined,
          );

          break;
        }
      }
    };

    const checkForAppStore = async () => {
      const isAppStore = await isDvAppStore();

      if (isAppStore) addModalToQueue(ModalType.AppStoreDv);
    };

    void process();
    void checkForAppStore();
  }, []);

  return (
    <FlexContainer
      flexDirection="column"
      justifyContent="space-between"
      gap={SpacingPx.p16}
      style={{height: '100%'}}
    >
      {showInvalidVersionModal ? (
        <Modal
          title={intl.formatMessage({
            id: 'launchManager_steps_versionValidation_modal_title',
            defaultMessage: 'Invalid version',
          })}
          withCloseButton={false}
        >
          <TranslatedMessage
            id="launchManager_steps_versionValidation_modal_body"
            defaultMessage="We detect that you are using an old version of AutoCut that is no longer functioning.{br}Please update to the new version by downloading and installing AutoCut :"
          />
          <br />
          <TranslatedMessage
            id="launchManager_steps_versionValidation_modal_link1"
            valuePamareters={{
              href: 'https://www.autocut.com/en/download/',
            }}
            defaultMessage="If you are using DaVinci, you can download it from here:"
          />
          <br />
          <TranslatedMessage
            id="launchManager_steps_versionValidation_modal_link2"
            valuePamareters={{
              href: 'https://www.autocut.com/en/download-beta/',
            }}
            defaultMessage="If you are using Premiere Pro with AutoCut v2, you can download it from here:"
          />
        </Modal>
      ) : null}
      {showErrorModal && (
        <Modal
          title={intl.formatMessage({
            id: 'launchManager_steps_resources_modal_title',
            defaultMessage: 'Something went wrong',
          })}
          footer={
            <Button
              color={colors.primary600}
              onClick={() => preload().electron.reload()}
            >
              <TranslatedMessage
                id="launchManager_steps_resources_modal_cta"
                defaultMessage="Restart AutoCut"
              />
            </Button>
          }
          withCloseButton={false}
          closeOnClickOutside={false}
        >
          <Text
            variant="textSm"
            color={colors.gray200}
          >
            <TranslatedMessage
              id="launchManager_steps_resources_modal_body"
              defaultMessage="AutoCut is unable to start, some ressources cannot be installed. Check you disk space and be sure to allow AutoCut to have access to others apps data to continue. If the problem persists, please contact us at"
            />
            <Button
              variant="tertiary"
              color={colors.gray200}
              onClick={() =>
                preload().electron.openLinkInBrowser(
                  'mailto:contact@autocut.fr',
                )
              }
              fullWidth={false}
            >
              contact@autocut.fr
            </Button>
          </Text>
        </Modal>
      )}
      {showUpdateModal ? (
        <Modal
          noHeader
          footer={
            <Button
              color={colors.primary600}
              onClick={() => preload().updater.quitAndInstall()}
            >
              <TranslatedMessage id="launchManager_steps_update_modal_cta" />
            </Button>
          }
        >
          <FlexContainer
            flexDirection="column"
            justifyContent="space-between"
            gap={8}
            style={{padding: `0px ${SpacingPx.p16}`}}
          >
            <h1 style={{textAlign: 'center', marginBottom: SpacingPx.p24}}>
              <TranslatedMessage id="launchManager_steps_update_modal_title" />
            </h1>
            <h2>
              <TranslatedMessage id="launchManager_steps_update_modal_subtitle" />
            </h2>
            <p>
              <TranslatedMessage id="launchManager_steps_update_modal_body" />
            </p>
          </FlexContainer>
        </Modal>
      ) : null}
      {showHostUpdateModal ? (
        <Modal
          noHeader
          footer={
            <Button
              color={colors.primary600}
              onClick={() => setShowHostUpdateModal(null)}
            >
              <TranslatedMessage id="globals_ok" />
            </Button>
          }
        >
          <FlexContainer
            flexDirection="column"
            justifyContent="space-between"
            gap={8}
            style={{padding: `0px ${SpacingPx.p16}`}}
          >
            <h1 style={{textAlign: 'center', marginBottom: SpacingPx.p24}}>
              <TranslatedMessage
                id={`launchManager_steps_hostIPC_modals_${showHostUpdateModal}_title`}
              />
            </h1>
            <h2>
              <TranslatedMessage
                id={`launchManager_steps_hostIPC_modals_${showHostUpdateModal}_subtitle`}
              />
            </h2>
            <p>
              <TranslatedMessage
                id={`launchManager_steps_hostIPC_modals_${showHostUpdateModal}_body`}
              />
            </p>
          </FlexContainer>
        </Modal>
      ) : null}
      {stepIndex === launchSteps.length - 1 ? (
        <FlexContainer
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={SpacingPx.p16}
          style={{margin: SpacingPx.p16, marginTop: 0}}
        >
          <h2>
            <TranslatedMessage
              id="launchManager_steps_hostIPC_title"
              defaultMessage="Installation / Update"
            />
          </h2>
          <div
            style={{
              position: 'relative',
              maxWidth: '100vw',
              padding: `${SpacingPx.p8} 0`,
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '82px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{
                  width: '55.5%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: SpacingPx.p4,
                  paddingBottom: SpacingPx.p8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: SpacingPx.p8,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 26,
                  }}
                >
                  <img
                    src={pproLogoUrl}
                    alt="ppro_logo"
                    width={25}
                  />
                  <h2>Premiere Pro</h2>
                </div>
                <p>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_ppro_part1"
                    defaultMessage="Launch AutoCut extension from :"
                  />
                </p>
                <p style={{color: 'white', fontWeight: 'bolder'}}>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_ppro_part2"
                    defaultMessage="Window > extensions > AutoCut V2"
                  />
                </p>
              </div>
              <div
                style={{
                  width: '44.5%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingBottom: SpacingPx.p8,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    gap: SpacingPx.p8,
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 26,
                  }}
                >
                  <img
                    src={davinciLogoUrl}
                    alt="davinci_logo"
                    width={25}
                  />
                  <h2>DaVinci</h2>
                </div>
                <p>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_davinci_part1"
                    defaultMessage="Launch script from :"
                  />
                </p>
                <p style={{color: 'white', fontWeight: 'bolder'}}>
                  <TranslatedMessage
                    id="launchManager_steps_hostIPC_instructions_davinci_part2"
                    defaultMessage="Workspace > Scripts > AutoCut"
                  />
                </p>
              </div>
            </div>
            <img
              src={hostScreenshotUrl}
              alt="host_screenshot"
              style={{
                maxWidth: '100vw',
                objectFit: 'cover',
              }}
            />
          </div>
          <h3 style={{textAlign: 'center'}}>
            <TranslatedMessage id={'launchManager_steps_hostIPC_subtitle'} />
          </h3>
          <p style={{textAlign: 'center'}}>
            <TranslatedMessage
              id={'launchManager_steps_hostIPC_stillNot'}
              valuePamareters={{
                copyText: intl.formatMessage({id: 'globals_contactMail'}),
              }}
            />
          </p>
        </FlexContainer>
      ) : (
        <>
          <FlexContainer
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap={SpacingPx.p16}
            style={{margin: `${SpacingPx.p32} ${SpacingPx.p16} 0`}}
          >
            <h1>
              <TranslatedMessage
                id="launchManager_title"
                defaultMessage="Installation / Update"
              />
            </h1>
            <h3 style={{textAlign: 'center'}}>
              {stepIndex}/{launchSteps.length} -
              <TranslatedMessage id={launchSteps[stepIndex].title} />
            </h3>
            <LoaderAutocut height={150} />
            {/* {preload().os.platform() === 'darwin' && (
              <InfoText style="warning">
                <TranslatedMessage
                  id="launchManager_steps_update_sudoPrompt"
                  defaultMessage="If you are using the App Store version of DaVinci Resolve, we will need to ask you for temporary administrator privilieges to install the extension"
                />
              </InfoText>
            )} */}

            {error ? (
              <>
                <p style={{color: 'red', textAlign: 'center'}}>
                  <TranslatedMessage id={error} />
                </p>
                <Button
                  color={colors.error600}
                  onClick={() => preload().electron.reload()}
                >
                  <TranslatedMessage id="launchManager_errors_cta" />
                </Button>
              </>
            ) : null}
          </FlexContainer>
          <FlexContainer
            flexDirection="column"
            gap={SpacingPx.p16}
            style={{margin: `0 ${SpacingPx.p16} ${SpacingPx.p16}`}}
          >
            <Button
              variant="tertiary"
              color={colors.gray300}
              onClick={() =>
                preload().electron.openLinkInBrowser(
                  'https://discord.gg/autocut',
                )
              }
              style={{display: 'flex', alignItems: 'center', gap: SpacingPx.p8}}
              fullWidth={false}
            >
              <DiscordIcon
                size={24}
                color="white"
              />
              <TranslatedMessage
                id="launchManager_footer_discord"
                defaultMessage="Click here to join the AutoCut Discord!"
              />
            </Button>
            <Button
              variant="tertiary"
              color={colors.gray300}
              onClick={() =>
                preload().electron.openLinkInBrowser(
                  'https://www.autocut.com/help-center/',
                )
              }
              style={{display: 'flex', alignItems: 'center', gap: 8}}
              fullWidth={false}
            >
              <HiOutlineAcademicCap
                size={24}
                color="white"
              />
              <TranslatedMessage
                id="launchManager_footer_learnMore"
                defaultMessage="Click here to discover tips on mastering all AutoCut features!"
              />
            </Button>
          </FlexContainer>
        </>
      )}
    </FlexContainer>
  );
};
