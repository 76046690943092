import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import React, {CSSProperties, MouseEvent} from 'react';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {GoInfo} from 'react-icons/go';

import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import css from './InfoText.module.css';

export type ClosableInfoTextProps = {
  hideHelper?: boolean;
  isOpenDefault?: boolean;
  onClose?: (event?: MouseEvent) => void;
  style?: 'info' | 'warning' | 'error';
  className?: string;
  blob?: {
    size?: CSSProperties['width'];
    blurRadius?: number;
    position?: {
      top?: CSSProperties['top'];
      right?: CSSProperties['right'];
    };
  };
} & (
  | {
      descriptionId: TranslationKeys;
      descriptionDefault: string;
    }
  | {
      children: React.ReactNode;
    }
);

export const InfoText = ({
  style = 'warning',
  className,
  hideHelper,
  onClose,
  isOpenDefault = true,
  blob,
  ...props
}: ClosableInfoTextProps) => {
  const [isOpen, setIsOpen] = React.useState(isOpenDefault);

  const content =
    'children' in props ? (
      props.children
    ) : (
      <TranslatedMessage
        id={props.descriptionId}
        defaultMessage={props.descriptionDefault}
      />
    );

  return (
    <div
      className={`${css.container} ${css[style]} ${
        !isOpen ? css.closed : ''
      } ${className}`}
    >
      {style === 'info' && (
        <div
          className={css.blob}
          style={
            blob
              ? ({
                  '--blob-size': blob.size,
                  '--blob-blur': `${blob.blurRadius}px`,
                  '--blob-top': blob.position?.top,
                  '--blob-right': blob.position?.right,
                } as CSSProperties)
              : undefined
          }
        />
      )}
      {onClose && (
        <AiOutlineCloseCircle
          className={css.close}
          onClick={event => {
            if (onClose) onClose(event);
            setIsOpen(false);
          }}
          size={20}
        />
      )}
      <FlexContainer
        alignItems="center"
        gap={16}
      >
        {!hideHelper && (
          <GoInfo
            color="white"
            size={18}
            style={{flexShrink: 0}}
          />
        )}
        <Text>{content}</Text>
      </FlexContainer>
    </div>
  );
};
