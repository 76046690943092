import ColorPicker from '@autocut/components/ColorPicker/ColorPicker';
import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLocale} from '@autocut/hooks/useLocale';
import {preload} from '@autocut/types/ElectronPreload';
import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';

import {FontSelect} from './FontSelect';

const TextCustomization = () => {
  const {locale} = useLocale();
  const {textColor} = useAutoCutStore(state => ({
    textColor: state.ui.parameters.caption.text.color,
  }));

  return (
    <FormSection
      title={
        <TranslatedMessage
          id={'modes_caption_steps_customization_parts_style_modal_text_title'}
          defaultMessage="Font"
        />
      }
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={8}
        >
          <ParameterLayout
            title={
              <FlexContainer
                gap={4}
                flexWrap="wrap"
                alignItems="center"
              >
                <TranslatedMessage
                  id={
                    'modes_caption_steps_customization_parts_style_modal_text_font'
                  }
                  defaultMessage="Font name"
                />
                <a
                  style={{
                    fontSize: '10px',
                    color: 'inherit',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    void preload().electron.openLinkInBrowser(
                      `https://www.autocut.fr/${locale.toLocaleLowerCase()}/academy/autocut-parameters/captions-fonts/`,
                    );
                  }}
                >
                  <TranslatedMessage
                    id={
                      'modes_caption_steps_customization_parts_style_modal_text_help'
                    }
                    defaultMessage="How to add a new font family?"
                  />
                </a>
              </FlexContainer>
            }
            height={31}
          >
            <FontSelect />
          </ParameterLayout>

          <ParameterLayout
            title={
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_style_modal_text_color'
                }
                defaultMessage="Color"
              />
            }
          >
            <ColorPicker
              value={textColor}
              onChange={color =>
                setAutocutStore('ui.parameters.caption.text.color', color)
              }
              position="bottom-left"
            />
          </ParameterLayout>
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};

export default TextCustomization;
