import {createContext, ReactNode, useRef, useState} from 'react';
import {createPortal} from 'react-dom';

import css from './NotificationProvider.module.scss';

interface INotificationContext {
  displayNotification: (childrenNotif: ReactNode, lifetime?: number) => void;
}

export const NotificationContext = createContext<INotificationContext>({
  displayNotification: () => {},
});

type NotificationProviderProps = {
  children: ReactNode;
};

export const NotificationProvider = ({children}: NotificationProviderProps) => {
  const [isShowing, setIsShowing] = useState(false);
  const [childrenNotification, setIsChildrenNotification] =
    useState<ReactNode>();
  const timeoutRef = useRef<NodeJS.Timeout>();

  const displayNotification = (childrenNotif: ReactNode, lifetime = 5000) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setIsShowing(true);
    setIsChildrenNotification(childrenNotif);
    timeoutRef.current = setTimeout(() => setIsShowing(false), lifetime);
  };

  return (
    <NotificationContext.Provider
      value={{
        displayNotification,
      }}
    >
      {createPortal(
        <div
          id="notification"
          className={`${css.container} ${isShowing ? css.visible : ''}`}
        >
          <div className={css.background}>
            <div className={css.paddedSection}>{childrenNotification}</div>
          </div>
        </div>,
        document.body,
      )}
      {children}
    </NotificationContext.Provider>
  );
};
