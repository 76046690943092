import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {ModalType} from '@autocut/enums/modals.enum';
import {defaultFontSize} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {host} from '@autocut/utils/host';
import {displayPriorityModal} from '@autocut/utils/modal/displayPriorityModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import React, {useEffect} from 'react';
import {FaArrowsAlt} from 'react-icons/fa';
import {IoSearchOutline} from 'react-icons/io5';
import {MdOutlineModeEditOutline} from 'react-icons/md';

import CaptionsPresets from '../CaptionsPresets/CaptionsPresets';
import CaptionsPreview from '../CustomizationModal/Parts/ExampleCaption/CaptionsPreview';

export const CaptionsStyleStep = () => {
  const {userPresets, fontSize} = useAutoCutStore(state => ({
    userPresets: state.ui.parameters.caption.userPresets,
    fontSize: state.ui.parameters.caption.text.fontSize,
  }));

  useEffect(() => {
    const resetFontSize = async () => {
      if (fontSize !== defaultFontSize) return;

      const sequenceWidth = parseInt(
        (await host.timeline.getTimelineFormat()).width,
      );
      setAutocutStore(
        'ui.parameters.caption.text.fontSize',
        sequenceWidth / 10,
      );
    };

    void resetFontSize();
  }, []);

  return (
    <FlexContainer
      flexDirection="column"
      gap={12}
      style={{
        width: '100%',
      }}
    >
      <FlexContainer
        flexDirection="row"
        gap={12}
        style={{
          width: '100%',
        }}
        alignItems="center"
      >
        <FlexContainer
          onClick={e => {
            e.stopPropagation();
            displayPriorityModal(ModalType.CaptionsPreview);
          }}
        >
          <CaptionsPreview
            withPreviewButton={false}
            maxHeight="160px"
            disableInteractions={true}
          />
        </FlexContainer>

        <FlexContainer
          flexDirection="column"
          gap={12}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() =>
              displayPriorityModal(ModalType.CaptionsCustomization)
            }
            variant="secondary"
          >
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              gap={4}
            >
              <MdOutlineModeEditOutline size="1rem" />
              <TranslatedMessage
                id={'modes_caption_steps_customization_parts_style_modal_title'}
                defaultMessage="Edit style"
              />
            </FlexContainer>
          </Button>
          <Button
            onClick={() => displayPriorityModal(ModalType.CaptionsPreview)}
            variant="secondary"
            color="white"
          >
            <FlexContainer
              justifyContent="center"
              alignItems="center"
              gap={4}
            >
              <FaArrowsAlt color="#F2F4F7" />
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_preview_modal_title'
                }
                defaultMessage="Position & size"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </FlexContainer>

      <FlexContainer
        flexDirection="column"
        gap={8}
      >
        <CaptionsPresets />

        <FlexContainer
          flexDirection="row"
          flexWrap="nowrap"
          gap={2}
        >
          <Button
            onClick={() => displayPriorityModal(ModalType.DeletePresets)}
            variant="tertiary"
            disabled={userPresets.length === 0}
          >
            <TranslatedMessage
              id={
                'modes_caption_steps_customization_parts_presets_delete_title'
              }
              defaultMessage="Delete presets"
            />
          </Button>
          <Button
            onClick={() => displayPriorityModal(ModalType.SearchPreset)}
            variant="tertiary"
            color="white"
          >
            <FlexContainer
              gap={4}
              alignItems="center"
              justifyContent="center"
            >
              <IoSearchOutline
                color="white"
                size="16px"
              />
              <TranslatedMessage
                id={
                  'modes_caption_steps_customization_parts_presets_search_title'
                }
                defaultMessage="Community preset"
              />
            </FlexContainer>
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};
