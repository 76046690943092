import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import React, {CSSProperties, ReactNode} from 'react';

import {IconXClose} from '../../Icon/general/IconXClose';
import {Text} from '../../Text/Text';
import {SelectVariant} from '../variants';
import css from './Item.module.scss';

export type ItemProps = {
  variant?: SelectVariant;
  children: ReactNode;
  isPlaceholder?: boolean;
} & (
  | {
      isBadge: true;
      onDelete: () => void;
      color?: string;
    }
  | {
      isBadge: false;
      onDelete?: never;
      color?: never;
    }
);

export const Item = ({
  children,
  isBadge,
  onDelete,
  isPlaceholder = false,
  color,
  variant,
}: ItemProps) => {
  if (!isBadge) {
    return (
      <Text
        data-select-item-variant={variant}
        className={`${css.text} ${isPlaceholder ? css.isPlaceholder : ''}`}
        variant="textXs"
        color="white"
      >
        {children}
      </Text>
    );
  }

  return (
    <FlexContainer
      className={css.badge}
      gap={Spacing.s2}
      alignItems="center"
      flexDirection="row"
      flexWrap="nowrap"
      style={
        {
          '--badge-color': color,
        } as CSSProperties
      }
    >
      <Text
        variant="textXs"
        color="white"
      >
        {children}
      </Text>
      <IconXClose
        className={css.icon}
        onClick={event => {
          event.stopPropagation();
          onDelete();
        }}
        size={14}
      />
    </FlexContainer>
  );
};
