import React from 'react';

import FlexContainer, {FlexContainerProps} from '../../molecules/FlexContainer';
import css from './PaddedSection.module.css';

export type PaddedSectionType = {
  children: React.ReactNode;
} & Partial<FlexContainerProps>;

export const PaddedSection = ({children, ...props}: PaddedSectionType) => {
  return (
    <FlexContainer
      className={css.root}
      flexDirection="column"
      {...props}
    >
      {children}
    </FlexContainer>
  );
};
