import React from 'react';

import {IconComponent, IconComponentProps} from '../Icon';

export const IconAngelList = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M17.8 10.1c.5-1.5 2.1-6 2.1-7.4 0-1.2-.7-2.3-2-2.3-2.1 0-4 6.2-4.6 7.7C12.8 6.8 10.7 0 8.8 0 7.4 0 6.7 1 6.7 2.4c0 1.7 1.6 6 2.2 7.6l-1-.2c-1 0-2.2 1.4-2.2 2.5 0 .4.2 1 .4 1.4-1.8.4-2.4 1.6-2.4 3.3 0 3.4 3.2 7 7.7 7 5.5 0 9-4.2 9-9.5 0-2-.4-3.9-2.6-4.4Zm-1.7-5c.2-.6 1-3 1.8-3 .4 0 .5.4.5.7 0 .9-1.8 5.8-2.2 7l-1.6-.3L16 5Zm-8-2.8c0-.6.8-2.2 2.2 2.2L12 9.2c-.8 0-1.3-.2-1.7 0-.5-1.3-2.1-5.6-2.1-7Zm0 9.1c1.3 0 3.1 4.5 3.1 5 0 .3-.2.6-.5.6-1 0-3.6-3.6-3.6-4.6 0-.3.6-1 1-1Zm8.6 8.8a6.7 6.7 0 0 1-5.1 2.2c-2.8 0-5-1.5-6-4-.9-2.1.1-3.3.9-3.3C7 15.1 9 18 9 18.5c0 .3-.3.4-.5.4-.8 0-1-.7-2.4-2.4-1.4 1.4 1 4 2.7 4 1.2 0 2-1 1.8-1.9h.5c.1 1.3.5 2.8 2 2.9l.1-.4c0-.8-.5-1.5-.5-2.3 0-1.3 1-2.6 2-3.4.4-.3.9-.4 1.3-.6a4 4 0 0 0 1.3-.7c0-.5-.3-1-.8-1-1.3 0-5.6.2-5.6-1.9 0-.3 0-.6.8-.6 1.5 0 5.3.4 6.5 1.4.8.7 1 5.3-1.5 8.2Zm-4.6-6c.4.2.9.3 1.4.3l-1 1-.4-1.2Z" />
    </IconComponent>
  );
};
