import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import './CuttingDashboard.css';

import {Outlet} from 'react-router-dom';

export const CuttingDashboard = () => {
  return (
    <>
      <FlexContainer
        flexDirection="column"
        className={'cutting-dashboard-container'}
        style={{position: 'relative'}}
      >
        <Outlet /> {/* Nested routes */}
      </FlexContainer>
    </>
  );
};
