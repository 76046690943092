import {OldButton} from '@autocut/components/Button/Button';
import Modal from '@autocut/components/ModalLayout/BaseModal';
import {StepLayout} from '@autocut/components/StepLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLocale} from '@autocut/hooks/useLocale';
import {closeModal} from '@autocut/utils/modal/closeModal';

const StartUpModals = () => {
  const startUpModals = useAutoCutStore(state => state.ui.startUpModals);

  const {locale} = useLocale();
  const upperCaseLocale = locale.toUpperCase() === 'FR' ? 'FR' : 'EN';

  return (
    <Modal
      title={
        <TranslatedMessage
          id="globals_misc_since_your_last_visit"
          defaultMessage="Since your last visit"
        />
      }
      footer={
        <OldButton onClickFunction={closeModal}>
          <TranslatedMessage
            id="globals_misc_back_to_autocut"
            defaultMessage="Go Back to AutoCut"
          />
        </OldButton>
      }
    >
      <FlexContainer
        justifyContent="center"
        flexDirection="column"
      >
        {startUpModals != null ? (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
              width: '100%',
              padding: '0.5rem',
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
          >
            {startUpModals.map((modal: any) => (
              <StepLayout
                key={modal.id}
                title={modal[`title_${upperCaseLocale}`]}
                alwaysOpen={true}
              >
                <div
                  className="inputPanelContainer"
                  key={modal.id}
                >
                  <p style={{textAlign: 'justify'}}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: modal[`body_message_${upperCaseLocale}`],
                      }}
                    />
                  </p>
                </div>
                <br />
                {modal[`link_${upperCaseLocale}`] &&
                modal[`linkText_${upperCaseLocale}`] ? (
                  <OldButton
                    onClickFunction={() => {
                      // TODO : openLinkInBrowser
                      // openLinkInBrowser(modal[`link_${upperCaseLocale}`])
                    }}
                  >
                    {modal[`linkText_${upperCaseLocale}`]}
                  </OldButton>
                ) : null}
              </StepLayout>
            ))}
          </div>
        ) : (
          ''
        )}
        <FlexContainer
          style={{
            marginBottom: '15px',
            paddingLeft: '24px',
            paddingRight: '24px',
          }}
          justifyContent="center"
          alignItems="center"
        ></FlexContainer>
      </FlexContainer>
    </Modal>
  );
};

export {StartUpModals};
