import type {ResizedXMLTemplates} from './_resizeTemplate';

import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import {localPositionEffectName} from '@autocut/enums/videoEffect.enum';
import {CaptionChunk} from '@autocut/types/Captions';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import {TimelinePpro} from '../temp/_pproTimeline.temp';
import {calculateBackgroundPosition, copyBackgroundClip} from './_backgrounds';
import {getLineHeight} from './_getLineHeight';

export const handleTextBackground = async ({
  chunk,
  secondTimes,
  parameters,
  templates,
  timeline,
  chunkIndex,
}: {
  chunk: CaptionChunk;
  secondTimes: {start: number; end: number};
  parameters: CaptionsParameters;
  templates: ResizedXMLTemplates;
  timeline: TimelinePpro;
  chunkIndex: number;
}) => {
  if (!parameters.box.enabled) return;

  const textBoxXPadding =
    ((parameters.box?.xPadding ?? 10) / 100) * parameters.text.fontSize;
  const textBoxYPadding =
    ((parameters.box?.yPadding ?? 10) / 100) * parameters.text.fontSize;

  const textBoxRadius =
    (parameters.box.radius / 100) *
    (parameters.text.fontSize +
      (parameters.box.yPadding / 100) * parameters.text.fontSize);

  const size: [number, number] = [
    (chunk.maxLineWidth ?? 0) * templates.factors.finalMogrtXFactor,
    getLineHeight(parameters) *
      chunk.nbLines *
      templates.factors.finalMogrtYFactor,
  ];

  const {newClip: newTextBackgroundClip} = await copyBackgroundClip({
    originalClip: templates.textBackgroundClip,
    startTime: secondTimes.start,
    endTime: secondTimes.end,
    color: parameters.box.color,
    index: chunkIndex,
    opacity: Math.min(
      parameters.box.enabled ? parameters.box.opacity : 0,
      99.9,
    ),
    size,
    radius: textBoxRadius / templates.factors.optimalFactor,
    padding: [
      textBoxXPadding * templates.factors.finalMogrtXFactor,
      textBoxYPadding * templates.factors.finalMogrtYFactor,
    ],
  });

  const {xPercentage, yPercentage} = calculateBackgroundPosition(
    [timeline.settings.width, timeline.settings.height],
    parameters.position.xPercentage,
    parameters.position.yPercentage,
    templates.factors.optimalFactor,
  );

  newTextBackgroundClip?.updateTransformParam(
    localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
    [xPercentage, yPercentage],
  );
};
