import {LoadingRing} from '@autocut/components/LoadingRing/LoadingRing';
import {Input} from '@autocut/designSystem/components/atoms/Input/Input';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {EmojiCategory} from '@autocut/utils/emojis/getEmojisCategories';
import React, {CSSProperties} from 'react';

import css from './EmojiPicker.module.scss';
import {CATEGORIES_PARAMS} from './utils';

export const EmojiPicker = ({
  onEmojiClick,
  getEmojis,
  getCategories,
}: {
  getEmojis: (
    category?: EmojiCategory,
  ) => Promise<{url: string; name: string}[]>;
  getCategories: () => Promise<EmojiCategory[]>;
  onEmojiClick: (emoji: {name: string; url: string}) => void;
}) => {
  const [emojis, setEmojis] = React.useState<{url: string; name: string}[]>([]);
  const [categories, setCategories] = React.useState<EmojiCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<EmojiCategory>(
    EmojiCategory.SMILEYS,
  );
  const [search, setSearch] = React.useState('');
  const [isLoadingCategories, setIsLoadingCategories] = React.useState(true);
  const [isLoadingEmojis, setIsLoadingEmojis] = React.useState(true);

  React.useEffect(() => {
    setIsLoadingCategories(true);
    setIsLoadingEmojis(true);
    void getCategories().then(categories => {
      setCategories(categories);
      setIsLoadingCategories(false);
    });
    void getEmojis(selectedCategory).then(emojis => {
      setEmojis(emojis);
      setIsLoadingEmojis(false);
    });
  }, [getEmojis, getCategories]);

  React.useEffect(() => {
    setIsLoadingEmojis(true);
    void getEmojis(selectedCategory).then(emojis => {
      setEmojis(emojis);
      setIsLoadingEmojis(false);
    });
  }, [selectedCategory]);

  const filteredEmojis = emojis.filter(emoji =>
    emoji.name.toLowerCase().includes(search.toLowerCase()),
  );

  if (isLoadingCategories)
    return (
      <FlexContainer
        style={{
          width: '100%',
          height: '96px',
        }}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingRing />
      </FlexContainer>
    );

  const sortedCategories = categories.sort(
    (a, b) =>
      (CATEGORIES_PARAMS[a].order ?? 100) - (CATEGORIES_PARAMS[b].order ?? 100),
  );

  return (
    <div className={css.container}>
      <div
        className={css.categoriesContainer}
        style={{
          ...({
            '--categories-count': categories.length,
          } as CSSProperties),
        }}
      >
        {sortedCategories.map(category => {
          return (
            <div
              key={category}
              className={css.category}
              onClick={() => {
                setSelectedCategory(category);
              }}
            >
              {CATEGORIES_PARAMS[category]?.icon}
            </div>
          );
        })}
      </div>

      <div className={css.searchInputContainer}>
        <Input
          value={search}
          placeholder="Search for emojis"
          onChange={newValue => setSearch(newValue)}
        />
      </div>

      {isLoadingEmojis && (
        <FlexContainer
          style={{
            width: '100%',
            height: '96px',
          }}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <LoadingRing />
        </FlexContainer>
      )}
      {!isLoadingEmojis && (
        <div className={css.emojisContainer}>
          {filteredEmojis.map(emoji => {
            return (
              <div
                key={emoji.name}
                className={css.emoji}
                onClick={() => onEmojiClick(emoji)}
              >
                <Emoji emoji={emoji} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const Emoji = ({
  emoji: {name, url},
  style,
}: {
  emoji: {name: string; url: string};
  style?: CSSProperties;
}) => {
  const [loading, setLoading] = React.useState(true);

  return (
    <>
      {loading && <LoadingRing />}
      <img
        style={{
          display: loading ? 'none' : 'block',
          ...style,
        }}
        // loading="lazy"
        src={url}
        alt={name}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </>
  );
};
