import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {
  drawDebugRectangle,
  drawTextOnCanvas,
  getHighlightedWordMetrics,
} from '@autocut/pages/modes/captions/utils/canvas/canvas.utils';
import {rgbToHex} from '@autocut/utils/color.utils';
import chroma from 'chroma-js';
import {cloneDeep, isEqual} from 'lodash';
import {useMemo, useRef} from 'react';

import {HIGHLIGHT_DURATION_FRAMES} from '../../../ExampleCaptionText';
import {CaptionDrawHook} from '../captionHooks.type';

export const useDrawText: CaptionDrawHook = ({xFactor, metrics, debug}) => {
  const {params} = useAutoCutStore(state => ({
    params: {
      text: state.ui.parameters.caption.text,
      formating: state.ui.parameters.caption.formating,
      outline: state.ui.parameters.caption.outline,
      animations: {
        enabled: state.ui.parameters.caption.animations.enabled,
        highlight: state.ui.parameters.caption.animations.highlight,
      },
    },
  }));
  //Cache management
  const lastDrawnParams = useRef(params);
  const lastDrawnHighlight = useRef(0);

  const isHighlightEnabled =
    params.animations.enabled && params.animations.highlight.enabled;

  const outlineConfig = useMemo(
    () => ({
      ...params.outline,
      width: params.outline.width / xFactor,
      color: rgbToHex(params.outline.color, true),
    }),
    [params.outline, xFactor],
  );
  const glowConfig = useMemo(
    () => ({
      ...params.formating.glow,
      color: rgbToHex(params.formating.glow.color, true),
    }),
    [params.formating.glow],
  );

  const {
    textMetrics,
    fontParamObject,
    fontParam,
    maxLineWidth,
    lineHeight,
    lines,
    accentSize,
  } = metrics;

  const draw = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    args: {frame: number},
  ) => {
    ctx.font = fontParam;
    ctx.textBaseline = 'top';
    ctx.textAlign = 'center';

    const highlightIndex = Math.floor(args?.frame / HIGHLIGHT_DURATION_FRAMES);

    const leftX = x - maxLineWidth / 2;
    const topY = y - textMetrics.height / 2;

    lines.map((line: any, lineIndex: number) => {
      // ===== Process positions =====
      // Sequence based position of the center of the text
      const lineXPosition = leftX;
      const lineYPosition =
        topY + // Center top of text block
        lineHeight * lineIndex;

      // ===== Draw text =====
      if (isHighlightEnabled) {
        const index =
          highlightIndex %
          lines
            .map((line: any) => line.value)
            .join(' ')
            .split(' ').length;

        const {beforeText, highlightedWord, afterText} =
          getHighlightedWordMetrics(
            {index: index, startIndexOfLine: line.startIndex},
            {
              value: line.value,
              position: {x: lineXPosition, y: lineYPosition},
              size: {width: line.width},
              font: fontParamObject,
              uppercase: params.formating.uppercase,
            },
          );

        // ===== Highlight animation + Text =====
        const textParts = [
          {
            ...beforeText,
            outline: outlineConfig,
            color: params.text.color,
          },
          {
            ...highlightedWord,
            outline: outlineConfig,
            color: params.animations.highlight.color,
          },
          {
            ...afterText,
            outline: outlineConfig,
            color: params.animations.highlight.revealText.enabled
              ? params.animations.highlight.revealText.color
              : params.text.color,
          },
        ];

        textParts.forEach((textPart, index) => {
          const beforeTextWidth = textParts
            .filter((_, partIndex) => partIndex < index)
            .map(part => part.size.width)
            .reduce((a, b) => a + b, 0);

          const highlightTextX =
            lineXPosition +
            maxLineWidth / 2 -
            line.width / 2 +
            textPart.size.width / 2 +
            beforeTextWidth;
          const highlightTextY = textPart.position.y;

          if (debug && textPart.value.trim() !== '') {
            const originalColor = chroma(
              rgbToHex(params.animations.highlight.color, true),
            )
              .set('hsl.h', '+180')
              .luminance(0.4)
              .hex();
            const color =
              index === 0
                ? chroma(originalColor).luminance(0.7).hex()
                : index === 1
                  ? originalColor
                  : chroma(originalColor).luminance(0.1).hex();
            drawDebugRectangle(
              ctx,
              highlightTextX - textPart.size.width / 2,
              highlightTextY - accentSize,
              textPart.size.width,
              lineHeight,
              color,
            );
          }

          drawTextOnCanvas({
            context: ctx,
            x: highlightTextX,
            y: highlightTextY,
            text: {
              value: textPart.value,
              font: fontParam,
              color: rgbToHex(textPart.color, true),
            },
            outline: textPart.outline,
            glow: glowConfig,
          });
        });
      } else {
        drawTextOnCanvas({
          context: ctx,
          x: lineXPosition + maxLineWidth / 2,
          y: lineYPosition,
          text: {
            value: line.value,
            font: fontParam,
            color: rgbToHex(params.text.color, true),
          },
          outline: outlineConfig,
          glow: glowConfig,
        });
      }
    });

    lastDrawnParams.current = cloneDeep(params);
    lastDrawnHighlight.current = highlightIndex;
  };

  const customCacheCheck = (args: {frame: number}) => {
    const highlightIndex = Math.floor(args?.frame / HIGHLIGHT_DURATION_FRAMES);
    return (
      isEqual(lastDrawnParams.current, params) &&
      (!isHighlightEnabled || lastDrawnHighlight.current === highlightIndex)
    );
  };

  return {
    draw,
    customCacheCheck,
    drawDebug: isHighlightEnabled ? () => void 0 : undefined,
    objectMetrics: {
      width: textMetrics.width,
      height: textMetrics.height,
    },
  };
};
