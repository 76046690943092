import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {z as zod} from 'zod';

import {resizePresetValidationSchema} from './resizePreset.validationSchema';

const ACCEPTED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
];

export const resizeValidationSchema = zod.object({
  preset: resizePresetValidationSchema,
  autoReframePreset: zod.enum(['slower', 'default', 'faster', 'none'], {
    required_error:
      'modes_resize_error_validation_autoReframePreset_required' as TranslationKeys,
    invalid_type_error:
      'modes_resize_error_validation_autoReframePreset_invalid' as TranslationKeys,
  }),
  watermark: zod
    .instanceof(File)
    .optional()
    .refine(
      file => !file || ACCEPTED_IMAGE_MIME_TYPES.includes(file?.type),
      'modes_resize_error_validation_watermark' as TranslationKeys,
    ),
});
