import type {AutoCutState} from '@autocut/utils/zustand';

import brollThumbnail from '@autocut/assets/images/modes/broll.png?url';
import captionsThumbnail from '@autocut/assets/images/modes/captions.png?url';
import chapterThumbnail from '@autocut/assets/images/modes/chapter.png?url';
import podcastThumbnail from '@autocut/assets/images/modes/podcast.png?url';
import profanityThumbnail from '@autocut/assets/images/modes/profanity.png?url';
import repeatThumbnail from '@autocut/assets/images/modes/repeat.png?url';
import resizeThumbnail from '@autocut/assets/images/modes/resize.png?url';
import silenceThumbnail from '@autocut/assets/images/modes/silence.png?url';
import viralThumbnail from '@autocut/assets/images/modes/viral.png?url';
import zoomThumbnail from '@autocut/assets/images/modes/zoom.png?url';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {StatMode} from '@autocut/enums/statCategory.enum';
import {TextBoxAnimation} from '@autocut/types/CaptionsParameters';
import {LicenceKeyType} from '@autocut/types/IsKeyValidResponse';
import {Traits} from '@autocut/utils/stats.utils';
// import { TransitionSlugs } from '@autocut/utils/captions/effects.utils';
import {cloneDeep} from 'lodash';
import React from 'react';
import {IconType} from 'react-icons';
import {AiOutlineScissor, AiOutlineZoomIn} from 'react-icons/ai';
import {BiMessageSquareX, BiMicrophone} from 'react-icons/bi';
import {CiMobile1} from 'react-icons/ci';
import {FaInstagram} from 'react-icons/fa6';
import {HiOutlineVideoCamera} from 'react-icons/hi';
import {LuLanguages} from 'react-icons/lu';
import {TbRepeatOff} from 'react-icons/tb';
import {TfiParagraph} from 'react-icons/tfi';

import {bleepSounds} from './bleepFile.enum';
import {REPEAT_ALGORITHM} from './repeatAlgorithm.enum';
import {ZoomTypes} from './zoomTypes.enum';

export const defaultTextBox: TextBoxAnimation = {
  enabled: false,
  xPadding: 40,
  yPadding: 20,
  opacity: 75,
  color: {r: 0, g: 0, b: 0},
  radius: 0,
};

export const defaultFontSize = -1;

// As AutocutModeConfig uses AutoCutStateKeys which is extracted from AutoCutState we cannot use any mode type in the AutoCutState
// To avoid circular dependencies we use this type instead
export type AutocutModeState = {
  id: string;
  name: string;
  description?: React.ReactNode;
  thumbnail: string;
  path: string;
  icon: IconType;
  statCategory: StatMode;
  parametersPath: string;
  defaultParameters: {
    [key: string]: any;
  };
  endProcessConfig: {
    goToNextMode?: string | false;
    restoreSequence?: boolean;
    rateAutoCut?: boolean;
    timeTaken?: boolean;
    timeSaved?: boolean;
  };
  available: boolean;
  status: string;
  minKeyType: LicenceKeyType;
  minPProVersion?: string;
  getStatValue: (states: AutoCutState) => number;
  getStatsTraits?: () => Traits;
};

export type AutocutModeConfig = Omit<AutocutModeState, 'parametersPath'> & {
  parametersPath: string;
};

export const AutocutModes = {
  Legacy: {
    id: 'silence',
    icon: AiOutlineScissor,
    name: 'AutoCut Silences',
    description: (
      <TranslatedMessage
        id="modes_silence_description"
        defaultMessage="Remove silences in your timeline"
      />
    ),
    thumbnail: silenceThumbnail,
    path: '/legacy',
    statCategory: StatMode.LEGACY,
    parametersPath: 'ui.parameters.silence',
    defaultParameters: {
      minTimeOfSilence: 0.3,
      minTimeOfTalk: 0.2,
      marginBefore: 0.2,
      marginAfter: 0.2,
      transistions: 'none',
      noiseLevel: -35,
      silences: 'delete',
      aggressivenessLevel: 3,
      languageOfTranscription: {
        label:
          localStorage.getItem('language') === 'FR' ? 'Anglais' : 'English',
        value: 'en',
        textDirection: 'ltr',
      },
      transcriptionProvider: 'deepgram',
    },
    endProcessConfig: {
      goToNextMode: 'zoom',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'basic',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.timelineBasedSilencesTotal?.length ||
      1,
  },
  Podcast: {
    id: 'podcast',
    icon: BiMicrophone,
    name: 'AutoCut Podcast',
    description: (
      <TranslatedMessage
        id="modes_podcast_description"
        defaultMessage="Edits podcast instantly"
      />
    ),
    thumbnail: podcastThumbnail,
    path: '/podcast',
    statCategory: StatMode.PODCAST,
    parametersPath: 'ui.parameters.podcast',
    defaultParameters: {
      minimumCamTime: 3,
      maximumCamTime: 10,
      deleteUnusedClips: false,
      speakers: [
        {
          name: '',
          videoTracks: [],
          audioTrack: undefined,
        },
      ],
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.nbStepTotal || 1,
  },
  Repeat: {
    id: 'repeat',
    icon: TbRepeatOff,
    name: 'AutoCut Repeat',
    description: (
      <TranslatedMessage
        id="modes_repeat_description"
        defaultMessage="Removes bad takes with AI"
      />
    ),
    thumbnail: repeatThumbnail,
    path: '/repeat',
    statCategory: StatMode.REPEAT,
    parametersPath: 'ui.parameters.repeat',
    defaultParameters: {
      selectedAlgorithm: REPEAT_ALGORITHM.PRECISE,
      transistions: 'none',
      silences: 'delete',
      languageOfTranscription: {
        label: 'English',
        value: 'en',
        textDirection: 'ltr',
      },
      transcriptionProvider: 'deepgram',
    },
    endProcessConfig: {
      goToNextMode: 'silence',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: false,
    },
    available: true,
    status: 'BETA',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.timelineBasedSilencesTotal?.length ||
      1,
  },
  Zoom: {
    id: 'zoom',
    icon: AiOutlineZoomIn,
    name: 'AutoZoom',
    description: (
      <TranslatedMessage
        id="modes_zoom_description"
        defaultMessage="Automatically add zooms"
      />
    ),
    thumbnail: zoomThumbnail,
    path: '/zoom',
    statCategory: StatMode.AUTOZOOM,
    parametersPath: 'ui.parameters.zoom',
    defaultParameters: {
      autoZoomMinCoef: 1.2, // deprecated
      autoZoomMaxCoef: 1.4,
      anchorPosMinCoef: {xPercentage: 0.5, yPercentage: 0.5},
      anchorPosMaxCoef: {xPercentage: 0.5, yPercentage: 0.5},
      totalZoomPercentage: 0.6,
      dynamicZoomPercentage: 0.1,
      dynamicMinZoomTime: 0.5,
      dynamicMaxZoomTime: 5,
      dynamicMinZoomIntensity: 0.4,
      constraintZoom: true,
      smoothZoomPercentage: 0.9,
      smoothMinZoomTime: 2,
      smoothMaxZoomTime: 15,
      smoothZoomSpeed: 0.01, // deprecated
      nervousness: 0.3,
      zoomTypes: {
        [ZoomTypes.SMOOTH]: true,
        [ZoomTypes.DYNAMIC]: true,
      },
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.nbStepTotal || 1,
  },
  Captions: {
    id: 'caption',
    icon: LuLanguages,
    name: 'Auto Captions',
    description: (
      <TranslatedMessage
        id="modes_caption_description"
        defaultMessage="Add animated captions with AI"
      />
    ),
    thumbnail: captionsThumbnail,
    path: '/caption',
    statCategory: StatMode.CAPTIONS,
    parametersPath: 'ui.parameters.caption',
    defaultParameters: {
      userPresets: [],
      utils: {
        hasClosedInfoText: false,
      },
      languageOfTranscription: {
        label: 'English',
        value: 'en',
        textDirection: 'ltr',
      },
      transcriptionProvider: 'deepgram',
      position: {
        xPercentage: 0.5,
        yPercentage: 0.95,
        emojiXPercentage: 0.5,
        emojiYPercentage: 0.9,
        rotation: 0,
        offset: [0, 0] as [number, number],
        scale: [1, 1] as [number, number],
      },
      text: {
        color: {r: 255, g: 255, b: 255},
        fontSize: defaultFontSize,
        font: {
          fontFamily: 'Arial',
          postscriptName: 'ArialMT',
          fontStyle: 'Regular',
          lineGap: 67,
          ascent: 1854,
          descent: -434,
          unitsPerEm: 2048,
          bbox: {
            minX: 0,
            minY: -434,
            maxX: 2048,
            maxY: 1854,
          },
          label: 'Arial - Regular',
        },
      },
      animations: {
        enabled: true,
        highlight: {
          enabled: true,
          color: {r: 230, g: 80, b: 25},
          revealText: {
            enabled: false,
            color: {
              r: 255,
              g: 255,
              b: 255,
            },
          },
        },
        wordBox: cloneDeep(defaultTextBox),
        boldWithText: false,
        floatingText: false,
      },
      outline: {
        enabled: true,
        color: {r: 0, g: 0, b: 0},
        width: 8,
      },
      box: cloneDeep(defaultTextBox),
      transition: {
        enabled: true,
        effects: [],
        // effects: [] as TransitionSlugs[], // TODO : captions
      },
      emojis: {
        enabled: false,
        size: 30,
      },
      formating: {
        uppercase: false,
        italic: false,
        nbLines: 1,
        maxWidth: 80,
        glow: {
          enabled: false,
          intensity: 0,
          color: {r: 0, g: 0, b: 0},
        },
        removePunctuation: false,
      },
      postProcessing: {
        formattedNumbers: 0,
      },
    },
    endProcessConfig: {
      goToNextMode: false,
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: '23',
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.caption?.chunks?.length || 1,
  },
  Resize: {
    id: 'resize',
    icon: CiMobile1,
    name: 'AutoResize',
    description: (
      <TranslatedMessage
        id="modes_resize_description"
        defaultMessage="Resizes your sequence with AI"
      />
    ),
    thumbnail: resizeThumbnail,
    path: '/resize',
    statCategory: StatMode.RESIZE,
    parametersPath: 'ui.parameters.resize',
    defaultParameters: {
      preset: undefined,
      watermark: undefined,
      autoReframePreset: 'default',
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: _ => 1,
  },
  BRoll: {
    id: 'broll',
    icon: HiOutlineVideoCamera,
    name: 'Auto B-Rolls',
    description: (
      <TranslatedMessage
        id={'modes_broll_description'}
        defaultMessage="Automatically adds B-Roll with AI"
      />
    ),
    thumbnail: brollThumbnail,
    path: '/broll',
    statCategory: StatMode.BROLL,
    parametersPath: 'ui.parameters.broll',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      transcriptionProvider: 'deepgram',
      minimumBRollTime: 2,
      maximumBRollTime: 30,
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: false,
    },
    available: true,
    status: 'BETA',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.nbStepTotal || 1,
  },
  SwearWords: {
    id: 'swear_word',
    icon: BiMessageSquareX,
    name: 'AutoProfanity Filter',
    description: (
      <TranslatedMessage
        id="modes_swearWords_description"
        defaultMessage="Bleeps out swear words"
      />
    ),
    thumbnail: profanityThumbnail,
    path: '/swear_word',
    statCategory: StatMode.SWEAR_WORD,
    parametersPath: 'ui.parameters.swear_word',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      transcriptionProvider: 'deepgram',
      bleepFile: bleepSounds[0].value,
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: true,
      rateAutoCut: true,
      timeTaken: true,
      timeSaved: false,
    },
    available: true,
    status: '',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.nbStepTotal || 1,
  },
  Chapters: {
    id: 'chapters',
    icon: TfiParagraph,
    name: 'Auto Chapter',
    description: (
      <TranslatedMessage
        id="modes_chapters_description"
        defaultMessage="Detect chapters and add markers with AI"
      />
    ),
    thumbnail: chapterThumbnail,
    path: '/chapters',
    statCategory: StatMode.CHAPTERS,
    parametersPath: 'ui.parameters.chapters',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      transcriptionProvider: 'deepgram',
      color: 'Green',
    },
    endProcessConfig: {},
    available: true,
    status: 'BETA',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.nbStepTotal || 1,
  },
  ViralClips: {
    id: 'viral_clips',
    icon: FaInstagram,
    name: 'Auto Viral Clips',
    description: (
      <TranslatedMessage
        id="modes_viralClips_description"
        defaultMessage="Identify viral shorts in long videos in one click"
      />
    ),
    thumbnail: viralThumbnail,
    path: '/viral_clips',
    statCategory: StatMode.VIRALCLIPS,
    parametersPath: 'ui.parameters.viral_clips',
    defaultParameters: {
      languageOfTranscription: {
        label: 'English',
        value: 'en',
      },
      transcriptionProvider: 'deepgram',
    },
    endProcessConfig: {
      goToNextMode: 'caption',
      restoreSequence: false,
      rateAutoCut: true,
      timeTaken: false,
      timeSaved: false,
    },
    available: true,
    status: 'BETA',
    minKeyType: 'ai',
    minPProVersion: undefined,
    getStatValue: autocutStoreVanilla =>
      autocutStoreVanilla.onGoingProcess?.nbStepTotal || 1,
  },
} as const satisfies {[key: string]: AutocutModeConfig};

export type AutocutModesKeys = keyof typeof AutocutModes;
export type AutocutMode = (typeof AutocutModes)[AutocutModesKeys];
export type AutocutModeIds = (typeof AutocutModes)[AutocutModesKeys]['id'];
