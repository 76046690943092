import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {StatType} from '@autocut/enums/statType.enum';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {autocutApi} from '@autocut/utils/http.utils';

import {handleBackup} from '../backup';
import {compute} from '../compute.utils';
import {isFirstCut, isFirstCutToday} from '../localStorage.utils';
import {logger} from '../logger';
import {manageError} from '../manageError';
import {displayPriorityModal} from '../modal/displayPriorityModal';
import {getModeStatTrait} from '../modes.utils';
import {sendStats} from '../stats.utils';
import {autocutStoreVanilla, setAutocutStore} from '../zustand';
import {muteSelectedTracks} from './selectedTracks';

const logMessage = (level: logLevel, message = 'log', objects = {}) => {
  logger('handleProcessStart', level, message, objects);
};

export const handleProcessStart = async () => {
  try {
    const autocutState = autocutStoreVanilla();
    const mode = autocutState.ui.process.mode;

    // TODO : peut être faire une fonction python pour checker rapidement l'état de l'audio ?
    // if (mode.id != 'resize') {
    //   const isTimelineMuted = await evalTS('isTimelineMuted');
    //   if (isTimelineMuted) {
    //     throw new IncrementalError('TIMELINE_MUTED', 'handleProcessStart');
    //   }
    // }

    logMessage(logLevel.notice, `Starting process ${mode.id}`);
    setAutocutStore('onGoingProcess.startDate', new Date());
    setAutocutStore('ui.process.isProcessing', true);
    displayPriorityModal(ModalType.Processing);

    await handleStatsPreProcess();
    await autocutApi.post('/statistics/user-stats', {
      mode: mode.id,
    });

    await handleBackup();
    await muteSelectedTracks();
  } catch (err: any) {
    throw new IncrementalError(err, 'handleProcessStart');
  }
};

const handleStatsPreProcess = async () => {
  try {
    const autocutStates = autocutStoreVanilla();
    const mode = autocutStates.ui.process.mode;
    const license = autocutStates.user.license;
    const StatCategoryValue = getModeStatTrait(mode);

    const preProcessStats = await compute.timeline.getPreProcessStats();

    await sendStats({
      type: StatType.PROCESS_STARTED,
      value: 1,
      traits: {
        mode: StatCategoryValue,
        width: preProcessStats.width,
        height: preProcessStats.height,
        duration: preProcessStats.duration,
        nbClips: preProcessStats.trackItemsCount,
        ...(license?.type === 'trial' && isFirstCut()
          ? {firstTrialCut: 1}
          : {}),
        ...(isFirstCutToday() ? {firstCutToday: 1} : {}),
      },
    });
  } catch (error: any) {
    manageError({
      error: new IncrementalError(error, 'handleStatsPreProcess'),
      disableModal: true,
    });
  }
};
