import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import React, {useEffect} from 'react';

import css from './FromBottomModalLayout.module.css';

export type FromBottomModalLayoutProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
};

export const FromBottomModalLayout = ({
  children,
  footer = undefined,
}: FromBottomModalLayoutProps) => {
  useEffect(() => {
    // @ts-ignore
    disableBodyScroll(document);

    return () => {
      // @ts-ignore
      enableBodyScroll(document);
    };
  }, []);

  return (
    <div className={css.container}>
      <div className={css.background}></div>
      <div className={css.modal}>
        <div className={css.content}>{children}</div>

        {footer && <div className={css.footer}>{footer}</div>}
        <div className={css.blobContainer}>
          <div className={css.blob} />
          <div className={css.blob} />
          <div className={css.blob} />
          <div className={css.blob} />
        </div>
      </div>
    </div>
  );
};
