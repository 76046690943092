import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Tabs} from '@autocut/designSystem/components/atoms/Tabs/Tabs';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {closeModal as modalCloseModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import React from 'react';
import {FaFont} from 'react-icons/fa6';
import {MdOutlineModeEditOutline} from 'react-icons/md';
import {MdOutlineFormatSize} from 'react-icons/md';

import {processCaptionsChunksState} from '../../../utils/processCaptionsChunks';
import css from './CaptionsCustomizationModal.module.scss';
import {AnimationIcon} from './icons/AnimationIcon';
import {BoxIcon} from './icons/BoxIcon';
import {OutlineIcon} from './icons/OutlineIcon';
import {TransitionIcon} from './icons/TransitionIcon';
import AnimationsCustomization from './Parts/AnimationsCustomization/AnimationsCustomization';
import BoxesCustomization from './Parts/BoxesCustomization/BoxesCustomization';
import TextPreview from './Parts/ExampleCaption/TextPreview';
import OutlineCustomization from './Parts/OutlineCustomization/OutlineCustomization';
import TextCustomization from './Parts/TextCustomization/TextCustomization';
import TextFormatCustomization from './Parts/TextFormatCustomization/TextFormatCustomization';
import TransitionCustomization from './Parts/TransitionCustomization/TransitionCustomization';

export const CaptionsCustomizationModal = () => {
  const chunks =
    useAutoCutStore(state => state.onGoingProcess.caption?.chunks) ?? [];

  const closeModal = async () => {
    await processCaptionsChunksState(
      chunks?.flatMap(chunk => chunk.lines.flat()) ?? [],
    );
    modalCloseModal();
  };
  return (
    <Modal
      title={
        <TranslatedMessage
          id={'modes_caption_steps_customization_parts_style_modal_title'}
          defaultMessage="Edit style"
        />
      }
      icon={<MdOutlineModeEditOutline size="1.5rem" />}
      fullScreen
      withCloseButton={false}
      closeOnClickOutside={false}
      shouldScroll={false}
      footer={
        <FlexContainer
          flexDirection="row"
          gap={12}
          justifyContent="space-between"
          style={{
            paddingInline: 16,
          }}
        >
          <Button
            onClick={closeModal}
            variant="secondary"
          >
            <TranslatedMessage
              id={'modes_caption_steps_customization_parts_style_modal_apply'}
              defaultMessage="Apply style"
            />
          </Button>
          <Button
            onClick={async () => {
              addModalToQueue(ModalType.CreatePresetModal);
              await closeModal();
            }}
            color={colors.primary600}
          >
            <TranslatedMessage
              id={'modes_caption_steps_customization_parts_style_modal_save'}
              defaultMessage="Apply style and save as preset"
            />
          </Button>
        </FlexContainer>
      }
      closeModalFunction={closeModal}
    >
      <FlexContainer
        flexDirection="column"
        gap={24}
        style={{
          height: '100%',
        }}
      >
        <TextPreview />

        <Tabs
          content={[
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <FaFont
                    size={16}
                    color="#F2F4F7"
                  />
                </div>
              ),
              Panel: <TextCustomization />,
              value: 'font',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <AnimationIcon
                    size={20}
                    color="#F2F4F7"
                    strokeWidth={3}
                  />
                </div>
              ),
              Panel: <AnimationsCustomization />,
              value: 'animation',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <TransitionIcon
                    size={20}
                    color="#F2F4F7"
                    strokeWidth={3}
                    fill="#1D2939de"
                  />
                </div>
              ),
              Panel: <TransitionCustomization />,
              value: 'transition',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <MdOutlineFormatSize
                    size={20}
                    color="#F2F4F7"
                  />
                </div>
              ),
              Panel: <TextFormatCustomization />,
              value: 'text-format',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <OutlineIcon
                    size={16}
                    color="#F2F4F7"
                    strokeWidth={2}
                  />
                </div>
              ),
              Panel: <OutlineCustomization />,
              value: 'outline',
            },
            {
              TabContent: (
                <div className={css.iconContainer}>
                  <BoxIcon
                    size={20}
                    color="#F2F4F7"
                    strokeWidth={2}
                  />
                </div>
              ),
              Panel: <BoxesCustomization />,
              value: 'box',
            },
          ]}
        />
      </FlexContainer>
    </Modal>
  );
};
