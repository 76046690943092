export enum Env {
  Production = 'production',
  Staging = 'staging',
  Development = 'development',
}

const ENV_ORIGIN_MAP: Record<string, Env> = {
  'front.v4.autocut.com': Env.Production,
  'front.tatin.autocut.com': Env.Production,
  'front.cookie.autocut.com': Env.Production,
  localhost: Env.Development,
};

export const CURRENT_ENV =
  ENV_ORIGIN_MAP[window.location.hostname] || Env.Staging;
export const IS_RUNNING_ON_ELECTRON = navigator.userAgent.includes('AutoCut');
