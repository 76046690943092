import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {useIntl} from 'react-intl';

export const NotEnoughTracksModal = () => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage({
        id: 'modals_notEnoughTracks_title',
        defaultMessage: 'Not enough tracks in your timeline',
      })}
      footer={
        <Button
          variant="primary"
          color={colors.primary600}
          onClick={() => closeModal()}
        >
          <TranslatedMessage
            id="modals_notEnoughTracks_cta"
            defaultMessage="I understand"
          />
        </Button>
      }
    >
      <Text>
        <TranslatedMessage
          id="modals_notEnoughTracks_text"
          defaultMessage="You need at least 2 video and 2 audio tracks to use this feature."
        />
      </Text>
    </Modal>
  );
};
