import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {SelectedAudioTrackStep} from '@autocut/components/SelectedAudioTracksStep/SelectedAudioTracksStep';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useCheckDeactivatedTracks} from '@autocut/hooks/useCheckDeactivatedTracks';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {AlgorithmSelectStep} from '@autocut/pages/modes/repeat/Steps/Language/Parts/AlgorithmSelectStep';
import {useEffect} from 'react';

export const RepeatLanguage = () => {
  const {allTracksDeactivated, isInitialized} = useCheckDeactivatedTracks();
  const {handleProcessTranscript, error, loading} =
    useHandleProcessTranscript('repeat');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({isLoading, isDisabled}) => (
            <Button
              onClick={() => handleProcessTranscript()}
              disabled={
                loading || isLoading || isDisabled || allTracksDeactivated
              }
              isLoading={!isInitialized || isLoading || loading}
              color={colors.primary600}
            >
              <TranslatedMessage
                id="modes_repeat_steps_language_cta"
                defaultMessage="Generate transcript"
              />
            </Button>
          )}
          errorId={
            allTracksDeactivated
              ? 'modes_general_steps_transcription_noTrackSelected'
              : undefined
          }
        />
      }
    >
      <FlexContainer
        style={{paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <TranscriptLanguageStep modeId="repeat" />
        <AlgorithmSelectStep />
        <SelectedAudioTrackStep />
      </FlexContainer>
    </ModeLayout>
  );
};

export default RepeatLanguage;
