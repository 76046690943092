import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {compute} from '@autocut/utils/compute.utils';
import {handleTrialTask} from '@autocut/utils/game/trialGamfication.util';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {forwardRef} from 'react';
import {useIntl} from 'react-intl';

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  handleClickWithValidation: (baseHandleClick: () => Promise<void>) => any;
};

export const PodcastFooterButton = forwardRef<HTMLButtonElement, Props>(
  ({isDisabled, isLoading, handleClickWithValidation}, ref) => {
    const intl = useIntl();
    const podcastParameters = useAutoCutStore(
      state => state.ui.parameters.podcast,
    );

    const handleClick = async () => {
      await handleProcessBase(
        {
          executeProcess: async (_, progress) => {
            const podcastProcess = compute.process.podcast(podcastParameters);

            updateProcessStep(progress, 'podcast_mainProcess', {
              progress: {
                computeTaskId: podcastProcess.requestId,
              },
              countFor: 16, // Number of steps in compute
            });

            const result = await podcastProcess;

            endProcessStep(progress, 'podcast_mainProcess');

            return result;
          },
        },
        {
          processTitleNameKey: 'modes_podcast_title',
          processSteps: [
            {
              id: 'podcast_mainProcess',
              translationKey: 'progress_steps_podcast_mainProcess',
              progress: '',
            },
          ],
        },
      )(intl);

      await handleTrialTask('podcast');
    };

    return (
      <Button
        ref={ref}
        color={colors.primary600}
        onClick={() => handleClickWithValidation(async () => handleClick())}
        disabled={isDisabled || isLoading}
        isLoading={isLoading}
      >
        <TranslatedMessage
          id={`modes_podcast_steps_customization_cta_${podcastParameters.deleteUnusedClips ? 'delete' : 'disable'}`}
          defaultMessage="Edit and disable unused clips"
        />
      </Button>
    );
  },
);
