import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {z as zod} from 'zod';

import {languageOfTranscriptionValidationSchema} from './languageOfTranscription.validationSchema';
import {transcriptionProviderValidationSchema} from './transcriptionProvider.validationSchema';

export const brollValidationSchema = zod
  .object({
    languageOfTranscription: languageOfTranscriptionValidationSchema,
    transcriptionProvider: transcriptionProviderValidationSchema,
    minimumBRollTime: zod
      .number()
      .min(
        0,
        'modes_broll_error_validation_minimumBRollTime' as TranslationKeys,
      ),
    maximumBRollTime: zod
      .number()
      .min(
        0,
        'modes_broll_error_validation_maximumBRollTime' as TranslationKeys,
      ),
  })
  .refine(
    schema => schema.minimumBRollTime < schema.maximumBRollTime,
    'modes_broll_error_validation_minMaxDuration' as TranslationKeys,
  );
