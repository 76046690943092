import {CAPTIONS_XML_PROJECT_LOCALE} from '@autocut/contexts/LocaleContext';
import {localPositionEffectName} from '@autocut/enums/videoEffect.enum';
import {CaptionChunk, CaptionChunkHighlight} from '@autocut/types/Captions';
import {CaptionsParameters} from '@autocut/types/CaptionsParameters';

import {TimelinePpro} from '../temp/_pproTimeline.temp';
import {calculateBackgroundPosition, copyBackgroundClip} from './_backgrounds';
import {getLineHeight} from './_getLineHeight';
import {ResizedXMLTemplates} from './_resizeTemplate';

export const handleHighlightBox = async ({
  highlight,
  chunk,
  parameters,
  templates,
  timeline,
  secondTimes,
  highlightIndex,
}: {
  highlight: CaptionChunkHighlight;
  chunk: CaptionChunk;
  parameters: CaptionsParameters;
  templates: ResizedXMLTemplates;
  timeline: TimelinePpro;
  secondTimes: {start: number; end: number};
  highlightIndex: number;
}) => {
  const wordBoxXPadding =
    ((parameters.animations.wordBox?.xPadding ?? 10) / 100) *
    parameters.text.fontSize;
  const wordBoxYPadding =
    ((parameters.animations.wordBox?.yPadding ?? 10) / 100) *
    parameters.text.fontSize;
  const wordBoxRadius =
    (parameters.animations.wordBox?.radius / 100) *
    (parameters.text.fontSize +
      (parameters.animations.wordBox.yPadding / 100) *
        parameters.text.fontSize);

  const {newClip: newWordBoxClip} = await copyBackgroundClip({
    originalClip: templates.wordBackgroundClip,
    startTime: secondTimes.start,
    endTime: secondTimes.end,
    color: parameters.animations.wordBox?.color,
    index: highlightIndex,
    opacity: parameters.animations.wordBox?.enabled
      ? parameters.animations.wordBox?.opacity
      : 0,
    padding: [
      wordBoxXPadding * templates.factors.finalMogrtXFactor,
      wordBoxYPadding * templates.factors.finalMogrtYFactor,
    ],
    size: [
      (highlight.wordWidth ?? 0) * templates.factors.finalMogrtXFactor,
      getLineHeight(parameters) * templates.factors.finalMogrtYFactor,
    ],
    radius: wordBoxRadius / templates.factors.optimalFactor,
  });

  const textBlockHeight = getLineHeight(parameters) * chunk.nbLines;
  const {xPercentage, yPercentage} = calculateBackgroundPosition(
    [timeline.settings.width, timeline.settings.height],
    parameters.position.xPercentage,
    parameters.position.yPercentage,
    templates.factors.optimalFactor,
  );

  newWordBoxClip?.updateTransformParam(
    localPositionEffectName[CAPTIONS_XML_PROJECT_LOCALE],
    [
      xPercentage -
        (highlight.position?.x ?? 0) * templates.factors.finalMogrtXFactor,
      yPercentage -
        ((highlight.position?.y ?? 0) + textBlockHeight / 2) *
          templates.factors.finalMogrtYFactor,
    ],
  );
};
