import {AxiosError} from 'axios';

export type TAutoCutApiError = AxiosError<{
  name: string;
  message?: string;
  description?: string;
}>;

export class AutoCutApiError extends Error {
  description?: string;
  error: TAutoCutApiError;
  status: number;

  constructor(baseError: TAutoCutApiError) {
    super(baseError.response?.data.name);
    this.error = baseError;

    const response = baseError.response;
    if (response) {
      this.name = response.data.name;
      this.message =
        response.data.message ||
        `An error occured with status code ${response.status}. Please contact us on Discord or at contact@autocut.fr.`;

      this.status = response.status;
      this.description = response.data.description;
    } else {
      this.name = 'UNKNWON_API_ERROR';
      this.status = 0;
      this.message = `Unknown error occured during API request. Please contact us on Discord or at contact@autocut.fr.\n${
        baseError.message || ''
      }`;
    }
  }
}
