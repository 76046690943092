import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {compute} from '@autocut/utils/compute.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {addXp} from '@autocut/utils/game/addXp';
import {
  endProcessStep,
  ProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {handleProcessCaptions} from '@autocut/utils/prproj/temp/_handleProcessCaptions.temp';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {IntlShape} from 'react-intl';

import {handleProcessBase} from '../handleProcessBase';
import {downloadEmojis} from './prepareCaptions/downloadEmojis';
import {
  CaptionsPremiereData,
  prepareCaptionsDavinci,
  prepareCaptionsPremiere,
} from './prepareCaptions/prepareCaptions';

const addCaptions = async (_: IntlShape, progress: ProcessProgress) => {
  try {
    let chunks = autocutStoreVanilla().onGoingProcess.caption?.chunks ?? [];
    if (!chunks) {
      throw new IncrementalError('No chunks found', 'addCaptions');
    }

    const {
      userPresets,
      languageOfTranscription,
      postProcessing,
      enableSoundPlay,
      utils,
      ...captionsParameters
    } = autocutStoreVanilla().ui.parameters.caption;

    const hostName = autocutStoreVanilla().ui.host;

    chunks = await downloadEmojis(chunks);

    const {
      parameters: finalParameters,
      chunks: finalChunks,
      ...data
    } = hostName === 'davinci'
      ? prepareCaptionsDavinci({
          parameters: captionsParameters,
          chunks,
        })
      : await prepareCaptionsPremiere({
          parameters: captionsParameters as CaptionsParameters,
          chunks,
        });

    endProcessStep(progress, 'caption_initialization');

    let captionsProcess;

    if (hostName === 'davinci') {
      captionsProcess = compute.process.captions({
        chunks: finalChunks,
        parameters: finalParameters as CaptionsParameters,
        ...data,
      });

      updateProcessStep(progress, 'caption_mainProcess', {
        progress: {
          computeTaskId: captionsProcess.requestId,
        },
        countFor: 10, // Number of steps in compute
      });
    } else {
      await handleProcessCaptions(
        {
          chunks: finalChunks,
          parameters: finalParameters as CaptionsParameters,
          xmlProjectPath: (data as CaptionsPremiereData).xmlFilePath,
        },
        progress,
      );
    }
    await captionsProcess;

    endProcessStep(progress, 'caption_mainProcess');

    const chunksTotal = chunks.length;

    const xpGained = chunksTotal * 1.66;
    await addXp(xpGained);
    setAutocutStore('game.level.xpGained', xpGained);
  } catch (err: any) {
    throw new IncrementalError(err, 'addCaptions');
  }
};

export const handleCaptionsProcess = async (intl: IntlShape) => {
  const hostName = autocutStoreVanilla().ui.host;

  await handleProcessBase(
    {
      executeProcess: addCaptions,
    },
    {
      processTitleNameKey: 'modes_caption_title',
      processSteps:
        hostName === 'davinci'
          ? [
              {
                id: 'caption_initialization',
                translationKey:
                  'progress_steps_caption_generation_initialization',
                progress: 1,
              },
              {
                id: 'caption_mainProcess',
                translationKey: 'progress_steps_caption_generation_mainProcess',
                progress: '',
              },
            ]
          : [
              {
                id: 'caption_initialization',
                translationKey:
                  'progress_steps_caption_generation_initialization',
                progress: 1,
              },
              {
                id: 'caption_prepare_timeline',
                translationKey:
                  'progress_steps_caption_generation_prepareTimeline',
                progress: '',
              },
              {
                id: 'caption_generate_sequence',
                translationKey:
                  'progress_steps_caption_generation_generateSequence',
                progress: '',
              },
              {
                id: 'caption_import_sequence',
                translationKey:
                  'progress_steps_caption_generation_importSequence',
                progress: '',
              },
            ],
    },
  )(intl);
};
