import logLevel from '@autocut/enums/logLevel.enum';
import {ModalType} from '@autocut/enums/modals.enum';
import {AutocutModes, AutocutModesKeys} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useLogger} from '@autocut/hooks/useLogger';
import {preload} from '@autocut/types/ElectronPreload';
import {logout} from '@autocut/utils/license/logout';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {getModeByLocation} from '@autocut/utils/modes.utils';
import {parameterReset} from '@autocut/utils/observers/parameterReset';
import {logOutSentryUser} from '@autocut/utils/sentry.utils';
import {setAutocutStore} from '@autocut/utils/zustand';
import {AiOutlineMail, AiOutlineUndo, AiOutlineUser} from 'react-icons/ai';
import {BsStars} from 'react-icons/bs';
import {HiLogout} from 'react-icons/hi';
import {IoStatsChartSharp} from 'react-icons/io5';
import {MdOutlineRateReview} from 'react-icons/md';
import {RiGitRepositoryLine} from 'react-icons/ri';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {KebabIcon} from './KebabIcon';
import css from './kebabMenu.module.css';
import {MenuItem} from './MenuItem';

export const KebabMenu = () => {
  const isLogged = useAutoCutStore(state => state.user.isLogged);
  const {logMessage} = useLogger('KebabMenu');
  const navigate = useNavigate();
  const location = useLocation();

  const intl = useIntl();

  const handleLogOut = () => {
    logMessage(logLevel.info, 'User Logged out.');
    logout("User clicked on 'Log out' button in the kebab menu.");
    logOutSentryUser();
    navigate('/');
  };

  const looseFocus = () => {
    // @ts-ignore
    document.activeElement.blur();
  };

  return (
    <div
      className={css.container}
      tabIndex={0}
    >
      <KebabIcon />
      <div className={css.menu}>
        <MenuItem
          icon={<AiOutlineUser />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_account',
            defaultMessage: 'Manage licence key',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User went to account page.');
            void preload().electron.openLinkInBrowser(
              preload().electron.getSharedVariables().WEBAPP_URL,
            );
          }}
        />
        <MenuItem
          icon={<IoStatsChartSharp />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_usageStatistics',
            defaultMessage: 'Usage statistics',
          })}
          onClick={() => {
            looseFocus();
            addModalToQueue(ModalType.UsageStats);
          }}
        />
        <MenuItem
          icon={<RiGitRepositoryLine />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_academy',
            defaultMessage: 'Help',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User went to academy');

            void preload().electron.openLinkInBrowser(
              preload().electron.getSharedVariables().ACADEMY_URL,
            );
          }}
        />
        <MenuItem
          icon={<MdOutlineRateReview />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_review',
            defaultMessage: 'Review AutoCut',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User opened Senja in browser.');
            void preload().electron.openLinkInBrowser(
              'https://senja.io/p/autocut/r/ikmgDE',
            );
          }}
        />
        <MenuItem
          icon={<AiOutlineMail />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_bug',
            defaultMessage: 'Bug report',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User opened bug report modal.');
            addModalToQueue(ModalType.ReportIssue);
          }}
        />
        <MenuItem
          icon={<BsStars />}
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_featureRequest',
            defaultMessage: 'Feature request',
          })}
          onClick={() => {
            looseFocus();
            logMessage(logLevel.info, 'User opened feature request modal.');
            addModalToQueue(ModalType.FeatureRequest);
          }}
        />

        <MenuItem
          label={intl.formatMessage({
            id: 'globals_defaults_components_KebabMenu_settings',
            defaultMessage: 'Reset settings',
          })}
          onClick={() => {
            looseFocus();

            const mode = getModeByLocation(location.pathname);

            if (!mode) {
              Object.keys(AutocutModes).map((modeKey: string) => {
                const mode = AutocutModes[modeKey as AutocutModesKeys];

                setAutocutStore(
                  mode.parametersPath,
                  mode.defaultParameters as any,
                );
              });

              return;
            }

            parameterReset.reset();

            setAutocutStore(mode.parametersPath, mode.defaultParameters as any);
          }}
          icon={<AiOutlineUndo />}
        />

        {isLogged && (
          <MenuItem
            icon={<HiLogout />}
            label={intl.formatMessage({
              id: 'globals_defaults_components_KebabMenu_logout',
              defaultMessage: 'Logout',
            })}
            onClick={() => {
              looseFocus();
              handleLogOut();
            }}
          />
        )}
      </div>
    </div>
  );
};
