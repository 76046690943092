import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';

import {BRollsSizeStep} from './Parts/BRollsSizeStep';

export const BRollsParameters = () => {
  const {handleProcessTranscript, loading} =
    useHandleProcessTranscript('broll');

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <Button
              color={colors.primary600}
              ref={buttonRef}
              isLoading={loading || isLoading}
              disabled={isDisabled}
              onClick={() =>
                handleClickWithValidation(() => handleProcessTranscript())
              }
            >
              <TranslatedMessage
                id="modes_broll_steps_parameters_cta"
                defaultMessage="Next"
              />
            </Button>
          )}
        />
      }
    >
      <FlexContainer
        style={{paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <TranscriptLanguageStep modeId="broll" />
        <BRollsSizeStep />
      </FlexContainer>
    </ModeLayout>
  );
};
