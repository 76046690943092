export const DEFAULT_COLUMNS_NUMBER = 3;
export const CARD_SIZE = 155;
export const GRID_GAP = 24;

// Function to calculate the number of columns based on the container width
// As auto-fit does not work nicely in this chromium version
export const calculateColumnNumbers = ({
  cardsContainerElement,
  cardsNumber,
}: {
  cardsContainerElement: HTMLDivElement | null;
  cardsNumber: number;
}) => {
  if (!cardsContainerElement) return DEFAULT_COLUMNS_NUMBER;

  const cardWidth = CARD_SIZE + GRID_GAP;
  const containerWidth = cardsContainerElement.clientWidth;
  let columnNumbers = Math.floor(containerWidth / cardWidth);

  const columnWidthWithoutLastGap =
    (columnNumbers + 1) * CARD_SIZE + columnNumbers * GRID_GAP;

  if (columnWidthWithoutLastGap < containerWidth) columnNumbers += 1;

  return Math.min(columnNumbers, cardsNumber);
};
