import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import {autocutStoreVanilla} from '@autocut/utils/zustand';
import {useEffect} from 'react';

export const useBetaOnboarding = () => {
  const {isBetaOnboarded, host} = useAutoCutStore(state => ({
    isBetaOnboarded: state.user.isBetaOnboarded,
    host: state.ui.host,
  }));
  console.log(
    'isBetaOnboarded',
    isBetaOnboarded,
    autocutStoreVanilla().user.isBetaOnboarded,
  );
  useEffect(() => {
    if (!isBetaOnboarded && host === 'ppro') {
      addModalToQueue(ModalType.BetaOnboarding);
    }
  }, [isBetaOnboarded, host]);
};
