import Range from '@autocut/components/Range/Range';
import React, {CSSProperties} from 'react';

import {EditableText} from '../EditableText/EditableText';
import css from './InputRangeParameter.module.css';

export type InputRangeParameterProps = {
  unit?: string;
  unitFormatting?: (value: any) => string;
  onChange?: (value: string) => void;
} & Omit<
  React.ComponentPropsWithoutRef<'input'>,
  'type' | 'className' | 'onChange'
>;

export const InputRangeParameter = ({
  unit,
  unitFormatting,
  onChange,
  ...inputProps
}: InputRangeParameterProps) => {
  const valueToDisplay = unitFormatting
    ? unitFormatting(inputProps.value)
    : `${inputProps.value ?? ''}`;
  const maxValueToDisplay = unitFormatting
    ? unitFormatting(inputProps.max)
    : `${inputProps.max ?? ''}`;

  return (
    <>
      <div
        className={css.inputContainer}
        style={
          {
            '--max-input-size': `${maxValueToDisplay.length}ch`,
            '--unit-size': `${unit?.length || 0}ch`,
          } as CSSProperties
        }
      >
        <EditableText
          initialValue={valueToDisplay}
          onChange={value => onChange?.(value)}
          disabled={inputProps.disabled}
        />
        {unit ? <p className={css.unit}>{unit}</p> : null}
      </div>
      <Range
        inputProps={{
          ...inputProps,
          onChange: (e: any) => onChange?.(e.target.value),
        }}
      />
    </>
  );
};
