import {preload} from '@autocut/types/ElectronPreload';
import {TrackItem, TrackType} from '@autocut/types/JSONTimeline';

import {IncrementalError} from './errors/IncrementalError';
import {AutoCutState, autocutStoreVanilla} from './zustand';

export type HostRequestId = ReturnType<
  ReturnType<typeof preload>['hostNodeIpc']['request']
>['requestId'];

export type HostHandler<OutputType> = Promise<OutputType> & {
  requestId: HostRequestId;
};

type ExportArgs =
  | {inPoint: number; outPoint: number}[]
  | {exportBetweenMarkers?: boolean; inPoint?: number; outPoint?: number};

/* This function is there to check if the current environment is Host or Premiere */
export const isHost = (host: AutoCutState['ui']['host']) => {
  return host === autocutStoreVanilla().ui.host;
};
export const isMac = () => {
  return navigator.userAgent.toUpperCase().indexOf('MAC') >= 0;
};

const hostHandler = <OutputType>(
  path: string,
  data?: {},
  sequenceId?: string,
): HostHandler<OutputType> => {
  const runRequest = () => {
    console.log(
      ...[
        `[Lua] Requesting "${path}"${data ? 'with data:' : ''}`,
        ...(data ? [data] : []),
      ],
    );
    return preload().hostNodeIpc.request(path, {...data, sequenceId});
  };

  const request = runRequest();
  const res = request.promise.catch(err => {
    console.log({err});
    throw new IncrementalError(err.content, err.from);
  }) as HostHandler<OutputType>;
  res.requestId = request.requestId;

  return res;
};

export const onlyFor = <F extends (...args: any) => any>(
  hostName: 'ppro' | 'davinci',
  func: F,
): ReturnType<F> => {
  if (
    autocutStoreVanilla().ui.host !== hostName &&
    autocutStoreVanilla().ui.host !== undefined
  ) {
    throw new IncrementalError(
      `This function is only available for ${hostName}`,
      'host',
    );
  }

  return func();
};

const hostFunc = (sequenceId?: string) => ({
  misc: {
    isConnected: () =>
      hostHandler<{isConnected: boolean}>('isConnected', sequenceId),
    close: () => hostHandler<{isConnected: boolean}>('close', sequenceId),
    version: () =>
      hostHandler<{
        clientVersion: string;
        serverVersion: string;
        hostName: 'ppro' | 'davinci';
      }>('/misc/version', sequenceId),
    hostVersion: () => hostHandler<number[]>('/misc/host-version', sequenceId),
    dev: () => hostHandler<string>('/misc/dev', sequenceId),
    bigData: () => hostHandler<string>('/misc/big-data', sequenceId),
    getProductName: () =>
      onlyFor('davinci', () =>
        hostHandler<'DaVinci Resolve Studio' | 'DaVinci Resolve'>(
          '/misc/get-product-name',
          {},
          sequenceId,
        ),
      ),
  },
  timeline: {
    getName: () => hostHandler<string>('/timeline/get-name', sequenceId),
    getFrameRate: () => hostHandler<number>('/timeline/framerate', sequenceId),
    getStartFrame: () =>
      hostHandler<number>('/timeline/start-frame', sequenceId),
    exportFrame: () =>
      hostHandler<string>('/timeline/export-frame', sequenceId),
    getCurrentTimecode: () =>
      hostHandler<number>('/timeline/get-current-timecode', sequenceId),
    setCurrentTimecode: (data: {timecode: string} | {frames: number}) =>
      hostHandler<number>('/timeline/set-current-timecode', data, sequenceId),
    getTrackCount: (trackType: TrackType) =>
      hostHandler<number>(
        '/timeline/track-count',
        {
          track_type: trackType,
        },
        sequenceId,
      ),
    getInOut: () =>
      hostHandler<{
        inPoint: number;
        outPoint: number;
      }>('/timeline/get-in-out', sequenceId),
    getTimelineFormat: () =>
      hostHandler<{
        width: `${number}`;
        height: `${number}`;
      }>('/timeline/format', sequenceId),
    getTrackItemsCount: () =>
      hostHandler<{audio: number; video: number}>(
        '/timeline/get-track-items-count',
        sequenceId,
      ),
    drt: {
      export: () => hostHandler<string>('/timeline/export'),
      import: (drtPath: string) =>
        hostHandler<void>('/timeline/import', drtPath, sequenceId),
    },
    wav: {
      export: <T extends string | string[]>(data: ExportArgs): Promise<T> =>
        hostHandler<T>('/export-audio/timeline', {intervals: data}, sequenceId),
    },
    backup: () =>
      onlyFor('ppro', () =>
        hostHandler<{backupId: string; originalId: string}>(
          '/timeline/backup',
          {},
          sequenceId,
        ),
      ),
    restore: (backupId: string, originalId: string) =>
      onlyFor('ppro', () =>
        hostHandler<string>(
          '/timeline/restore',
          {backupId, originalId},
          sequenceId,
        ),
      ),
    addMarker: (data: {
      startFrame: number;
      duration: number;
      name: string;
      color: string;
    }) =>
      onlyFor('ppro', () =>
        hostHandler('/timeline/add-marker', data, sequenceId),
      ),
    deleteAutocutMarkers: () =>
      onlyFor('ppro', () =>
        hostHandler('/timeline/delete-autocut-markers', sequenceId),
      ),
    getMarkers: (name: string) =>
      onlyFor('ppro', () =>
        hostHandler<{startFrame: number; endFrame: number}[]>(
          '/timeline/get-markers',
          {name},
          sequenceId,
        ),
      ),
    getSelection: () =>
      onlyFor('ppro', () =>
        hostHandler<{startFrame: number; endFrame: number}[]>(
          '/timeline/get-selection',
          sequenceId,
        ),
      ),
  },
  track: {
    enable: (trackType: TrackType, trackIndex: number) =>
      hostHandler<boolean>(
        '/track/enable',
        {
          track_type: trackType,
          track_index: trackIndex,
        },
        sequenceId,
      ),
    disable: (trackType: TrackType, trackIndex: number) =>
      hostHandler<boolean>(
        '/track/disable',
        {
          track_type: trackType,
          track_index: trackIndex,
        },
        sequenceId,
      ),
    isEnabled: (trackType: TrackType, trackIndex: number) =>
      hostHandler<boolean>(
        '/track/is-enabled',
        {
          track_type: trackType,
          track_index: trackIndex,
        },
        sequenceId,
      ),
    getTrackItems: (trackType: TrackType, trackIndex: number) =>
      hostHandler<TrackItem[]>(
        '/track/get-track-items',
        {
          track_type: trackType,
          track_index: trackIndex,
        },
        sequenceId,
      ),
  },
  mediaPool: {
    createFolders: (folders: string[]) =>
      hostHandler<boolean>(
        '/media-pool/create-folders',
        {
          folderPaths: folders,
        },
        sequenceId,
      ),
    deleteFolders: (folders: string[]) =>
      hostHandler<boolean>(
        '/media-pool/delete-folders',
        {
          folderPaths: folders,
        },
        sequenceId,
      ),
    isFileExists: (folders: string[], fileName: string) =>
      hostHandler<boolean>(
        '/media-pool/is-file-exists',
        {
          folderPaths: folders,
          fileName,
        },
        sequenceId,
      ),
    importFiles: (folderPaths: string[], filePaths: string[]) =>
      hostHandler<boolean>(
        '/media-pool/import-files',
        {
          folderPaths,
          filePaths,
        },
        sequenceId,
      ),
  },
});

function addProperties(objectA: any, objectB: any) {
  for (const prop in objectB) {
    objectA[prop] = objectB[prop];
  }
}

type Host = {
  (...args: Parameters<typeof hostFunc>): ReturnType<typeof hostFunc>;
} & ReturnType<typeof hostFunc>;
const host: Host = ((sequenceId?: string) =>
  hostFunc(sequenceId)) as unknown as Host;
addProperties(host, hostFunc());

export {host};
