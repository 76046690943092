import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useScroll} from '@autocut/hooks/useScroll';
import {useRef, useState} from 'react';
import {useIntl} from 'react-intl';

import {PaddingStep} from './PaddingStep/PaddingStep';
import {PresetStep} from './PresetStep/PresetStep';
import {SilenceSizeStep} from './SilenceSizeStep/SilenceSizeStep';

export const PacingStep = () => {
  const intl = useIntl();
  const ref = useRef<NodeJS.Timeout | null>(null);
  const {scrollTo} = useScroll();

  const [isAnimated, setIsAnimated] = useState(false);

  const animate = () => {
    scrollTo('pacingSection');
    setIsAnimated(true);
    if (ref.current) {
      clearTimeout(ref.current);
    }
    ref.current = setTimeout(() => {
      setIsAnimated(false);
    }, 4000);
  };
  return (
    <FormSection
      id="pacingSection"
      title={intl.formatMessage({
        id: 'modes_silence_steps_pacing_title',
        defaultMessage: 'Pacing',
      })}
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s3}
      >
        <PresetStep animate={animate} />
        <SilenceSizeStep isAnimated={isAnimated} />
        <PaddingStep isAnimated={isAnimated} />
      </FlexContainer>
    </FormSection>
  );
};
