import {LoadingRing} from '@autocut/components/LoadingRing/LoadingRing';
import {ColorKeys} from '@autocut/designSystem/colors';
import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  forwardRef,
  MouseEvent,
  useState,
} from 'react';

import FlexContainer from '../../molecules/FlexContainer';
import {Text} from '../Text/Text';
import css from './Button.module.scss';
import {ButtonVariant} from './variants';

export type ButtonProps = {
  children: React.ReactNode;
  onClick?: (e?: MouseEvent<HTMLButtonElement>) => Promise<any> | any;
  disabled?: boolean;
  variant?: ButtonVariant;
  size?: 'lg' | 'md' | 'sm';
  className?: string;
  style?: CSSProperties;
  color?: CSSProperties['color'] | ColorKeys;
  fullWidth?: boolean;
  isLoading?: boolean;
  loaderSize?: number;
} & Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'children' | 'onClick' | 'disabled' | 'className' | 'style'
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      onClick,
      disabled = false,
      variant = 'primary',
      size = 'md',
      className,
      isLoading,
      loaderSize = 20,
      style,
      color = 'white',
      fullWidth = true,
      ...props
    },
    ref,
  ) => {
    const [isLoadingState, setIsLoadingState] = useState(false);

    const loading = isLoadingState || isLoading;

    const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
      if (!onClick || loading) return;

      setIsLoadingState(true);
      await onClick(e);
      setIsLoadingState(false);
    };

    return (
      <button
        ref={ref}
        style={
          {
            '--color': color,
            '--width': fullWidth ? '100%' : 'fit-content',
            ...style,
          } as CSSProperties
        }
        className={`${css.root} ${className}`}
        data-variant={variant}
        data-disabled={disabled}
        data-size={size}
        onClick={handleClick}
        data-loading={loading}
        disabled={disabled}
        {...props}
      >
        {loading ? (
          <FlexContainer
            alignItems="center"
            justifyContent="center"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <LoadingRing size={loaderSize} />
          </FlexContainer>
        ) : (
          <Text
            variant="textSm"
            color={variant === 'tertiary' ? color : 'white'}
          >
            {children}
          </Text>
        )}
      </button>
    );
  },
);
