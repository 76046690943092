import English from '@autocut/lang/en-US';
import Spanish from '@autocut/lang/es';
import French from '@autocut/lang/fr';
import Japanese from '@autocut/lang/jp';
import Korean from '@autocut/lang/kr';
import Portugueses from '@autocut/lang/pt';
import {CURRENT_ENV, Env} from '@autocut/utils/currentEnv.utils';
import {getLanguage, setLanguage} from '@autocut/utils/localStorage.utils';
import {FilteredLiterral, GenerateKeys} from '@autocut/utils/type.utils';
import {createContext, useMemo, useState} from 'react';
import {IntlProvider} from 'react-intl';

export type DO_NOT_USE_AllTranslationKeys = GenerateKeys<
  typeof English,
  '_',
  true
>;
export type TranslationKeys = FilteredLiterral<
  DO_NOT_USE_AllTranslationKeys,
  'old_'
>;

function compareObjects(objects: object[], prefix: string = ''): string[][] {
  const properties: string[] = [];
  const objectProperties: string[] = [];

  // Get all unique properties from the objects
  objects.forEach(obj => {
    Object.keys(obj).forEach(prop => {
      if (
        !properties.includes(prop) &&
        typeof obj[prop as keyof typeof obj] !== 'object'
      ) {
        properties.push(prop);
      }
      if (
        !objectProperties.includes(prop) &&
        typeof obj[prop as keyof typeof obj] === 'object'
      ) {
        objectProperties.push(prop);
      }
    });
  });

  // Compare the objects and find the missing properties
  const differences: string[][] = objects.map(obj => {
    const missingLeafProps: string[] = properties
      .filter(prop => typeof obj === 'object' && !(prop in obj))
      .map(prop => `${prefix}${prop}`);
    return [...missingLeafProps];
  });

  const childDifferences: string[][] = objectProperties
    .map(prop => {
      const childObjects = objects.map(
        obj => obj[prop as keyof typeof obj] || {},
      );
      return compareObjects(childObjects, `${prefix}${prop}_`);
    })
    .flat();

  return differences.map((diff, index) => [
    ...diff,
    ...(childDifferences[index] || []),
  ]);
}

const translationsMissings = compareObjects([
  English,
  Spanish,
  French,
  Japanese,
  Korean,
  Portugueses,
]);
if (translationsMissings.some(missing => missing.length > 0)) {
  const report = {
    English: translationsMissings[0],
    Spanish: translationsMissings[1],
    French: translationsMissings[2],
    Japanese: translationsMissings[3],
    Korean: translationsMissings[4],
    Portugueses: translationsMissings[5],
  };
  if (CURRENT_ENV === Env.Development) {
    console.warn('Missing translations !\n', report);
  } else {
    // throw new Error(
    //   `Missing translations !\n${JSON.stringify(report, null, 2)}`,
    // );
  }
}

const formatMessages = (obj: unknown, prefix: string = '') => {
  if (typeof obj === 'string') {
    return {[prefix]: obj};
  } else if (typeof obj === 'object') {
    let result = {};
    for (const key in obj) {
      if (key)
        result = {
          ...result,
          ...formatMessages(
            (obj as any)[key],
            `${prefix ? `${prefix}_` : ''}${key}`,
          ),
        };
    }
    return result;
  }
};

const getMessages = (locale: Locale) => {
  switch (locale) {
    case 'FR':
      return French;
    case 'EN':
      return English;
    case 'PT':
      return Portugueses;
    case 'ES':
      return Spanish;
    case 'KR':
      return Korean;
    case 'JP':
      return Japanese;
    default:
      return English;
  }
};

export const availableLocales = ['FR', 'EN', 'PT', 'ES', 'KR', 'JP'] as const;

export const isTranslationKeyExistInObj = (
  key: string,
  obj: Record<string, string | Record<string, string>>,
): boolean => {
  const keyParts = key.split('_');
  if (keyParts.length === 1) {
    return key in obj && typeof obj[key] === 'string';
  }

  const firstPart = keyParts.shift();
  return (
    !!firstPart &&
    firstPart in obj &&
    typeof obj[firstPart] !== 'string' &&
    isTranslationKeyExistInObj(keyParts.join('_'), obj[firstPart] as any)
  );
};

export const getTranslationKeyStatus = (
  key: string,
): 'full' | 'partial' | 'missing' => {
  // 'full' : exist everywhere
  // 'partial' : exist in some locales
  // 'missing' : does not exist
  let res: 'full' | 'partial' | 'missing' = 'missing';
  let found = false;

  availableLocales.forEach(locale => {
    // console.info('Checking ', key, ' in ', locale);
    const foundInLanguage = isTranslationKeyExistInObj(
      key,
      getMessages(locale) as any,
    );
    // console.info(foundInLanguage ? 'Found' : 'Not found', ' in ', locale);
    if (foundInLanguage) {
      if (res === 'missing') {
        res = 'full';
        found = true;
      }
    } else {
      if (found) {
        res = 'partial';
      }
    }
  });

  return res;
};

export type Locale = (typeof availableLocales)[number];

export const pproLocales = [
  'de_DE',
  'en_US',
  'es_ES',
  'fr_FR',
  'it_IT',
  'ja_JP',
  'ko_KR',
  'pt_BR',
  'ru_RU',
  'zh_CN',
] as const;
export type PProLocale = (typeof pproLocales)[number];
export const CAPTIONS_XML_PROJECT_LOCALE = 'en_US';

interface ILocaleContext {
  locale: Locale;
  setAndSaveLocale: (locale: Locale) => void;
  availableLocales: typeof availableLocales;
}

export const LocaleContext = createContext<ILocaleContext>({
  locale: null as any,
  setAndSaveLocale: null as any,
  availableLocales: null as any,
});

export const LocaleProvider = ({children}: {children: any}) => {
  const loadedLocale = getLanguage();

  const [locale, setLocale] = useState<Locale>(loadedLocale);

  const setAndSaveLocale = (locale: Locale) => {
    setLocale(locale);
    setLanguage(locale);
  };

  const messages = useMemo(
    () =>
      formatMessages(getMessages(locale)) as Record<
        DO_NOT_USE_AllTranslationKeys,
        string
      >,
    [locale],
  );

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setAndSaveLocale,
        availableLocales,
      }}
    >
      <IntlProvider
        locale={locale}
        messages={messages}
        onError={
          CURRENT_ENV !== Env.Production
            ? e => {
                console.info('Translation error\n', e.message);
              }
            : undefined
        }
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};
