import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {SelectedAudioTrackStep} from '@autocut/components/SelectedAudioTracksStep/SelectedAudioTracksStep';
import {TranscriptLanguageStep} from '@autocut/components/TranscriptLanguageStep/TranscriptLanguageStep';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useHandleProcessTranscript} from '@autocut/hooks/useHandleProcessTranscript/useHandleProcessTranscript';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect} from 'react';

import {PreviousTranscriptStep} from './PreviousTranscriptStep/PreviousTranscriptStep';

export const CaptionsLanguage = () => {
  const {handleProcessTranscript, error, loading} =
    useHandleProcessTranscript('caption');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({isLoading, isDisabled}) => (
            <Button
              onClick={async () => {
                setAutocutStore(
                  'onGoingProcess.caption.transcriptFileName',
                  undefined,
                );
                await handleProcessTranscript();
              }}
              disabled={loading || isLoading || isDisabled}
              color={colors.primary600}
            >
              <TranslatedMessage
                id="modes_caption_steps_language_button"
                defaultMessage="Generate transcript"
              />
            </Button>
          )}
        />
      }
    >
      <FlexContainer
        style={{paddingBottom: Spacing.s20}}
        flexDirection="column"
        gap={Spacing.s4}
      >
        <TranscriptLanguageStep modeId="caption" />
        <SelectedAudioTrackStep />
        <PreviousTranscriptStep />
      </FlexContainer>
    </ModeLayout>
  );
};
