import {ModalType} from '@autocut/enums/modals.enum';

import {autocutStoreVanilla, setAutocutStore} from '../zustand';

/**
 * This function adds a modal to the start of the queue.
 * It will display the modal immediately.
 * If there is a modal already displayed, it will be pushed to the start of the queue.
 */
export const displayPriorityModal = (modal: ModalType) => {
  console.log('[MODAL] Displaying priority modal: ', modal);
  const currentModal = autocutStoreVanilla().ui.openedModalName;

  if (currentModal === modal) return;

  setAutocutStore('ui.openedModalName', modal);
  if (currentModal)
    setAutocutStore('ui.modalQueue', s => [currentModal, ...s.ui.modalQueue]);
};
