import {nanoid} from 'nanoid';
import {useRef, useState} from 'react';

import {IconXClose} from '../../atoms/Icon/general/IconXClose';
import {Text} from '../../atoms/Text/Text';
import FlexContainer from '../FlexContainer';
import css from './FileInput.module.scss';

export type FileWithPath = File & {path: string};

export type FileInputProps = {
  onChange: (files: FileWithPath[]) => void;
  defaultFiles?: FileWithPath[];
  buttonText?: string;
  noFileText?: string;
  multiple?: boolean;
};

export const FileInput = ({
  onChange,
  defaultFiles,
  buttonText,
  noFileText,
  multiple = false,
}: FileInputProps) => {
  const id = useRef(nanoid());
  const input = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<FileWithPath[] | undefined>(defaultFiles);

  const onDelete = () => {
    setFiles(undefined);
    onChange([]);
  };

  return (
    <div className={css.root}>
      <label
        className={css.label}
        htmlFor={id.current}
      >
        <FlexContainer
          className={css.container}
          alignItems="center"
        >
          <Text
            className={css.button}
            variant="textSm.semibold"
          >
            {buttonText || 'Choose a file'}
          </Text>
          <FlexContainer
            className={css.text}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text variant="textSm.semibold">
              {!files || files.length === 0
                ? noFileText || 'No file chosen'
                : files.map(file => file.name).join(', ')}
            </Text>
            {files && files.length > 0 && (
              <IconXClose
                className={css.closeIcon}
                size={18}
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  onDelete();
                }}
              />
            )}
          </FlexContainer>
        </FlexContainer>
      </label>
      <input
        id={id.current}
        ref={input}
        type="file"
        style={{display: 'none'}}
        onChange={e => {
          const files = Array.from(e.target.files || []);
          setFiles(files as FileWithPath[]);
          onChange(files as FileWithPath[]);
        }}
        multiple={multiple}
      />
    </div>
  );
};
