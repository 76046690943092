import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {
  Button,
  ButtonProps,
} from '@autocut/designSystem/components/atoms/Button/Button';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';

import css from './ConfirmModal.module.scss';

export namespace ConfirmModal {
  export type Props = {
    onConfirm: () => void;
    onCancel: () => void;
    title?: JSX.Element | string;
    confirmButtonProps?: Partial<ButtonProps>;
    cancelButtonProps?: Partial<ButtonProps>;
    body?: React.ReactNode;
  };
}

export const ConfirmModal = ({
  onConfirm,
  onCancel,
  title,
  confirmButtonProps,
  cancelButtonProps,
  body,
}: ConfirmModal.Props) => {
  return (
    <Modal
      title={
        title ?? (
          <TranslatedMessage
            id="modals_confirmModal_title"
            defaultMessage="Are you sure?"
          />
        )
      }
      closeModalFunction={onCancel}
      footer={
        <FlexContainer
          flexDirection="row"
          gap={16}
          justifyContent="space-between"
          style={{width: '100%'}}
        >
          <Button
            onClick={onCancel}
            className={css.button}
            variant="secondary"
            {...cancelButtonProps}
          >
            {cancelButtonProps?.children ?? (
              <TranslatedMessage
                id="modals_confirmModal_cancel"
                defaultMessage="Cancel"
              />
            )}
          </Button>
          <Button
            onClick={onConfirm}
            className={css.button}
            variant="primary"
            color={colors.primary600}
            {...confirmButtonProps}
          >
            {confirmButtonProps?.children ?? (
              <TranslatedMessage
                id="modals_confirmModal_confirm"
                defaultMessage="Confirm"
              />
            )}
          </Button>
        </FlexContainer>
      }
    >
      {body ?? (
        <TranslatedMessage
          id="modals_confirmModal_text"
          defaultMessage="This action cannot be undone."
        />
      )}
    </Modal>
  );
};
