import {CanvasFontParams} from '@autocut/types/Captions';

import {measureTextOnCanvas} from './measureTextOnCanvas';

export const splitTextIntoLines = (
  text: string,
  {
    font,
    maxWidth,
    uppercase,
  }: {
    font: CanvasFontParams;
    maxWidth: number;
    uppercase: boolean;
  },
) => {
  const words = text.split(' ');
  const lines = [];

  let currentLine = '';
  let currentLineNbWords = 0;
  let currentIndex = 0;

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    const newLineWidth = measureTextOnCanvas(
      `${currentLine} ${word}`,
      font,
      uppercase,
    ).metrics.width;

    // If the new line width is greater than the max width, we push the current line and start a new one
    if (newLineWidth > maxWidth) {
      if (currentLine === '') {
        const wordMetrics = measureTextOnCanvas(word, font, uppercase).metrics;
        //If the new line is empty, it's only the new word that is too long, so we add it as a line
        lines.push({
          value: word,
          width: wordMetrics.width,
          height: wordMetrics.height,
          nbWords: 1,
          startIndex: currentIndex,
          endIndex: currentIndex,
        });
        currentLine = '';
        currentLineNbWords = 0;
      } else {
        const lineMetrics = measureTextOnCanvas(
          currentLine.trim(),
          font,
          uppercase,
        ).metrics;
        lines.push({
          value: currentLine.trim(),
          width: lineMetrics.width,
          height: lineMetrics.height,
          nbWords: currentLineNbWords,
          startIndex: currentIndex - currentLineNbWords,
          endIndex: currentIndex - 1,
        });
        currentLine = word;
        currentLineNbWords = 1;
      }
    } else {
      currentLine = `${currentLine} ${word}`;
      currentLineNbWords++;
    }
    currentIndex++;
  }

  if (currentLine !== '') {
    const lineMetrics = measureTextOnCanvas(
      currentLine.trim(),
      font,
      uppercase,
    ).metrics;
    //If the last line is empty because the last word was too long, we don't add it
    lines.push({
      value: currentLine.trim(),
      width: lineMetrics.width,
      height: lineMetrics.height,
      nbWords: currentLineNbWords,
      startIndex: currentIndex - currentLineNbWords,
      endIndex: currentIndex - 1,
    });
  }

  return lines;
};
