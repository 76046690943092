import {CaptionsParameters} from '@autocut/types/CaptionsParameters';
import {rgbToInteger} from '@autocut/utils/color.utils';

import {
  PproSourceTextCapsOptionsEnum,
  PproSourceTextParam,
} from '../_formatSourceTextData';

export const getXMLTextParameters = (
  parameters: CaptionsParameters,
): PproSourceTextParam => {
  return {
    mTextParam: {
      mNumStrokes: parameters.outline.enabled ? 1 : 0,
      mShadowVisible: parameters.formating.glow.enabled,
      mShadowColor: rgbToInteger(parameters.formating.glow.color),
      mShadowBlur: parameters.formating.glow.intensity,
      mShadowOpacity: parameters.formating.glow.enabled ? 100 : 0,
      mShadowOffset: 0,
      mShadowSize: 0,
      mStyleSheet: {
        mFillColor: {mParamValues: [[0, rgbToInteger(parameters.text.color)]]},
        mFontName: {mParamValues: [[0, parameters.text.font.postscriptName]]},
        mFontSize: {mParamValues: [[0.0, parameters.text.fontSize]]},
        mStrokeVisible: {
          mParamValues: [[0, parameters.outline.enabled ? true : false]],
        },
        mStrokeWidth: {mParamValues: [[0, parameters.outline.width]]},
        mStrokeColor: {
          mParamValues: [[0, rgbToInteger(parameters.outline.color)]],
        },
        mFauxItalic: {mParamValues: [[0, parameters.formating.italic]]},
        mCapsOption: {
          mParamValues: [
            [
              0,
              parameters.formating.uppercase
                ? PproSourceTextCapsOptionsEnum.ALL_CAPS
                : PproSourceTextCapsOptionsEnum.NORMAL,
            ],
          ],
        },
      },
    },
  };
};
