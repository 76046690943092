import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {preload} from '@autocut/types/ElectronPreload';

export const versionValidation: LaunchStepHandler = async (
  setProgress,
): Promise<boolean> => {
  //TODO
  if (
    preload().electron.getVersion().endsWith('4.19.1') &&
    preload().os.platform() === 'win32'
  ) {
    return false;
  }

  return true;
};
