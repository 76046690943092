import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {
  addProcessSteps,
  endCurrentProcessProgress,
  endProcessStep,
  initProcessProgress,
  ProcessProgress,
  startProcessProgress,
} from '@autocut/utils/process/progress';
import {autocutStoreVanilla, setAutocutStore} from '@autocut/utils/zustand';
import {IntlShape} from 'react-intl';

import {handleRestore} from '../backup';
import {handleProcessEnd} from './handleProcessEnd';
import {handleProcessError} from './handleProcessError';
import {handleProcessFinally} from './handleProcessFinally';
import {handleProcessStart} from './handleProcessStart';

type HandleProcessBaseType<Args extends any[]> = {
  executeProcess: (
    intl: IntlShape,
    processProgress: ProcessProgress,
    ...params: Args
  ) => any;
  handleProcessErrorAdditional?: (err: unknown, intl: IntlShape) => void;
  handleProcessFinallyAdditional?: (intl: IntlShape) => void;
};

export const handleProcessBase =
  <Args extends any[]>(
    {
      executeProcess,
      handleProcessErrorAdditional,
      handleProcessFinallyAdditional,
    }: HandleProcessBaseType<Args>,
    {
      processTitleNameKey,
      processSteps,
    }: {
      processTitleNameKey: TranslationKeys;
      processSteps: Parameters<typeof addProcessSteps>[1];
    },
  ) =>
  async (intl: IntlShape, additionalParams: Args = [] as unknown as Args) => {
    try {
      if (autocutStoreVanilla().ui.process.isProcessing) {
        throw new IncrementalError(
          new Error('Process already running'),
          'handleProcessBase',
        );
      }
      setAutocutStore('ui.process.isProcessing', true);

      const processProgress = initProcessProgress(
        autocutStoreVanilla().ui.process.mode.id,
        processTitleNameKey,
      );
      addProcessSteps(processProgress, [
        {
          id: 'starting',
          translationKey: 'progress_steps_global_starting',
          progress: 1,
        },
        ...processSteps,
        {
          id: 'ending',
          translationKey: 'progress_steps_global_ending',
          progress: 1,
        },
      ]);

      startProcessProgress(processProgress);

      await handleProcessStart();

      endProcessStep(processProgress, 'starting');

      try {
        await executeProcess(intl, processProgress, ...additionalParams);
      } catch (err: any) {
        await handleRestore();
        throw new IncrementalError(err, 'executeProcess');
      }

      await handleProcessEnd(intl);
      endProcessStep(processProgress, 'ending');
    } catch (err) {
      await handleProcessError(err, intl);
      if (handleProcessErrorAdditional) handleProcessErrorAdditional(err, intl);
    } finally {
      await handleProcessFinally();
      if (handleProcessFinallyAdditional) handleProcessFinallyAdditional(intl);
      endCurrentProcessProgress();
    }
  };
