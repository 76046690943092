import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {CaptionChunk} from '@autocut/types/Captions';
import React from 'react';

export const CaptionsTranscriptButtons = ({
  captionChunks,
  updateChunks,
}: {
  captionChunks: CaptionChunk[];
  updateChunks: (captionChunks: CaptionChunk[]) => void;
}) => {
  return (
    <FlexContainer
      flexDirection="row"
      alignItems="center"
      gap={8}
    >
      {/* 
      TODO : Generate emojis
      <Button
        onClick={() => {
          addModalToQueue([ModalType.GenerateEmojis, ModalType.EditTranscript]);
          setAutocutStore('ui.openedModalName', '');
        }}
        variant="secondary"
        fullWidth={false}
      >
        <FlexContainer
          flexDirection="row"
          alignItems="center"
          gap={4}
        >
          <BsEmojiSmile />

          <TranslatedMessage
            id={'caption_transcript_add_emojis_cta' as any}
            defaultMessage="Generate emojis"
          />
        </FlexContainer>
      </Button> */}

      {/* <Button
        onClick={() => {
          setAutocutStore('ui.openedModalName', ModalType.Processing);
          setAutocutStore(
            'ui.cutButtonMessage',
            'process_modal_formatting_numbers',
          );

          void formatNumbersFromChunks({
            transcription: captionChunks,
          }).then(({newTranscription, replacedWords}) => {
            setAutocutStore(
              'ui.parameters.caption.postProcessing.formattedNumbers',
              replacedWords,
            );

            setAutocutStore('ui.openedModalName', ModalType.FormattedNumber);
            addModalToQueue(ModalType.EditTranscript);
            setAutocutStore('ui.cutButtonMessage', '');
            updateChunks(newTranscription);
          });
        }}
        variant="secondary"
        fullWidth={false}
      >
        <FlexContainer
          flexDirection="row"
          alignItems="center"
          gap={4}
        >
          <TbNumbers />

          <TranslatedMessage
            id={
              'modes_caption_steps_customization_parts_transcription_modal_formatNumbers'
            }
            defaultMessage="Format numbers"
          />
        </FlexContainer>
      </Button> */}
    </FlexContainer>
  );
};
