import {EmojiCategory} from '@autocut/utils/emojis/getEmojisCategories';

export const CATEGORIES_PARAMS: Record<
  EmojiCategory,
  {
    intlId: string;
    icon: string;
    order: number;
  }
> = {
  [EmojiCategory.SMILEYS]: {
    intlId: 'emojis.smileys',
    icon: '😀',
    order: 0,
  },
  [EmojiCategory.PEOPLE]: {
    intlId: 'emojis.people',
    icon: '👨',
    order: 1,
  },
  [EmojiCategory.ANIMALS_AND_NATURE]: {
    intlId: 'emojis.nature',
    icon: '🌳',
    order: 2,
  },
  [EmojiCategory.FOOD_AND_DRINK]: {
    intlId: 'emojis.food',
    icon: '🍔',
    order: 3,
  },
  [EmojiCategory.ACTIVITY]: {
    intlId: 'emojis.activities',
    icon: '🎾',
    order: 4,
  },
  [EmojiCategory.TRAVEL_AND_PLACES]: {
    intlId: 'emojis.travel',
    icon: '🚗',
    order: 5,
  },
  [EmojiCategory.OBJECTS]: {
    intlId: 'emojis.objects',
    icon: '💡',
    order: 6,
  },
  [EmojiCategory.SYMBOLS]: {
    intlId: 'emojis.symbols',
    icon: '🔣',
    order: 7,
  },
  [EmojiCategory.FLAGS]: {
    intlId: 'emojis.flags',
    icon: '🏳️',
    order: 8,
  },
  [EmojiCategory.SKIN_TONES]: {
    intlId: 'emojis.skinTones',
    icon: '👌',
    order: 9,
  },
};
