import {IconComponent, IconComponentProps} from '../Icon';

export const IconGift01 = (props: IconComponentProps) => {
  return (
    <IconComponent
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M12 6v16m0-16H8L7 5a2 2 0 0 1 0-2l1-1c3 0 4 4 4 4Zm0 0h4l1-1a2 2 0 0 0 0-2l-1-1c-3 0-4 4-4 4Zm8 5v10l-1 1H5l-1-1V11M2 8v2l1 1h18l1-1V7l-1-1H3L2 7v1Z"
      />
    </IconComponent>
  );
};
