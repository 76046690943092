import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {Badge} from '@autocut/designSystem/components/atoms/Badge/Badge';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {useScroll} from '@autocut/hooks/useScroll';
import {Preset} from '@autocut/types/Preset';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useIntl} from 'react-intl';

const silencePresets: Preset<'silence'>[] = [
  {
    id: 'calm',
    parameters: {
      minTimeOfSilence: 0.5,
      minTimeOfTalk: 0.5,
      marginBefore: 0.5,
      marginAfter: 0.5,
    },
  },
  {
    id: 'measured',
    parameters: {
      minTimeOfSilence: 0.35,
      minTimeOfTalk: 0.35,
      marginBefore: 0.35,
      marginAfter: 0.35,
    },
  },
  {
    id: 'paced',
    parameters: {
      minTimeOfSilence: 0.2,
      minTimeOfTalk: 0.2,
      marginBefore: 0.2,
      marginAfter: 0.2,
    },
  },
  {
    id: 'energetic',
    parameters: {
      minTimeOfSilence: 0.1,
      minTimeOfTalk: 0.1,
      marginBefore: 0.1,
      marginAfter: 0.1,
    },
  },
  {
    id: 'jumpy',
    parameters: {
      minTimeOfSilence: 0.05,
      minTimeOfTalk: 0.05,
      marginBefore: 0.05,
      marginAfter: 0.05,
    },
  },
];

export type PresetStepProps = {
  animate?: () => void;
};

export const PresetStep = ({animate}: PresetStepProps) => {
  const intl = useIntl();
  const silenceParameters = useAutoCutStore(
    state => state.ui.parameters.silence,
  );

  const handleClick = (preset: Preset<'silence'>) => {
    const updatedParameters = {
      ...silenceParameters,
      ...preset.parameters,
    };
    setAutocutStore('ui.parameters.silence', updatedParameters);

    if (animate) {
      animate();
    }
  };
  return (
    <FormSection
      variant="secondary"
      title={intl.formatMessage({
        id: 'modes_silence_steps_presets_title',
        defaultMessage: 'Default presets',
      })}
    >
      <FlexContainer
        flexDirection="row"
        gap={Spacing.s2}
      >
        {silencePresets.map(preset => (
          <Badge
            hoverable
            onClick={() => handleClick(preset)}
            key={preset.id}
          >
            <TranslatedMessage
              id={
                `modes_silence_steps_presets_items_${preset.id}` as TranslationKeys
              }
              defaultMessage={preset.id.toLocaleUpperCase()}
            />
          </Badge>
        ))}
      </FlexContainer>
    </FormSection>
  );
};
