import {PickPartial} from '@autocut/utils/type.utils';

import {isInBounds} from '../canvas.utils';
import {Canvas, CanvasObject} from './canvas.class.utils';

export type DraggableCanvasObject = CanvasObject & {
  isDragging: boolean;
  forcedX?: number;
  forcedY?: number;
  onMouseDown?: (x: number, y: number) => void;
  onMove?: (x: number, y: number) => void;
  onMouseUp?: (x: number, y: number) => void;
};

/**
 * Extends the Canvas class to add the ability to drag objects on the canvas.
 */
export class DraggableCanvas extends Canvas {
  protected declare objects: DraggableCanvasObject[]; // Only typescript, override the objects property type from the parent class

  protected xAnchor?: {
    position: number;
    threshold: number;
  };
  protected yAnchor?: {
    position: number;
    threshold: number;
  };

  /**
   * Constructs a new DraggableCanvas instance.
   * @param canvasRef - The reference to the HTML canvas element.
   * @param debug - Whether to enable debug mode.
   */
  constructor(canvasRef: React.RefObject<HTMLCanvasElement>, debug?: boolean) {
    super(canvasRef, debug);

    this.init();

    return this;
  }

  /**
   * Gets the anchored mouse position.
   * @param x - The x-coordinate of the mouse position.
   * @param y - The y-coordinate of the mouse position.
   * @returns The anchored mouse position.
   */
  private getAnchoredMousePosition(x: number, y: number) {
    let newX = x;
    let newY = y;

    if (this.xAnchor) {
      const {position, threshold} = this.xAnchor;
      if (Math.abs(newX - position) < threshold) {
        newX = position;
      }
    }
    if (this.yAnchor) {
      const {position, threshold} = this.yAnchor;
      if (Math.abs(newY - position) < threshold) {
        newY = position;
      }
    }

    return {x: newX, y: newY};
  }

  /**
   * Gets the mouse position relative to the canvas. Manage case where the mouse is out of the canvas.
   * @param e - The mouse event.
   * @returns The mouse position.
   */
  private getMousePosition(e: MouseEvent) {
    const {top, bottom, right, left, height, width} =
      this.canvasRef.getBoundingClientRect();

    const isOutTop = e.clientY <= top;
    const isOutBottom = e.clientY >= bottom;
    const isOutLeft = e.clientX <= left;
    const isOutRight = e.clientX >= right;

    return this.getAnchoredMousePosition(
      isOutLeft ? 0 : isOutRight ? width : e.offsetX,
      isOutTop ? 0 : isOutBottom ? height : e.offsetY,
    );
  }

  /**
   * Handles the mouse down event.
   * @param e - The mouse event.
   */
  private onMouseDown(e: MouseEvent) {
    const {x, y} = this.getMousePosition(e);

    const clickedObject = this.objects?.find(object =>
      isInBounds(x, y, {
        x1: object.x - object.width / 2,
        x2: object.x + object.width / 2,
        y1: object.y - object.height / 2,
        y2: object.y + object.height / 2,
      }),
    );

    if (clickedObject) {
      clickedObject.isDragging = true;
      clickedObject.onMouseDown?.(x, y);
    }
  }

  /**
   * Handles the mouse move event.
   * @param e - The mouse event.
   */
  private onMouseMove(e: MouseEvent) {
    const firstDraggedObject = this.objects?.find(object => object.isDragging);
    if (firstDraggedObject) {
      const {x, y} = this.getMousePosition(e);

      const newX = firstDraggedObject.forcedX ?? x;
      const newY = firstDraggedObject.forcedY ?? y;

      firstDraggedObject.x = newX;
      firstDraggedObject.y = newY;

      firstDraggedObject.onMove?.(newX, newY);
    }
  }

  /**
   * Handles the mouse up event.
   * @param e - The mouse event.
   */
  private onMouseUp(e: MouseEvent) {
    const firstDraggedObject = this.objects?.find(object => object.isDragging);
    if (firstDraggedObject) {
      const {x, y} = this.getMousePosition(e);

      const newX = firstDraggedObject.forcedX ?? x;
      const newY = firstDraggedObject.forcedY ?? y;

      firstDraggedObject.onMove?.(newX, newY);
      firstDraggedObject.onMouseUp?.(newX, newY);

      firstDraggedObject.x = newX;
      firstDraggedObject.y = newY;
      setTimeout(() => {
        firstDraggedObject.isDragging = false;
      }, 10);

      this.draw();
    }
  }

  /**
   * Adds an anchor to the canvas. (Only functionnal, no visual)
   * @param type - The type of anchor ('x' or 'y').
   * @param position - The position of the anchor.
   * @param threshold - The threshold for the anchor.
   */
  public addAnchor(type: 'x' | 'y', position: number, threshold: number) {
    if (type === 'x') {
      this.xAnchor = {
        position,
        threshold,
      };
    } else {
      this.yAnchor = {
        position,
        threshold,
      };
    }
  }

  /**
   * Adds a new object to the canvas.
   * @param newObject - The new object to add.
   * @returns The added object.
   */
  public addObject(
    newObject: PickPartial<
      Omit<DraggableCanvasObject, 'isDragging' | 'id'>,
      'zIndex' | 'forcedX' | 'forcedY'
    >,
  ) {
    return super.addObject<DraggableCanvasObject>({
      isDragging: false,
      forcedX: undefined,
      forcedY: undefined,
      ...newObject,
    });
  }

  /**
   * Destroys the DraggableCanvas instance. Removes event listeners.
   */
  public destroy() {
    super.destroy();
    this.canvasRef.removeEventListener('mousedown', e => this.onMouseDown(e));
    this.canvasRef.removeEventListener('mousemove', e => this.onMouseMove(e));
    this.canvasRef.removeEventListener('click', e => {
      const firstDraggedObject = this.objects?.find(
        object => object.isDragging,
      );
      if (firstDraggedObject) e.stopPropagation();
    });
    document.removeEventListener('mouseup', e => this.onMouseUp(e));
  }

  /**
   * Init the DraggableCanvas instance. Add event listeners.
   */
  public init() {
    super.init();
    // Arrow function are important here to keep the context of the class
    this.canvasRef.addEventListener('mousedown', e => this.onMouseDown(e));
    this.canvasRef.addEventListener('mousemove', e => this.onMouseMove(e));
    this.canvasRef.addEventListener('click', e => {
      const firstDraggedObject = this.objects?.find(
        object => object.isDragging,
      );
      if (firstDraggedObject) e.stopPropagation();
    });
    document.addEventListener('mouseup', e => this.onMouseUp(e));
  }

  /**
   * Sets the objects in the canvas. Overrides the current objects.
   * @param objects - The objects to set.
   * @returns The set objects.
   */
  public setObjects(
    objects: PickPartial<
      Omit<DraggableCanvasObject, 'isDragging' | 'id'>,
      'zIndex' | 'forcedX' | 'forcedY'
    >[],
  ) {
    return super.setObjects(
      objects.map(obj => ({
        isDragging: false,
        forcedX: undefined,
        forcedY: undefined,
        ...obj,
      })),
    );
  }

  /**
   * Updates an object in the canvas by ID.
   * @param objectId - The ID of the object to update.
   * @param newObject - The new object properties.
   */
  public updateObjectById(
    objectId: string,
    newObject: Partial<Omit<DraggableCanvasObject, 'isDragging' | 'id'>>,
  ) {
    super.updateObjectById(objectId, newObject);
  }

  /**
   * Updates an object in the canvas by index.
   * @param objectIndex - The index of the object to update.
   * @param newObject - The new object properties.
   */
  public updateObjectByIndex(
    objectIndex: number,
    newObject: Partial<Omit<DraggableCanvasObject, 'isDragging' | 'id'>>,
  ) {
    super.updateObjectByIndex(objectIndex, newObject);
  }
}
