import {SuccessLoader} from '@autocut/components/SuccessLoader/SuccessLoader';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import css from './SendingEmail.module.css';

export type SendingEmailProps = {
  email: string;
};

export const SendingEmail = ({email}: SendingEmailProps) => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(true);
      setTimeout(() => {
        navigate('../login');
      }, 3000);
    }, 2000);
  }, []);

  return (
    <FlexContainer
      className={css.container}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={16}
    >
      <SuccessLoader isSuccess={success} />
      {success && (
        <TranslatedMessage
          id="onboarding_auth_trial_steps_sendingEmail_success"
          defaultMessage="Email sent successfully to {email}"
          values={{
            email: email,
          }}
        />
      )}
    </FlexContainer>
  );
};
