import {ColorKeys, colors} from '@autocut/designSystem/colors';
import {CSSProperties} from 'react';

import css from './ActionIcon.module.scss';

type ActionIconProps = {
  variant?: 'primary' | 'filled' | 'subtle' | 'outline' | 'transparent';
  color?: CSSProperties['color'] | ColorKeys;
  secondartColors?: CSSProperties['color'] | ColorKeys;
  children: JSX.Element;
  onClick: () => void;
  style?: CSSProperties;
  disabled?: boolean;
  size?: number;
};

export const ActionIcon = ({
  children,
  variant = 'transparent',
  color = colors.gray400,
  secondartColors = 'white',
  style,
  size,
  onClick,
  disabled,
}: ActionIconProps) => {
  return (
    <div
      className={css.actionIcon}
      data-variant={variant}
      data-disabled={disabled}
      style={
        {
          '--color': color,
          '--secondayColor': secondartColors,
          width: size,
          height: size,
          ...style,
        } as CSSProperties
      }
      onClick={() => (disabled ? null : onClick())}
    >
      {children}
    </div>
  );
};
