import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {compute} from '@autocut/utils/compute.utils';
import {handleProcessBase} from '@autocut/utils/process/handleProcessBase';
import {
  endProcessStep,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {forwardRef} from 'react';
import {useIntl} from 'react-intl';

type Props = {
  isDisabled: boolean;
  isLoading: boolean;
  handleClickWithValidation: (baseHandleClick: () => Promise<void>) => any;
};

export const ChaptersFooterButton = forwardRef<HTMLButtonElement, Props>(
  ({isLoading, isDisabled, handleClickWithValidation}, ref) => {
    const intl = useIntl();
    const {color, chapters} = useAutoCutStore(state => ({
      color: state.ui.parameters.chapters.color,
      chapters: state.onGoingProcess.chapters,
    }));

    const handleClick = handleProcessBase(
      {
        executeProcess: async (_, progress) => {
          const chapterProcess = compute.process.chapters({
            color,
            chapters: chapters as Chapter[],
          });

          updateProcessStep(progress, 'chapters_mainProcess', {
            progress: {
              computeTaskId: chapterProcess.requestId,
            },
            countFor: 1,
          });

          const result = await chapterProcess;

          endProcessStep(progress, 'chapters_mainProcess');

          return result;
        },
      },
      {
        processTitleNameKey: 'modes_chapters_title',
        processSteps: [
          {
            id: 'chapters_mainProcess',
            translationKey: 'progress_steps_chapters_generation_mainProcess',
            progress: '',
          },
        ],
      },
    );

    return (
      <Button
        ref={ref}
        color={colors.primary600}
        onClick={async () =>
          handleClickWithValidation(async () => await handleClick(intl))
        }
        disabled={isDisabled || isLoading}
        isLoading={isLoading}
      >
        <TranslatedMessage
          id={`modes_chapters_steps_cta`}
          defaultMessage="Add Chapters"
        />
      </Button>
    );
  },
);
