import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import * as React from 'react';

import {Helper, HelperProps} from '../Helper/Helper';
import css from './textWithHelper.module.css';

export type TextWithHelperProps = {
  children: React.ReactNode;
  helperId: TranslationKeys;
  helperDefault: string;
  helperProps?: HelperProps;
};

export const TextWithHelper = ({
  children,
  helperId,
  helperDefault,
  ...helperProps
}: TextWithHelperProps) => {
  return (
    <div className={css.container}>
      {children}
      <Helper
        id_message={helperId}
        default_message={helperDefault}
        {...helperProps}
      />
    </div>
  );
};
