import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useRef} from 'react';
import {FiUpload} from 'react-icons/fi';
import {GrStatusGood} from 'react-icons/gr';

import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import css from './FileInput.module.css';

export const FileInput = ({
  id,
  files,
  setFiles,
  multiple = false,
  textId = 'globals_defaults_components_FileInput_uploadScreenshot',
}: {
  id: string;
  files: File[];
  setFiles: (files: File[]) => void;
  multiple?: boolean;
  textId?: TranslationKeys;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <FlexContainer alignItems="center">
      <label
        htmlFor={id}
        className={
          inputRef.current &&
          inputRef.current.files &&
          inputRef.current.files.length > 0
            ? css.custom_upload_label_active
            : css.custom_upload_label
        }
      >
        <span className={css.custom_upload_label_container}>
          <FiUpload />
          <span className={css.text}>
            <TranslatedMessage
              id={textId}
              defaultMessage="Upload your file(s)"
            />
          </span>
          <span
            id={`span_${id}`}
            className={
              inputRef.current &&
              inputRef.current.files &&
              inputRef.current.files.length > 0
                ? css.custom_upload_label_span_active
                : css.custom_upload_label_span
            }
          >
            <GrStatusGood />
          </span>
        </span>
      </label>
      <input
        id={id}
        className={css.file_input}
        ref={inputRef}
        type="file"
        accept="image/*"
        multiple={multiple}
        onChange={e => {
          if (e.target.files && e.target.files[0]) {
            setFiles([...e.target.files]);
          }
        }}
      />
    </FlexContainer>
  );
};
