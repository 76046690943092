import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {z as zod} from 'zod';

import {languageOfTranscriptionValidationSchema} from './languageOfTranscription.validationSchema';
import {transcriptionProviderValidationSchema} from './transcriptionProvider.validationSchema';

export const swearWordValidationSchema = zod.object({
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  transcriptionProvider: transcriptionProviderValidationSchema,
  bleepFile: zod.string({
    invalid_type_error:
      'modes_swearWords_error_validation_bleepFile' as TranslationKeys,
  }),
});
