import {getOS} from '@autocut/utils/system/os.system.utils';
import {getUUID} from '@autocut/utils/system/uuid.system.utils';

import {getHostName} from './system/hostname.system.utils';
import {autocutStoreVanilla} from './zustand';

export const isRequiredDataProvided = ({
  issue,
  linkToUserFile,
}: {
  issue: string;
  linkToUserFile: string;
}) => {
  switch (issue) {
    case 'ALWAYS_NO_SILENCE':
      return linkToUserFile;
    case 'ALWAYS_UNCOMPATIBLE_FILES':
      return linkToUserFile;
    case 'BAD_CUTS':
      return linkToUserFile;
    case 'SILENCE_NOT_DELETED':
      return linkToUserFile;
    default:
      return true;
  }
};

export const deleteAllFiles = () => {
  const inputList = document.getElementsByTagName('input');

  for (let i = 0; i < inputList.length; i++) {
    if (inputList[i].type === 'file') {
      inputList[i].value = '';
    }
  }
};

export const isUrlValid = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}' + // domain name
      '(\\?[;&a-z\\d%_.~+=-]*)?', // query string
    'i',
  );
  return pattern.test(url);
};

export const generateBugData = async (
  screenshots: File[],
  bugDescription: string,
  bugReproduction: string,
  blocking: boolean,
): Promise<{
  logDate: string;
  bugDescription: string;
  bugReproduction: string;
  blocking: boolean;
  screenshots: {
    name: string;
    type: string;
  }[];
  key?: string;
  uuid?: string;
  host?: string;
}> => {
  const license = autocutStoreVanilla().user.license;

  const uuid = await getUUID();
  const host = await getHostName();
  const today = new Date().toISOString().split('T')[0];

  const data = {
    logDate: today,
    screenshots: screenshots.map(screenshot => ({
      name: screenshot.name,
      type: screenshot.type,
    })),
    bugDescription,
    bugReproduction,
    blocking,
    ...(license && license.key && {key: license.key}),
    ...(uuid && {uuid: uuid}),
    ...(host && {host: host}),
  };

  return data;
};

export const generateMessage = (
  description: string,
  reproduction: string,
  linkToUserFile: string,
  blocking: boolean,
  bugData: {
    logDate: string;
    screenshots: {
      name: string;
      type: string;
    }[];
    key?: string;
    uuid?: string;
    host?: string;
  },
  filesData: {
    screenshots: {
      [key: string]: {
        name: string;
        url: string;
      };
    };
    logs: {
      url: string;
    };
  },
) => {
  const mode = autocutStoreVanilla().ui.process.mode;
  const os = getOS();

  return `
  [+] DESCRIPTION

  ${description}

  =====================

  [+] REPRODUCTION

  ${reproduction}

  =====================

  [+] BLOCKING

  ${blocking ? 'Yes' : 'No'}

  =====================

  [+] LINK TO USER FILE

  ${linkToUserFile ? linkToUserFile : 'No link provided'}

  =====================

  [+] BUG DATA

     => Key: ${bugData.key?.trim() || 'No key'}
     => UUID: ${bugData.uuid?.trim() || 'No UUID'}
     => Host: ${bugData.host?.trim() || 'No host'}
     => OS: ${os}
     => Mode: ${mode.id}

  =====================

  [+] FILES
      => Screenshots: 
          ${bugData.screenshots
            .map(screenshot => filesData.screenshots[screenshot.name].url)
            .join('\n\n')}

            
      => Logs: ${filesData.logs.url}
  `;
};
