import captionsThumbnail from '@autocut/assets/images/modes/captions.png?url';
import {SelectionScreen} from '@autocut/components/SelectionScreen/SelectionScreen';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {ModeWrapper} from '../ModeWrapper';
import CaptionsCustomization from './customization/CaptionsCustomization';
import {EditTranscriptModalProvider} from './customization/parts/EditTranscriptModal/EditTranscriptModalContext';
import {CaptionsLanguage} from './language/CaptionsLanguage';

export const CaptionsMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={AutocutModes.Captions.icon}
      text={AutocutModes.Captions.name}
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/caption"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            index: true,
            clickable: true,
            path: '',
            element: (
              <SelectionScreen
                image={{
                  src: captionsThumbnail,
                  alt: AutocutModes.Captions.name,
                }}
                title={{
                  id: 'modes_general_steps_select_title',
                  defaultMessage: 'Set In/Out points',
                }}
                texts={[
                  {
                    id: 'modes_general_steps_select_descriptions_1',
                    defaultMessage: '',
                  },
                  {
                    id: 'modes_general_steps_select_descriptions_2',
                    defaultMessage: '',
                  },
                ]}
                cta={
                  <Button
                    color={colors.primary600}
                    onClick={async () => {
                      navigate('./language');
                    }}
                  >
                    <TranslatedMessage
                      id="modes_general_steps_select_cta"
                      defaultMessage="Confirm In/Out points"
                    />
                  </Button>
                }
              />
            ),
          },
          {
            clickable: false,
            label: intl.formatMessage({
              id: 'modes_general_steps_transcription_title',
              defaultMessage: 'Select Audio Clip Language',
            }),
            path: 'language',
            element: <CaptionsLanguage />,
          },
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_customization_title',
              defaultMessage: 'Customize parameters',
            }),
            path: 'customization',
            element: (
              <EditTranscriptModalProvider>
                <CaptionsCustomization />
              </EditTranscriptModalProvider>
            ),
          },
        ]}
      />
    </ModeWrapper>
  );
};
