import {CaptionChunk} from '@autocut/types/Captions';
import {preload} from '@autocut/types/ElectronPreload';

import {getResourceFolderPath} from '../electron/app.electron.utils';
import {autocutStoreVanilla, setAutocutStore} from '../zustand';

export type TranscriptFile = {
  id: string;
  name: string;
  duration: number;
  modifiedAt: string;
  chunks: CaptionChunk[];
};

const TRANSCRIPT_FOLDER = 'transcripts';

export const saveCurrentTranscript = (newChunks: CaptionChunk[]) => {
  const currentTranscript = getCurrentTranscript();

  let newFileContent: Omit<TranscriptFile, 'modifiedAt'> | null = null;
  if (currentTranscript) {
    newFileContent = {
      ...currentTranscript,
      chunks: newChunks,
    };
  } else {
    const newId = preload().nodeCrypto.randomUUID();

    newFileContent = {
      id: newId,
      chunks: newChunks,
      name: 'Transcript ' + newId,
      duration: newChunks[newChunks.length - 1].end - newChunks[0].start,
    };
  }

  if (newFileContent) {
    const fileName = saveTranscript(newFileContent);

    setAutocutStore('onGoingProcess.caption.transcriptFileName', fileName);
  }
};

export const updateCurrentTranscriptName = (name: string) => {
  const currentTranscript = getCurrentTranscript();

  if (!currentTranscript) return;

  const newFileContent = {
    ...currentTranscript,
    name,
  };

  const fileName = saveTranscript(newFileContent);

  setAutocutStore('onGoingProcess.caption.transcriptFileName', fileName);
};

export const saveTranscript = (
  transcript: Omit<TranscriptFile, 'modifiedAt'>,
) => {
  const fileName = `${transcript.id}.json`;
  const filePath = preload().path.join(
    getResourceFolderPath(),
    TRANSCRIPT_FOLDER,
    fileName,
  );

  preload().fs.writeFileSync(
    filePath,
    JSON.stringify({...transcript, modifiedAt: new Date()}),
  );

  return fileName;
};

/**
 * Get the list of transcripts. Ordered by the last modified date.
 */
export const getTranscripts = async () => {
  const folderPath = preload().path.join(
    getResourceFolderPath(),
    TRANSCRIPT_FOLDER,
  );

  if (!preload().fs.existsSync(folderPath)) {
    preload().fs.mkdirSync(folderPath);
  }

  const transcripts = preload()
    .fs.readdirSync(folderPath)
    .map(fileName => {
      const filePath = preload().path.join(folderPath, fileName);
      const file = JSON.parse(
        preload().fs.readFileSync(filePath, 'utf8'),
      ) as TranscriptFile;

      return {...file, fileName};
    })
    .sort((a, b) => {
      return (
        new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
      );
    });

  return transcripts;
};

export const getCurrentTranscript = () => {
  const transcriptFileName =
    autocutStoreVanilla().onGoingProcess.caption.transcriptFileName;

  if (!transcriptFileName) return null;

  const filePath = preload().path.join(
    getResourceFolderPath(),
    TRANSCRIPT_FOLDER,
    transcriptFileName,
  );

  if (!preload().fs.existsSync(filePath)) {
    return null;
  }

  return JSON.parse(
    preload().fs.readFileSync(filePath, 'utf8'),
  ) as TranscriptFile;
};

export const deleteTranscriptFile = (fileName: string) => {
  const filePath = preload().path.join(
    getResourceFolderPath(),
    TRANSCRIPT_FOLDER,
    fileName,
  );

  preload().fs.unlinkSync(filePath);
};
