import {useEffect, useRef} from 'react';

const getElementParentsReducer = (element: Node, array: Node[]): Node[] => {
  array.push(element);
  if (element.parentElement) {
    return getElementParentsReducer(element.parentElement, array);
  }
  return array;
};

export const useClickOutside = <T extends HTMLElement>(
  callback: (event: MouseEvent) => void,
  includePortal?: boolean,
) => {
  const ref = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // check if clicked node id contains portal
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (includePortal) {
          const parents: Node[] = getElementParentsReducer(
            event.target as Node,
            [],
          );
          const isPortal = parents.some(node => (node as any).id === 'portal');
          if (isPortal) return;

          callback(event);
        } else {
          callback(event);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback, includePortal]);

  return ref;
};
