import {colors} from '@autocut/designSystem/colors';
import {FontSize} from '@autocut/designSystem/enums/fontSize.enum';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import React from 'react';

import {Text} from '../../atoms/Text/Text';
import FlexContainer, {FlexContainerProps} from '../../molecules/FlexContainer';
import {FormSectionVariant} from './variants';

export namespace FormSection {
  export type Props = {
    title: string | React.ReactNode;
    description?: string | React.ReactNode;
    children?: React.ReactNode;
    variant?: FormSectionVariant;
  } & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> &
    FlexContainerProps;
}

export const FormSection = ({
  title,
  description,
  children,
  variant,
  style,
  ...props
}: FormSection.Props) => {
  return (
    <FlexContainer
      flexDirection="column"
      gap={Spacing.s2}
      style={{width: '100%', ...style}}
      {...props}
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s1}
      >
        <Text
          variant={
            variant === 'primary'
              ? 'textMd.bold'
              : variant === 'secondary'
                ? 'textSm.semibold'
                : 'textMd.bold'
          }
          color={colors.gray300}
        >
          {title}
        </Text>
        {description && (
          <Text
            variant="textXs"
            color={colors.gray400}
            style={{lineHeight: FontSize.textXs}}
          >
            {description}
          </Text>
        )}
      </FlexContainer>
      {children}
    </FlexContainer>
  );
};
