import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import UsageStatsModal from '@autocut/modals/UsageStatsModal/UsageStatsModal';
import {DeletePresetsModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPresets/DeletePresetsModal/DeletePresetsModal';
import {SearchPublicPresetModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPresets/SearchPublicPresetModal/SearchPublicPresetModal';
import {CaptionsPreviewModal} from '@autocut/pages/modes/captions/customization/parts/CaptionsPreviewModal/CaptionsPreviewModal';
import {CreatePresetModal} from '@autocut/pages/modes/captions/customization/parts/CreatePresetModal/CreatePresetModal';
import {CaptionsCustomizationModal} from '@autocut/pages/modes/captions/customization/parts/CustomizationModal/CaptionsCustomizationModal';
import {CreateResizePresetModal} from '@autocut/pages/modes/resize/customization/steps/ResizePresetStep/CreateResizePresetModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useEffect, useMemo} from 'react';

import {AppStoreDvModal} from './AppStoreDvModal/AppStoreDvModal';
import {BetaFeedbackModal} from './BetaFeedbackModal/BetaFeedbackModal';
import {BetaOnboardingModal} from './BetaOnboardingModal/BetaOnboardingModal';
import {BRollCreditsModal} from './BRollCreditsModal/BRollCreditsModal';
import ContactUsModal from './ContactUsModal/ContactUsModal';
import {DeepgramErrorModal} from './DeepgramErrorModal/DeepgramErrorModal';
import {EndCaptionsProcessModal} from './EndProcessModal/captions/EndCaptionsProcessModal';
import {EndProcessModal} from './EndProcessModal/EndProcessModal';
import ErrorModal from './ErrorModal/ErrorModal';
import ExchangeRateModal from './ExchangeRateModal/ExchangeRateModal';
import FeatureRequestModal from './FeatureRequestModal/FeatureRequestModal';
import {FeedbackModal} from './FeedbackModal/FeedbackModal';
import {FontNotFoundModal} from './FontNotFoundModal/FontNotFoundModal';
import {GamingTrialModal} from './GamingTrialModal/GamingTrialModal';
import {KeyExpiredModal} from './KeyExpiredModal/KeyExpiredModal';
import {NoLinkModal} from './NoLinkModal/NoLinkModal';
import {NotEnoughTracksModal} from './NotEnoughTracksModal/NotEnoughTracksModal';
import {OnboardingStatModal} from './OnboardingStatModal/OnboardingStatModal';
import {ProcessModal} from './ProcessModal/ProcessModal';
import {ReportIssueModal} from './ReportIssueModal/ReportIssueModal';
import ReviewModal from './ReviewModal/ReviewModal';
import {StartUpModals} from './StartUpModals';
import {TrialExpiredModal} from './TrialExpiredModal/TrialExpiredModal';
import {UsageModal} from './UsageModal/UsageModal';

const ModalManager = () => {
  const {openedModalName, modalQueue} = useAutoCutStore(state => ({
    openedModalName: state.ui.openedModalName,
    modalQueue: state.ui.modalQueue,
  }));

  useEffect(() => {
    console.log(
      '[MODAL] OpenedModalName: ',
      openedModalName,
      ' ModalQueue: ',
      modalQueue,
    );
    console.log({openedModalName});
    if (!openedModalName && modalQueue.length > 0) {
      const newModal = modalQueue.splice(0, 1);
      setAutocutStore('ui.openedModalName', newModal[0]);
      setAutocutStore('ui.modalQueue', modalQueue);
    }
  }, [openedModalName, modalQueue]);

  const ModalComponent = useMemo(() => {
    switch (openedModalName) {
      // Global modals
      case ModalType.Feedback:
        return FeedbackModal;
      case ModalType.TrialExpired:
        return TrialExpiredModal;
      case ModalType.StartUp:
        return StartUpModals;
      case ModalType.Processing:
        return ProcessModal;
      case ModalType.Onboarding:
        return OnboardingStatModal;
      case ModalType.KeyExpired:
        return KeyExpiredModal;
      case ModalType.UsageStats:
        return UsageStatsModal;
      case ModalType.BetaOnboarding:
        return BetaOnboardingModal;
      case ModalType.BetaFeedback:
        return BetaFeedbackModal;
      case ModalType.NoLink:
        return NoLinkModal;
      case ModalType.GamingTrial:
        return GamingTrialModal;
      case ModalType.AppStoreDv:
        return AppStoreDvModal;

      // Header modals
      case ModalType.ContactUs:
        return ContactUsModal;
      case ModalType.FeatureRequest:
        return FeatureRequestModal;
      case ModalType.ReportIssue:
        return ReportIssueModal;

      // Process modals
      case ModalType.Review:
        return ReviewModal;
      case ModalType.ExchangeRate:
        return ExchangeRateModal;
      case ModalType.EndProcess:
        return EndProcessModal;

      // TODO : autre modes, resize captions broll
      // Modes modals
      case ModalType.CreateResizePreset:
        return CreateResizePresetModal;
      case ModalType.EndCaptionsProcess:
        return EndCaptionsProcessModal;
      case ModalType.BRollCredits:
        return BRollCreditsModal;
      case ModalType.NotEnoughTracks:
        return NotEnoughTracksModal;
      // case ModalType.FormattedNumber:
      //   return FormattedNumberModal;

      // Captions modals
      case ModalType.CaptionsCustomization:
        return CaptionsCustomizationModal;
      case ModalType.CaptionsPreview:
        return CaptionsPreviewModal;
      case ModalType.CreatePresetModal:
        return CreatePresetModal;
      case ModalType.SearchPreset:
        return SearchPublicPresetModal;
      case ModalType.DeletePresets:
        return DeletePresetsModal;
      case ModalType.GenerateEmojis:
        return null;

      // Errors modals
      case ModalType.Error:
        return ErrorModal;
      case ModalType.UsageLimit:
        return UsageModal;
      case ModalType.DeepgramError:
        return DeepgramErrorModal;
      case ModalType.FontNotFound:
        return FontNotFoundModal;

      default:
        return null;
    }
  }, [openedModalName]);

  return ModalComponent ? <ModalComponent /> : null;
};

export default ModalManager;
