import {LoaderInfinity} from '@autocut/components/LoaderInfinity/LoaderInfinity';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {getTranscripts} from '@autocut/utils/transcript/transcriptFiles.utils';
import {useEffect, useState} from 'react';

import {PreviousTranscriptRow} from './PreviousTranscriptRow';

const DEFAULT_DISPLAYED_NUMBER = 3;

export const PreviousTranscriptStep = () => {
  const [displayedNumber, setDisplayedNumber] = useState(
    DEFAULT_DISPLAYED_NUMBER,
  );
  const [transcriptions, setTranscriptions] = useState<
    Awaited<ReturnType<typeof getTranscripts>>
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const transcripts = await getTranscripts();
      setTranscriptions(transcripts);
    };

    void init().finally(() => {
      setIsLoading(false);
    });
  }, []);

  return (
    <FormSection
      title={
        <TranslatedMessage
          id="modes_caption_steps_language_previousTranscription_title"
          defaultMessage="Load previous transcription"
        />
      }
    >
      <PaddedSection>
        <FlexContainer
          flexDirection="column"
          gap={12}
          alignItems="center"
        >
          {isLoading && <LoaderInfinity height={128} />}
          {!isLoading &&
            (!!transcriptions.length ? (
              <FlexContainer
                flexDirection="column"
                gap={12}
                style={{width: '100%'}}
                alignItems="center"
              >
                <FlexContainer
                  flexDirection="column"
                  gap={8}
                  style={{width: '100%'}}
                  alignItems="center"
                >
                  {transcriptions.slice(0, displayedNumber).map(transcript => (
                    <PreviousTranscriptRow
                      key={transcript.name}
                      transcript={transcript}
                      fileName={transcript.fileName}
                      onDelete={async () => {
                        const filteredTranscriptions = transcriptions.filter(
                          transcription =>
                            transcription.fileName !== transcript.fileName,
                        );
                        setTranscriptions(filteredTranscriptions);
                      }}
                    />
                  ))}
                </FlexContainer>
                {transcriptions.length > displayedNumber && (
                  <Button
                    variant="tertiary"
                    onClick={() => setDisplayedNumber(transcriptions.length)}
                    fullWidth={false}
                  >
                    <TranslatedMessage
                      id="modes_caption_steps_language_previousTranscription_showMore"
                      defaultMessage="Show more"
                    />
                  </Button>
                )}
                {displayedNumber > DEFAULT_DISPLAYED_NUMBER && (
                  <Button
                    variant="tertiary"
                    onClick={() => setDisplayedNumber(DEFAULT_DISPLAYED_NUMBER)}
                    fullWidth={false}
                  >
                    <TranslatedMessage
                      id="modes_caption_steps_language_previousTranscription_showLess"
                      defaultMessage="Show less"
                    />
                  </Button>
                )}
              </FlexContainer>
            ) : (
              <Text
                variant="textMd"
                color="gray300"
              >
                <TranslatedMessage
                  id="modes_caption_steps_language_previousTranscription_noTranscription"
                  defaultMessage="No previous transcriptions"
                />
              </Text>
            ))}
        </FlexContainer>
      </PaddedSection>
    </FormSection>
  );
};
