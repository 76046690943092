import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import React from 'react';

import {Badge} from '../../atoms/Badge/Badge';
import FlexContainer from '../FlexContainer';
import css from './BadgeSelector.module.scss';

export type BadgeSelectorOptionsProps = {
  value: string;
  label: React.ReactNode;
};

type BaseSelectorProps<T extends boolean> = {
  allowMultiple?: T;
  options: BadgeSelectorOptionsProps[];
};
type MultipleSelectProps = BaseSelectorProps<true> & {
  selected?: BadgeSelectorOptionsProps['value'][];
  onChange: (value: BadgeSelectorOptionsProps['value'][]) => void;
};
type SingleSelectProps = BaseSelectorProps<false> & {
  selected?: BadgeSelectorOptionsProps['value'];
  onChange: (value: BadgeSelectorOptionsProps['value']) => void;
};
type NewBadgeSelectorProps<T extends boolean> = T extends true
  ? MultipleSelectProps
  : SingleSelectProps;

const isMultipleSelect = (
  props: MultipleSelectProps | SingleSelectProps,
): props is MultipleSelectProps => {
  return props.allowMultiple === true;
};

export type BadgeSelectorProps = {
  options: BadgeSelectorOptionsProps[];
  selected: BadgeSelectorOptionsProps['value'] | undefined;
  onChange: (value: BadgeSelectorOptionsProps['value']) => void;
};

export const BadgeSelector = <T extends boolean>(
  props: NewBadgeSelectorProps<T>,
) => {
  const {onChange, selected, options} = isMultipleSelect(props)
    ? {
        ...props,
        onChange: (value: string) => {
          const selectedArray = props.selected || [];
          const isSelected = selectedArray.includes(value);
          if (isSelected) {
            return props.onChange(selectedArray.filter(v => v !== value));
          }
          return props.onChange([...selectedArray, value]);
        },
        selected: props.selected,
      }
    : {
        ...props,
        onChange: (value: string) => props.onChange(value),
        selected: [props.selected],
      };

  return (
    <FlexContainer
      gap={Spacing.s1}
      flexWrap="wrap"
    >
      {options.map(option => (
        <div
          className={css.badgeContainer}
          onClick={() => onChange(option.value)}
          key={option.value}
        >
          <Badge
            hoverable
            selected={selected?.includes(option.value)}
          >
            {option.label}
          </Badge>
        </div>
      ))}
    </FlexContainer>
  );
};
