import {z as zod} from 'zod';

import {languageOfTranscriptionValidationSchema} from './languageOfTranscription.validationSchema';
import {transcriptionProviderValidationSchema} from './transcriptionProvider.validationSchema';

export const chapterValidationSchema = zod.object({
  languageOfTranscription: languageOfTranscriptionValidationSchema,
  transcriptionProvider: transcriptionProviderValidationSchema,
  color: zod.string().default('Green'),
});
