import ParameterLayout from '@autocut/components/ParametersGroupLayout/ParameterLayout';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {AutocutModes} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {ZoomTypeKeys, ZoomTypes} from '@autocut/types/ZoomType';
import {updateCoverage} from '@autocut/utils/zoom';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useIntl} from 'react-intl';

import css from './ZoomTypesStep.module.css';

const zoomTypePropertyNames = {
  [ZoomTypes.SMOOTH]: 'smoothZoomPercentage',
  [ZoomTypes.DYNAMIC]: 'dynamicZoomPercentage',
} as const;
const zoomTypeDefaultValue = {
  [ZoomTypes.SMOOTH]: AutocutModes.Zoom.defaultParameters.smoothZoomPercentage,
  [ZoomTypes.DYNAMIC]:
    AutocutModes.Zoom.defaultParameters.dynamicZoomPercentage,
} as const;

export const ZoomTypesStep = () => {
  const {zoomTypes} = useAutoCutStore(state => state.ui.parameters.zoom);
  const intl = useIntl();

  const toggleZoomType = (zoomType: ZoomTypeKeys) => () => {
    const newValue = !zoomTypes[zoomType];
    const isOnlyZoomType =
      Object.values(zoomTypes).filter(Boolean).length === 0;
    if (newValue)
      if (isOnlyZoomType) updateCoverage(zoomTypePropertyNames[zoomType], 1);
      else
        updateCoverage(
          zoomTypePropertyNames[zoomType],
          zoomTypeDefaultValue[zoomType],
        );
    else updateCoverage(zoomTypePropertyNames[zoomType], 0);
    setAutocutStore(`ui.parameters.zoom.zoomTypes.${zoomType}`, newValue); // Updating the object as a whole does not trigger a refresh, it's a bug
  };

  return (
    <ParameterLayout
      title={
        <TranslatedMessage
          id="modes_zoom_parameters_zoomTypes"
          defaultMessage="Zoom types"
        />
      }
      alignLeft={true}
    >
      <FlexContainer gap={32}>
        <div className={css.checkGroup}>
          <CheckBox
            size={16}
            variant="box"
            checked={zoomTypes.SMOOTH}
            onChange={toggleZoomType(ZoomTypes.SMOOTH)}
            description={intl.formatMessage({
              id: 'modes_zoom_types_smooth',
              defaultMessage: 'Smooth',
            })}
          />
        </div>
        <div className={css.checkGroup}>
          <CheckBox
            size={16}
            variant="box"
            checked={zoomTypes.DYNAMIC}
            onChange={toggleZoomType(ZoomTypes.DYNAMIC)}
            description={intl.formatMessage({
              id: 'modes_zoom_types_dynamic',
              defaultMessage: 'Dynamic',
            })}
          />
        </div>
      </FlexContainer>
    </ParameterLayout>
  );
};
