import {ActionIcon} from '@autocut/designSystem/components/atoms/ActionIcon/ActionIcon';
import {ModalType} from '@autocut/enums/modals.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {isHost} from '@autocut/utils/host';
import {addModalToQueue} from '@autocut/utils/modal/addModalToQueue';
import ReactDOM from 'react-dom';
import {HiOutlineBellAlert} from 'react-icons/hi2';

import css from './BetaFeedbackAffix.module.scss';

export const BetaFeedbackAffix = () => {
  const {openedModalName, license} = useAutoCutStore(state => ({
    openedModalName: state.ui.openedModalName,
    license: state.user.license,
  }));

  if (!license || isHost('davinci')) return null;

  return openedModalName.length
    ? null
    : ReactDOM.createPortal(
        <div className={css.container}>
          <ActionIcon
            variant="primary"
            size={40}
            onClick={() => addModalToQueue(ModalType.BetaFeedback)}
          >
            <HiOutlineBellAlert
              size={24}
              color="white"
            />
          </ActionIcon>
        </div>,
        document.body,
      );
};
