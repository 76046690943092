import profanityThumbnail from '@autocut/assets/images/modes/profanity.png?url';
import {SelectionScreen} from '@autocut/components/SelectionScreen/SelectionScreen';
import StepperRouter, {
  getPreviousStepPath,
} from '@autocut/components/Stepper/StepperRouter/StepperRouter';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {BiMessageSquareX} from 'react-icons/bi';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router-dom';

import {MarkerSelectionScreen} from '../MarkerSelectionScreen/MarkerSelectionScreen';
import {ModeWrapper} from '../ModeWrapper';
import {SwearWordsCustomization} from './SwearWordsCustomization';

export const SwearWordsMode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const intl = useIntl();

  return (
    <ModeWrapper
      Icon={BiMessageSquareX}
      text="AutoProfanity Filter"
      customHomeButtonHandler={() => {
        const path = getPreviousStepPath(location);

        navigate(path);
      }}
    >
      <StepperRouter
        basePath="/swear_words"
        steps={[
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_select_title',
              defaultMessage: 'Set In/Out points',
            }),
            path: 'selection',
            element: (
              <SelectionScreen
                image={{src: profanityThumbnail, alt: 'Silence mode'}}
                title={{
                  id: 'modes_general_steps_select_title',
                  defaultMessage: 'Set In/Out points',
                }}
                texts={[
                  {
                    id: 'modes_general_steps_select_descriptions_1',
                    defaultMessage: '',
                  },
                  {
                    id: 'modes_general_steps_select_descriptions_2',
                    defaultMessage: '',
                  },
                ]}
                cta={
                  <Button
                    color={colors.primary600}
                    onClick={() => {
                      navigate('./customization');
                    }}
                  >
                    <TranslatedMessage
                      id="modes_general_steps_select_cta"
                      defaultMessage="Confirm In/Out points"
                    />
                  </Button>
                }
              />
            ),
          },
          {
            label: intl.formatMessage({
              id: 'modes_general_steps_customization_title',
              defaultMessage: 'Customize parameters',
            }),
            path: 'customization',
            element: <SwearWordsCustomization />,
          },
        ]}
      />
    </ModeWrapper>
  );
};
