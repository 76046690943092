import {processCaptionsChunksState} from '@autocut/pages/modes/captions/utils/processCaptionsChunks';
import {ProcessTranscriptFunction} from '@autocut/types/Transcription';
import {compute} from '@autocut/utils/compute.utils';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';
import {getParametersForMode} from '@autocut/utils/parameters.utils';
import {
  addProcessSteps,
  endProcessStep,
  initProcessProgress,
  startProcessProgress,
  updateProcessStep,
} from '@autocut/utils/process/progress';
import {getTranscriptionLanguage} from '@autocut/utils/transcript/getTranscriptionLanguage';
import {setAutocutStore} from '@autocut/utils/zustand';

export const handleCaptionProcessTranscript: ProcessTranscriptFunction = async (
  intl,
  usedModel = 0,
) => {
  try {
    const processProgress = initProcessProgress(
      'captions_transcript',
      'progress_steps_transcript_mainProcess',
    );
    addProcessSteps(processProgress, [
      {
        id: 'captions_transcript_get_language',
        translationKey: 'progress_steps_caption_transcript_get_language',
        progress: 1,
      },
      {
        id: 'captions_transcript_mainProcess',
        translationKey: 'progress_steps_transcript_mainProcess',
        progress: '',
        countFor: 4,
      },
      {
        id: 'captions_transcript_split_chunks',
        translationKey: 'progress_steps_caption_transcript_split_chunks',
        progress: 1,
      },
    ]);

    startProcessProgress(processProgress);

    const parameters = getParametersForMode('caption');

    const language = getTranscriptionLanguage({
      languageOfTranscriptionValue: parameters.languageOfTranscription.value,
      usedModel,
    });
    endProcessStep(processProgress, 'captions_transcript_get_language');

    const transcriptProcess = compute.transcription({
      type: 'utterances',
      skipEmbedding: true,
      language,
    });

    updateProcessStep(processProgress, 'caption_transcript_mainProcess', {
      progress: {
        computeTaskId: transcriptProcess.requestId,
      },
    });

    const {transcript, filePath} = await transcriptProcess;
    const timelineInfos = await compute.timeline.getInfos();

    setAutocutStore('onGoingProcess.audioFilepath', filePath);
    setAutocutStore('onGoingProcess.timelineInfos', timelineInfos);

    endProcessStep(processProgress, 'captions_transcript_mainProcess');

    const finalChunks = await processCaptionsChunksState(
      transcript.flatMap(utterance => utterance.words),
    );

    endProcessStep(processProgress, 'caption_transcript_split_chunks');

    console.log('finalChunks', finalChunks, finalChunks.length);
  } catch (error: any) {
    throw new IncrementalError(error, 'handlecaptionProcessTranscript');
  }
};
