import {preload} from '@autocut/types/ElectronPreload';
import {randomColor} from '@autocut/utils/color.utils';
import {PickPartial} from '@autocut/utils/type.utils';

import {drawDebugRectangle} from '../canvas.utils';
import {CanvasCache} from './canvasCache.class.utils';

export type CanvasObject<ArgType = any> = {
  id: string;
  name: string;
  enabled: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  zIndex: number;
  draw: (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    arg?: ArgType,
  ) => void;
  drawDebug?: (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    arg?: ArgType,
  ) => void;
  customCacheCheck?: (arg?: ArgType) => boolean;
  debugColor?: string;
};

/**
 * Manage a canvas HTML element.
 */
export class Canvas {
  protected cache: CanvasCache = new CanvasCache();
  protected ctx: CanvasRenderingContext2D;

  //Debug part
  protected debug?: boolean;
  protected objects: CanvasObject[] = [];
  public canvasRef: HTMLCanvasElement;

  public id: string;

  /**
   * Creates a new instance of the Canvas class.
   * @param canvasRef - The reference to the HTML canvas element.
   * @param debug - Whether to enable debug mode.
   * @throws Error if the canvas element is not found.
   */
  constructor(canvasRef: React.RefObject<HTMLCanvasElement>, debug?: boolean) {
    const currentCanvas = canvasRef.current;
    this.debug = debug;

    this.id = preload().nodeCrypto.randomUUID();

    if (!currentCanvas) {
      throw new Error('Canvas not found');
    }

    this.canvasRef = canvasRef.current;

    this.ctx = this.canvasRef.getContext('2d') as CanvasRenderingContext2D;
  }

  /**
   * Adds a new object to the canvas.
   * @param newObject - The new object to add.
   * @returns An object containing the index and id of the newly added object.
   */
  public addObject<T extends CanvasObject = CanvasObject>(
    newObject: Omit<PickPartial<T, 'zIndex' | 'debugColor'>, 'id'>,
  ) {
    const id = preload().nodeCrypto.randomUUID();

    this.objects.push({
      id,
      zIndex: 1,
      debugColor: randomColor(),
      ...newObject,
    });

    const newIndex = this.objects.findIndex(
      object => object.id === id,
    ) as number;

    return {index: newIndex, id: id};
  }

  /**
   * Clears the canvas.
   */
  public clear() {
    this.ctx.clearRect(0, 0, this.canvasRef.width, this.canvasRef.height);
  }

  /**
   * Destroys the canvas by removing all objects.
   */
  destroy() {
    //Only for child classes
  }

  /**
   * Draws the objects on the canvas.
   * @param arg - Additional arguments for drawing.
   */
  public draw<C extends Canvas = Canvas>(
    arg?: any,
    options?: {
      additionnalDraw?: (canvas: C) => void;
    },
  ) {
    this.clear();

    const sortedObjects = [...this.objects].sort((a, b) => a.zIndex - b.zIndex);
    for (const object of sortedObjects) {
      if (!object.enabled) continue;

      const {canvas} = this.cache.get(object, this.canvasRef, arg);
      if (this.debug) {
        if (object.drawDebug) {
          object.drawDebug(this.ctx, object.x, object.y, arg);
        } else {
          drawDebugRectangle(
            this.ctx,
            object.x - object.width / 2,
            object.y - object.height / 2,
            object.width,
            object.height,
            object.debugColor,
          );
        }
      }
      this.ctx.drawImage(canvas, 0, 0);
    }

    options?.additionnalDraw?.(this as unknown as C);
  }

  /**
   * Gets all the objects on the canvas.
   * @returns An array of canvas objects.
   */
  public getObjects() {
    return this.objects;
  }

  /**
   * Destroys the canvas by removing all objects.
   */
  init() {
    //Only for child classes
  }

  /**
   * Sets the objects on the canvas.
   * @param objects - The new objects to set.
   * @returns An array of canvas objects.
   */
  public setObjects<T extends CanvasObject = CanvasObject>(
    objects: Omit<PickPartial<T, 'zIndex'>, 'id'>[],
  ) {
    this.objects = objects.map(obj => ({
      id: preload().nodeCrypto.randomUUID(),
      zIndex: 1,
      debugColor: randomColor(),
      ...obj,
    }));
    return this.objects;
  }

  /**
   * Updates an object on the canvas by its id.
   * @param objectId - The id of the object to update.
   * @param newObject - The new object properties to update.
   */
  public updateObjectById(
    objectId: string,
    newObject: Partial<Omit<CanvasObject, 'id'>>,
  ) {
    const objectToUpdate = this.objects.find(object => object.id === objectId);
    if (objectToUpdate) {
      Object.assign(objectToUpdate, newObject);
    }

    this.draw();
  }

  /**
   * Updates an object on the canvas by its index.
   * @param objectIndex - The index of the object to update.
   * @param newObject - The new object properties to update.
   */
  public updateObjectByIndex(
    objectIndex: number,
    newObject: Partial<Omit<CanvasObject, 'id'>>,
  ) {
    if (objectIndex >= 0 && objectIndex < this.objects.length) {
      const objectToUpdate = this.objects[objectIndex];
      Object.assign(objectToUpdate, newObject);
    }

    this.draw();
  }
}
