import logLevel from '@autocut/enums/logLevel.enum';
import {preload} from '@autocut/types/ElectronPreload';
import {IncrementalError} from '@autocut/utils/errors/IncrementalError';

import {logger} from './logger';

export const exec = async ({
  command,
  options = {},
}: {
  command: string;
  options?: {[key: string]: any};
  sentryData?: {
    fingerPrint: string;
    context?: {[key: string]: any};
  };
  disableSentryReport?: boolean;
}) => {
  try {
    logger('execUtils', logLevel.info, `Launching ${command}`);
    const {stdout, stderr} = (await preload()
      .childProcess.node_exec_async(command, options)
      .catch(error => {
        throw new IncrementalError(error, 'node_exec');
      })) || {stdout: '', stderr: ''};
    logger('execUtils', logLevel.info, `Command ${command} ended successfully`);

    return {stdout, stderr};
  } catch (error: any) {
    throw new IncrementalError(error, 'exec');
  }
};
