import {LoadingRing} from '@autocut/components/LoadingRing/LoadingRing';
import {Card} from '@autocut/designSystem/components/atoms/Card/Card';
import {CheckBox} from '@autocut/designSystem/components/atoms/CheckBox/CheckBox';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';
import {PlaybackChunkArgs} from '@autocut/hooks/usePlayback';
import {useState} from 'react';
import {FaPlay, FaStop} from 'react-icons/fa6';

import {ViralityScore} from '../ViralityScore/ViralityScore';
import css from './ViralClipItem.module.scss';

export type ItemProps = {
  viralClip: ViralClip;
  previewFrame?: string;
  onClick: () => void;
  playbackChunk: ({startSecond, endSecond, onEnd}: PlaybackChunkArgs) => void;
  stopPlaybackChunk: () => void;
};

export const Item = ({
  viralClip,
  previewFrame,
  onClick,
  playbackChunk,
  stopPlaybackChunk,
}: ItemProps) => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Card
      onClick={onClick}
      className={css.root}
    >
      <FlexContainer
        gap={Spacing.s2}
        alignItems="flex-start"
      >
        <FlexContainer
          flexDirection="column"
          gap={Spacing.s2}
        >
          {previewFrame ? (
            <FlexContainer
              className={css.previewFrame}
              onClick={async event => {
                event.stopPropagation();
                if (isPlaying) {
                  stopPlaybackChunk();
                } else {
                  setIsPlaying(true);
                  playbackChunk({
                    startSecond: viralClip.startSecondsRelative,
                    endSecond: viralClip.endSecondsRelative,
                    onEnd: () => {
                      setIsPlaying(false);
                    },
                  });
                }
              }}
            >
              <img src={previewFrame} />
              {isPlaying ? (
                <FaStop
                  size={16}
                  className={css.icon}
                ></FaStop>
              ) : (
                <FaPlay
                  size={16}
                  className={css.icon}
                />
              )}
            </FlexContainer>
          ) : (
            <FlexContainer
              className={css.loaderContainer}
              alignItems="center"
              justifyContent="center"
            >
              <LoadingRing size={32} />
            </FlexContainer>
          )}
          <ViralityScore
            score={viralClip.score}
            reason={viralClip.reason}
          />
        </FlexContainer>

        <FlexContainer
          className={css.rightContainer}
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <FlexContainer
            className={css.textContainer}
            flexDirection="column"
            justifyContent="space-between"
            gap={Spacing.s2}
          >
            <FlexContainer
              gap={Spacing.s1}
              alignItems="center"
            >
              <Card className={css.timecodeContainer}>
                <Text variant="textSm.semibold">{viralClip.timecodes[0]}</Text>
              </Card>
              <Text variant="textSm.semibold">-</Text>
              <Card className={css.timecodeContainer}>
                <Text variant="textSm.semibold">{viralClip.timecodes[1]}</Text>
              </Card>
            </FlexContainer>
            <FlexContainer
              flexDirection="column"
              gap={Spacing.s1}
            >
              <Text variant="textMd">{viralClip.title}</Text>
              <Text variant="textSm">{viralClip.text}</Text>
            </FlexContainer>
          </FlexContainer>
          <div
            style={{
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          >
            <CheckBox
              size={18}
              checked={!!viralClip.selected}
            />
          </div>
        </FlexContainer>
      </FlexContainer>
    </Card>
  );
};
