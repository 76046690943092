import Modal from '@autocut/components/ModalLayout/BaseModal';
import {TranslatedMessage} from '@autocut/components/TranslatedMessage/TranslatedMessage';
import {colors} from '@autocut/designSystem/colors';
import {Author} from '@autocut/designSystem/components/atoms/Author/Author';
import {getRandomAuthor} from '@autocut/designSystem/components/atoms/Author/utils';
import {Button} from '@autocut/designSystem/components/atoms/Button/Button';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import {FormSection} from '@autocut/designSystem/components/layout/FormSection/FormSection';
import {PaddedSection} from '@autocut/designSystem/components/layout/PaddedSection/PaddedSection';
import {FileWithPath} from '@autocut/designSystem/components/molecules/FileInput/FileInput';

import './BetaFeedbackModal.css';

import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {closeModal} from '@autocut/utils/modal/closeModal';
import {setAutocutStore} from '@autocut/utils/zustand';
import {useMemo, useState} from 'react';

import {BETA_FEEDBACK_MODAL_STEPS} from './betaFeedbackModalSteps';

export const BetaFeedbackModal = () => {
  const isBetaFeedbackOnboarded = useAutoCutStore(
    state => state.user.isBetaFeedbackOnboarded,
  );
  const openedModalName = useAutoCutStore(state => state.ui.openedModalName);
  console.log('openedModalName', openedModalName);
  const [activeStepIndex, setActiveStepIndex] = useState(
    isBetaFeedbackOnboarded ? 0 : -1,
  );

  const [form, setForm] = useState<{
    text: string;
    files: FileWithPath[];
  }>({
    text: '',
    files: [],
  });
  const [error, setError] = useState<React.ReactNode | string | null>(null);

  const currentStep = BETA_FEEDBACK_MODAL_STEPS[activeStepIndex + 1];
  const randomAuthor = useMemo(() => getRandomAuthor(), []);

  const onClose = () => {
    setAutocutStore('user.isBetaFeedbackOnboarded', true);
    closeModal();
  };

  const nextStep = async () => {
    if (currentStep.onSubmit) {
      await currentStep
        .onSubmit(form)
        .then(() => {
          setActiveStepIndex(prev => {
            return prev + 1;
          });
        })
        .catch((error: any) => {
          setError(
            <TranslatedMessage
              id={error.message}
              defaultMessage={error.message}
            />,
          );
          return;
        });
    } else {
      if (activeStepIndex === BETA_FEEDBACK_MODAL_STEPS.length - 2) onClose();

      setActiveStepIndex(prev => {
        return prev + 1;
      });
    }
  };

  return (
    <Modal
      title={currentStep.title}
      footer={
        <FlexContainer
          flexDirection="column"
          gap={4}
        >
          {error && <Text color={colors.error600}>{error}</Text>}
          <Button
            color={colors.primary600}
            onClick={nextStep}
          >
            {currentStep.footerLabel}
          </Button>
        </FlexContainer>
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={12}
      >
        <FormSection title={<Author author={randomAuthor} />}>
          <PaddedSection>
            <FlexContainer
              flexDirection="column"
              gap={8}
            >
              {currentStep.body({
                onTextChange: (text: string) => {
                  setError(null);
                  setForm(prev => ({...prev, text}));
                },
                onFilesChange: (files: FileWithPath[]) => {
                  setError(null);
                  setForm(prev => ({...prev, files}));
                },
              })}
            </FlexContainer>
          </PaddedSection>
        </FormSection>
      </FlexContainer>
    </Modal>
  );
};
