import {preload} from '@autocut/types/ElectronPreload';
import axios from 'axios';

import {getInstallResourceAtSpecificPathConfig} from '../downloadableRessources.utils';

export const getPproExtension = () =>
  getInstallResourceAtSpecificPathConfig({
    resourceName: 'ppro_extension',
    mac: {
      extractSubfolder: 'CEP/extensions/com.autocut.ppro',
      folderPath: preload().path.join(
        preload()
          .electron.getPath('appData')
          .replace(/\/AutoCut[^\/]*(\/)?$/, ''),
        'Adobe',
      ),
      downloadUrl: preload().path.joinUrl(
        preload().electron.getSharedVariables().PPRO_DOWNLOAD_URL,
        'latest',
      ),
    },
    windows: {
      extractSubfolder: 'CEP/extensions/com.autocut.ppro',
      folderPath: preload().path.join(
        preload().os.homedir(),
        'AppData',
        'Roaming',
        'Adobe',
      ),
      downloadUrl: preload().path.joinUrl(
        preload().electron.getSharedVariables().PPRO_DOWNLOAD_URL,
        'latest',
      ),
    },
    createFolderIfNotExist: false,
    checkIfUpToDate: async versionFileContent => {
      const response = await axios.get(
        preload().electron.getSharedVariables().DAVINCI_DOWNLOAD_URL +
          '/version',
      );
      const data = response.data;
      const outdated = data.toString() !== versionFileContent.toString();
      return outdated;
    },
  })();
