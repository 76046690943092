import {createContext, useEffect, useRef} from 'react';

interface IScrollContext {
  scroll: number;
  addOnScroll: (
    functionKey: string,
    callback: (scroll?: number) => void,
  ) => void;
  removeOnScroll: (functionKey: string) => void;
  scrollTo: (elementId: string) => void;
}

export const ScrollContext = createContext<IScrollContext>({
  scroll: 0,
  addOnScroll: () => {},
  removeOnScroll: () => {},
  scrollTo: () => {},
});

export const ScrollProvider = ({
  children,
  scrollValue,
  scrollElementRef,
}: {
  children: any;
  scrollValue: number;
  scrollElementRef: React.RefObject<HTMLDivElement>;
}) => {
  const onScrollFunctions = useRef<{[key: string]: (...args: any[]) => void}>(
    {},
  ).current;

  useEffect(() => {
    Object.values(onScrollFunctions).forEach(callback => callback(scroll));
  }, [scrollValue, onScrollFunctions]);

  const addOnScroll = (
    functionKey: string,
    callback: (scroll?: number) => void,
  ) => {
    onScrollFunctions[functionKey] = callback;
  };

  const removeOnScroll = (functionKey: string) => {
    delete onScrollFunctions[functionKey];
  };

  const scrollTo = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (!element) return;

    const x = 0;
    const y = element.offsetTop + 150;

    scrollElementRef.current?.scrollTo(x, y);
  };

  return (
    <ScrollContext.Provider
      value={{
        scroll: scrollValue,
        addOnScroll,
        removeOnScroll,
        scrollTo,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
