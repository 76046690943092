import {ModeFooter} from '@autocut/components/ModeFooter/ModeFooter';
import {ModeLayout} from '@autocut/components/ModeLayout/ModeLayout';
import {Divider} from '@autocut/designSystem/components/atoms/Divider/Divider';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {Spacing} from '@autocut/designSystem/enums/spacing.enum';

import {InOutStep} from './steps/InOutStep';
import {ReframeStep} from './steps/ReframeStep';
import {ResizeFooterButton} from './steps/ResizeFooterButton';
import {ResizePresetStep} from './steps/ResizePresetStep/ResizePresetStep';
import {WatermarkStep} from './steps/WatermarkStep';

export const ResizeCustomizationStep = () => {
  return (
    <ModeLayout
      footer={
        <ModeFooter
          renderButton={({
            buttonRef,
            isLoading,
            isDisabled,
            handleClickWithValidation,
          }) => (
            <ResizeFooterButton
              ref={buttonRef}
              isLoading={isLoading}
              isDisabled={isDisabled}
              handleClickWithValidation={handleClickWithValidation}
            />
          )}
        />
      }
    >
      <FlexContainer
        flexDirection="column"
        gap={Spacing.s4}
      >
        <InOutStep />
        <Divider />
        <ResizePresetStep />
        <Divider />
        <ReframeStep />
        <Divider />
        <WatermarkStep />
      </FlexContainer>
    </ModeLayout>
  );
};
