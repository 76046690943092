import loaderUrl from '@autocut/assets/images/LoadingInfinity.gif';
import * as React from 'react';
const loader = new URL(loaderUrl, import.meta.url).href;

interface LoaderProps {
  isLoading?: boolean;
  height: number;
}
export const LoaderInfinity = ({isLoading = true, height}: LoaderProps) => {
  return (
    <div style={{width: `${height}px`, height: `${height}px`}}>
      {isLoading ? (
        <img
          height={height}
          src={loader}
          alt="loader"
        />
      ) : null}
    </div>
  );
};
