import {TranslationKeys} from '@autocut/contexts/LocaleContext';
import {colors} from '@autocut/designSystem/colors';
import {Text} from '@autocut/designSystem/components/atoms/Text/Text';
import FlexContainer from '@autocut/designSystem/components/molecules/FlexContainer';
import {AutocutModeIds} from '@autocut/enums/modes.enum';
import {useAutoCutStore} from '@autocut/hooks/useAutoCutStore';
import {parametersValidationSchema} from '@autocut/validationSchemas/parameters.validationSchema';
import React, {useCallback, useEffect, useRef, useState} from 'react';

import {FooterLayout} from '../FooterLayout';
import {TranslatedMessage} from '../TranslatedMessage/TranslatedMessage';
import css from './ModeFooter.module.css';

export namespace ModeFooter {
  export type Props = {
    renderButton: ({
      buttonRef,
      isLoading,
      isDisabled,
      handleClickWithValidation,
    }: {
      buttonRef: React.RefObject<HTMLButtonElement>;
      isLoading: boolean;
      isDisabled: boolean;
      handleClickWithValidation: (baseHandleClick: () => Promise<void>) => any;
    }) => React.ReactElement;
    errorId?: string;
  };
  export type ErrorType = React.ReactNode | null;
}

export const ModeFooter = ({renderButton, errorId}: ModeFooter.Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const {isProcessing, mode, parameters} = useAutoCutStore(state => ({
    isProcessing: state.ui.process.isProcessing,
    mode: state.ui.process.mode,
    parameters: state.ui.parameters,
  }));

  const [validationError, setValidationError] =
    useState<ModeFooter.ErrorType>(null);

  const currentModeParameters = parameters[mode.id as AutocutModeIds];

  const validateParameters = useCallback<() => ModeFooter.ErrorType>(() => {
    const currentModeValidator =
      parametersValidationSchema[mode.id as AutocutModeIds];

    const error: string[] = [];

    try {
      currentModeValidator.parse(currentModeParameters);
    } catch (e: any) {
      const errorIds = [
        ...new Set(e.issues.map((issue: any) => issue.message)),
      ] as string[];

      error.push(...errorIds);
    }

    if (error.length === 0) return null;

    return <Error errorIds={error} />;
  }, [currentModeParameters, mode.id]);

  const handleClickWithValidation = useCallback(
    (originalHandleClick: () => any) => {
      const error = validateParameters();
      if (error) {
        setValidationError(error);
        return;
      }

      if (!isProcessing) {
        originalHandleClick();
      }
    },
    [isProcessing, validateParameters],
  );

  useEffect(() => {
    if (validationError) {
      setValidationError(null);
    }
  }, [currentModeParameters]);

  return (
    <FooterLayout>
      <FlexContainer
        className={css.container}
        flexDirection="column"
        gap="16px"
      >
        {validationError}
        {errorId && <Error errorIds={[errorId]} />}

        <div className={css.cutButton}>
          {renderButton({
            buttonRef,
            isLoading: isProcessing,
            isDisabled: isProcessing || validationError !== null,
            handleClickWithValidation: handleClickWithValidation,
          })}
        </div>
      </FlexContainer>
    </FooterLayout>
  );
};

const Error = ({errorIds}: {errorIds: string[]}) => {
  return (
    <FlexContainer
      flexDirection="column"
      gap="4px"
    >
      {errorIds.map(errorId => (
        <Text
          variant="textXs.medium"
          key={errorId}
          color={colors.error500}
        >
          <TranslatedMessage
            id={errorId as TranslationKeys}
            defaultMessage={errorId}
          />
        </Text>
      ))}
    </FlexContainer>
  );
};
