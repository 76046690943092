import {
  ResourceManagerKeys,
  resourcesConfigs,
} from '@autocut/enums/resources.enum';
import {LaunchStepHandler} from '@autocut/pages/LaunchManager/LaunchManager';
import {preload} from '@autocut/types/ElectronPreload';
import {ResourceManagerElementConfig} from '@autocut/types/ResourceManager';

import {IncrementalError} from '../errors/IncrementalError';

export const resourcesSetup: LaunchStepHandler = async setProgress => {
  const resources = resourcesConfigs();

  await setProgress?.(0);
  const initResources = Object.keys(resources).filter(
    key =>
      resources[key as ResourceManagerKeys]().scope === 'global' &&
      resources[key as ResourceManagerKeys]().requestOnInit,
  );
  return (
    await Promise.all(
      initResources.map(async (key, index) => {
        let finishedExistsCheck = false;
        let finishedDownload = false;
        try {
          const config = resources[
            key as ResourceManagerKeys
          ]() as ResourceManagerElementConfig;

          if (config.prepare) await config.prepare();

          const {isExist, outputFilePath} = await config.existCheck();
          finishedExistsCheck = true;
          if (isExist) return true;

          const filePath = await config.downloadMethod();
          finishedDownload = true;
          if (config.move) {
            if (!preload().fs.existsSync(filePath)) {
              throw new IncrementalError(
                `File not found: ${filePath}`,
                'resourcesSetup',
              );
            }
            await config.move(filePath);
          }

          return true;
        } catch (e) {
          console.error(
            `Error while ${finishedExistsCheck ? (finishedDownload ? 'moving' : 'downloading') : 'checking'} resources ${key}: ${JSON.stringify(e)}`,
          );
          return `Error while ${finishedExistsCheck ? (finishedDownload ? 'moving' : 'downloading') : 'checking'} resources ${key}: ${JSON.stringify(e)}`;
        } finally {
          await setProgress?.(((index + 1) * 100) / initResources.length);
        }
      }),
    )
  ).reduce(
    (acc, cur) =>
      typeof acc === 'string' ? acc : typeof cur === 'string' ? cur : true,
    true,
  );
};
