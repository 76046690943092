import {host} from '@autocut/utils/host';
import {useEffect, useState} from 'react';

import {useAutoCutStore} from './useAutoCutStore';

export const useCheckDeactivatedTracks = () => {
  const [trackNumbers, setTrackNumbers] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);
  const deactivatedTracks = useAutoCutStore(
    state => state.ui.deactivatedTrackIndexes,
  );

  useEffect(() => {
    const init = async () => {
      setTrackNumbers(await host.timeline.getTrackCount('audio'));
    };

    void init().finally(() => setIsInitialized(true));
  }, []);

  const allTracksDeactivated = deactivatedTracks.length === trackNumbers;

  return {
    isInitialized,
    allTracksDeactivated,
  };
};
