import {ScrollContext} from '@autocut/contexts/ScrollContext';
import {useContext} from 'react';

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (context === undefined) {
    throw new Error(
      'Please make sure you use this hook in the ScrollProvider component',
    );
  }

  return context;
};
